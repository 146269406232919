import { IconButton, ProgressPointBar, ScrollbarContainer, SidebarItem } from 'UI';
import { ManualTypeEnum, PaymentDtoLevelEnum, PaymentDtoTypeEnum } from 'api/generated';
import {
  BookFillIcon,
  CloseIcon,
  DonutChartIcon,
  FlashlightIcon,
  HomeIcon,
  LayoutBottomIcon,
  MenuIcon,
  TodoIcon,
  UserIcon,
} from 'assets/icons';
import { accessLevels } from 'constant';
import { MAXIMUM_COURSE_POINTS } from 'constant/maximumPoints';
import { routes } from 'constant/routes';
import { useClientSize, useToggle, useToggleScroll } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectors, useAppSelector } from 'store';
import styled from 'styled-components';
import { respondToHeight, respondToWidth } from 'styles/general/respondTo';
import { trackAmplitudeEvent } from 'utils/trackAmplitudeEvent';

import { NoStreakModal, StreakModal, UpgradeSubscriptionModal } from '../Modals';
import StreakFireButton from '../StreakFireButton';
import StudyThisButton from '../StydyThisButton';
import { PerformancePlug } from './components';

export type SubscriptionType = 'gold' | 'silver' | 'bronze';

type SidebarProps = {
  className?: string;
};

const Sidebar: FC<SidebarProps> = ({ className }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { getIsBreakpoint } = useClientSize();
  const { isOpen, close, open } = useToggle();
  const isHomePage = location?.pathname === '/home';

  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const direction = useAppSelector(selectors.direction.selectDirection);
  const coursePoints = useAppSelector(selectors.performance.selectCourseStatistic);
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  const paymentLevel = useAppSelector(selectors.courses.selectCurrentPaymentLevel);
  const paymentType = useAppSelector(selectors.courses.selectCurrentPaymentType);
  const streakCount = useAppSelector((store) => store.performance.courseStatistic?.streak);
  const hasNoStreak = !Number(streakCount);
  const isGoldPaymentOrTrial = paymentLevel === PaymentDtoLevelEnum.Gold || paymentType === PaymentDtoTypeEnum.Free;
  const points = coursePoints?.totalInPoints || 0;
  const logo = isDarkMode ? direction?.darkLogoUrl : direction?.logoUrl;

  const [isShowStreakModal, setIsShowStreakModal] = useState(false);

  const handleCloseStreakModal = () => {
    setIsShowStreakModal(false);
  };

  const handleOpenStreakModal = () => {
    setIsShowStreakModal(true);
  };

  const { hasPerformance } = activeCourse?.currentPayment.level
    ? accessLevels[activeCourse.currentPayment.level]
    : accessLevels.null;

  const isWidthSm = getIsBreakpoint('sm');

  useToggleScroll(isWidthSm && showSidebar);

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };
  const enableScroll = () => {
    document.body.style.overflow = 'auto';
  };

  useEffect(() => (showSidebar ? disableScroll() : enableScroll()), [showSidebar]);

  useEffect(() => {
    if (!isWidthSm) {
      enableScroll();
      setShowSidebar(false);
    }
  }, [isWidthSm]);

  const handleOpenProfilePage = () => {
    navigate(routes.profile);
  };

  return (
    <Root className={className} $isShow={showSidebar} $isHomePage={isHomePage}>
      <Header>
        <LogoContainer onClick={() => navigate(routes.home)}>
          <LogoImg src={logo} />
        </LogoContainer>
        <MenuContainer>
          {<StyledProfileButton iconComponent={<UserIcon />} onClick={handleOpenProfilePage} />}
          <IconWrapper>
            {isHomePage && isGoldPaymentOrTrial && <StyledStreakFireButton onClick={handleOpenStreakModal} />}
            {isHomePage && <StyledStudyThisButton />}
          </IconWrapper>
          <StyledButton onClick={() => setShowSidebar((prev) => !prev)}>
            {showSidebar ? <CloseIcon /> : <MenuIcon />}
          </StyledButton>
        </MenuContainer>
      </Header>
      <Wrapper $show={showSidebar}>
        <SidebarItemContainer>
          <StyledSidebarItem
            title="Home"
            iconComponent={<HomeIcon />}
            isSelect={location.pathname === routes.home}
            onClick={() => navigate(routes.home)}
          />
        </SidebarItemContainer>
        <SidebarItemContainer>
          <StyledSidebarItem
            title="Study guide"
            iconComponent={<BookFillIcon />}
            isSelect={location.pathname === routes.studyGuide}
            onClick={() => {
              navigate(routes.studyGuide);
              trackAmplitudeEvent('Study Guide Pressed');
            }}
          />
        </SidebarItemContainer>
        <SidebarItemContainer>
          <StyledSidebarItem
            title="Tests"
            iconComponent={<TodoIcon />}
            isSelect={location.pathname === routes.testList}
            onClick={() => {
              navigate(routes.testList);
              trackAmplitudeEvent('Tests Pressed');
            }}
          />
        </SidebarItemContainer>
        <SidebarItemContainer>
          <StyledSidebarItem
            title="Flashcards"
            iconComponent={<FlashlightIcon />}
            isSelect={location.pathname === routes.flashcardPacks}
            onClick={() => {
              navigate(routes.flashcardPacks);
              trackAmplitudeEvent('Flashcards Pressed');
            }}
          />
        </SidebarItemContainer>
        <SidebarItemContainer>
          <StyledSidebarItem
            title="Mnemonic cards"
            iconComponent={<LayoutBottomIcon />}
            isSelect={location.pathname === routes.mnemonicCardList}
            onClick={() => {
              navigate(routes.mnemonicCardList);
              trackAmplitudeEvent('Mnemonic Cards Pressed');
            }}
          />
        </SidebarItemContainer>
        {hasPerformance && (
          <SidebarItemContainer>
            <StyledSidebarItem
              title="Performance"
              iconComponent={<DonutChartIcon />}
              isSelect={location.pathname === routes.performance}
              onClick={() => {
                navigate(routes.performance);
                trackAmplitudeEvent('Performance Pressed');
              }}
            />
          </SidebarItemContainer>
        )}
        <ProgressBarContainer $hasPerformance={hasPerformance}>
          {activeCourse &&
            (hasPerformance ? (
              <ProgressPointBar points={points} total={MAXIMUM_COURSE_POINTS} />
            ) : (
              <PerformancePlug onClick={open} />
            ))}
        </ProgressBarContainer>
      </Wrapper>
      <UpgradeSubscriptionModal isOpen={isOpen} onClose={close} manualType={ManualTypeEnum.PerformanceUpgrade} />
      {isShowStreakModal && hasNoStreak ? (
        <NoStreakModal isModalVisible={isShowStreakModal} setModalVisible={handleCloseStreakModal} />
      ) : (
        <StreakModal isOpen={isShowStreakModal} onClose={handleCloseStreakModal} />
      )}
    </Root>
  );
};

export default Sidebar;

const Root = styled.div<{ $isShow: boolean; $isHomePage?: boolean }>`
  width: 148px;
  height: 100%;
  max-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 28px 12px 28px 20px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  align-self: start;

  ${respondToHeight.ls`
    padding: 5px 12px 5px 20px;
  `}

  ${respondToWidth.sm`
    width: 100%;
    height: 47px;

    z-index: 1001;

    padding: 5px 16px 0px 16px;
    align-items: start;
    justify-content: initial;
  `};

  ${({ $isHomePage }) => respondToWidth.s`
    height:${$isHomePage ? '90px' : 'unset'}
  `}
`;

const Wrapper = styled(ScrollbarContainer)<{ $show: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  ${({ $show, theme: { colors } }) => respondToWidth.sm`
    width: 100%;
    height: calc(100% - 47px);

    position: absolute;
    display:${$show ? 'flex' : 'none'};

    margin-top: 47px;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: 1000;

    background-color: ${colors.neutrals[11]};


    align-items: start;
    padding: 20px 16px;
  `}
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  cursor: pointer;
  padding-right: 8px;

  svg {
    width: 68px;
    height: 32px;
  }

  ${respondToWidth.sm`
    width:68px;
    height: 32px;
    padding:0;
    margin:0;

    ${respondToHeight.lg`
    margin-bottom:0;
  `}
  `}
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${respondToWidth.sm`
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
  `}
`;

const SidebarItemContainer = styled.div`
  width: 100%;

  ${({ theme: { colors } }) => respondToWidth.sm`
  border-top: 1px solid ${colors.neutrals[9]};
  &:first-of-type {
    border: none;
  }
  `}
`;

const IconWrapper = styled.div`
  display: flex;

  ${respondToWidth.s`
    position: absolute;
    bottom: -42px;
    right: -18px;
  `}
`;

const StyledSidebarItem = styled(SidebarItem)`
  ${respondToHeight.sm`
    padding: 10px 0;
  `}

  ${respondToWidth.sm`
    width:100%;
    padding: 20px 0;
  `}
`;

const ProgressBarContainer = styled.div<{ $hasPerformance: boolean }>`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledButton = styled.button`
  display: none;
  background-color: inherit;
  outline: none;
  border: none;
  padding: 0;
  height: 32px;

  ${respondToWidth.sm`
  display:block;
`}
`;

const LogoImg = styled.img`
  max-width: 100%;
  max-height: 40px;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${respondToWidth.s`
  position: relative;`}
`;

const StyledStudyThisButton = styled(StudyThisButton)`
  display: none;
  width: 137px;
  margin-right: 8px;

  ${respondToWidth.sm`
    display: flex !important;
  `}
`;

const StyledStreakFireButton = styled(StreakFireButton)`
  display: none !important;
  margin-right: 8px;

  ${respondToWidth.sm`
    display: flex !important;
  `}
`;

const StyledProfileButton = styled(IconButton)`
  max-height: 36px;
  display: none !important;
  margin-right: 8px;

  ${respondToWidth.sm`
    display: flex !important;
  `}
`;
