import { PasswordInput } from 'UI';
import { SpamIcon } from 'assets/icons';
import { CreatePasswordModal, ForgotPasswordModal } from 'components';
import { regexp } from 'constant/regexp';
import useAsyncAction from 'hooks/useAsyncAction';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { actions, selectors, useAppSelector } from 'store';
import { useTheme } from 'styled-components';

import { Header } from './components';
import {
  Container,
  Cover,
  Description,
  ErrorField,
  Form,
  IconContainer,
  Link,
  Main,
  StyledButton,
  StyledInput,
  StyledTextButton,
  Text,
  TextContainer,
  Title,
  Transfer,
  Wrapper,
} from './styled';

type FormType = {
  email: string;
  password: string;
};

const SignIn = () => {
  const { colors } = useTheme();
  const [isShowForgotPasswordModal, setIsShowForgotPasswordModal] = useState(false);
  const [isShowCreatePasswordModal, setIsShowCreatePasswordModal] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [signIn, isLoading] = useAsyncAction(actions.auth.signIn);
  const { control, handleSubmit } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: { email: '', password: '' },
  });
  const direction = useAppSelector(selectors.direction.selectDirection);
  const { getEventUserDataGA } = useGoogleAnalytics();

  const onSubmit = async (data: FormType) => {
    try {
      const res = await signIn({
        ...data,
        // staging direction (to connect with local build)
        // directionId: 'ff9c663b-19ea-491d-a1af-d823a6845831',
        // directionId: 'da57d06c-d744-43ba-bb74-df4e4d02b8a9',
        directionId: direction?.id || '',
      });
      getEventUserDataGA({ email: data.email });
      localStorage.setItem('hasSeenOnboarding', `${res.customer.isFirstLogin}`);
    } catch {
      setIsShowError(true);
    }
  };

  const onBackIconClick = () => {
    setIsShowCreatePasswordModal(false);
    setIsShowForgotPasswordModal(true);
  };

  return (
    <Container>
      <Header />
      <Main>
        <Wrapper>
          <TextContainer>
            <Title $hasError={isShowError}>Sign in</Title>
            {isShowError && (
              <ErrorField>
                <IconContainer>
                  <SpamIcon color={colors.system.red} />
                </IconContainer>
                <Text>
                  We couldn’t find an account matching the email and password
                  <Transfer>{'\n'}</Transfer> you entered. Please check your email and password
                </Text>
              </ErrorField>
            )}
          </TextContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: true,
                  pattern: {
                    value: regexp.emailRegExp,
                    message: 'Incorrect email',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <StyledInput
                    type="email"
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value.trim())}
                    label="Email"
                    error={error?.message}
                    maxLength={155}
                  />
                )}
              />
              <Cover>
                <Controller
                  control={control}
                  name="password"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <PasswordInput
                      iconButtonType="button"
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value.trim())}
                      label="Password"
                    />
                  )}
                />
                <StyledTextButton
                  type="button"
                  text="Forgot password?"
                  onClick={() => setIsShowForgotPasswordModal(true)}
                />
              </Cover>
            </div>
            <StyledButton variant="primary" type="submit" isLoading={isLoading}>
              Sign in
            </StyledButton>
          </Form>
          <Description>
            To create an account and buy the desired course or get a trial version, go to our{' '}
            <Link href={direction?.landingUrl}>website</Link>
          </Description>
        </Wrapper>
      </Main>
      <ForgotPasswordModal
        isOpen={isShowForgotPasswordModal}
        onClose={() => setIsShowForgotPasswordModal(false)}
        goBack={() => setIsShowForgotPasswordModal(false)}
        openCreatePasswordModal={() => setIsShowCreatePasswordModal(true)}
      />
      <CreatePasswordModal
        isOpen={isShowCreatePasswordModal}
        onClose={() => setIsShowCreatePasswordModal(false)}
        onBackIconClick={onBackIconClick}
      />
    </Container>
  );
};

export default SignIn;
