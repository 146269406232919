// import { CloseIcon } from 'assets/icons';
import { FC } from 'react';
import styled from 'styled-components';

// import { respondToWidth } from 'styles/general/respondTo';

interface StepInfoProps {
  title: string;
  description: string;
}

const bulletPoints = ['Exam Pass Guarantee', 'Exam Simulator', 'Interactive Study Guide', 'Flashcard System'];

const StepInfo: FC<StepInfoProps> = ({ title, description }) => {
  return (
    <div>
      <StepTitle>{title}</StepTitle>
      <StepDescription>{description}</StepDescription>
      <StepTitleList>Upgrade to get access:</StepTitleList>
      <Container>
        {bulletPoints.map((text, index) => (
          <BulletPoint key={index}>{text}</BulletPoint>
        ))}
      </Container>
    </div>
  );
};

export default StepInfo;

const StepTitle = styled.h3`
  margin-top: -55px;

  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.2px;
`;

const StepTitleList = styled.p`
  margin-bottom: 12px;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

const StepDescription = styled.p`
  margin-top: 12px;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BulletPoint = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #333;
  line-height: 28px;
  padding-left: 16px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 8px;
    height: 8px;
    background-color: #cc6a44;
    border-radius: 50%;
    transform: translateY(-50%);
  }
`;
