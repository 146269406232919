/* tslint:disable */
/* eslint-disable */
/**
 * LMS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddExamDateDto
 */
export interface AddExamDateDto {
    /**
     * 
     * @type {string}
     * @memberof AddExamDateDto
     */
    'examDate': string;
}
/**
 * 
 * @export
 * @interface AddFreeAccessDto
 */
export interface AddFreeAccessDto {
    /**
     * 
     * @type {string}
     * @memberof AddFreeAccessDto
     */
    'billingType': AddFreeAccessDtoBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AddFreeAccessDto
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof AddFreeAccessDto
     */
    'courseId': string;
}

export const AddFreeAccessDtoBillingTypeEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type AddFreeAccessDtoBillingTypeEnum = typeof AddFreeAccessDtoBillingTypeEnum[keyof typeof AddFreeAccessDtoBillingTypeEnum];

/**
 * 
 * @export
 * @interface AddSuggestionRequestDto
 */
export interface AddSuggestionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AddSuggestionRequestDto
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface AddTextBookRequestDto
 */
export interface AddTextBookRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AddTextBookRequestDto
     */
    'textbookUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface Admin
 */
export interface Admin {
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'role': AdminRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'expiresInGeneratingPassword'?: string | null;
    /**
     * 
     * @type {AdminPasswordChanges}
     * @memberof Admin
     */
    'changedPassword'?: AdminPasswordChanges;
    /**
     * 
     * @type {Array<Direction>}
     * @memberof Admin
     */
    'directions': Array<Direction>;
}

export const AdminRoleEnum = {
    Admin: 'admin',
    Superadmin: 'superadmin'
} as const;

export type AdminRoleEnum = typeof AdminRoleEnum[keyof typeof AdminRoleEnum];

/**
 * 
 * @export
 * @interface AdminAuthResponseDto
 */
export interface AdminAuthResponseDto {
    /**
     * JWT token
     * @type {string}
     * @memberof AdminAuthResponseDto
     */
    'token': string;
    /**
     * 
     * @type {AdminAuthResponseDtoAdmin}
     * @memberof AdminAuthResponseDto
     */
    'admin': AdminAuthResponseDtoAdmin;
}
/**
 * Admin info
 * @export
 * @interface AdminAuthResponseDtoAdmin
 */
export interface AdminAuthResponseDtoAdmin {
    /**
     * Admin id
     * @type {string}
     * @memberof AdminAuthResponseDtoAdmin
     */
    'id': string;
    /**
     * Admin email
     * @type {string}
     * @memberof AdminAuthResponseDtoAdmin
     */
    'email': string;
    /**
     * Admin nickname
     * @type {string}
     * @memberof AdminAuthResponseDtoAdmin
     */
    'nickname': string;
    /**
     * Admin first name
     * @type {string}
     * @memberof AdminAuthResponseDtoAdmin
     */
    'firstName': string;
    /**
     * Admin last name
     * @type {string}
     * @memberof AdminAuthResponseDtoAdmin
     */
    'lastName': string;
    /**
     * Admin role
     * @type {string}
     * @memberof AdminAuthResponseDtoAdmin
     */
    'role': AdminAuthResponseDtoAdminRoleEnum;
    /**
     * 
     * @type {Array<AdminDirectionDto>}
     * @memberof AdminAuthResponseDtoAdmin
     */
    'directions'?: Array<AdminDirectionDto>;
}

export const AdminAuthResponseDtoAdminRoleEnum = {
    Admin: 'admin',
    Superadmin: 'superadmin'
} as const;

export type AdminAuthResponseDtoAdminRoleEnum = typeof AdminAuthResponseDtoAdminRoleEnum[keyof typeof AdminAuthResponseDtoAdminRoleEnum];

/**
 * 
 * @export
 * @interface AdminBaseManualDto
 */
export interface AdminBaseManualDto {
    /**
     * 
     * @type {string}
     * @memberof AdminBaseManualDto
     */
    'type': AdminBaseManualDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminBaseManualDto
     */
    'title': string;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminBaseManualDto
     */
    'content': AdminCreateInstructionManualDtoContent;
}

export const AdminBaseManualDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminBaseManualDtoTypeEnum = typeof AdminBaseManualDtoTypeEnum[keyof typeof AdminBaseManualDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminCancellationPromotionTermsRequestDto
 */
export interface AdminCancellationPromotionTermsRequestDto {
    /**
     * 
     * @type {number}
     * @memberof AdminCancellationPromotionTermsRequestDto
     */
    'discount': number;
    /**
     * 
     * @type {string}
     * @memberof AdminCancellationPromotionTermsRequestDto
     */
    'discountType': AdminCancellationPromotionTermsRequestDtoDiscountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCancellationPromotionTermsRequestDto
     */
    'duration': AdminCancellationPromotionTermsRequestDtoDurationEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminCancellationPromotionTermsRequestDto
     */
    'durationInMonths'?: number;
}

export const AdminCancellationPromotionTermsRequestDtoDiscountTypeEnum = {
    Percentage: 'percentage',
    Amount: 'amount'
} as const;

export type AdminCancellationPromotionTermsRequestDtoDiscountTypeEnum = typeof AdminCancellationPromotionTermsRequestDtoDiscountTypeEnum[keyof typeof AdminCancellationPromotionTermsRequestDtoDiscountTypeEnum];
export const AdminCancellationPromotionTermsRequestDtoDurationEnum = {
    Once: 'once',
    Forever: 'forever',
    Repeating: 'repeating'
} as const;

export type AdminCancellationPromotionTermsRequestDtoDurationEnum = typeof AdminCancellationPromotionTermsRequestDtoDurationEnum[keyof typeof AdminCancellationPromotionTermsRequestDtoDurationEnum];

/**
 * 
 * @export
 * @interface AdminCourseDto
 */
export interface AdminCourseDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDto
     */
    'releasedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDto
     */
    'leastKnownCategory': AdminCourseDtoLeastKnownCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDto
     */
    'domainButtonName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDto
     */
    'chapterButtonName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDto
     */
    'type': AdminCourseDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDto
     */
    'domainStatsButtonName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDto
     */
    'chapterStatsButtonName': string;
    /**
     * 
     * @type {Array<AdminCourseDtoManualsInner>}
     * @memberof AdminCourseDto
     */
    'manuals': Array<AdminCourseDtoManualsInner>;
    /**
     * 
     * @type {AdminCoursePricesDto}
     * @memberof AdminCourseDto
     */
    'prices': AdminCoursePricesDto;
    /**
     * 
     * @type {Array<AdminResponseDto>}
     * @memberof AdminCourseDto
     */
    'watchers': Array<AdminResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseDto
     */
    'activeCampaignListId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseDto
     */
    'assignmentsWeight': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseDto
     */
    'flashcardsWeight': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseDto
     */
    'testTopicsWeight': number;
}

export const AdminCourseDtoLeastKnownCategoryEnum = {
    Domain: 'domain',
    Chapter: 'chapter'
} as const;

export type AdminCourseDtoLeastKnownCategoryEnum = typeof AdminCourseDtoLeastKnownCategoryEnum[keyof typeof AdminCourseDtoLeastKnownCategoryEnum];
export const AdminCourseDtoTypeEnum = {
    Default: 'default',
    Certificate: 'certificate'
} as const;

export type AdminCourseDtoTypeEnum = typeof AdminCourseDtoTypeEnum[keyof typeof AdminCourseDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminCourseDtoManualsInner
 */
export interface AdminCourseDtoManualsInner {
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDtoManualsInner
     */
    'type': AdminCourseDtoManualsInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDtoManualsInner
     */
    'title': string;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCourseDtoManualsInner
     */
    'content': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCourseDtoManualsInner
     */
    'yes': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCourseDtoManualsInner
     */
    'no': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCourseDtoManualsInner
     */
    'kinda': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCourseDtoManualsInner
     */
    'purchase': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCourseDtoManualsInner
     */
    'monthlySubscriptions': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCourseDtoManualsInner
     */
    'annualSubscriptions': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCourseDtoManualsInner
     */
    'mostEffective': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseDtoManualsInner
     */
    'buttonText': string;
}

export const AdminCourseDtoManualsInnerTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminCourseDtoManualsInnerTypeEnum = typeof AdminCourseDtoManualsInnerTypeEnum[keyof typeof AdminCourseDtoManualsInnerTypeEnum];

/**
 * 
 * @export
 * @interface AdminCoursePricesDto
 */
export interface AdminCoursePricesDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCoursePricesDto
     */
    'id': string;
    /**
     * 
     * @type {AdminPurchasePricesDto}
     * @memberof AdminCoursePricesDto
     */
    'installment'?: AdminPurchasePricesDto;
    /**
     * 
     * @type {AdminPurchasePricesDto}
     * @memberof AdminCoursePricesDto
     */
    'purchase'?: AdminPurchasePricesDto;
    /**
     * 
     * @type {AdminSubscriptionPricesDto}
     * @memberof AdminCoursePricesDto
     */
    'monthlySubscriptions'?: AdminSubscriptionPricesDto;
    /**
     * 
     * @type {AdminSubscriptionPricesDto}
     * @memberof AdminCoursePricesDto
     */
    'annualSubscriptions'?: AdminSubscriptionPricesDto;
}
/**
 * 
 * @export
 * @interface AdminCreateCouponDto
 */
export interface AdminCreateCouponDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCouponDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCouponDto
     */
    'directionId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCouponDto
     */
    'promoCode': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCouponDto
     */
    'discountType': AdminCreateCouponDtoDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateCouponDto
     */
    'discountAmount': number;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCouponDto
     */
    'subscriptionDurationType'?: AdminCreateCouponDtoSubscriptionDurationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateCouponDto
     */
    'subscriptionDuration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateCouponDto
     */
    'maxRedemptions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCouponDto
     */
    'redeemBy'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateCouponDto
     */
    'allowedPeriods': Array<AdminCreateCouponDtoAllowedPeriodsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateCouponDto
     */
    'allowedPlans'?: Array<AdminCreateCouponDtoAllowedPlansEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateCouponDto
     */
    'allowedCoursesIds'?: Array<string>;
}

export const AdminCreateCouponDtoDiscountTypeEnum = {
    Percentage: 'percentage',
    Amount: 'amount'
} as const;

export type AdminCreateCouponDtoDiscountTypeEnum = typeof AdminCreateCouponDtoDiscountTypeEnum[keyof typeof AdminCreateCouponDtoDiscountTypeEnum];
export const AdminCreateCouponDtoSubscriptionDurationTypeEnum = {
    Once: 'once',
    Forever: 'forever',
    Repeating: 'repeating'
} as const;

export type AdminCreateCouponDtoSubscriptionDurationTypeEnum = typeof AdminCreateCouponDtoSubscriptionDurationTypeEnum[keyof typeof AdminCreateCouponDtoSubscriptionDurationTypeEnum];
export const AdminCreateCouponDtoAllowedPeriodsEnum = {
    Monthly: 'monthly',
    Annual: 'annual',
    Lifetime: 'lifetime',
    Installment: 'installment',
    InstallmentFirstMonth: 'installment_first_month'
} as const;

export type AdminCreateCouponDtoAllowedPeriodsEnum = typeof AdminCreateCouponDtoAllowedPeriodsEnum[keyof typeof AdminCreateCouponDtoAllowedPeriodsEnum];
export const AdminCreateCouponDtoAllowedPlansEnum = {
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type AdminCreateCouponDtoAllowedPlansEnum = typeof AdminCreateCouponDtoAllowedPlansEnum[keyof typeof AdminCreateCouponDtoAllowedPlansEnum];

/**
 * 
 * @export
 * @interface AdminCreateCourseDto
 */
export interface AdminCreateCourseDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseDto
     */
    'type': AdminCreateCourseDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateCourseDto
     */
    'activeCampaignListId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseDto
     */
    'leastKnownCategory': AdminCreateCourseDtoLeastKnownCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseDto
     */
    'directionId': string;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateCourseDto
     */
    'assignmentsWeight': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateCourseDto
     */
    'flashcardsWeight': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateCourseDto
     */
    'testTopicsWeight': number;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseDto
     */
    'domainButtonName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseDto
     */
    'chapterButtonName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseDto
     */
    'domainStatsButtonName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseDto
     */
    'chapterStatsButtonName'?: string;
}

export const AdminCreateCourseDtoTypeEnum = {
    Default: 'default',
    Certificate: 'certificate'
} as const;

export type AdminCreateCourseDtoTypeEnum = typeof AdminCreateCourseDtoTypeEnum[keyof typeof AdminCreateCourseDtoTypeEnum];
export const AdminCreateCourseDtoLeastKnownCategoryEnum = {
    Domain: 'domain',
    Chapter: 'chapter'
} as const;

export type AdminCreateCourseDtoLeastKnownCategoryEnum = typeof AdminCreateCourseDtoLeastKnownCategoryEnum[keyof typeof AdminCreateCourseDtoLeastKnownCategoryEnum];

/**
 * 
 * @export
 * @interface AdminCreateCoursePricesDto
 */
export interface AdminCreateCoursePricesDto {
    /**
     * 
     * @type {AdminCreateCoursePurchasePriceDto}
     * @memberof AdminCreateCoursePricesDto
     */
    'installment'?: AdminCreateCoursePurchasePriceDto;
    /**
     * 
     * @type {AdminCreateCoursePurchasePriceDto}
     * @memberof AdminCreateCoursePricesDto
     */
    'purchase'?: AdminCreateCoursePurchasePriceDto;
    /**
     * 
     * @type {AdminCreateCourseSubscriptionPriceDto}
     * @memberof AdminCreateCoursePricesDto
     */
    'monthlySubscriptions'?: AdminCreateCourseSubscriptionPriceDto;
    /**
     * 
     * @type {AdminCreateCourseSubscriptionPriceDto}
     * @memberof AdminCreateCoursePricesDto
     */
    'annualSubscriptions'?: AdminCreateCourseSubscriptionPriceDto;
}
/**
 * 
 * @export
 * @interface AdminCreateCoursePurchasePriceDto
 */
export interface AdminCreateCoursePurchasePriceDto {
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDto}
     * @memberof AdminCreateCoursePurchasePriceDto
     */
    'gold': AdminCreateCourseSinglePriceDto;
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDto}
     * @memberof AdminCreateCoursePurchasePriceDto
     */
    'silver': AdminCreateCourseSinglePriceDto;
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDto}
     * @memberof AdminCreateCoursePurchasePriceDto
     */
    'bronze': AdminCreateCourseSinglePriceDto;
}
/**
 * 
 * @export
 * @interface AdminCreateCourseSinglePriceDto
 */
export interface AdminCreateCourseSinglePriceDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseSinglePriceDto
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateCourseSinglePriceDto
     * @deprecated
     */
    'oldAmount'?: number;
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDtoCancellationPromotionTerms}
     * @memberof AdminCreateCourseSinglePriceDto
     */
    'cancellationPromotionTerms'?: AdminCreateCourseSinglePriceDtoCancellationPromotionTerms | null;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateCourseSinglePriceDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseSinglePriceDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseSinglePriceDto
     */
    'defaultCouponId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseSinglePriceDto
     */
    'firstMonthDiscountCouponId'?: string;
}
/**
 * 
 * @export
 * @interface AdminCreateCourseSinglePriceDtoCancellationPromotionTerms
 */
export interface AdminCreateCourseSinglePriceDtoCancellationPromotionTerms {
    /**
     * 
     * @type {number}
     * @memberof AdminCreateCourseSinglePriceDtoCancellationPromotionTerms
     */
    'discount': number;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseSinglePriceDtoCancellationPromotionTerms
     */
    'discountType': AdminCreateCourseSinglePriceDtoCancellationPromotionTermsDiscountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCourseSinglePriceDtoCancellationPromotionTerms
     */
    'duration': AdminCreateCourseSinglePriceDtoCancellationPromotionTermsDurationEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateCourseSinglePriceDtoCancellationPromotionTerms
     */
    'durationInMonths'?: number;
}

export const AdminCreateCourseSinglePriceDtoCancellationPromotionTermsDiscountTypeEnum = {
    Percentage: 'percentage',
    Amount: 'amount'
} as const;

export type AdminCreateCourseSinglePriceDtoCancellationPromotionTermsDiscountTypeEnum = typeof AdminCreateCourseSinglePriceDtoCancellationPromotionTermsDiscountTypeEnum[keyof typeof AdminCreateCourseSinglePriceDtoCancellationPromotionTermsDiscountTypeEnum];
export const AdminCreateCourseSinglePriceDtoCancellationPromotionTermsDurationEnum = {
    Once: 'once',
    Forever: 'forever',
    Repeating: 'repeating'
} as const;

export type AdminCreateCourseSinglePriceDtoCancellationPromotionTermsDurationEnum = typeof AdminCreateCourseSinglePriceDtoCancellationPromotionTermsDurationEnum[keyof typeof AdminCreateCourseSinglePriceDtoCancellationPromotionTermsDurationEnum];

/**
 * 
 * @export
 * @interface AdminCreateCourseSubscriptionPriceDto
 */
export interface AdminCreateCourseSubscriptionPriceDto {
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDto}
     * @memberof AdminCreateCourseSubscriptionPriceDto
     */
    'gold': AdminCreateCourseSinglePriceDto;
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDto}
     * @memberof AdminCreateCourseSubscriptionPriceDto
     */
    'silver': AdminCreateCourseSinglePriceDto;
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDto}
     * @memberof AdminCreateCourseSubscriptionPriceDto
     */
    'bronze': AdminCreateCourseSinglePriceDto;
}
/**
 * 
 * @export
 * @interface AdminCreateFlashcardDto
 */
export interface AdminCreateFlashcardDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateFlashcardDto
     */
    'question'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateFlashcardDto
     */
    'answer'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateFlashcardDto
     */
    'questionImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateFlashcardDto
     */
    'answerImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateFlashcardDto
     */
    'packId': string;
}
/**
 * 
 * @export
 * @interface AdminCreateFlashcardsManualDto
 */
export interface AdminCreateFlashcardsManualDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateFlashcardsManualDto
     */
    'type': AdminCreateFlashcardsManualDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateFlashcardsManualDto
     */
    'title': string;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCreateFlashcardsManualDto
     */
    'yes': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCreateFlashcardsManualDto
     */
    'no': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCreateFlashcardsManualDto
     */
    'kinda': AdminCreateInstructionManualDtoContent;
}

export const AdminCreateFlashcardsManualDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminCreateFlashcardsManualDtoTypeEnum = typeof AdminCreateFlashcardsManualDtoTypeEnum[keyof typeof AdminCreateFlashcardsManualDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminCreateFlashcardsPackDto
 */
export interface AdminCreateFlashcardsPackDto {
    /**
     * Entity in front of which you want to insert.    The Get many query returns the data in a specific order.    For example you had      1. entity with id \"q\"     2. entity with id \"w\"     3. entity with id \"e\"        You need to insert so that the new entity is before the \"w\". You pass in the field \"before\" \"w\".      1. pack with id \"q\"     2. pack with id \"r\"     3. pack with id \"w\"     4. pack with id \"e\"    To insert at the end, don\'t pass the \"before\" field   
     * @type {string}
     * @memberof AdminCreateFlashcardsPackDto
     */
    'before'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateFlashcardsPackDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateFlashcardsPackDto
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface AdminCreateInstructionManualDto
 */
export interface AdminCreateInstructionManualDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateInstructionManualDto
     */
    'type': AdminCreateInstructionManualDtoTypeEnum;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCreateInstructionManualDto
     */
    'content': AdminCreateInstructionManualDtoContent;
}

export const AdminCreateInstructionManualDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminCreateInstructionManualDtoTypeEnum = typeof AdminCreateInstructionManualDtoTypeEnum[keyof typeof AdminCreateInstructionManualDtoTypeEnum];

/**
 * @type AdminCreateInstructionManualDtoContent
 * @export
 */
export type AdminCreateInstructionManualDtoContent = object | string;

/**
 * 
 * @export
 * @interface AdminCreateManyFlashcardsDto
 */
export interface AdminCreateManyFlashcardsDto {
    /**
     * 
     * @type {Array<AdminCreateFlashcardDto>}
     * @memberof AdminCreateManyFlashcardsDto
     */
    'flashcards': Array<AdminCreateFlashcardDto>;
}
/**
 * 
 * @export
 * @interface AdminCreateNewQuestionRequestDto
 */
export interface AdminCreateNewQuestionRequestDto {
    /**
     * Domain id
     * @type {string}
     * @memberof AdminCreateNewQuestionRequestDto
     */
    'domainId'?: string;
    /**
     * Chapter id
     * @type {string}
     * @memberof AdminCreateNewQuestionRequestDto
     */
    'chapterId'?: string;
    /**
     * Question subject
     * @type {string}
     * @memberof AdminCreateNewQuestionRequestDto
     */
    'subject': string;
    /**
     * Right answer index
     * @type {number}
     * @memberof AdminCreateNewQuestionRequestDto
     */
    'rightAnswerIndex': number;
    /**
     * Question options
     * @type {Array<string>}
     * @memberof AdminCreateNewQuestionRequestDto
     */
    'options': Array<string>;
    /**
     * Question explanation text
     * @type {string}
     * @memberof AdminCreateNewQuestionRequestDto
     */
    'explanationText'?: string | null;
    /**
     * Question explanation image url
     * @type {string}
     * @memberof AdminCreateNewQuestionRequestDto
     */
    'explanationImageUrl'?: string | null;
    /**
     * Question hint
     * @type {string}
     * @memberof AdminCreateNewQuestionRequestDto
     */
    'hint'?: string;
    /**
     * Question images urls
     * @type {Array<string>}
     * @memberof AdminCreateNewQuestionRequestDto
     */
    'imagesUrls': Array<string>;
    /**
     * Question available for free
     * @type {boolean}
     * @memberof AdminCreateNewQuestionRequestDto
     */
    'availableForFree': boolean;
}
/**
 * 
 * @export
 * @interface AdminCreatePaymentDiscountsInfoDto
 */
export interface AdminCreatePaymentDiscountsInfoDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreatePaymentDiscountsInfoDto
     */
    'type': AdminCreatePaymentDiscountsInfoDtoTypeEnum;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCreatePaymentDiscountsInfoDto
     */
    'purchase': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCreatePaymentDiscountsInfoDto
     */
    'monthlySubscriptions': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCreatePaymentDiscountsInfoDto
     */
    'annualSubscriptions': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCreatePaymentDiscountsInfoDto
     */
    'mostEffective': AdminCreateInstructionManualDtoContent;
}

export const AdminCreatePaymentDiscountsInfoDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminCreatePaymentDiscountsInfoDtoTypeEnum = typeof AdminCreatePaymentDiscountsInfoDtoTypeEnum[keyof typeof AdminCreatePaymentDiscountsInfoDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminCreatePlanPhaseDto
 */
export interface AdminCreatePlanPhaseDto {
    /**
     * Entity in front of which you want to insert.    The Get many query returns the data in a specific order.    For example you had      1. entity with id \"q\"     2. entity with id \"w\"     3. entity with id \"e\"        You need to insert so that the new entity is before the \"w\". You pass in the field \"before\" \"w\".      1. pack with id \"q\"     2. pack with id \"r\"     3. pack with id \"w\"     4. pack with id \"e\"    To insert at the end, don\'t pass the \"before\" field   
     * @type {string}
     * @memberof AdminCreatePlanPhaseDto
     */
    'before'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreatePlanPhaseDto
     */
    'planId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreatePlanPhaseDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AdminCreatePlanPhaseTaskDto
 */
export interface AdminCreatePlanPhaseTaskDto {
    /**
     * Entity in front of which you want to insert.    The Get many query returns the data in a specific order.    For example you had      1. entity with id \"q\"     2. entity with id \"w\"     3. entity with id \"e\"        You need to insert so that the new entity is before the \"w\". You pass in the field \"before\" \"w\".      1. pack with id \"q\"     2. pack with id \"r\"     3. pack with id \"w\"     4. pack with id \"e\"    To insert at the end, don\'t pass the \"before\" field   
     * @type {string}
     * @memberof AdminCreatePlanPhaseTaskDto
     */
    'before'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreatePlanPhaseTaskDto
     */
    'planPhaseId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreatePlanPhaseTaskDto
     */
    'taskId': string;
}
/**
 * 
 * @export
 * @interface AdminCreateQuestionChapterRequestDto
 */
export interface AdminCreateQuestionChapterRequestDto {
    /**
     * Course id
     * @type {string}
     * @memberof AdminCreateQuestionChapterRequestDto
     */
    'courseId': string;
    /**
     * Chapter title
     * @type {string}
     * @memberof AdminCreateQuestionChapterRequestDto
     */
    'title': string;
    /**
     * Chapter sort index
     * @type {number}
     * @memberof AdminCreateQuestionChapterRequestDto
     */
    'sortIndex'?: number;
}
/**
 * 
 * @export
 * @interface AdminCreateQuestionDomainRequestDto
 */
export interface AdminCreateQuestionDomainRequestDto {
    /**
     * Course id
     * @type {string}
     * @memberof AdminCreateQuestionDomainRequestDto
     */
    'courseId': string;
    /**
     * Sort index
     * @type {number}
     * @memberof AdminCreateQuestionDomainRequestDto
     */
    'sortIndex'?: number;
    /**
     * Title of domain
     * @type {string}
     * @memberof AdminCreateQuestionDomainRequestDto
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface AdminCreateQuizRequestDto
 */
export interface AdminCreateQuizRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateQuizRequestDto
     */
    'questionsIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateQuizRequestDto
     */
    'guideChapterId': string;
}
/**
 * 
 * @export
 * @interface AdminCreateTaskDto
 */
export interface AdminCreateTaskDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateTaskDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateTaskDto
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface AdminCreateTimedTestRequestDto
 */
export interface AdminCreateTimedTestRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateTimedTestRequestDto
     */
    'courseId': string;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateTimedTestRequestDto
     */
    'durationSec': number;
    /**
     * 
     * @type {Array<DomainOrChapterRequestDto>}
     * @memberof AdminCreateTimedTestRequestDto
     */
    'domains': Array<DomainOrChapterRequestDto>;
    /**
     * 
     * @type {Array<DomainOrChapterRequestDto>}
     * @memberof AdminCreateTimedTestRequestDto
     */
    'chapters': Array<DomainOrChapterRequestDto>;
}
/**
 * 
 * @export
 * @interface AdminCreateUpgradeManualDto
 */
export interface AdminCreateUpgradeManualDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateUpgradeManualDto
     */
    'type': AdminCreateUpgradeManualDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateUpgradeManualDto
     */
    'title': string;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminCreateUpgradeManualDto
     */
    'content': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateUpgradeManualDto
     */
    'buttonText': string;
}

export const AdminCreateUpgradeManualDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminCreateUpgradeManualDtoTypeEnum = typeof AdminCreateUpgradeManualDtoTypeEnum[keyof typeof AdminCreateUpgradeManualDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminCustomerCourseDto
 */
export interface AdminCustomerCourseDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerCourseDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerCourseDto
     */
    'lastActivatedAt': string;
    /**
     * 
     * @type {AdminCourseDto}
     * @memberof AdminCustomerCourseDto
     */
    'course': AdminCourseDto;
}
/**
 * 
 * @export
 * @interface AdminCustomerCourseStatisticsDtoV2
 */
export interface AdminCustomerCourseStatisticsDtoV2 {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerCourseStatisticsDtoV2
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerCourseStatisticsDtoV2
     */
    'courseName': string;
    /**
     * 
     * @type {AdminCustomerCourseStatisticsDtoV2Points}
     * @memberof AdminCustomerCourseStatisticsDtoV2
     */
    'points': AdminCustomerCourseStatisticsDtoV2Points;
}
/**
 * Points for course in percents
 * @export
 * @interface AdminCustomerCourseStatisticsDtoV2Points
 */
export interface AdminCustomerCourseStatisticsDtoV2Points {
    /**
     * 
     * @type {number}
     * @memberof AdminCustomerCourseStatisticsDtoV2Points
     */
    'totalInPercents': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCustomerCourseStatisticsDtoV2Points
     */
    'totalInPoints': number;
    /**
     * 
     * @type {FlashcardsPointsDto}
     * @memberof AdminCustomerCourseStatisticsDtoV2Points
     */
    'flashcardsPoints': FlashcardsPointsDto;
    /**
     * 
     * @type {TestsPointsDto}
     * @memberof AdminCustomerCourseStatisticsDtoV2Points
     */
    'testsPoints': TestsPointsDto;
    /**
     * 
     * @type {TasksPointsDto}
     * @memberof AdminCustomerCourseStatisticsDtoV2Points
     */
    'tasksPoints': TasksPointsDto;
    /**
     * 
     * @type {number}
     * @memberof AdminCustomerCourseStatisticsDtoV2Points
     */
    'streak': number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCustomerCourseStatisticsDtoV2Points
     */
    'addedStreakToday': boolean;
}
/**
 * 
 * @export
 * @interface AdminCustomerDto
 */
export interface AdminCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerDto
     */
    'directionId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerDto
     */
    'stripeCustomerId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerDto
     */
    'lastSessionAt': string;
    /**
     * 
     * @type {Array<AdminCustomerCourseDto>}
     * @memberof AdminCustomerDto
     */
    'customerCourses'?: Array<AdminCustomerCourseDto>;
    /**
     * 
     * @type {Array<AdminCustomerSubscriptionDto>}
     * @memberof AdminCustomerDto
     */
    'subscriptions'?: Array<AdminCustomerSubscriptionDto>;
    /**
     * 
     * @type {Array<AdminCustomerPurchaseDto>}
     * @memberof AdminCustomerDto
     */
    'purchases'?: Array<AdminCustomerPurchaseDto>;
    /**
     * 
     * @type {Array<AdminCustomerInstallmentDto>}
     * @memberof AdminCustomerDto
     */
    'billingInstallments'?: Array<AdminCustomerInstallmentDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerDto
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface AdminCustomerInstallmentDto
 */
export interface AdminCustomerInstallmentDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerInstallmentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerInstallmentDto
     */
    'prevPaymentDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerInstallmentDto
     */
    'nextPaymentDate': string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminCustomerInstallmentDto
     */
    'totalIterations': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCustomerInstallmentDto
     */
    'iterationRemains': number;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerInstallmentDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerInstallmentDto
     */
    'courseName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerInstallmentDto
     */
    'priceId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerInstallmentDto
     */
    'billingType': AdminCustomerInstallmentDtoBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerInstallmentDto
     */
    'pauseAt': string | null;
}

export const AdminCustomerInstallmentDtoBillingTypeEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type AdminCustomerInstallmentDtoBillingTypeEnum = typeof AdminCustomerInstallmentDtoBillingTypeEnum[keyof typeof AdminCustomerInstallmentDtoBillingTypeEnum];

/**
 * 
 * @export
 * @interface AdminCustomerPurchaseDto
 */
export interface AdminCustomerPurchaseDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerPurchaseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerPurchaseDto
     */
    'billingType': AdminCustomerPurchaseDtoBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerPurchaseDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerPurchaseDto
     */
    'courseName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerPurchaseDto
     */
    'priceId': string;
}

export const AdminCustomerPurchaseDtoBillingTypeEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type AdminCustomerPurchaseDtoBillingTypeEnum = typeof AdminCustomerPurchaseDtoBillingTypeEnum[keyof typeof AdminCustomerPurchaseDtoBillingTypeEnum];

/**
 * 
 * @export
 * @interface AdminCustomerSubscriptionChangeDto
 */
export interface AdminCustomerSubscriptionChangeDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionChangeDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionChangeDto
     */
    'activeFrom': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionChangeDto
     */
    'priceId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionChangeDto
     */
    'billingPeriod': AdminCustomerSubscriptionChangeDtoBillingPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionChangeDto
     */
    'billingType': AdminCustomerSubscriptionChangeDtoBillingTypeEnum;
}

export const AdminCustomerSubscriptionChangeDtoBillingPeriodEnum = {
    Monthly: 'monthly',
    Annual: 'annual'
} as const;

export type AdminCustomerSubscriptionChangeDtoBillingPeriodEnum = typeof AdminCustomerSubscriptionChangeDtoBillingPeriodEnum[keyof typeof AdminCustomerSubscriptionChangeDtoBillingPeriodEnum];
export const AdminCustomerSubscriptionChangeDtoBillingTypeEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type AdminCustomerSubscriptionChangeDtoBillingTypeEnum = typeof AdminCustomerSubscriptionChangeDtoBillingTypeEnum[keyof typeof AdminCustomerSubscriptionChangeDtoBillingTypeEnum];

/**
 * 
 * @export
 * @interface AdminCustomerSubscriptionDto
 */
export interface AdminCustomerSubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionDto
     */
    'status': AdminCustomerSubscriptionDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionDto
     */
    'billingPeriod': AdminCustomerSubscriptionDtoBillingPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionDto
     */
    'billingType': AdminCustomerSubscriptionDtoBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionDto
     */
    'prevPaymentDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionDto
     */
    'nextPaymentDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionDto
     */
    'courseName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomerSubscriptionDto
     */
    'priceId': string;
    /**
     * 
     * @type {AdminCustomerSubscriptionChangeDto}
     * @memberof AdminCustomerSubscriptionDto
     */
    'change'?: AdminCustomerSubscriptionChangeDto;
}

export const AdminCustomerSubscriptionDtoStatusEnum = {
    Active: 'active',
    Trialing: 'trialing',
    Incomplete: 'incomplete',
    IncompleteExpired: 'incomplete_expired',
    PastDue: 'past_due',
    Canceled: 'canceled',
    Unpaid: 'unpaid'
} as const;

export type AdminCustomerSubscriptionDtoStatusEnum = typeof AdminCustomerSubscriptionDtoStatusEnum[keyof typeof AdminCustomerSubscriptionDtoStatusEnum];
export const AdminCustomerSubscriptionDtoBillingPeriodEnum = {
    Monthly: 'monthly',
    Annual: 'annual'
} as const;

export type AdminCustomerSubscriptionDtoBillingPeriodEnum = typeof AdminCustomerSubscriptionDtoBillingPeriodEnum[keyof typeof AdminCustomerSubscriptionDtoBillingPeriodEnum];
export const AdminCustomerSubscriptionDtoBillingTypeEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type AdminCustomerSubscriptionDtoBillingTypeEnum = typeof AdminCustomerSubscriptionDtoBillingTypeEnum[keyof typeof AdminCustomerSubscriptionDtoBillingTypeEnum];

/**
 * 
 * @export
 * @interface AdminDirectionDto
 */
export interface AdminDirectionDto {
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'landingUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'siteUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'darkLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'checkoutLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'darkCheckoutLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'faviconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'termsOfServiceUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'privacyPolicyUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'mainColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'oldColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'linkTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'linkDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'stripePublicKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'signUpTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'restorePasswordTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'changeEmailTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'subscriptionPaymentFailedTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'courseUnreleasedTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'inviteTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'freeAccessGrantedTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionDto
     */
    'paymentSuccessfulTemplateId'?: string;
    /**
     * 
     * @type {Array<AdminResponseDto>}
     * @memberof AdminDirectionDto
     */
    'admins'?: Array<AdminResponseDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDirectionDto
     */
    'isReleased': boolean;
    /**
     * 
     * @type {AdminDirectionMailSettingsDto}
     * @memberof AdminDirectionDto
     */
    'mailSettings'?: AdminDirectionMailSettingsDto;
}
/**
 * 
 * @export
 * @interface AdminDirectionMailSettingsDto
 */
export interface AdminDirectionMailSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionMailSettingsDto
     */
    'emailDomain'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionMailSettingsDto
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionMailSettingsDto
     */
    'footerLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDirectionMailSettingsDto
     */
    'backgroundUrl'?: string;
}
/**
 * 
 * @export
 * @interface AdminFlashcardDto
 */
export interface AdminFlashcardDto {
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardDto
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardDto
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardDto
     */
    'questionImageUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardDto
     */
    'answerImageUrl': string | null;
}
/**
 * 
 * @export
 * @interface AdminFlashcardsManualDto
 */
export interface AdminFlashcardsManualDto {
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardsManualDto
     */
    'type': AdminFlashcardsManualDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardsManualDto
     */
    'title': string;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminFlashcardsManualDto
     */
    'yes': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminFlashcardsManualDto
     */
    'no': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminFlashcardsManualDto
     */
    'kinda': AdminCreateInstructionManualDtoContent;
}

export const AdminFlashcardsManualDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminFlashcardsManualDtoTypeEnum = typeof AdminFlashcardsManualDtoTypeEnum[keyof typeof AdminFlashcardsManualDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminFlashcardsPackDto
 */
export interface AdminFlashcardsPackDto {
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardsPackDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardsPackDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardsPackDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardsPackDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardsPackDto
     */
    'courseId': string;
    /**
     * 
     * @type {number}
     * @memberof AdminFlashcardsPackDto
     */
    'flashcardsCount': number;
    /**
     * 
     * @type {AdminFlashcardsPackOrderDto}
     * @memberof AdminFlashcardsPackDto
     */
    'order': AdminFlashcardsPackOrderDto;
}
/**
 * 
 * @export
 * @interface AdminFlashcardsPackOrderDto
 */
export interface AdminFlashcardsPackOrderDto {
    /**
     * 
     * @type {number}
     * @memberof AdminFlashcardsPackOrderDto
     */
    'orderIndex': number;
}
/**
 * 
 * @export
 * @interface AdminFlashcardsPackWithCardsDto
 */
export interface AdminFlashcardsPackWithCardsDto {
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardsPackWithCardsDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardsPackWithCardsDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardsPackWithCardsDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardsPackWithCardsDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlashcardsPackWithCardsDto
     */
    'courseId': string;
    /**
     * 
     * @type {number}
     * @memberof AdminFlashcardsPackWithCardsDto
     */
    'flashcardsCount': number;
    /**
     * 
     * @type {AdminFlashcardsPackOrderDto}
     * @memberof AdminFlashcardsPackWithCardsDto
     */
    'order': AdminFlashcardsPackOrderDto;
    /**
     * 
     * @type {Array<AdminFlashcardDto>}
     * @memberof AdminFlashcardsPackWithCardsDto
     */
    'flashcards': Array<AdminFlashcardDto>;
}
/**
 * 
 * @export
 * @interface AdminForgottenPasswordDto
 */
export interface AdminForgottenPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof AdminForgottenPasswordDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminForgottenPasswordDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AdminGenerateDummyFlashcardsRequestDto
 */
export interface AdminGenerateDummyFlashcardsRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AdminGenerateDummyFlashcardsRequestDto
     */
    'flashcardPackId': string;
    /**
     * 
     * @type {number}
     * @memberof AdminGenerateDummyFlashcardsRequestDto
     */
    'percentsCorrect': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGenerateDummyFlashcardsRequestDto
     */
    'percentsIncorrect': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGenerateDummyFlashcardsRequestDto
     */
    'percentsKinda': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGenerateDummyFlashcardsRequestDto
     */
    'percentsUnanswered': number;
    /**
     * 
     * @type {string}
     * @memberof AdminGenerateDummyFlashcardsRequestDto
     */
    'customerId': string;
}
/**
 * 
 * @export
 * @interface AdminGenerateDummyTestsRequestDto
 */
export interface AdminGenerateDummyTestsRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AdminGenerateDummyTestsRequestDto
     */
    'courseId': string;
    /**
     * 
     * @type {number}
     * @memberof AdminGenerateDummyTestsRequestDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof AdminGenerateDummyTestsRequestDto
     */
    'customerId': string;
}
/**
 * 
 * @export
 * @interface AdminGetManyPlanPhaseTasksItem
 */
export interface AdminGetManyPlanPhaseTasksItem {
    /**
     * 
     * @type {string}
     * @memberof AdminGetManyPlanPhaseTasksItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetManyPlanPhaseTasksItem
     */
    'planPhaseId': string;
    /**
     * 
     * @type {AdminGetManyPlanPhaseTasksTask}
     * @memberof AdminGetManyPlanPhaseTasksItem
     */
    'task': AdminGetManyPlanPhaseTasksTask;
}
/**
 * 
 * @export
 * @interface AdminGetManyPlanPhaseTasksResponseDto
 */
export interface AdminGetManyPlanPhaseTasksResponseDto {
    /**
     * 
     * @type {Array<AdminGetManyPlanPhaseTasksItem>}
     * @memberof AdminGetManyPlanPhaseTasksResponseDto
     */
    'data': Array<AdminGetManyPlanPhaseTasksItem>;
    /**
     * 
     * @type {number}
     * @memberof AdminGetManyPlanPhaseTasksResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetManyPlanPhaseTasksResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetManyPlanPhaseTasksResponseDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetManyPlanPhaseTasksResponseDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetManyPlanPhaseTasksResponseDto
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface AdminGetManyPlanPhaseTasksTask
 */
export interface AdminGetManyPlanPhaseTasksTask {
    /**
     * 
     * @type {string}
     * @memberof AdminGetManyPlanPhaseTasksTask
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetManyPlanPhaseTasksTask
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetManyPlanPhaseTasksTask
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetManyPlanPhaseTasksTask
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminGetOneMnemonicCardsPackDto
 */
export interface AdminGetOneMnemonicCardsPackDto {
    /**
     * 
     * @type {string}
     * @memberof AdminGetOneMnemonicCardsPackDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetOneMnemonicCardsPackDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetOneMnemonicCardsPackDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetOneMnemonicCardsPackDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminGetOneMnemonicCardsPackDto
     */
    'courseId': string;
    /**
     * 
     * @type {number}
     * @memberof AdminGetOneMnemonicCardsPackDto
     */
    'orderIndex': number;
}
/**
 * 
 * @export
 * @interface AdminHowProgressIsCalculatedManualDto
 */
export interface AdminHowProgressIsCalculatedManualDto {
    /**
     * 
     * @type {string}
     * @memberof AdminHowProgressIsCalculatedManualDto
     */
    'type': AdminHowProgressIsCalculatedManualDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminHowProgressIsCalculatedManualDto
     */
    'title': string;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminHowProgressIsCalculatedManualDto
     */
    'content': AdminCreateInstructionManualDtoContent;
}

export const AdminHowProgressIsCalculatedManualDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminHowProgressIsCalculatedManualDtoTypeEnum = typeof AdminHowProgressIsCalculatedManualDtoTypeEnum[keyof typeof AdminHowProgressIsCalculatedManualDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminInvitedCustomerDto
 */
export interface AdminInvitedCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof AdminInvitedCustomerDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminInvitedCustomerDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminInvitedCustomerDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminInvitedCustomerDto
     */
    'expireAt': string;
}
/**
 * 
 * @export
 * @interface AdminMergeQuestionChaptersRequestDto
 */
export interface AdminMergeQuestionChaptersRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminMergeQuestionChaptersRequestDto
     */
    'chaptersIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminMergeQuestionChaptersRequestDto
     */
    'newChapterTitle': string;
}
/**
 * 
 * @export
 * @interface AdminMergeQuestionDomainsRequestDto
 */
export interface AdminMergeQuestionDomainsRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminMergeQuestionDomainsRequestDto
     */
    'domainsIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminMergeQuestionDomainsRequestDto
     */
    'newDomainTitle': string;
}
/**
 * 
 * @export
 * @interface AdminMnemonicCardDto
 */
export interface AdminMnemonicCardDto {
    /**
     * 
     * @type {string}
     * @memberof AdminMnemonicCardDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminMnemonicCardDto
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminMnemonicCardDto
     */
    'packId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminMnemonicCardDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminMnemonicCardDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof AdminMnemonicCardDto
     */
    'answersCount': number;
    /**
     * 
     * @type {string}
     * @memberof AdminMnemonicCardDto
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface AdminMnemonicCardsPackDto
 */
export interface AdminMnemonicCardsPackDto {
    /**
     * 
     * @type {string}
     * @memberof AdminMnemonicCardsPackDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminMnemonicCardsPackDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminMnemonicCardsPackDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminMnemonicCardsPackDto
     */
    'courseId': string;
    /**
     * 
     * @type {number}
     * @memberof AdminMnemonicCardsPackDto
     */
    'orderIndex': number;
    /**
     * 
     * @type {number}
     * @memberof AdminMnemonicCardsPackDto
     */
    'cardsAmount': number;
    /**
     * 
     * @type {Array<AdminMnemonicCardDto>}
     * @memberof AdminMnemonicCardsPackDto
     */
    'cards': Array<AdminMnemonicCardDto>;
    /**
     * 
     * @type {OrderDto}
     * @memberof AdminMnemonicCardsPackDto
     */
    'order': OrderDto;
}
/**
 * 
 * @export
 * @interface AdminMoveFlashcardsPackDto
 */
export interface AdminMoveFlashcardsPackDto {
    /**
     * Entity in front of which you want to insert.    The Get many query returns the data in a specific order.    For example you had      1. entity with id \"q\"     2. entity with id \"w\"     3. entity with id \"e\"        You need to insert so that the new entity is before the \"w\". You pass in the field \"before\" \"w\".      1. pack with id \"q\"     2. pack with id \"r\"     3. pack with id \"w\"     4. pack with id \"e\"    To insert at the end, don\'t pass the \"before\" field   
     * @type {string}
     * @memberof AdminMoveFlashcardsPackDto
     */
    'before': string;
}
/**
 * 
 * @export
 * @interface AdminNewQuestionOptionResponseDto
 */
export interface AdminNewQuestionOptionResponseDto {
    /**
     * Id
     * @type {string}
     * @memberof AdminNewQuestionOptionResponseDto
     */
    'id': string;
    /**
     * Option text
     * @type {string}
     * @memberof AdminNewQuestionOptionResponseDto
     */
    'title': string;
    /**
     * Order index
     * @type {number}
     * @memberof AdminNewQuestionOptionResponseDto
     */
    'orderIndex': number;
    /**
     * Is that option correct
     * @type {boolean}
     * @memberof AdminNewQuestionOptionResponseDto
     */
    'isCorrect': boolean;
    /**
     * Created at
     * @type {string}
     * @memberof AdminNewQuestionOptionResponseDto
     */
    'createdAt': string;
    /**
     * Updated at
     * @type {string}
     * @memberof AdminNewQuestionOptionResponseDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminNewQuestionResponseDto
 */
export interface AdminNewQuestionResponseDto {
    /**
     * Id
     * @type {string}
     * @memberof AdminNewQuestionResponseDto
     */
    'id': string;
    /**
     * Domain id
     * @type {string}
     * @memberof AdminNewQuestionResponseDto
     */
    'domainId'?: string;
    /**
     * 
     * @type {AdminNewQuestionResponseDtoDomain}
     * @memberof AdminNewQuestionResponseDto
     */
    'domain'?: AdminNewQuestionResponseDtoDomain | null;
    /**
     * Chapter id
     * @type {string}
     * @memberof AdminNewQuestionResponseDto
     */
    'chapterId'?: string;
    /**
     * 
     * @type {AdminNewQuestionResponseDtoChapter}
     * @memberof AdminNewQuestionResponseDto
     */
    'chapter'?: AdminNewQuestionResponseDtoChapter | null;
    /**
     * Subject of the question
     * @type {string}
     * @memberof AdminNewQuestionResponseDto
     */
    'subject': string;
    /**
     * Explanation text
     * @type {string}
     * @memberof AdminNewQuestionResponseDto
     */
    'explanationText'?: string;
    /**
     * Explanation image url
     * @type {string}
     * @memberof AdminNewQuestionResponseDto
     */
    'explanationImageUrl'?: string;
    /**
     * Hint
     * @type {string}
     * @memberof AdminNewQuestionResponseDto
     */
    'hint': string;
    /**
     * Images urls
     * @type {Array<string>}
     * @memberof AdminNewQuestionResponseDto
     */
    'imagesUrls'?: Array<string>;
    /**
     * Available for free plans
     * @type {boolean}
     * @memberof AdminNewQuestionResponseDto
     */
    'availableForFree': boolean;
    /**
     * Question options
     * @type {Array<AdminNewQuestionOptionResponseDto>}
     * @memberof AdminNewQuestionResponseDto
     */
    'options': Array<AdminNewQuestionOptionResponseDto>;
    /**
     * Created at
     * @type {string}
     * @memberof AdminNewQuestionResponseDto
     */
    'createdAt': string;
    /**
     * Updated at
     * @type {string}
     * @memberof AdminNewQuestionResponseDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminNewQuestionResponseDtoChapter
 */
export interface AdminNewQuestionResponseDtoChapter {
    /**
     * Id
     * @type {string}
     * @memberof AdminNewQuestionResponseDtoChapter
     */
    'id': string;
    /**
     * Course id
     * @type {string}
     * @memberof AdminNewQuestionResponseDtoChapter
     */
    'courseId': string;
    /**
     * Title of chapter
     * @type {string}
     * @memberof AdminNewQuestionResponseDtoChapter
     */
    'title': string;
    /**
     * Sort index
     * @type {number}
     * @memberof AdminNewQuestionResponseDtoChapter
     */
    'sortIndex': number;
    /**
     * Created at
     * @type {string}
     * @memberof AdminNewQuestionResponseDtoChapter
     */
    'createdAt': string;
    /**
     * Updated at
     * @type {string}
     * @memberof AdminNewQuestionResponseDtoChapter
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminNewQuestionResponseDtoDomain
 */
export interface AdminNewQuestionResponseDtoDomain {
    /**
     * Id
     * @type {string}
     * @memberof AdminNewQuestionResponseDtoDomain
     */
    'id': string;
    /**
     * Course id
     * @type {string}
     * @memberof AdminNewQuestionResponseDtoDomain
     */
    'courseId': string;
    /**
     * Title of domain
     * @type {string}
     * @memberof AdminNewQuestionResponseDtoDomain
     */
    'title': string;
    /**
     * Sort index
     * @type {number}
     * @memberof AdminNewQuestionResponseDtoDomain
     */
    'sortIndex': number;
    /**
     * Created at
     * @type {string}
     * @memberof AdminNewQuestionResponseDtoDomain
     */
    'createdAt': string;
    /**
     * Updated at
     * @type {string}
     * @memberof AdminNewQuestionResponseDtoDomain
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminPaginatedCoursesDto
 */
export interface AdminPaginatedCoursesDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedCoursesDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedCoursesDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedCoursesDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedCoursesDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedCoursesDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminCourseDto>}
     * @memberof AdminPaginatedCoursesDto
     */
    'data': Array<AdminCourseDto>;
}
/**
 * 
 * @export
 * @interface AdminPaginatedCustomersDto
 */
export interface AdminPaginatedCustomersDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedCustomersDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedCustomersDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedCustomersDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedCustomersDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedCustomersDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminCustomerDto>}
     * @memberof AdminPaginatedCustomersDto
     */
    'data': Array<AdminCustomerDto>;
}
/**
 * 
 * @export
 * @interface AdminPaginatedDirectionsDto
 */
export interface AdminPaginatedDirectionsDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedDirectionsDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedDirectionsDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedDirectionsDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedDirectionsDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedDirectionsDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminDirectionDto>}
     * @memberof AdminPaginatedDirectionsDto
     */
    'data': Array<AdminDirectionDto>;
}
/**
 * 
 * @export
 * @interface AdminPaginatedFlashcardPacksDto
 */
export interface AdminPaginatedFlashcardPacksDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedFlashcardPacksDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedFlashcardPacksDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedFlashcardPacksDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedFlashcardPacksDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedFlashcardPacksDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminFlashcardsPackDto>}
     * @memberof AdminPaginatedFlashcardPacksDto
     */
    'data': Array<AdminFlashcardsPackDto>;
}
/**
 * 
 * @export
 * @interface AdminPaginatedFlashcardsDto
 */
export interface AdminPaginatedFlashcardsDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedFlashcardsDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedFlashcardsDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedFlashcardsDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedFlashcardsDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedFlashcardsDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminFlashcardDto>}
     * @memberof AdminPaginatedFlashcardsDto
     */
    'data': Array<AdminFlashcardDto>;
}
/**
 * 
 * @export
 * @interface AdminPaginatedInvitedCustomersDto
 */
export interface AdminPaginatedInvitedCustomersDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedInvitedCustomersDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedInvitedCustomersDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedInvitedCustomersDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedInvitedCustomersDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedInvitedCustomersDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminInvitedCustomerDto>}
     * @memberof AdminPaginatedInvitedCustomersDto
     */
    'data': Array<AdminInvitedCustomerDto>;
}
/**
 * 
 * @export
 * @interface AdminPaginatedMnemonicCardPacksDto
 */
export interface AdminPaginatedMnemonicCardPacksDto {
    /**
     * 
     * @type {Array<AdminMnemonicCardsPackDto>}
     * @memberof AdminPaginatedMnemonicCardPacksDto
     */
    'data': Array<AdminMnemonicCardsPackDto>;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedMnemonicCardPacksDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedMnemonicCardPacksDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedMnemonicCardPacksDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedMnemonicCardPacksDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface AdminPaginatedMnemonicCardsDto
 */
export interface AdminPaginatedMnemonicCardsDto {
    /**
     * 
     * @type {Array<AdminMnemonicCardDto>}
     * @memberof AdminPaginatedMnemonicCardsDto
     */
    'data': Array<AdminMnemonicCardDto>;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedMnemonicCardsDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedMnemonicCardsDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedMnemonicCardsDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedMnemonicCardsDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface AdminPaginatedNewQuestionsResponseDto
 */
export interface AdminPaginatedNewQuestionsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedNewQuestionsResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedNewQuestionsResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedNewQuestionsResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedNewQuestionsResponseDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedNewQuestionsResponseDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminNewQuestionResponseDto>}
     * @memberof AdminPaginatedNewQuestionsResponseDto
     */
    'data': Array<AdminNewQuestionResponseDto>;
}
/**
 * 
 * @export
 * @interface AdminPaginatedQuestionChaptersResponseDto
 */
export interface AdminPaginatedQuestionChaptersResponseDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedQuestionChaptersResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedQuestionChaptersResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedQuestionChaptersResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedQuestionChaptersResponseDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedQuestionChaptersResponseDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminQuestionChapterResponseDto>}
     * @memberof AdminPaginatedQuestionChaptersResponseDto
     */
    'data': Array<AdminQuestionChapterResponseDto>;
}
/**
 * 
 * @export
 * @interface AdminPaginatedQuestionDomainsResponseDto
 */
export interface AdminPaginatedQuestionDomainsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedQuestionDomainsResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedQuestionDomainsResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedQuestionDomainsResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedQuestionDomainsResponseDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedQuestionDomainsResponseDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminQuestionDomainResponseDto>}
     * @memberof AdminPaginatedQuestionDomainsResponseDto
     */
    'data': Array<AdminQuestionDomainResponseDto>;
}
/**
 * 
 * @export
 * @interface AdminPaginatedResponseDto
 */
export interface AdminPaginatedResponseDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedResponseDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedResponseDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminResponseDto>}
     * @memberof AdminPaginatedResponseDto
     */
    'data': Array<AdminResponseDto>;
}
/**
 * 
 * @export
 * @interface AdminPaginatedTasksDto
 */
export interface AdminPaginatedTasksDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedTasksDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedTasksDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedTasksDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedTasksDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedTasksDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminTaskDto>}
     * @memberof AdminPaginatedTasksDto
     */
    'data': Array<AdminTaskDto>;
}
/**
 * 
 * @export
 * @interface AdminPaginatedTimedTestsResponseDto
 */
export interface AdminPaginatedTimedTestsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedTimedTestsResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedTimedTestsResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedTimedTestsResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedTimedTestsResponseDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPaginatedTimedTestsResponseDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminTimedTestResponseDto>}
     * @memberof AdminPaginatedTimedTestsResponseDto
     */
    'data': Array<AdminTimedTestResponseDto>;
}
/**
 * 
 * @export
 * @interface AdminPasswordChanges
 */
export interface AdminPasswordChanges {
    /**
     * 
     * @type {string}
     * @memberof AdminPasswordChanges
     */
    'adminId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminPasswordChanges
     */
    'password': string;
    /**
     * 
     * @type {Admin}
     * @memberof AdminPasswordChanges
     */
    'admin'?: Admin;
}
/**
 * 
 * @export
 * @interface AdminPaymentsDiscountsInfoDto
 */
export interface AdminPaymentsDiscountsInfoDto {
    /**
     * 
     * @type {string}
     * @memberof AdminPaymentsDiscountsInfoDto
     */
    'type': AdminPaymentsDiscountsInfoDtoTypeEnum;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminPaymentsDiscountsInfoDto
     */
    'purchase': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminPaymentsDiscountsInfoDto
     */
    'monthlySubscriptions': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminPaymentsDiscountsInfoDto
     */
    'annualSubscriptions': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminPaymentsDiscountsInfoDto
     */
    'mostEffective': AdminCreateInstructionManualDtoContent;
}

export const AdminPaymentsDiscountsInfoDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminPaymentsDiscountsInfoDtoTypeEnum = typeof AdminPaymentsDiscountsInfoDtoTypeEnum[keyof typeof AdminPaymentsDiscountsInfoDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminPlanDto
 */
export interface AdminPlanDto {
    /**
     * 
     * @type {string}
     * @memberof AdminPlanDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminPlanDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminPlanDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminPlanDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AdminPlanPhaseDto
 */
export interface AdminPlanPhaseDto {
    /**
     * 
     * @type {string}
     * @memberof AdminPlanPhaseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminPlanPhaseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPlanPhaseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminPlanPhaseDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminPlanPhaseTaskDto
 */
export interface AdminPlanPhaseTaskDto {
    /**
     * 
     * @type {string}
     * @memberof AdminPlanPhaseTaskDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminPlanPhaseTaskDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminPlanPhaseTaskDto
     */
    'planPhaseId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminPlanPhaseTaskDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminPlanPhaseTaskDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminPlanPhasesDto
 */
export interface AdminPlanPhasesDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPlanPhasesDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPlanPhasesDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPlanPhasesDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPlanPhasesDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPlanPhasesDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminPlanPhaseDto>}
     * @memberof AdminPlanPhasesDto
     */
    'data': Array<AdminPlanPhaseDto>;
}
/**
 * 
 * @export
 * @interface AdminPlansDto
 */
export interface AdminPlansDto {
    /**
     * 
     * @type {number}
     * @memberof AdminPlansDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPlansDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPlansDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPlansDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPlansDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<AdminPlanDto>}
     * @memberof AdminPlansDto
     */
    'data': Array<AdminPlanDto>;
}
/**
 * 
 * @export
 * @interface AdminPriceDto
 */
export interface AdminPriceDto {
    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AdminPriceDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminPriceDto
     */
    'oldAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    'defaultCouponId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPriceDto
     */
    'firstMonthDiscountCouponId'?: string;
    /**
     * 
     * @type {CancellationPromotionTermsResponseDto}
     * @memberof AdminPriceDto
     */
    'cancellationPromotionTerms'?: CancellationPromotionTermsResponseDto;
}
/**
 * 
 * @export
 * @interface AdminProgressManualDto
 */
export interface AdminProgressManualDto {
    /**
     * 
     * @type {string}
     * @memberof AdminProgressManualDto
     */
    'type': AdminProgressManualDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminProgressManualDto
     */
    'title': string;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminProgressManualDto
     */
    'content': AdminCreateInstructionManualDtoContent;
}

export const AdminProgressManualDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminProgressManualDtoTypeEnum = typeof AdminProgressManualDtoTypeEnum[keyof typeof AdminProgressManualDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminPurchasePricesDto
 */
export interface AdminPurchasePricesDto {
    /**
     * 
     * @type {AdminPriceDto}
     * @memberof AdminPurchasePricesDto
     */
    'bronze': AdminPriceDto;
    /**
     * 
     * @type {AdminPriceDto}
     * @memberof AdminPurchasePricesDto
     */
    'silver': AdminPriceDto;
    /**
     * 
     * @type {AdminPriceDto}
     * @memberof AdminPurchasePricesDto
     */
    'gold': AdminPriceDto;
}
/**
 * 
 * @export
 * @interface AdminQuestionChapterResponseDto
 */
export interface AdminQuestionChapterResponseDto {
    /**
     * Id
     * @type {string}
     * @memberof AdminQuestionChapterResponseDto
     */
    'id': string;
    /**
     * Course id
     * @type {string}
     * @memberof AdminQuestionChapterResponseDto
     */
    'courseId': string;
    /**
     * Title of chapter
     * @type {string}
     * @memberof AdminQuestionChapterResponseDto
     */
    'title': string;
    /**
     * Sort index
     * @type {number}
     * @memberof AdminQuestionChapterResponseDto
     */
    'sortIndex': number;
    /**
     * Created at
     * @type {string}
     * @memberof AdminQuestionChapterResponseDto
     */
    'createdAt': string;
    /**
     * Updated at
     * @type {string}
     * @memberof AdminQuestionChapterResponseDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminQuestionDomainResponseDto
 */
export interface AdminQuestionDomainResponseDto {
    /**
     * Id
     * @type {string}
     * @memberof AdminQuestionDomainResponseDto
     */
    'id': string;
    /**
     * Course id
     * @type {string}
     * @memberof AdminQuestionDomainResponseDto
     */
    'courseId': string;
    /**
     * Title of domain
     * @type {string}
     * @memberof AdminQuestionDomainResponseDto
     */
    'title': string;
    /**
     * Sort index
     * @type {number}
     * @memberof AdminQuestionDomainResponseDto
     */
    'sortIndex': number;
    /**
     * Created at
     * @type {string}
     * @memberof AdminQuestionDomainResponseDto
     */
    'createdAt': string;
    /**
     * Updated at
     * @type {string}
     * @memberof AdminQuestionDomainResponseDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminQuizResponseDto
 */
export interface AdminQuizResponseDto {
    /**
     * Quiz ID
     * @type {string}
     * @memberof AdminQuizResponseDto
     */
    'id': string;
    /**
     * Questions IDs
     * @type {Array<string>}
     * @memberof AdminQuizResponseDto
     */
    'questionsIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminReportDto
 */
export interface AdminReportDto {
    /**
     * 
     * @type {string}
     * @memberof AdminReportDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminReportDto
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminReportDto
     */
    'resourceTitle': string;
    /**
     * 
     * @type {string}
     * @memberof AdminReportDto
     */
    'resource': AdminReportDtoResourceEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminReportDto
     */
    'resolvedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminReportDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminReportDto
     */
    'content': string;
    /**
     * 
     * @type {ReportCourseDto}
     * @memberof AdminReportDto
     */
    'course': ReportCourseDto;
}

export const AdminReportDtoResourceEnum = {
    Flashcard: 'flashcard',
    MnemonicCard: 'mnemonic_card',
    TestQuestion: 'test_question'
} as const;

export type AdminReportDtoResourceEnum = typeof AdminReportDtoResourceEnum[keyof typeof AdminReportDtoResourceEnum];

/**
 * 
 * @export
 * @interface AdminReportsControllerDeleteResolved200Response
 */
export interface AdminReportsControllerDeleteResolved200Response {
    /**
     * 
     * @type {number}
     * @memberof AdminReportsControllerDeleteResolved200Response
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface AdminResponseDto
 */
export interface AdminResponseDto {
    /**
     * Admin id
     * @type {string}
     * @memberof AdminResponseDto
     */
    'id': string;
    /**
     * Admin email
     * @type {string}
     * @memberof AdminResponseDto
     */
    'email': string;
    /**
     * Admin nickname
     * @type {string}
     * @memberof AdminResponseDto
     */
    'nickname': string;
    /**
     * Admin first name
     * @type {string}
     * @memberof AdminResponseDto
     */
    'firstName': string;
    /**
     * Admin last name
     * @type {string}
     * @memberof AdminResponseDto
     */
    'lastName': string;
    /**
     * Admin role
     * @type {string}
     * @memberof AdminResponseDto
     */
    'role': AdminResponseDtoRoleEnum;
    /**
     * 
     * @type {Array<AdminDirectionDto>}
     * @memberof AdminResponseDto
     */
    'directions'?: Array<AdminDirectionDto>;
}

export const AdminResponseDtoRoleEnum = {
    Admin: 'admin',
    Superadmin: 'superadmin'
} as const;

export type AdminResponseDtoRoleEnum = typeof AdminResponseDtoRoleEnum[keyof typeof AdminResponseDtoRoleEnum];

/**
 * 
 * @export
 * @interface AdminRestorePasswordDto
 */
export interface AdminRestorePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof AdminRestorePasswordDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface AdminRestorePasswordResponseDto
 */
export interface AdminRestorePasswordResponseDto {
    /**
     * 
     * @type {AdminResponseDto}
     * @memberof AdminRestorePasswordResponseDto
     */
    'admin': AdminResponseDto;
    /**
     * 
     * @type {string}
     * @memberof AdminRestorePasswordResponseDto
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface AdminSignInDto
 */
export interface AdminSignInDto {
    /**
     * Admin email
     * @type {string}
     * @memberof AdminSignInDto
     */
    'email': string;
    /**
     * Admin password
     * @type {string}
     * @memberof AdminSignInDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AdminSignedUrlDto
 */
export interface AdminSignedUrlDto {
    /**
     * 
     * @type {string}
     * @memberof AdminSignedUrlDto
     */
    'signedUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSignedUrlDto
     */
    'accessUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSignedUrlDto
     */
    'fileKey': string;
}
/**
 * 
 * @export
 * @interface AdminSignedUrlResponseDto
 */
export interface AdminSignedUrlResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AdminSignedUrlResponseDto
     */
    'signedUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSignedUrlResponseDto
     */
    'accessUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSignedUrlResponseDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface AdminSubscriptionPricesDto
 */
export interface AdminSubscriptionPricesDto {
    /**
     * 
     * @type {AdminPriceDto}
     * @memberof AdminSubscriptionPricesDto
     */
    'bronze': AdminPriceDto;
    /**
     * 
     * @type {AdminPriceDto}
     * @memberof AdminSubscriptionPricesDto
     */
    'silver': AdminPriceDto;
    /**
     * 
     * @type {AdminPriceDto}
     * @memberof AdminSubscriptionPricesDto
     */
    'gold': AdminPriceDto;
}
/**
 * 
 * @export
 * @interface AdminSuggestionCustomer
 */
export interface AdminSuggestionCustomer {
    /**
     * 
     * @type {string}
     * @memberof AdminSuggestionCustomer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSuggestionCustomer
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AdminSuggestionResponseDto
 */
export interface AdminSuggestionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AdminSuggestionResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSuggestionResponseDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSuggestionResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSuggestionResponseDto
     */
    'resolvedAt': string | null;
    /**
     * 
     * @type {AdminSuggestionResponseDtoCustomer}
     * @memberof AdminSuggestionResponseDto
     */
    'customer': AdminSuggestionResponseDtoCustomer | null;
}
/**
 * 
 * @export
 * @interface AdminSuggestionResponseDtoCustomer
 */
export interface AdminSuggestionResponseDtoCustomer {
    /**
     * 
     * @type {string}
     * @memberof AdminSuggestionResponseDtoCustomer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSuggestionResponseDtoCustomer
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AdminTaskDto
 */
export interface AdminTaskDto {
    /**
     * 
     * @type {string}
     * @memberof AdminTaskDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminTaskDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminTaskDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminTaskDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminTaskDto
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface AdminTimedTestResponseDto
 */
export interface AdminTimedTestResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AdminTimedTestResponseDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AdminTimedTestResponseDto
     */
    'durationSec': number;
    /**
     * 
     * @type {string}
     * @memberof AdminTimedTestResponseDto
     */
    'courseId': string;
    /**
     * 
     * @type {Array<DomainOrChapterResponseDto>}
     * @memberof AdminTimedTestResponseDto
     */
    'domains': Array<DomainOrChapterResponseDto>;
    /**
     * 
     * @type {Array<DomainOrChapterResponseDto>}
     * @memberof AdminTimedTestResponseDto
     */
    'chapters': Array<DomainOrChapterResponseDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminTimedTestResponseDto
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface AdminUpdateBaseManualDto
 */
export interface AdminUpdateBaseManualDto {
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminUpdateBaseManualDto
     */
    'content'?: AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateBaseManualDto
     */
    'type': AdminUpdateBaseManualDtoTypeEnum;
}

export const AdminUpdateBaseManualDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminUpdateBaseManualDtoTypeEnum = typeof AdminUpdateBaseManualDtoTypeEnum[keyof typeof AdminUpdateBaseManualDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminUpdateCouponDto
 */
export interface AdminUpdateCouponDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCouponDto
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpdateCouponDto
     */
    'allowedPeriods'?: Array<AdminUpdateCouponDtoAllowedPeriodsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpdateCouponDto
     */
    'allowedPlans'?: Array<AdminUpdateCouponDtoAllowedPlansEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpdateCouponDto
     */
    'allowedCoursesIds'?: Array<string>;
}

export const AdminUpdateCouponDtoAllowedPeriodsEnum = {
    Monthly: 'monthly',
    Annual: 'annual',
    Lifetime: 'lifetime',
    Installment: 'installment',
    InstallmentFirstMonth: 'installment_first_month'
} as const;

export type AdminUpdateCouponDtoAllowedPeriodsEnum = typeof AdminUpdateCouponDtoAllowedPeriodsEnum[keyof typeof AdminUpdateCouponDtoAllowedPeriodsEnum];
export const AdminUpdateCouponDtoAllowedPlansEnum = {
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type AdminUpdateCouponDtoAllowedPlansEnum = typeof AdminUpdateCouponDtoAllowedPlansEnum[keyof typeof AdminUpdateCouponDtoAllowedPlansEnum];

/**
 * 
 * @export
 * @interface AdminUpdateCourseDto
 */
export interface AdminUpdateCourseDto {
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateCourseDto
     */
    'activeCampaignListId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCourseDto
     */
    'leastKnownCategory'?: AdminUpdateCourseDtoLeastKnownCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCourseDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCourseDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateCourseDto
     */
    'assignmentsWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateCourseDto
     */
    'flashcardsWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateCourseDto
     */
    'testTopicsWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCourseDto
     */
    'domainButtonName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCourseDto
     */
    'chapterButtonName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCourseDto
     */
    'domainStatsButtonName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCourseDto
     */
    'chapterStatsButtonName'?: string;
}

export const AdminUpdateCourseDtoLeastKnownCategoryEnum = {
    Domain: 'domain',
    Chapter: 'chapter'
} as const;

export type AdminUpdateCourseDtoLeastKnownCategoryEnum = typeof AdminUpdateCourseDtoLeastKnownCategoryEnum[keyof typeof AdminUpdateCourseDtoLeastKnownCategoryEnum];

/**
 * 
 * @export
 * @interface AdminUpdateCoursePricesDto
 */
export interface AdminUpdateCoursePricesDto {
    /**
     * 
     * @type {AdminUpdateCoursePricesDtoInstallment}
     * @memberof AdminUpdateCoursePricesDto
     */
    'installment'?: AdminUpdateCoursePricesDtoInstallment | null;
    /**
     * 
     * @type {AdminUpdateCoursePricesDtoInstallment}
     * @memberof AdminUpdateCoursePricesDto
     */
    'purchase'?: AdminUpdateCoursePricesDtoInstallment | null;
    /**
     * 
     * @type {AdminUpdateCoursePricesDtoMonthlySubscriptions}
     * @memberof AdminUpdateCoursePricesDto
     */
    'monthlySubscriptions'?: AdminUpdateCoursePricesDtoMonthlySubscriptions | null;
    /**
     * 
     * @type {AdminUpdateCoursePricesDtoMonthlySubscriptions}
     * @memberof AdminUpdateCoursePricesDto
     */
    'annualSubscriptions'?: AdminUpdateCoursePricesDtoMonthlySubscriptions | null;
}
/**
 * 
 * @export
 * @interface AdminUpdateCoursePricesDtoInstallment
 */
export interface AdminUpdateCoursePricesDtoInstallment {
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDto}
     * @memberof AdminUpdateCoursePricesDtoInstallment
     */
    'gold': AdminCreateCourseSinglePriceDto;
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDto}
     * @memberof AdminUpdateCoursePricesDtoInstallment
     */
    'silver': AdminCreateCourseSinglePriceDto;
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDto}
     * @memberof AdminUpdateCoursePricesDtoInstallment
     */
    'bronze': AdminCreateCourseSinglePriceDto;
}
/**
 * 
 * @export
 * @interface AdminUpdateCoursePricesDtoMonthlySubscriptions
 */
export interface AdminUpdateCoursePricesDtoMonthlySubscriptions {
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDto}
     * @memberof AdminUpdateCoursePricesDtoMonthlySubscriptions
     */
    'gold': AdminCreateCourseSinglePriceDto;
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDto}
     * @memberof AdminUpdateCoursePricesDtoMonthlySubscriptions
     */
    'silver': AdminCreateCourseSinglePriceDto;
    /**
     * 
     * @type {AdminCreateCourseSinglePriceDto}
     * @memberof AdminUpdateCoursePricesDtoMonthlySubscriptions
     */
    'bronze': AdminCreateCourseSinglePriceDto;
}
/**
 * 
 * @export
 * @interface AdminUpdateCustomerPasswordDto
 */
export interface AdminUpdateCustomerPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCustomerPasswordDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AdminUpdateFlashcardDto
 */
export interface AdminUpdateFlashcardDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateFlashcardDto
     */
    'question'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateFlashcardDto
     */
    'answer'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateFlashcardDto
     */
    'questionImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateFlashcardDto
     */
    'answerImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminUpdateFlashcardsManualDto
 */
export interface AdminUpdateFlashcardsManualDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateFlashcardsManualDto
     */
    'title'?: string;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminUpdateFlashcardsManualDto
     */
    'yes'?: AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminUpdateFlashcardsManualDto
     */
    'no'?: AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminUpdateFlashcardsManualDto
     */
    'kinda'?: AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateFlashcardsManualDto
     */
    'type': AdminUpdateFlashcardsManualDtoTypeEnum;
}

export const AdminUpdateFlashcardsManualDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminUpdateFlashcardsManualDtoTypeEnum = typeof AdminUpdateFlashcardsManualDtoTypeEnum[keyof typeof AdminUpdateFlashcardsManualDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminUpdateFlashcardsPackDto
 */
export interface AdminUpdateFlashcardsPackDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateFlashcardsPackDto
     */
    'name'?: string;
}
/**
 * @type AdminUpdateManualDto
 * @export
 */
export type AdminUpdateManualDto = AdminUpdateBaseManualDto | AdminUpdateFlashcardsManualDto | AdminUpdatePaymentsDiscountsInfoDto | AdminUpdateProgressManualDto | AdminUpdateUpgradeManualDto;

/**
 * 
 * @export
 * @interface AdminUpdateNewQuestionRequestDto
 */
export interface AdminUpdateNewQuestionRequestDto {
    /**
     * Domain id
     * @type {string}
     * @memberof AdminUpdateNewQuestionRequestDto
     */
    'domainId'?: string | null;
    /**
     * Chapter id
     * @type {string}
     * @memberof AdminUpdateNewQuestionRequestDto
     */
    'chapterId'?: string | null;
    /**
     * Question subject
     * @type {string}
     * @memberof AdminUpdateNewQuestionRequestDto
     */
    'subject'?: string;
    /**
     * Right answer index
     * @type {number}
     * @memberof AdminUpdateNewQuestionRequestDto
     */
    'rightAnswerIndex'?: number;
    /**
     * Question options
     * @type {Array<string>}
     * @memberof AdminUpdateNewQuestionRequestDto
     */
    'options'?: Array<string>;
    /**
     * Question explanation text
     * @type {string}
     * @memberof AdminUpdateNewQuestionRequestDto
     */
    'explanationText'?: string | null;
    /**
     * Question explanation image url
     * @type {string}
     * @memberof AdminUpdateNewQuestionRequestDto
     */
    'explanationImageUrl'?: string | null;
    /**
     * Question hint
     * @type {string}
     * @memberof AdminUpdateNewQuestionRequestDto
     */
    'hint'?: string;
    /**
     * Question images urls
     * @type {Array<string>}
     * @memberof AdminUpdateNewQuestionRequestDto
     */
    'imagesUrls'?: Array<string>;
    /**
     * Is question available for free
     * @type {boolean}
     * @memberof AdminUpdateNewQuestionRequestDto
     */
    'availableForFree'?: boolean;
}
/**
 * 
 * @export
 * @interface AdminUpdatePaymentsDiscountsInfoDto
 */
export interface AdminUpdatePaymentsDiscountsInfoDto {
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminUpdatePaymentsDiscountsInfoDto
     */
    'purchase'?: AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminUpdatePaymentsDiscountsInfoDto
     */
    'monthlySubscriptions'?: AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminUpdatePaymentsDiscountsInfoDto
     */
    'annualSubscriptions'?: AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminUpdatePaymentsDiscountsInfoDto
     */
    'mostEffective'?: AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdatePaymentsDiscountsInfoDto
     */
    'type': AdminUpdatePaymentsDiscountsInfoDtoTypeEnum;
}

export const AdminUpdatePaymentsDiscountsInfoDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminUpdatePaymentsDiscountsInfoDtoTypeEnum = typeof AdminUpdatePaymentsDiscountsInfoDtoTypeEnum[keyof typeof AdminUpdatePaymentsDiscountsInfoDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminUpdatePlanDto
 */
export interface AdminUpdatePlanDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdatePlanDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AdminUpdatePlanPhaseDto
 */
export interface AdminUpdatePlanPhaseDto {
    /**
     * Entity in front of which you want to insert.    The Get many query returns the data in a specific order.    For example you had      1. entity with id \"q\"     2. entity with id \"w\"     3. entity with id \"e\"        You need to insert so that the new entity is before the \"w\". You pass in the field \"before\" \"w\".      1. pack with id \"q\"     2. pack with id \"r\"     3. pack with id \"w\"     4. pack with id \"e\"    To insert at the end, don\'t pass the \"before\" field   
     * @type {string}
     * @memberof AdminUpdatePlanPhaseDto
     */
    'before'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdatePlanPhaseDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AdminUpdatePlanPhaseTaskDto
 */
export interface AdminUpdatePlanPhaseTaskDto {
    /**
     * Entity in front of which you want to insert.    The Get many query returns the data in a specific order.    For example you had      1. entity with id \"q\"     2. entity with id \"w\"     3. entity with id \"e\"        You need to insert so that the new entity is before the \"w\". You pass in the field \"before\" \"w\".      1. pack with id \"q\"     2. pack with id \"r\"     3. pack with id \"w\"     4. pack with id \"e\"    To insert at the end, don\'t pass the \"before\" field   
     * @type {string}
     * @memberof AdminUpdatePlanPhaseTaskDto
     */
    'before'?: string;
}
/**
 * 
 * @export
 * @interface AdminUpdateProgressManualDto
 */
export interface AdminUpdateProgressManualDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateProgressManualDto
     */
    'title'?: string;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminUpdateProgressManualDto
     */
    'content'?: AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateProgressManualDto
     */
    'type': AdminUpdateProgressManualDtoTypeEnum;
}

export const AdminUpdateProgressManualDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminUpdateProgressManualDtoTypeEnum = typeof AdminUpdateProgressManualDtoTypeEnum[keyof typeof AdminUpdateProgressManualDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminUpdateQuestionChapterRequestDto
 */
export interface AdminUpdateQuestionChapterRequestDto {
    /**
     * Chapter title
     * @type {string}
     * @memberof AdminUpdateQuestionChapterRequestDto
     */
    'title'?: string;
    /**
     * Chapter sort index
     * @type {number}
     * @memberof AdminUpdateQuestionChapterRequestDto
     */
    'sortIndex'?: number;
}
/**
 * 
 * @export
 * @interface AdminUpdateQuestionDomainRequestDto
 */
export interface AdminUpdateQuestionDomainRequestDto {
    /**
     * Sort index
     * @type {number}
     * @memberof AdminUpdateQuestionDomainRequestDto
     */
    'sortIndex'?: number;
    /**
     * Title of domain
     * @type {string}
     * @memberof AdminUpdateQuestionDomainRequestDto
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface AdminUpdateQuizRequestDto
 */
export interface AdminUpdateQuizRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpdateQuizRequestDto
     */
    'questionsIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminUpdateTaskDto
 */
export interface AdminUpdateTaskDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateTaskDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AdminUpdateTimedTestRequestDto
 */
export interface AdminUpdateTimedTestRequestDto {
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateTimedTestRequestDto
     */
    'durationSec'?: number;
    /**
     * 
     * @type {Array<DomainOrChapterRequestDto>}
     * @memberof AdminUpdateTimedTestRequestDto
     */
    'domains'?: Array<DomainOrChapterRequestDto>;
    /**
     * 
     * @type {Array<DomainOrChapterRequestDto>}
     * @memberof AdminUpdateTimedTestRequestDto
     */
    'chapters'?: Array<DomainOrChapterRequestDto>;
}
/**
 * 
 * @export
 * @interface AdminUpdateUpgradeManualDto
 */
export interface AdminUpdateUpgradeManualDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateUpgradeManualDto
     */
    'title'?: string;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminUpdateUpgradeManualDto
     */
    'content'?: AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateUpgradeManualDto
     */
    'buttonText'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateUpgradeManualDto
     */
    'type': AdminUpdateUpgradeManualDtoTypeEnum;
}

export const AdminUpdateUpgradeManualDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminUpdateUpgradeManualDtoTypeEnum = typeof AdminUpdateUpgradeManualDtoTypeEnum[keyof typeof AdminUpdateUpgradeManualDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminUpgradeManualDto
 */
export interface AdminUpgradeManualDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpgradeManualDto
     */
    'type': AdminUpgradeManualDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminUpgradeManualDto
     */
    'title': string;
    /**
     * 
     * @type {AdminCreateInstructionManualDtoContent}
     * @memberof AdminUpgradeManualDto
     */
    'content': AdminCreateInstructionManualDtoContent;
    /**
     * 
     * @type {string}
     * @memberof AdminUpgradeManualDto
     */
    'buttonText': string;
}

export const AdminUpgradeManualDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type AdminUpgradeManualDtoTypeEnum = typeof AdminUpgradeManualDtoTypeEnum[keyof typeof AdminUpgradeManualDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdminUploadFlashcardImageDto
 */
export interface AdminUploadFlashcardImageDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUploadFlashcardImageDto
     */
    'packId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUploadFlashcardImageDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface AdminUploadImageDto
 */
export interface AdminUploadImageDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUploadImageDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface AdminUploadImageRequestDto
 */
export interface AdminUploadImageRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUploadImageRequestDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface AnswerQuestionRequestDto
 */
export interface AnswerQuestionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AnswerQuestionRequestDto
     */
    'questionId': string;
    /**
     * 
     * @type {string}
     * @memberof AnswerQuestionRequestDto
     */
    'optionId': string;
}
/**
 * 
 * @export
 * @interface AnswerQuestionResponseDto
 */
export interface AnswerQuestionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AnswerQuestionResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnswerQuestionResponseDto
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof AnswerQuestionResponseDto
     */
    'orderIndex': number;
    /**
     * 
     * @type {boolean}
     * @memberof AnswerQuestionResponseDto
     */
    'isCorrect': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AnswerQuestionResponseDto
     */
    'correctOptionId': string | null;
}
/**
 * 
 * @export
 * @interface ApplyCancellationPromotionRequestDto
 */
export interface ApplyCancellationPromotionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ApplyCancellationPromotionRequestDto
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface BillingInstallment
 */
export interface BillingInstallment {
    /**
     * 
     * @type {string}
     * @memberof BillingInstallment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BillingInstallment
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof BillingInstallment
     */
    'prevPaymentDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingInstallment
     */
    'nextPaymentDate': string | null;
    /**
     * 
     * @type {number}
     * @memberof BillingInstallment
     */
    'totalIterations': number;
    /**
     * 
     * @type {number}
     * @memberof BillingInstallment
     */
    'iterationRemains': number;
    /**
     * 
     * @type {Course}
     * @memberof BillingInstallment
     */
    'course'?: Course;
    /**
     * 
     * @type {string}
     * @memberof BillingInstallment
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof BillingInstallment
     */
    'customer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof BillingInstallment
     */
    'priceId': string;
    /**
     * 
     * @type {string}
     * @memberof BillingInstallment
     */
    'billingType': BillingInstallmentBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BillingInstallment
     */
    'pauseAt': string | null;
}

export const BillingInstallmentBillingTypeEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type BillingInstallmentBillingTypeEnum = typeof BillingInstallmentBillingTypeEnum[keyof typeof BillingInstallmentBillingTypeEnum];

/**
 * 
 * @export
 * @interface BillingPortalDto
 */
export interface BillingPortalDto {
    /**
     * 
     * @type {string}
     * @memberof BillingPortalDto
     */
    'checkout_url': string;
}
/**
 * 
 * @export
 * @interface BillingPortalResponseDto
 */
export interface BillingPortalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof BillingPortalResponseDto
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface BillingPurchase
 */
export interface BillingPurchase {
    /**
     * 
     * @type {string}
     * @memberof BillingPurchase
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BillingPurchase
     */
    'courseId': string;
    /**
     * 
     * @type {Course}
     * @memberof BillingPurchase
     */
    'course'?: Course;
    /**
     * 
     * @type {string}
     * @memberof BillingPurchase
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof BillingPurchase
     */
    'customer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof BillingPurchase
     */
    'priceId': string;
    /**
     * 
     * @type {string}
     * @memberof BillingPurchase
     */
    'billingType': BillingPurchaseBillingTypeEnum;
}

export const BillingPurchaseBillingTypeEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type BillingPurchaseBillingTypeEnum = typeof BillingPurchaseBillingTypeEnum[keyof typeof BillingPurchaseBillingTypeEnum];

/**
 * 
 * @export
 * @interface BillingSubscription
 */
export interface BillingSubscription {
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    'status': BillingSubscriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    'billingPeriod': BillingSubscriptionBillingPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    'billingType': BillingSubscriptionBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    'prevPaymentDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    'nextPaymentDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    'courseId': string;
    /**
     * 
     * @type {Course}
     * @memberof BillingSubscription
     */
    'course'?: Course;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof BillingSubscription
     */
    'customer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    'priceId': string;
    /**
     * 
     * @type {BillingSubscriptionChange}
     * @memberof BillingSubscription
     */
    'change'?: BillingSubscriptionChange;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    'onPauseUntil': string | null;
}

export const BillingSubscriptionStatusEnum = {
    Active: 'active',
    Trialing: 'trialing',
    Incomplete: 'incomplete',
    IncompleteExpired: 'incomplete_expired',
    PastDue: 'past_due',
    Canceled: 'canceled',
    Unpaid: 'unpaid'
} as const;

export type BillingSubscriptionStatusEnum = typeof BillingSubscriptionStatusEnum[keyof typeof BillingSubscriptionStatusEnum];
export const BillingSubscriptionBillingPeriodEnum = {
    Monthly: 'monthly',
    Annual: 'annual'
} as const;

export type BillingSubscriptionBillingPeriodEnum = typeof BillingSubscriptionBillingPeriodEnum[keyof typeof BillingSubscriptionBillingPeriodEnum];
export const BillingSubscriptionBillingTypeEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type BillingSubscriptionBillingTypeEnum = typeof BillingSubscriptionBillingTypeEnum[keyof typeof BillingSubscriptionBillingTypeEnum];

/**
 * 
 * @export
 * @interface BillingSubscriptionChange
 */
export interface BillingSubscriptionChange {
    /**
     * 
     * @type {string}
     * @memberof BillingSubscriptionChange
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscriptionChange
     */
    'subscriptionId': string;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscriptionChange
     */
    'activeFrom': string;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscriptionChange
     */
    'priceId': string;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscriptionChange
     */
    'billingPeriod': BillingSubscriptionChangeBillingPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscriptionChange
     */
    'billingType': BillingSubscriptionChangeBillingTypeEnum;
    /**
     * 
     * @type {BillingSubscription}
     * @memberof BillingSubscriptionChange
     */
    'subscription'?: BillingSubscription;
}

export const BillingSubscriptionChangeBillingPeriodEnum = {
    Monthly: 'monthly',
    Annual: 'annual'
} as const;

export type BillingSubscriptionChangeBillingPeriodEnum = typeof BillingSubscriptionChangeBillingPeriodEnum[keyof typeof BillingSubscriptionChangeBillingPeriodEnum];
export const BillingSubscriptionChangeBillingTypeEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type BillingSubscriptionChangeBillingTypeEnum = typeof BillingSubscriptionChangeBillingTypeEnum[keyof typeof BillingSubscriptionChangeBillingTypeEnum];

/**
 * 
 * @export
 * @interface ButtonNamesDto
 */
export interface ButtonNamesDto {
    /**
     * 
     * @type {string}
     * @memberof ButtonNamesDto
     */
    'domainStatsButtonName': string;
    /**
     * 
     * @type {string}
     * @memberof ButtonNamesDto
     */
    'chapterStatsButtonName': string;
    /**
     * 
     * @type {string}
     * @memberof ButtonNamesDto
     */
    'domainButtonName': string;
    /**
     * 
     * @type {string}
     * @memberof ButtonNamesDto
     */
    'chapterButtonName': string;
}
/**
 * 
 * @export
 * @interface CancellationPromotionTermsResponseDto
 */
export interface CancellationPromotionTermsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof CancellationPromotionTermsResponseDto
     */
    'discount': number;
    /**
     * 
     * @type {CouponDiscountType}
     * @memberof CancellationPromotionTermsResponseDto
     */
    'discountType': CouponDiscountType;
    /**
     * 
     * @type {CouponSubscriptionDurationType}
     * @memberof CancellationPromotionTermsResponseDto
     */
    'duration': CouponSubscriptionDurationType;
    /**
     * 
     * @type {number}
     * @memberof CancellationPromotionTermsResponseDto
     */
    'durationInMonths'?: number;
}
/**
 * 
 * @export
 * @interface ChangeCurrentPlanDto
 */
export interface ChangeCurrentPlanDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeCurrentPlanDto
     */
    'newPlanId': string;
}
/**
 * 
 * @export
 * @interface ChangePromocodeRequestDto
 */
export interface ChangePromocodeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ChangePromocodeRequestDto
     */
    'promocode': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePromocodeRequestDto
     */
    'invoiceId': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePromocodeRequestDto
     */
    'directionId': string;
}
/**
 * 
 * @export
 * @interface CheatSheetUrlDto
 */
export interface CheatSheetUrlDto {
    /**
     * 
     * @type {string}
     * @memberof CheatSheetUrlDto
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface CheckPromocodeRequestDto
 */
export interface CheckPromocodeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CheckPromocodeRequestDto
     */
    'paymentPeriod': CheckPromocodeRequestDtoPaymentPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckPromocodeRequestDto
     */
    'level'?: CheckPromocodeRequestDtoLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckPromocodeRequestDto
     */
    'promocode': string;
    /**
     * 
     * @type {string}
     * @memberof CheckPromocodeRequestDto
     */
    'directionId': string;
}

export const CheckPromocodeRequestDtoPaymentPeriodEnum = {
    Monthly: 'monthly',
    Annual: 'annual',
    Lifetime: 'lifetime',
    Installment: 'installment',
    InstallmentFirstMonth: 'installment_first_month'
} as const;

export type CheckPromocodeRequestDtoPaymentPeriodEnum = typeof CheckPromocodeRequestDtoPaymentPeriodEnum[keyof typeof CheckPromocodeRequestDtoPaymentPeriodEnum];
export const CheckPromocodeRequestDtoLevelEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type CheckPromocodeRequestDtoLevelEnum = typeof CheckPromocodeRequestDtoLevelEnum[keyof typeof CheckPromocodeRequestDtoLevelEnum];

/**
 * 
 * @export
 * @interface CheckPromocodeResponseDto
 */
export interface CheckPromocodeResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof CheckPromocodeResponseDto
     */
    'valid': boolean;
    /**
     * 
     * @type {CouponResponseDto}
     * @memberof CheckPromocodeResponseDto
     */
    'coupon': CouponResponseDto;
}
/**
 * 
 * @export
 * @interface CheckPurchaseResponseDto
 */
export interface CheckPurchaseResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof CheckPurchaseResponseDto
     */
    'isPurchased': boolean;
}
/**
 * 
 * @export
 * @interface ClientSecretDto
 */
export interface ClientSecretDto {
    /**
     * 
     * @type {string}
     * @memberof ClientSecretDto
     */
    'clientSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSecretDto
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface CompleteFlashcardsPackResponseDto
 */
export interface CompleteFlashcardsPackResponseDto {
    /**
     * 
     * @type {number}
     * @memberof CompleteFlashcardsPackResponseDto
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface ContentBlockDto
 */
export interface ContentBlockDto {
    /**
     * 
     * @type {string}
     * @memberof ContentBlockDto
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof ContentBlockDto
     */
    'data': object;
    /**
     * 
     * @type {object}
     * @memberof ContentBlockDto
     */
    'tunes'?: object;
    /**
     * 
     * @type {string}
     * @memberof ContentBlockDto
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface CountDomainsAndChaptersResponseDto
 */
export interface CountDomainsAndChaptersResponseDto {
    /**
     * 
     * @type {number}
     * @memberof CountDomainsAndChaptersResponseDto
     */
    'domainsCount': number;
    /**
     * 
     * @type {number}
     * @memberof CountDomainsAndChaptersResponseDto
     */
    'chaptersCount': number;
}
/**
 * 
 * @export
 * @interface Coupon
 */
export interface Coupon {
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'directionId': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'promoCode': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'discountType': CouponDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'discountAmount': number;
    /**
     * 
     * @type {object}
     * @memberof Coupon
     */
    'subscriptionDurationType'?: object | null;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'subscriptionDuration': number | null;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'maxRedemptions': number | null;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'redeemBy': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Coupon
     */
    'allowedPeriods': Array<CouponAllowedPeriodsEnum>;
    /**
     * 
     * @type {Array<object>}
     * @memberof Coupon
     */
    'allowedPlans': Array<object> | null;
    /**
     * 
     * @type {Direction}
     * @memberof Coupon
     */
    'direction': Direction;
    /**
     * 
     * @type {Array<Course>}
     * @memberof Coupon
     */
    'allowedCourses'?: Array<Course>;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'updatedAt': string;
}

export const CouponDiscountTypeEnum = {
    Percentage: 'percentage',
    Amount: 'amount'
} as const;

export type CouponDiscountTypeEnum = typeof CouponDiscountTypeEnum[keyof typeof CouponDiscountTypeEnum];
export const CouponAllowedPeriodsEnum = {
    Monthly: 'monthly',
    Annual: 'annual',
    Lifetime: 'lifetime',
    Installment: 'installment',
    InstallmentFirstMonth: 'installment_first_month'
} as const;

export type CouponAllowedPeriodsEnum = typeof CouponAllowedPeriodsEnum[keyof typeof CouponAllowedPeriodsEnum];

/**
 * 
 * @export
 * @interface CouponDataDto
 */
export interface CouponDataDto {
    /**
     * 
     * @type {boolean}
     * @memberof CouponDataDto
     */
    'valid': boolean;
    /**
     * 
     * @type {CouponDataFieldsDto}
     * @memberof CouponDataDto
     */
    'coupon': CouponDataFieldsDto;
}
/**
 * 
 * @export
 * @interface CouponDataFieldsDto
 */
export interface CouponDataFieldsDto {
    /**
     * 
     * @type {string}
     * @memberof CouponDataFieldsDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CouponDataFieldsDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CouponDataFieldsDto
     */
    'directionId': string;
    /**
     * 
     * @type {string}
     * @memberof CouponDataFieldsDto
     */
    'promocode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponDataFieldsDto
     */
    'discountType': CouponDataFieldsDtoDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CouponDataFieldsDto
     */
    'discountAmount': number;
}

export const CouponDataFieldsDtoDiscountTypeEnum = {
    Percentage: 'percentage',
    Amount: 'amount'
} as const;

export type CouponDataFieldsDtoDiscountTypeEnum = typeof CouponDataFieldsDtoDiscountTypeEnum[keyof typeof CouponDataFieldsDtoDiscountTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const CouponDiscountType = {
    Percentage: 'percentage',
    Amount: 'amount'
} as const;

export type CouponDiscountType = typeof CouponDiscountType[keyof typeof CouponDiscountType];


/**
 * 
 * @export
 * @interface CouponResponseDto
 */
export interface CouponResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CouponResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CouponResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CouponResponseDto
     */
    'directionId': string;
    /**
     * 
     * @type {string}
     * @memberof CouponResponseDto
     */
    'promoCode': string;
    /**
     * 
     * @type {string}
     * @memberof CouponResponseDto
     */
    'discountType': CouponResponseDtoDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CouponResponseDto
     */
    'discountAmount': number;
    /**
     * 
     * @type {string}
     * @memberof CouponResponseDto
     */
    'subscriptionDurationType': CouponResponseDtoSubscriptionDurationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CouponResponseDto
     */
    'subscriptionDuration': number | null;
    /**
     * 
     * @type {number}
     * @memberof CouponResponseDto
     */
    'maxRedemptions': number | null;
    /**
     * 
     * @type {string}
     * @memberof CouponResponseDto
     */
    'redeemBy': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CouponResponseDto
     */
    'allowedPeriods': Array<CouponResponseDtoAllowedPeriodsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CouponResponseDto
     */
    'allowedPlans'?: Array<CouponResponseDtoAllowedPlansEnum>;
    /**
     * 
     * @type {Array<AdminCourseDto>}
     * @memberof CouponResponseDto
     */
    'allowedCourses': Array<AdminCourseDto>;
}

export const CouponResponseDtoDiscountTypeEnum = {
    Percentage: 'percentage',
    Amount: 'amount'
} as const;

export type CouponResponseDtoDiscountTypeEnum = typeof CouponResponseDtoDiscountTypeEnum[keyof typeof CouponResponseDtoDiscountTypeEnum];
export const CouponResponseDtoSubscriptionDurationTypeEnum = {
    Once: 'once',
    Forever: 'forever',
    Repeating: 'repeating'
} as const;

export type CouponResponseDtoSubscriptionDurationTypeEnum = typeof CouponResponseDtoSubscriptionDurationTypeEnum[keyof typeof CouponResponseDtoSubscriptionDurationTypeEnum];
export const CouponResponseDtoAllowedPeriodsEnum = {
    Monthly: 'monthly',
    Annual: 'annual',
    Lifetime: 'lifetime',
    Installment: 'installment',
    InstallmentFirstMonth: 'installment_first_month'
} as const;

export type CouponResponseDtoAllowedPeriodsEnum = typeof CouponResponseDtoAllowedPeriodsEnum[keyof typeof CouponResponseDtoAllowedPeriodsEnum];
export const CouponResponseDtoAllowedPlansEnum = {
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type CouponResponseDtoAllowedPlansEnum = typeof CouponResponseDtoAllowedPlansEnum[keyof typeof CouponResponseDtoAllowedPlansEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const CouponSubscriptionDurationType = {
    Once: 'once',
    Forever: 'forever',
    Repeating: 'repeating'
} as const;

export type CouponSubscriptionDurationType = typeof CouponSubscriptionDurationType[keyof typeof CouponSubscriptionDurationType];


/**
 * 
 * @export
 * @interface Course
 */
export interface Course {
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'releasedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'description': string;
    /**
     * 
     * @type {CoursePrices}
     * @memberof Course
     */
    'prices': CoursePrices;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'directionId': string;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    'flashcardsWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    'assignmentsWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    'testTopicsWeight': number;
    /**
     * 
     * @type {Array<Manual>}
     * @memberof Course
     */
    'manuals'?: Array<Manual>;
    /**
     * 
     * @type {object}
     * @memberof Course
     */
    'leastKnownCategory': object;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'type': CourseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'domainButtonName': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'chapterButtonName': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'domainStatsButtonName': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'chapterStatsButtonName': string;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    'activeCampaignListId'?: number;
    /**
     * 
     * @type {Direction}
     * @memberof Course
     */
    'direction'?: Direction;
    /**
     * 
     * @type {Array<BillingSubscription>}
     * @memberof Course
     */
    'billingSubscriptions': Array<BillingSubscription>;
    /**
     * 
     * @type {Array<BillingPurchase>}
     * @memberof Course
     */
    'purchases'?: Array<BillingPurchase>;
    /**
     * 
     * @type {Array<FlashcardsPack>}
     * @memberof Course
     */
    'flashcardsPacks'?: Array<FlashcardsPack>;
    /**
     * 
     * @type {Array<MnemonicCardsPackOrder>}
     * @memberof Course
     */
    'mnemonicCardsPackOrders': Array<MnemonicCardsPackOrder>;
    /**
     * 
     * @type {Array<FlashcardsPackOrder>}
     * @memberof Course
     */
    'flashcardsPackOrders': Array<FlashcardsPackOrder>;
    /**
     * 
     * @type {Array<MnemonicCardsPack>}
     * @memberof Course
     */
    'mnemonicCardsPacks'?: Array<MnemonicCardsPack>;
    /**
     * 
     * @type {Array<Plan>}
     * @memberof Course
     */
    'plans'?: Array<Plan>;
    /**
     * 
     * @type {Array<Task>}
     * @memberof Course
     */
    'tasks'?: Array<Task>;
    /**
     * 
     * @type {Guide}
     * @memberof Course
     */
    'guide': Guide;
    /**
     * 
     * @type {Array<CustomerCourse>}
     * @memberof Course
     */
    'customerCourses': Array<CustomerCourse>;
    /**
     * 
     * @type {Report}
     * @memberof Course
     */
    'reports': Report;
    /**
     * 
     * @type {Array<CourseReportWatchers>}
     * @memberof Course
     */
    'watchers': Array<CourseReportWatchers>;
    /**
     * 
     * @type {Array<QuestionDomain>}
     * @memberof Course
     */
    'domains': Array<QuestionDomain>;
    /**
     * 
     * @type {Array<QuestionChapter>}
     * @memberof Course
     */
    'chapters': Array<QuestionChapter>;
    /**
     * 
     * @type {Array<TimedTest>}
     * @memberof Course
     */
    'timedTests': Array<TimedTest>;
    /**
     * 
     * @type {Array<BillingInstallment>}
     * @memberof Course
     */
    'billingInstallments': Array<BillingInstallment>;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'updatedAt': string;
}

export const CourseTypeEnum = {
    Default: 'default',
    Certificate: 'certificate'
} as const;

export type CourseTypeEnum = typeof CourseTypeEnum[keyof typeof CourseTypeEnum];

/**
 * 
 * @export
 * @interface CourseDto
 */
export interface CourseDto {
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    'directionId': string;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    'assignmentsWeight': number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    'flashcardsWeight': number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    'testTopicsWeight': number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    'amountChaptersAtStudyGuide': number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    'amountFlashcardsPacks': number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    'amountMnemonicCardsPacks': number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    'amountQuestions': number;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    'type': CourseDtoTypeEnum;
    /**
     * 
     * @type {CoursePricesDto}
     * @memberof CourseDto
     */
    'prices': CoursePricesDto;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    'leastKnownCategory': CourseDtoLeastKnownCategoryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {PaymentDto}
     * @memberof CourseDto
     */
    'currentPayment': PaymentDto;
    /**
     * 
     * @type {PaymentDto}
     * @memberof CourseDto
     */
    'nextPayment'?: PaymentDto;
}

export const CourseDtoTypeEnum = {
    Default: 'default',
    Certificate: 'certificate'
} as const;

export type CourseDtoTypeEnum = typeof CourseDtoTypeEnum[keyof typeof CourseDtoTypeEnum];
export const CourseDtoLeastKnownCategoryEnum = {
    Domain: 'domain',
    Chapter: 'chapter'
} as const;

export type CourseDtoLeastKnownCategoryEnum = typeof CourseDtoLeastKnownCategoryEnum[keyof typeof CourseDtoLeastKnownCategoryEnum];

/**
 * 
 * @export
 * @interface CoursePrices
 */
export interface CoursePrices {
    /**
     * 
     * @type {string}
     * @memberof CoursePrices
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CoursePrices
     */
    'courseId': string;
    /**
     * 
     * @type {Course}
     * @memberof CoursePrices
     */
    'course'?: Course;
    /**
     * 
     * @type {object}
     * @memberof CoursePrices
     */
    'installment'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof CoursePrices
     */
    'purchase'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof CoursePrices
     */
    'monthlySubscriptions'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof CoursePrices
     */
    'annualSubscriptions'?: object | null;
}
/**
 * 
 * @export
 * @interface CoursePricesDto
 */
export interface CoursePricesDto {
    /**
     * 
     * @type {string}
     * @memberof CoursePricesDto
     */
    'id': string;
    /**
     * 
     * @type {CoursePricesDtoInstallment}
     * @memberof CoursePricesDto
     */
    'installment'?: CoursePricesDtoInstallment | null;
    /**
     * 
     * @type {CoursePricesDtoInstallment}
     * @memberof CoursePricesDto
     */
    'purchase'?: CoursePricesDtoInstallment | null;
    /**
     * 
     * @type {CoursePricesDtoMonthlySubscriptions}
     * @memberof CoursePricesDto
     */
    'monthlySubscriptions'?: CoursePricesDtoMonthlySubscriptions | null;
    /**
     * 
     * @type {CoursePricesDtoMonthlySubscriptions}
     * @memberof CoursePricesDto
     */
    'annualSubscriptions'?: CoursePricesDtoMonthlySubscriptions | null;
}
/**
 * 
 * @export
 * @interface CoursePricesDtoInstallment
 */
export interface CoursePricesDtoInstallment {
    /**
     * 
     * @type {string}
     * @memberof CoursePricesDtoInstallment
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CoursePricesDtoInstallment
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CoursePricesDtoInstallment
     */
    'oldAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursePricesDtoInstallment
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursePricesDtoInstallment
     */
    'defaultCouponId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursePricesDtoInstallment
     */
    'firstMonthDiscountCouponId'?: string;
    /**
     * 
     * @type {CouponResponseDto}
     * @memberof CoursePricesDtoInstallment
     */
    'defaultCoupon'?: CouponResponseDto;
    /**
     * 
     * @type {CouponResponseDto}
     * @memberof CoursePricesDtoInstallment
     */
    'firstMonthDiscountCoupon'?: CouponResponseDto;
    /**
     * 
     * @type {CancellationPromotionTermsResponseDto}
     * @memberof CoursePricesDtoInstallment
     */
    'cancellationPromotionTerms'?: CancellationPromotionTermsResponseDto;
}
/**
 * 
 * @export
 * @interface CoursePricesDtoMonthlySubscriptions
 */
export interface CoursePricesDtoMonthlySubscriptions {
    /**
     * 
     * @type {PriceDto}
     * @memberof CoursePricesDtoMonthlySubscriptions
     */
    'gold': PriceDto;
    /**
     * 
     * @type {PriceDto}
     * @memberof CoursePricesDtoMonthlySubscriptions
     */
    'silver': PriceDto;
    /**
     * 
     * @type {PriceDto}
     * @memberof CoursePricesDtoMonthlySubscriptions
     */
    'bronze': PriceDto;
}
/**
 * 
 * @export
 * @interface CourseReportSubscriberDto
 */
export interface CourseReportSubscriberDto {
    /**
     * 
     * @type {string}
     * @memberof CourseReportSubscriberDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CourseReportWatchers
 */
export interface CourseReportWatchers {
    /**
     * 
     * @type {string}
     * @memberof CourseReportWatchers
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CourseReportWatchers
     */
    'adminId': string;
    /**
     * 
     * @type {string}
     * @memberof CourseReportWatchers
     */
    'courseId': string;
    /**
     * 
     * @type {Admin}
     * @memberof CourseReportWatchers
     */
    'admin': Admin;
    /**
     * 
     * @type {Course}
     * @memberof CourseReportWatchers
     */
    'course': Course;
}
/**
 * 
 * @export
 * @interface CourseShortInfoResponseDto
 */
export interface CourseShortInfoResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CourseShortInfoResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CourseShortInfoResponseDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateAdminDto
 */
export interface CreateAdminDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'role': CreateAdminDtoRoleEnum;
    /**
     * 
     * @type {Array<DirectionIdDto>}
     * @memberof CreateAdminDto
     */
    'directions'?: Array<DirectionIdDto>;
}

export const CreateAdminDtoRoleEnum = {
    Admin: 'admin',
    Superadmin: 'superadmin'
} as const;

export type CreateAdminDtoRoleEnum = typeof CreateAdminDtoRoleEnum[keyof typeof CreateAdminDtoRoleEnum];

/**
 * 
 * @export
 * @interface CreateDirectionDto
 */
export interface CreateDirectionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'darkLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'checkoutLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'darkCheckoutLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'faviconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'signUpTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'restorePasswordTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'changeEmailTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'subscriptionPaymentFailedTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'courseUnreleasedTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'inviteTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'freeAccessGrantedTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'paymentSuccessfulTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'linkTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'linkDescription'?: string;
    /**
     * 
     * @type {CreateDirectionMailSettingsDto}
     * @memberof CreateDirectionDto
     */
    'mailSettings'?: CreateDirectionMailSettingsDto;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'landingUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'siteUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'termsOfServiceUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'privacyPolicyUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'mainColorHex'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionDto
     */
    'oldColorHex'?: string;
}
/**
 * 
 * @export
 * @interface CreateDirectionMailSettingsDto
 */
export interface CreateDirectionMailSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionMailSettingsDto
     */
    'emailDomain'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionMailSettingsDto
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionMailSettingsDto
     */
    'footerLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectionMailSettingsDto
     */
    'backgroundUrl'?: string;
}
/**
 * 
 * @export
 * @interface CreateFlashcardAnswerDto
 */
export interface CreateFlashcardAnswerDto {
    /**
     * 
     * @type {CustomerFlashcardAnswerType}
     * @memberof CreateFlashcardAnswerDto
     */
    'answer': CustomerFlashcardAnswerType;
    /**
     * 
     * @type {string}
     * @memberof CreateFlashcardAnswerDto
     */
    'packType': CreateFlashcardAnswerDtoPackTypeEnum;
}

export const CreateFlashcardAnswerDtoPackTypeEnum = {
    Questions: 'questions',
    Cards: 'cards'
} as const;

export type CreateFlashcardAnswerDtoPackTypeEnum = typeof CreateFlashcardAnswerDtoPackTypeEnum[keyof typeof CreateFlashcardAnswerDtoPackTypeEnum];

/**
 * 
 * @export
 * @interface CreateGuideChapterDto
 */
export interface CreateGuideChapterDto {
    /**
     * 
     * @type {string}
     * @memberof CreateGuideChapterDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGuideChapterDto
     */
    'audioUrl'?: string;
    /**
     * 
     * @type {Array<CreateGuideChapterUnitDto>}
     * @memberof CreateGuideChapterDto
     */
    'units': Array<CreateGuideChapterUnitDto>;
}
/**
 * 
 * @export
 * @interface CreateGuideChapterUnitDto
 */
export interface CreateGuideChapterUnitDto {
    /**
     * 
     * @type {string}
     * @memberof CreateGuideChapterUnitDto
     */
    'name': string;
    /**
     * 
     * @type {GuideContentDto}
     * @memberof CreateGuideChapterUnitDto
     */
    'content': GuideContentDto;
}
/**
 * 
 * @export
 * @interface CreateGuideChapterUnitRequestDto
 */
export interface CreateGuideChapterUnitRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateGuideChapterUnitRequestDto
     */
    'name': string;
    /**
     * 
     * @type {GuideContentDto}
     * @memberof CreateGuideChapterUnitRequestDto
     */
    'content': GuideContentDto;
    /**
     * 
     * @type {number}
     * @memberof CreateGuideChapterUnitRequestDto
     */
    'afterOrderIndex'?: number;
}
/**
 * 
 * @export
 * @interface CreateGuideChapterUnitsBulkRequestDto
 */
export interface CreateGuideChapterUnitsBulkRequestDto {
    /**
     * 
     * @type {Array<CreateGuideChapterUnitDto>}
     * @memberof CreateGuideChapterUnitsBulkRequestDto
     */
    'units': Array<CreateGuideChapterUnitDto>;
}
/**
 * 
 * @export
 * @interface CreateGuideRequestDto
 */
export interface CreateGuideRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateGuideRequestDto
     */
    'courseId': string;
    /**
     * 
     * @type {Array<CreateGuideChapterDto>}
     * @memberof CreateGuideRequestDto
     */
    'chapters': Array<CreateGuideChapterDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateGuideRequestDto
     */
    'cheatSheetUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateGuideRequestDto
     */
    'textbookUrl'?: string;
}
/**
 * 
 * @export
 * @interface CreateManyTaskDto
 */
export interface CreateManyTaskDto {
    /**
     * 
     * @type {Array<AdminCreateTaskDto>}
     * @memberof CreateManyTaskDto
     */
    'bulk': Array<AdminCreateTaskDto>;
}
/**
 * 
 * @export
 * @interface CreateMnemonicCardDto
 */
export interface CreateMnemonicCardDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMnemonicCardDto
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMnemonicCardDto
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof CreateMnemonicCardDto
     */
    'answersCount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateMnemonicCardDto
     */
    'packId': string;
}
/**
 * 
 * @export
 * @interface CreateMnemonicCardsPackDto
 */
export interface CreateMnemonicCardsPackDto {
    /**
     * Entity in front of which you want to insert.    The Get many query returns the data in a specific order.    For example you had      1. entity with id \"q\"     2. entity with id \"w\"     3. entity with id \"e\"        You need to insert so that the new entity is before the \"w\". You pass in the field \"before\" \"w\".      1. pack with id \"q\"     2. pack with id \"r\"     3. pack with id \"w\"     4. pack with id \"e\"    To insert at the end, don\'t pass the \"before\" field   
     * @type {string}
     * @memberof CreateMnemonicCardsPackDto
     */
    'before'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMnemonicCardsPackDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMnemonicCardsPackDto
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface CreateNewChapterDto
 */
export interface CreateNewChapterDto {
    /**
     * 
     * @type {string}
     * @memberof CreateNewChapterDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewChapterDto
     */
    'audioUrl'?: string;
    /**
     * 
     * @type {Array<CreateGuideChapterUnitDto>}
     * @memberof CreateNewChapterDto
     */
    'units': Array<CreateGuideChapterUnitDto>;
    /**
     * 
     * @type {number}
     * @memberof CreateNewChapterDto
     */
    'afterOrderIndex'?: number;
}
/**
 * 
 * @export
 * @interface CreateNoteRequestDto
 */
export interface CreateNoteRequestDto {
    /**
     * 
     * @type {number}
     * @memberof CreateNoteRequestDto
     */
    'startIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CreateNoteRequestDto
     */
    'endIndex': number;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequestDto
     */
    'selectedText': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequestDto
     */
    'noteText'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequestDto
     */
    'chapterId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequestDto
     */
    'unitId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequestDto
     */
    'blockId': string;
}
/**
 * 
 * @export
 * @interface CreateQuestionFlashcardDto
 */
export interface CreateQuestionFlashcardDto {
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionFlashcardDto
     */
    'questionId': string;
}
/**
 * 
 * @export
 * @interface CreateReportDto
 */
export interface CreateReportDto {
    /**
     * 
     * @type {string}
     * @memberof CreateReportDto
     */
    'resource': CreateReportDtoResourceEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateReportDto
     */
    'resourceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReportDto
     */
    'content': string;
}

export const CreateReportDtoResourceEnum = {
    Flashcard: 'flashcard',
    MnemonicCard: 'mnemonic_card',
    TestQuestion: 'test_question'
} as const;

export type CreateReportDtoResourceEnum = typeof CreateReportDtoResourceEnum[keyof typeof CreateReportDtoResourceEnum];

/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'stripeCustomerId': string;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    'isFirstLogin': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lastSessionAt': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'confirmationEmailHash'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'expiresInConfirmationEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'newEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'expiresInGeneratingPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'password': string;
    /**
     * 
     * @type {CustomerPasswordChanges}
     * @memberof Customer
     */
    'changedPassword'?: CustomerPasswordChanges;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'directionId': string;
    /**
     * 
     * @type {Direction}
     * @memberof Customer
     */
    'direction': Direction;
    /**
     * 
     * @type {Array<CustomerFlashcardAnswer>}
     * @memberof Customer
     */
    'flashcardAnswers'?: Array<CustomerFlashcardAnswer>;
    /**
     * 
     * @type {Array<BillingSubscription>}
     * @memberof Customer
     */
    'subscriptions': Array<BillingSubscription>;
    /**
     * 
     * @type {Array<BillingPurchase>}
     * @memberof Customer
     */
    'purchases'?: Array<BillingPurchase>;
    /**
     * 
     * @type {Array<CustomerCompletedTask>}
     * @memberof Customer
     */
    'completedTasks'?: Array<CustomerCompletedTask>;
    /**
     * 
     * @type {Array<CustomerCurrentPlan>}
     * @memberof Customer
     */
    'currentPlans'?: Array<CustomerCurrentPlan>;
    /**
     * 
     * @type {Note}
     * @memberof Customer
     */
    'notes'?: Note;
    /**
     * 
     * @type {Array<CustomerCourse>}
     * @memberof Customer
     */
    'customerCourses'?: Array<CustomerCourse>;
    /**
     * 
     * @type {Array<Report>}
     * @memberof Customer
     */
    'reports': Array<Report>;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof Customer
     */
    'paymentMethod'?: PaymentMethod;
    /**
     * 
     * @type {Array<NewQuestion>}
     * @memberof Customer
     */
    'newMarkedQuestions': Array<NewQuestion>;
    /**
     * 
     * @type {Array<NewTestPassage>}
     * @memberof Customer
     */
    'newPassages': Array<NewTestPassage>;
    /**
     * 
     * @type {CustomerAppSettingsEntity}
     * @memberof Customer
     */
    'appSettings'?: CustomerAppSettingsEntity;
    /**
     * 
     * @type {Array<BillingInstallment>}
     * @memberof Customer
     */
    'billingInstallments': Array<BillingInstallment>;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CustomerAppSettingsEntity
 */
export interface CustomerAppSettingsEntity {
    /**
     * 
     * @type {string}
     * @memberof CustomerAppSettingsEntity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAppSettingsEntity
     */
    'customerId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerAppSettingsEntity
     */
    'showStreaks': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerAppSettingsEntity
     */
    'turnOnSoundsInApp': boolean;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerAppSettingsEntity
     */
    'customer'?: Customer;
}
/**
 * 
 * @export
 * @interface CustomerAppSettingsResponseDto
 */
export interface CustomerAppSettingsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerAppSettingsResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAppSettingsResponseDto
     */
    'customerId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerAppSettingsResponseDto
     */
    'showStreaks': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerAppSettingsResponseDto
     */
    'turnOnSoundsInApp': boolean;
}
/**
 * 
 * @export
 * @interface CustomerAuthResponseDto
 */
export interface CustomerAuthResponseDto {
    /**
     * 
     * @type {CustomerDto}
     * @memberof CustomerAuthResponseDto
     */
    'customer': CustomerDto;
    /**
     * JWT token
     * @type {string}
     * @memberof CustomerAuthResponseDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface CustomerChangeEmailDto
 */
export interface CustomerChangeEmailDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerChangeEmailDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CustomerChapterNoteResponseDto
 */
export interface CustomerChapterNoteResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerChapterNoteResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerChapterNoteResponseDto
     */
    'chapterId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerChapterNoteResponseDto
     */
    'unitId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerChapterNoteResponseDto
     */
    'blockId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerChapterNoteResponseDto
     */
    'noteText'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerChapterNoteResponseDto
     */
    'selectedText': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerChapterNoteResponseDto
     */
    'startIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerChapterNoteResponseDto
     */
    'endIndex': number;
}
/**
 * 
 * @export
 * @interface CustomerChapterResponseDto
 */
export interface CustomerChapterResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerChapterResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerChapterResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerChapterResponseDto
     */
    'audioUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerChapterResponseDto
     */
    'quizId'?: string;
    /**
     * 
     * @type {Array<CustomerChapterUnitResponseDto>}
     * @memberof CustomerChapterResponseDto
     */
    'units': Array<CustomerChapterUnitResponseDto>;
    /**
     * 
     * @type {Array<CustomerChapterNoteResponseDto>}
     * @memberof CustomerChapterResponseDto
     */
    'notes'?: Array<CustomerChapterNoteResponseDto> | null;
}
/**
 * 
 * @export
 * @interface CustomerChapterUnitResponseDto
 */
export interface CustomerChapterUnitResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerChapterUnitResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerChapterUnitResponseDto
     */
    'name': string;
    /**
     * 
     * @type {GuideContentDto}
     * @memberof CustomerChapterUnitResponseDto
     */
    'content': GuideContentDto;
    /**
     * 
     * @type {number}
     * @memberof CustomerChapterUnitResponseDto
     */
    'orderIndex': number;
    /**
     * 
     * @type {Array<CustomerChapterNoteResponseDto>}
     * @memberof CustomerChapterUnitResponseDto
     */
    'notes'?: Array<CustomerChapterNoteResponseDto> | null;
}
/**
 * 
 * @export
 * @interface CustomerCompletedTask
 */
export interface CustomerCompletedTask {
    /**
     * 
     * @type {string}
     * @memberof CustomerCompletedTask
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompletedTask
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerCompletedTask
     */
    'customer': Customer;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompletedTask
     */
    'taskId': string;
    /**
     * 
     * @type {Task}
     * @memberof CustomerCompletedTask
     */
    'task': Task;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompletedTask
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompletedTask
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CustomerCompletedTaskDto
 */
export interface CustomerCompletedTaskDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerCompletedTaskDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompletedTaskDto
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompletedTaskDto
     */
    'taskId': string;
}
/**
 * 
 * @export
 * @interface CustomerConfirmEmailDto
 */
export interface CustomerConfirmEmailDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerConfirmEmailDto
     */
    'confirmationEmailHash': string;
}
/**
 * 
 * @export
 * @interface CustomerCourse
 */
export interface CustomerCourse {
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerCourse
     */
    'customer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'courseId': string;
    /**
     * 
     * @type {Course}
     * @memberof CustomerCourse
     */
    'course'?: Course;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'lastActivatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'examDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerCourse
     */
    'streak': number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerCourse
     */
    'addedStreakToday': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'lastActivityDate': string;
}
/**
 * 
 * @export
 * @interface CustomerCurrentPlan
 */
export interface CustomerCurrentPlan {
    /**
     * 
     * @type {string}
     * @memberof CustomerCurrentPlan
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCurrentPlan
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerCurrentPlan
     */
    'customer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof CustomerCurrentPlan
     */
    'planId': string;
    /**
     * 
     * @type {Plan}
     * @memberof CustomerCurrentPlan
     */
    'plan'?: Plan;
    /**
     * 
     * @type {string}
     * @memberof CustomerCurrentPlan
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCurrentPlan
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDto
     */
    'isFirstLogin': boolean | null;
    /**
     * 
     * @type {PaymentMethodDto}
     * @memberof CustomerDto
     */
    'paymentMethod': PaymentMethodDto;
}
/**
 * 
 * @export
 * @interface CustomerFlashcardAnswer
 */
export interface CustomerFlashcardAnswer {
    /**
     * 
     * @type {Customer}
     * @memberof CustomerFlashcardAnswer
     */
    'customer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof CustomerFlashcardAnswer
     */
    'customerId': string;
    /**
     * 
     * @type {Flashcard}
     * @memberof CustomerFlashcardAnswer
     */
    'flashcard'?: Flashcard;
    /**
     * 
     * @type {string}
     * @memberof CustomerFlashcardAnswer
     */
    'flashcardId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFlashcardAnswer
     */
    'lastAnswer': CustomerFlashcardAnswerLastAnswerEnum;
    /**
     * 
     * @type {number}
     * @memberof CustomerFlashcardAnswer
     */
    'interval': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerFlashcardAnswer
     */
    'willBeAvailableAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFlashcardAnswer
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFlashcardAnswer
     */
    'updatedAt': string;
}

export const CustomerFlashcardAnswerLastAnswerEnum = {
    No: 'NO',
    Kinda: 'KINDA',
    Yes: 'YES'
} as const;

export type CustomerFlashcardAnswerLastAnswerEnum = typeof CustomerFlashcardAnswerLastAnswerEnum[keyof typeof CustomerFlashcardAnswerLastAnswerEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerFlashcardAnswerType = {
    No: 'NO',
    Kinda: 'KINDA',
    Yes: 'YES'
} as const;

export type CustomerFlashcardAnswerType = typeof CustomerFlashcardAnswerType[keyof typeof CustomerFlashcardAnswerType];


/**
 * 
 * @export
 * @interface CustomerFlashcardsPack
 */
export interface CustomerFlashcardsPack {
    /**
     * 
     * @type {Customer}
     * @memberof CustomerFlashcardsPack
     */
    'customer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof CustomerFlashcardsPack
     */
    'customerId': string;
    /**
     * 
     * @type {FlashcardsPack}
     * @memberof CustomerFlashcardsPack
     */
    'pack'?: FlashcardsPack;
    /**
     * 
     * @type {string}
     * @memberof CustomerFlashcardsPack
     */
    'packId': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerFlashcardsPack
     */
    'lastNumberOfPositiveAnswers': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerFlashcardsPack
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFlashcardsPack
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CustomerGuideResponseDto
 */
export interface CustomerGuideResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerGuideResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerGuideResponseDto
     */
    'cheatSheetUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerGuideResponseDto
     */
    'textbookUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerPasswordChanges
 */
export interface CustomerPasswordChanges {
    /**
     * 
     * @type {string}
     * @memberof CustomerPasswordChanges
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPasswordChanges
     */
    'verificationCode': string;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerPasswordChanges
     */
    'customer': Customer;
}
/**
 * 
 * @export
 * @interface CustomerRestorePassword
 */
export interface CustomerRestorePassword {
    /**
     * 
     * @type {string}
     * @memberof CustomerRestorePassword
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRestorePassword
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRestorePassword
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRestorePassword
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerRestorePassword
     */
    'isFirstLogin': boolean | null;
    /**
     * 
     * @type {PaymentMethodDto}
     * @memberof CustomerRestorePassword
     */
    'paymentMethod': PaymentMethodDto;
    /**
     * 
     * @type {string}
     * @memberof CustomerRestorePassword
     */
    'expiresInGeneratingPassword': string | null;
}
/**
 * 
 * @export
 * @interface CustomerRestorePasswordDto
 */
export interface CustomerRestorePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerRestorePasswordDto
     */
    'directionId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRestorePasswordDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CustomerRestorePasswordResponseDto
 */
export interface CustomerRestorePasswordResponseDto {
    /**
     * 
     * @type {CustomerRestorePassword}
     * @memberof CustomerRestorePasswordResponseDto
     */
    'customer': CustomerRestorePassword;
    /**
     * Password. Will be send only in development environment
     * @type {string}
     * @memberof CustomerRestorePasswordResponseDto
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface CustomerSignInDto
 */
export interface CustomerSignInDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerSignInDto
     */
    'directionId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSignInDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSignInDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CustomerSignUpDto
 */
export interface CustomerSignUpDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerSignUpDto
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSignUpDto
     */
    'directionId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSignUpDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CustomerSignUpFromLandingDto
 */
export interface CustomerSignUpFromLandingDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerSignUpFromLandingDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSignUpFromLandingDto
     */
    'directionId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSignUpFromLandingDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CustomerSignUpPromoDto
 */
export interface CustomerSignUpPromoDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerSignUpPromoDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSignUpPromoDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSignUpPromoDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSignUpPromoDto
     */
    'billingType': CustomerSignUpPromoDtoBillingTypeEnum;
}

export const CustomerSignUpPromoDtoBillingTypeEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type CustomerSignUpPromoDtoBillingTypeEnum = typeof CustomerSignUpPromoDtoBillingTypeEnum[keyof typeof CustomerSignUpPromoDtoBillingTypeEnum];

/**
 * 
 * @export
 * @interface CustomerSignUpResponseDto
 */
export interface CustomerSignUpResponseDto {
    /**
     * 
     * @type {CustomerDto}
     * @memberof CustomerSignUpResponseDto
     */
    'customer': CustomerDto;
    /**
     * Password. Will be send only in development environment
     * @type {string}
     * @memberof CustomerSignUpResponseDto
     */
    'password'?: string;
    /**
     * JWT token
     * @type {string}
     * @memberof CustomerSignUpResponseDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface CustomerTableOfGuideContentChapterResponseDto
 */
export interface CustomerTableOfGuideContentChapterResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerTableOfGuideContentChapterResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTableOfGuideContentChapterResponseDto
     */
    'name': string;
    /**
     * 
     * @type {Array<CustomerTableOfGuideContentResponseChapterUnitDto>}
     * @memberof CustomerTableOfGuideContentChapterResponseDto
     */
    'units': Array<CustomerTableOfGuideContentResponseChapterUnitDto>;
}
/**
 * 
 * @export
 * @interface CustomerTableOfGuideContentResponseChapterUnitDto
 */
export interface CustomerTableOfGuideContentResponseChapterUnitDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerTableOfGuideContentResponseChapterUnitDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTableOfGuideContentResponseChapterUnitDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CustomerTableOfGuideContentResponseDto
 */
export interface CustomerTableOfGuideContentResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerTableOfGuideContentResponseDto
     */
    'id': string;
    /**
     * 
     * @type {Array<CustomerTableOfGuideContentChapterResponseDto>}
     * @memberof CustomerTableOfGuideContentResponseDto
     */
    'chapters': Array<CustomerTableOfGuideContentChapterResponseDto>;
}
/**
 * 
 * @export
 * @interface DeletePromocodeRequestDto
 */
export interface DeletePromocodeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DeletePromocodeRequestDto
     */
    'invoiceId': string;
    /**
     * 
     * @type {string}
     * @memberof DeletePromocodeRequestDto
     */
    'directionId': string;
}
/**
 * 
 * @export
 * @interface Direction
 */
export interface Direction {
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'landingUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'siteUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'linkTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'linkDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'termsOfServiceUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'privacyPolicyUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'mainColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'oldColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'darkLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'checkoutLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'darkCheckoutLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'faviconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'signUpTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'restorePasswordTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'changeEmailTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'subscriptionPaymentFailedTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'courseUnreleasedTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'inviteTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'freeAccessGrantedTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'paymentSuccessfulTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'passwordChangedTemplateId'?: string;
    /**
     * 
     * @type {Array<Course>}
     * @memberof Direction
     */
    'courses': Array<Course>;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof Direction
     */
    'customers': Array<Customer>;
    /**
     * 
     * @type {Array<Admin>}
     * @memberof Direction
     */
    'admins': Array<Admin>;
    /**
     * 
     * @type {Array<Coupon>}
     * @memberof Direction
     */
    'coupons': Array<Coupon>;
    /**
     * 
     * @type {StripeProvider}
     * @memberof Direction
     */
    'stripeProvider'?: StripeProvider;
    /**
     * 
     * @type {DirectionMailSettings}
     * @memberof Direction
     */
    'mailSettings'?: DirectionMailSettings;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface DirectionDto
 */
export interface DirectionDto {
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'landingUrl': string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'siteUrl': string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'darkLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'checkoutLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'darkCheckoutLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'faviconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'termsOfServiceUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'privacyPolicyUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'mainColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'oldColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'linkTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'linkDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DirectionDto
     */
    'stripePublicKey'?: string;
}
/**
 * 
 * @export
 * @interface DirectionIdDto
 */
export interface DirectionIdDto {
    /**
     * 
     * @type {string}
     * @memberof DirectionIdDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface DirectionMailSettings
 */
export interface DirectionMailSettings {
    /**
     * 
     * @type {string}
     * @memberof DirectionMailSettings
     */
    'directionId': string;
    /**
     * 
     * @type {string}
     * @memberof DirectionMailSettings
     */
    'emailDomain'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionMailSettings
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionMailSettings
     */
    'footerLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionMailSettings
     */
    'backgroundUrl'?: string;
    /**
     * 
     * @type {Direction}
     * @memberof DirectionMailSettings
     */
    'direction'?: Direction;
}
/**
 * 
 * @export
 * @interface DomainOrChapterRequestDto
 */
export interface DomainOrChapterRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DomainOrChapterRequestDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof DomainOrChapterRequestDto
     */
    'amountOfQuestions': number;
}
/**
 * 
 * @export
 * @interface DomainOrChapterResponseDto
 */
export interface DomainOrChapterResponseDto {
    /**
     * 
     * @type {string}
     * @memberof DomainOrChapterResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DomainOrChapterResponseDto
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof DomainOrChapterResponseDto
     */
    'amountOfQuestions': number;
}
/**
 * 
 * @export
 * @interface EditorJSImageData
 */
export interface EditorJSImageData {
    /**
     * 
     * @type {EditorJSImageFileData}
     * @memberof EditorJSImageData
     */
    'file': EditorJSImageFileData;
    /**
     * 
     * @type {string}
     * @memberof EditorJSImageData
     */
    'caption'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditorJSImageData
     */
    'withBorder'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditorJSImageData
     */
    'stretched'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditorJSImageData
     */
    'withBackground'?: boolean;
}
/**
 * 
 * @export
 * @interface EditorJSImageFileData
 */
export interface EditorJSImageFileData {
    /**
     * 
     * @type {string}
     * @memberof EditorJSImageFileData
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface EditorJSImageResponseDto
 */
export interface EditorJSImageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof EditorJSImageResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EditorJSImageResponseDto
     */
    'type': string;
    /**
     * 
     * @type {EditorJSImageData}
     * @memberof EditorJSImageResponseDto
     */
    'data': EditorJSImageData;
    /**
     * 
     * @type {object}
     * @memberof EditorJSImageResponseDto
     */
    'tunes': object;
}
/**
 * 
 * @export
 * @interface EditorJSListData
 */
export interface EditorJSListData {
    /**
     * 
     * @type {string}
     * @memberof EditorJSListData
     */
    'style': EditorJSListDataStyleEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditorJSListData
     */
    'items': Array<string>;
}

export const EditorJSListDataStyleEnum = {
    Ordered: 'ordered',
    Unordered: 'unordered'
} as const;

export type EditorJSListDataStyleEnum = typeof EditorJSListDataStyleEnum[keyof typeof EditorJSListDataStyleEnum];

/**
 * 
 * @export
 * @interface EditorJSListResponseDto
 */
export interface EditorJSListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof EditorJSListResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EditorJSListResponseDto
     */
    'type': string;
    /**
     * 
     * @type {EditorJSListData}
     * @memberof EditorJSListResponseDto
     */
    'data': EditorJSListData;
    /**
     * 
     * @type {object}
     * @memberof EditorJSListResponseDto
     */
    'tunes': object;
}
/**
 * 
 * @export
 * @interface EditorJSParagraphData
 */
export interface EditorJSParagraphData {
    /**
     * 
     * @type {string}
     * @memberof EditorJSParagraphData
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface EditorJSParagraphResponseDto
 */
export interface EditorJSParagraphResponseDto {
    /**
     * 
     * @type {string}
     * @memberof EditorJSParagraphResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EditorJSParagraphResponseDto
     */
    'type': string;
    /**
     * 
     * @type {EditorJSParagraphData}
     * @memberof EditorJSParagraphResponseDto
     */
    'data': EditorJSParagraphData;
    /**
     * 
     * @type {object}
     * @memberof EditorJSParagraphResponseDto
     */
    'tunes': object;
}
/**
 * 
 * @export
 * @interface ErrorResponseDto
 */
export interface ErrorResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ErrorResponseDto
     */
    'statusCode': number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseDto
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseDto
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface FinishedPassageResponseDto
 */
export interface FinishedPassageResponseDto {
    /**
     * Passage ID
     * @type {string}
     * @memberof FinishedPassageResponseDto
     */
    'id': string;
    /**
     * Course ID
     * @type {string}
     * @memberof FinishedPassageResponseDto
     */
    'courseId'?: string;
    /**
     * Timed test ID
     * @type {string}
     * @memberof FinishedPassageResponseDto
     */
    'timedTestId'?: string;
    /**
     * Quiz ID
     * @type {string}
     * @memberof FinishedPassageResponseDto
     */
    'quizId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinishedPassageResponseDto
     */
    'type': FinishedPassageResponseDtoTypeEnum;
    /**
     * Shows that customer passed the test. Defined only for timed tests
     * @type {boolean}
     * @memberof FinishedPassageResponseDto
     */
    'passed'?: boolean;
    /**
     * Points earned or lost for test
     * @type {number}
     * @memberof FinishedPassageResponseDto
     */
    'pointsEarned': number;
}

export const FinishedPassageResponseDtoTypeEnum = {
    TimedTest: 'timed_test',
    TutorTest: 'tutor_test',
    QuizTest: 'quiz_test'
} as const;

export type FinishedPassageResponseDtoTypeEnum = typeof FinishedPassageResponseDtoTypeEnum[keyof typeof FinishedPassageResponseDtoTypeEnum];

/**
 * 
 * @export
 * @interface Flashcard
 */
export interface Flashcard {
    /**
     * 
     * @type {string}
     * @memberof Flashcard
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Flashcard
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof Flashcard
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof Flashcard
     */
    'questionImageUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof Flashcard
     */
    'answerImageUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof Flashcard
     */
    'packId': string;
    /**
     * 
     * @type {FlashcardsPack}
     * @memberof Flashcard
     */
    'pack'?: FlashcardsPack;
    /**
     * 
     * @type {Array<CustomerFlashcardAnswer>}
     * @memberof Flashcard
     */
    'customerAnswers': Array<CustomerFlashcardAnswer>;
    /**
     * 
     * @type {string}
     * @memberof Flashcard
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Flashcard
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface FlashcardAnswerStatsDto
 */
export interface FlashcardAnswerStatsDto {
    /**
     * 
     * @type {number}
     * @memberof FlashcardAnswerStatsDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof FlashcardAnswerStatsDto
     */
    'percent': number;
}
/**
 * @type FlashcardControllerGetFlashcards200Response
 * @export
 */
export type FlashcardControllerGetFlashcards200Response = FlashcardsDto | QuestionFlashcardsDto;

/**
 * 
 * @export
 * @interface FlashcardDto
 */
export interface FlashcardDto {
    /**
     * 
     * @type {string}
     * @memberof FlashcardDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlashcardDto
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof FlashcardDto
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof FlashcardDto
     */
    'packId': string;
    /**
     * 
     * @type {string}
     * @memberof FlashcardDto
     */
    'questionImageUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof FlashcardDto
     */
    'answerImageUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof FlashcardDto
     */
    'lastAnswer': FlashcardDtoLastAnswerEnum;
    /**
     * 
     * @type {FlashcardNextIntervals}
     * @memberof FlashcardDto
     */
    'nextIntervals': FlashcardNextIntervals;
}

export const FlashcardDtoLastAnswerEnum = {
    No: 'NO',
    Kinda: 'KINDA',
    Yes: 'YES'
} as const;

export type FlashcardDtoLastAnswerEnum = typeof FlashcardDtoLastAnswerEnum[keyof typeof FlashcardDtoLastAnswerEnum];

/**
 * 
 * @export
 * @interface FlashcardNextIntervals
 */
export interface FlashcardNextIntervals {
    /**
     * 
     * @type {number}
     * @memberof FlashcardNextIntervals
     */
    'yes': number;
    /**
     * 
     * @type {number}
     * @memberof FlashcardNextIntervals
     */
    'kinda': number;
    /**
     * 
     * @type {number}
     * @memberof FlashcardNextIntervals
     */
    'no': number;
}
/**
 * 
 * @export
 * @interface FlashcardPackStatsDto
 */
export interface FlashcardPackStatsDto {
    /**
     * 
     * @type {number}
     * @memberof FlashcardPackStatsDto
     */
    'total': number;
    /**
     * 
     * @type {FlashcardAnswerStatsDto}
     * @memberof FlashcardPackStatsDto
     */
    'yes': FlashcardAnswerStatsDto;
    /**
     * 
     * @type {FlashcardAnswerStatsDto}
     * @memberof FlashcardPackStatsDto
     */
    'kinda': FlashcardAnswerStatsDto;
    /**
     * 
     * @type {FlashcardAnswerStatsDto}
     * @memberof FlashcardPackStatsDto
     */
    'no': FlashcardAnswerStatsDto;
    /**
     * 
     * @type {FlashcardAnswerStatsDto}
     * @memberof FlashcardPackStatsDto
     */
    'unanswered': FlashcardAnswerStatsDto;
    /**
     * 
     * @type {string}
     * @memberof FlashcardPackStatsDto
     */
    'packId': string;
    /**
     * 
     * @type {string}
     * @memberof FlashcardPackStatsDto
     */
    'packName': string;
}
/**
 * 
 * @export
 * @interface FlashcardsBaseStatsDto
 */
export interface FlashcardsBaseStatsDto {
    /**
     * 
     * @type {number}
     * @memberof FlashcardsBaseStatsDto
     */
    'total': number;
    /**
     * 
     * @type {FlashcardAnswerStatsDto}
     * @memberof FlashcardsBaseStatsDto
     */
    'yes': FlashcardAnswerStatsDto;
    /**
     * 
     * @type {FlashcardAnswerStatsDto}
     * @memberof FlashcardsBaseStatsDto
     */
    'kinda': FlashcardAnswerStatsDto;
    /**
     * 
     * @type {FlashcardAnswerStatsDto}
     * @memberof FlashcardsBaseStatsDto
     */
    'no': FlashcardAnswerStatsDto;
    /**
     * 
     * @type {FlashcardAnswerStatsDto}
     * @memberof FlashcardsBaseStatsDto
     */
    'unanswered': FlashcardAnswerStatsDto;
}
/**
 * 
 * @export
 * @interface FlashcardsDto
 */
export interface FlashcardsDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof FlashcardsDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<FlashcardDto>}
     * @memberof FlashcardsDto
     */
    'items': Array<FlashcardDto>;
    /**
     * Time to next available flashcard in seconds
     * @type {number}
     * @memberof FlashcardsDto
     */
    'timeToNextAvailableCard': number;
    /**
     * 
     * @type {string}
     * @memberof FlashcardsDto
     */
    'packName': string;
}
/**
 * 
 * @export
 * @interface FlashcardsPack
 */
export interface FlashcardsPack {
    /**
     * 
     * @type {string}
     * @memberof FlashcardsPack
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlashcardsPack
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlashcardsPack
     */
    'courseId': string;
    /**
     * 
     * @type {Course}
     * @memberof FlashcardsPack
     */
    'course'?: Course;
    /**
     * 
     * @type {Array<Flashcard>}
     * @memberof FlashcardsPack
     */
    'flashcards'?: Array<Flashcard>;
    /**
     * 
     * @type {FlashcardsPackOrder}
     * @memberof FlashcardsPack
     */
    'order': FlashcardsPackOrder;
    /**
     * 
     * @type {Array<CustomerFlashcardsPack>}
     * @memberof FlashcardsPack
     */
    'customerFlashcardsPacks': Array<CustomerFlashcardsPack>;
    /**
     * 
     * @type {string}
     * @memberof FlashcardsPack
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FlashcardsPack
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface FlashcardsPackDto
 */
export interface FlashcardsPackDto {
    /**
     * 
     * @type {string}
     * @memberof FlashcardsPackDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof FlashcardsPackDto
     */
    'learnedCount': number;
    /**
     * 
     * @type {string}
     * @memberof FlashcardsPackDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FlashcardsPackDto
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface FlashcardsPackOrder
 */
export interface FlashcardsPackOrder {
    /**
     * 
     * @type {string}
     * @memberof FlashcardsPackOrder
     */
    'flashcardsPackId': string;
    /**
     * 
     * @type {FlashcardsPack}
     * @memberof FlashcardsPackOrder
     */
    'flashcardsPack': FlashcardsPack;
    /**
     * 
     * @type {string}
     * @memberof FlashcardsPackOrder
     */
    'courseId': string;
    /**
     * 
     * @type {Course}
     * @memberof FlashcardsPackOrder
     */
    'course'?: Course;
    /**
     * 
     * @type {number}
     * @memberof FlashcardsPackOrder
     */
    'orderIndex': number;
    /**
     * 
     * @type {string}
     * @memberof FlashcardsPackOrder
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FlashcardsPackOrder
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface FlashcardsPacksDto
 */
export interface FlashcardsPacksDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof FlashcardsPacksDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<FlashcardsPackDto>}
     * @memberof FlashcardsPacksDto
     */
    'items': Array<FlashcardsPackDto>;
}
/**
 * 
 * @export
 * @interface FlashcardsPointsDto
 */
export interface FlashcardsPointsDto {
    /**
     * Total amount of flashcards in course
     * @type {number}
     * @memberof FlashcardsPointsDto
     */
    'total': number;
    /**
     * Amount of learned flashcards
     * @type {number}
     * @memberof FlashcardsPointsDto
     */
    'learned': number;
    /**
     * Weight of single flashcard in percents
     * @type {number}
     * @memberof FlashcardsPointsDto
     */
    'flashcardWeightInPercents': number;
    /**
     * Weight of single flashcard in points
     * @type {number}
     * @memberof FlashcardsPointsDto
     */
    'flashcardWeightInPoints': number;
    /**
     * Total weight of flashcards in percents
     * @type {number}
     * @memberof FlashcardsPointsDto
     */
    'totalFlashcardWeightInPercents': number;
    /**
     * Total weight of flashcards in points
     * @type {number}
     * @memberof FlashcardsPointsDto
     */
    'totalFlashcardWeightInPoints': number;
    /**
     * Amount of learned flashcards in percents
     * @type {number}
     * @memberof FlashcardsPointsDto
     */
    'learnedInPercents': number;
    /**
     * Amount of learned flashcards in points
     * @type {number}
     * @memberof FlashcardsPointsDto
     */
    'learnedInPoints': number;
}
/**
 * 
 * @export
 * @interface ForgottenPasswordDto
 */
export interface ForgottenPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ForgottenPasswordDto
     */
    'directionId': string;
    /**
     * 
     * @type {string}
     * @memberof ForgottenPasswordDto
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof ForgottenPasswordDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ForgottenPasswordDto
     */
    'verificationCode': string;
}
/**
 * 
 * @export
 * @interface GenerateDummyFlashcardsRequestDto
 */
export interface GenerateDummyFlashcardsRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GenerateDummyFlashcardsRequestDto
     */
    'flashcardPackId': string;
    /**
     * 
     * @type {number}
     * @memberof GenerateDummyFlashcardsRequestDto
     */
    'percentsCorrect': number;
    /**
     * 
     * @type {number}
     * @memberof GenerateDummyFlashcardsRequestDto
     */
    'percentsIncorrect': number;
    /**
     * 
     * @type {number}
     * @memberof GenerateDummyFlashcardsRequestDto
     */
    'percentsKinda': number;
    /**
     * 
     * @type {number}
     * @memberof GenerateDummyFlashcardsRequestDto
     */
    'percentsUnanswered': number;
}
/**
 * 
 * @export
 * @interface GenerateDummyFlashcardsResponseDto
 */
export interface GenerateDummyFlashcardsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GenerateDummyFlashcardsResponseDto
     */
    'flashcardPackId': string;
    /**
     * 
     * @type {Array<GenerateDummyFlashcardsResponseItemDto>}
     * @memberof GenerateDummyFlashcardsResponseDto
     */
    'answers': Array<GenerateDummyFlashcardsResponseItemDto>;
}
/**
 * 
 * @export
 * @interface GenerateDummyFlashcardsResponseItemDto
 */
export interface GenerateDummyFlashcardsResponseItemDto {
    /**
     * 
     * @type {string}
     * @memberof GenerateDummyFlashcardsResponseItemDto
     */
    'flashcardId': string;
    /**
     * 
     * @type {CustomerFlashcardAnswerType}
     * @memberof GenerateDummyFlashcardsResponseItemDto
     */
    'answer': CustomerFlashcardAnswerType;
}
/**
 * 
 * @export
 * @interface GenerateDummyTestsRequestDto
 */
export interface GenerateDummyTestsRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GenerateDummyTestsRequestDto
     */
    'courseId': string;
    /**
     * 
     * @type {number}
     * @memberof GenerateDummyTestsRequestDto
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface GenerateDummyTestsResponseDto
 */
export interface GenerateDummyTestsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GenerateDummyTestsResponseDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GetChaptersWithNotesResponseDto
 */
export interface GetChaptersWithNotesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetChaptersWithNotesResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetChaptersWithNotesResponseDto
     */
    'name': string;
    /**
     * 
     * @type {Array<NoteResponseDto>}
     * @memberof GetChaptersWithNotesResponseDto
     */
    'notes': Array<NoteResponseDto>;
}
/**
 * 
 * @export
 * @interface GetCurrentResultsResponseDto
 */
export interface GetCurrentResultsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCurrentResultsResponseDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentResultsResponseDto
     */
    'yes': number;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentResultsResponseDto
     */
    'kinda': number;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentResultsResponseDto
     */
    'no': number;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentResultsResponseDto
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface GetCustomerPaymentIntentSecretRequestDto
 */
export interface GetCustomerPaymentIntentSecretRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GetCustomerPaymentIntentSecretRequestDto
     */
    'stripeCustomerId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomerPaymentIntentSecretRequestDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomerPaymentIntentSecretRequestDto
     */
    'paymentType': GetCustomerPaymentIntentSecretRequestDtoPaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCustomerPaymentIntentSecretRequestDto
     */
    'subscriptionPeriod'?: GetCustomerPaymentIntentSecretRequestDtoSubscriptionPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCustomerPaymentIntentSecretRequestDto
     */
    'planLevel'?: GetCustomerPaymentIntentSecretRequestDtoPlanLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCustomerPaymentIntentSecretRequestDto
     */
    'promocode'?: string;
}

export const GetCustomerPaymentIntentSecretRequestDtoPaymentTypeEnum = {
    Purchase: 'purchase',
    Subscription: 'subscription'
} as const;

export type GetCustomerPaymentIntentSecretRequestDtoPaymentTypeEnum = typeof GetCustomerPaymentIntentSecretRequestDtoPaymentTypeEnum[keyof typeof GetCustomerPaymentIntentSecretRequestDtoPaymentTypeEnum];
export const GetCustomerPaymentIntentSecretRequestDtoSubscriptionPeriodEnum = {
    Monthly: 'monthly',
    Annual: 'annual'
} as const;

export type GetCustomerPaymentIntentSecretRequestDtoSubscriptionPeriodEnum = typeof GetCustomerPaymentIntentSecretRequestDtoSubscriptionPeriodEnum[keyof typeof GetCustomerPaymentIntentSecretRequestDtoSubscriptionPeriodEnum];
export const GetCustomerPaymentIntentSecretRequestDtoPlanLevelEnum = {
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type GetCustomerPaymentIntentSecretRequestDtoPlanLevelEnum = typeof GetCustomerPaymentIntentSecretRequestDtoPlanLevelEnum[keyof typeof GetCustomerPaymentIntentSecretRequestDtoPlanLevelEnum];

/**
 * 
 * @export
 * @interface GetGuideChaptersItem
 */
export interface GetGuideChaptersItem {
    /**
     * 
     * @type {string}
     * @memberof GetGuideChaptersItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetGuideChaptersItem
     */
    'guideId': string;
    /**
     * 
     * @type {string}
     * @memberof GetGuideChaptersItem
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetGuideChaptersItem
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetGuideChaptersItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetGuideChaptersItem
     */
    'audioUrl'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetGuideChaptersItem
     */
    'orderIndex': number;
    /**
     * 
     * @type {string}
     * @memberof GetGuideChaptersItem
     */
    'quizId'?: string;
}
/**
 * 
 * @export
 * @interface GetGuideChaptersResponseDto
 */
export interface GetGuideChaptersResponseDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof GetGuideChaptersResponseDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<GetGuideChaptersItem>}
     * @memberof GetGuideChaptersResponseDto
     */
    'items': Array<GetGuideChaptersItem>;
}
/**
 * 
 * @export
 * @interface GetManyCouponResponseDto
 */
export interface GetManyCouponResponseDto {
    /**
     * 
     * @type {Array<Coupon>}
     * @memberof GetManyCouponResponseDto
     */
    'data': Array<Coupon>;
    /**
     * 
     * @type {number}
     * @memberof GetManyCouponResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCouponResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCouponResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCouponResponseDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface GetManyGuidesCourse
 */
export interface GetManyGuidesCourse {
    /**
     * 
     * @type {string}
     * @memberof GetManyGuidesCourse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyGuidesCourse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GetManyGuidesItem
 */
export interface GetManyGuidesItem {
    /**
     * 
     * @type {string}
     * @memberof GetManyGuidesItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyGuidesItem
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyGuidesItem
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyGuidesItem
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyGuidesItem
     */
    'cheatSheetUrl'?: string | null;
    /**
     * 
     * @type {GetManyGuidesCourse}
     * @memberof GetManyGuidesItem
     */
    'course': GetManyGuidesCourse;
}
/**
 * 
 * @export
 * @interface GetManyGuidesResponseDto
 */
export interface GetManyGuidesResponseDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof GetManyGuidesResponseDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<GetManyGuidesItem>}
     * @memberof GetManyGuidesResponseDto
     */
    'items': Array<GetManyGuidesItem>;
}
/**
 * 
 * @export
 * @interface GetManyPlanPhaseResponseDto
 */
export interface GetManyPlanPhaseResponseDto {
    /**
     * 
     * @type {Array<PlanPhase>}
     * @memberof GetManyPlanPhaseResponseDto
     */
    'data': Array<PlanPhase>;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlanPhaseResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlanPhaseResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlanPhaseResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlanPhaseResponseDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface GetManyPlanPhaseTaskResponseDto
 */
export interface GetManyPlanPhaseTaskResponseDto {
    /**
     * 
     * @type {Array<PlanPhaseTask>}
     * @memberof GetManyPlanPhaseTaskResponseDto
     */
    'data': Array<PlanPhaseTask>;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlanPhaseTaskResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlanPhaseTaskResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlanPhaseTaskResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlanPhaseTaskResponseDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface GetManyPlanResponseDto
 */
export interface GetManyPlanResponseDto {
    /**
     * 
     * @type {Array<AdminPlanDto>}
     * @memberof GetManyPlanResponseDto
     */
    'data': Array<AdminPlanDto>;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlanResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlanResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlanResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlanResponseDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface GetManyQuizResponseDto
 */
export interface GetManyQuizResponseDto {
    /**
     * 
     * @type {Array<AdminQuizResponseDto>}
     * @memberof GetManyQuizResponseDto
     */
    'data': Array<AdminQuizResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof GetManyQuizResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyQuizResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyQuizResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyQuizResponseDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface GetManyReportResponseDto
 */
export interface GetManyReportResponseDto {
    /**
     * 
     * @type {Array<Report>}
     * @memberof GetManyReportResponseDto
     */
    'data': Array<Report>;
    /**
     * 
     * @type {number}
     * @memberof GetManyReportResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyReportResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyReportResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyReportResponseDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface GetManySuggestionResponseDto
 */
export interface GetManySuggestionResponseDto {
    /**
     * 
     * @type {Array<AdminSuggestionResponseDto>}
     * @memberof GetManySuggestionResponseDto
     */
    'data': Array<AdminSuggestionResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof GetManySuggestionResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof GetManySuggestionResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof GetManySuggestionResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManySuggestionResponseDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface GetPassageResultsRequestDto
 */
export interface GetPassageResultsRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GetPassageResultsRequestDto
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPassageResultsRequestDto
     */
    'limit'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetPassageResultsRequestDto
     */
    'onlyIncorrect': boolean;
}
/**
 * 
 * @export
 * @interface GetPassageResultsResponseDto
 */
export interface GetPassageResultsResponseDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof GetPassageResultsResponseDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<GetPassageResultsResponseItemDto>}
     * @memberof GetPassageResultsResponseDto
     */
    'items': Array<GetPassageResultsResponseItemDto>;
    /**
     * 
     * @type {number}
     * @memberof GetPassageResultsResponseDto
     */
    'correctAnswers': number;
}
/**
 * 
 * @export
 * @interface GetPassageResultsResponseItemDto
 */
export interface GetPassageResultsResponseItemDto {
    /**
     * 
     * @type {string}
     * @memberof GetPassageResultsResponseItemDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetPassageResultsResponseItemDto
     */
    'subject': string;
    /**
     * Question images
     * @type {Array<string>}
     * @memberof GetPassageResultsResponseItemDto
     */
    'imagesUrls': Array<string>;
    /**
     * 
     * @type {Array<QuestionOptionResponseDto>}
     * @memberof GetPassageResultsResponseItemDto
     */
    'options': Array<QuestionOptionResponseDto>;
    /**
     * 
     * @type {string}
     * @memberof GetPassageResultsResponseItemDto
     */
    'customerAnswer': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPassageResultsResponseItemDto
     */
    'customerAnswerId': string;
    /**
     * 
     * @type {string}
     * @memberof GetPassageResultsResponseItemDto
     */
    'correctAnswer': string;
    /**
     * 
     * @type {string}
     * @memberof GetPassageResultsResponseItemDto
     */
    'correctAnswerId': string;
    /**
     * 
     * @type {string}
     * @memberof GetPassageResultsResponseItemDto
     */
    'explanationText': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPassageResultsResponseItemDto
     */
    'explanationImageUrl': string | null;
    /**
     * Hint
     * @type {string}
     * @memberof GetPassageResultsResponseItemDto
     */
    'hint': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetPassageResultsResponseItemDto
     */
    'isMarked': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPassageResultsResponseItemDto
     */
    'isInFlashcard': boolean;
}
/**
 * 
 * @export
 * @interface GetPaymentMethodResponseDto
 */
export interface GetPaymentMethodResponseDto {
    /**
     * 
     * @type {GetPaymentMethodResponseDtoPaymentMethod}
     * @memberof GetPaymentMethodResponseDto
     */
    'paymentMethod': GetPaymentMethodResponseDtoPaymentMethod | null;
}
/**
 * 
 * @export
 * @interface GetPaymentMethodResponseDtoPaymentMethod
 */
export interface GetPaymentMethodResponseDtoPaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof GetPaymentMethodResponseDtoPaymentMethod
     */
    'id': string;
    /**
     * 
     * @type {PaymentMethodType}
     * @memberof GetPaymentMethodResponseDtoPaymentMethod
     */
    'type': PaymentMethodType;
    /**
     * 
     * @type {PaymentMethodCardDto}
     * @memberof GetPaymentMethodResponseDtoPaymentMethod
     */
    'card'?: PaymentMethodCardDto;
    /**
     * 
     * @type {PaymentMethodLinkDto}
     * @memberof GetPaymentMethodResponseDtoPaymentMethod
     */
    'link'?: PaymentMethodLinkDto;
    /**
     * 
     * @type {PaymentMethodCashappDto}
     * @memberof GetPaymentMethodResponseDtoPaymentMethod
     */
    'cashapp'?: PaymentMethodCashappDto;
}
/**
 * 
 * @export
 * @interface GetQuestionChaptersRequestDto
 */
export interface GetQuestionChaptersRequestDto {
    /**
     * Course ID
     * @type {string}
     * @memberof GetQuestionChaptersRequestDto
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface GetQuestionChaptersResponseItem
 */
export interface GetQuestionChaptersResponseItem {
    /**
     * Chapter ID
     * @type {string}
     * @memberof GetQuestionChaptersResponseItem
     */
    'id': string;
    /**
     * Chapter title
     * @type {string}
     * @memberof GetQuestionChaptersResponseItem
     */
    'title': string;
    /**
     * Chapter sort index
     * @type {number}
     * @memberof GetQuestionChaptersResponseItem
     */
    'sortIndex': number;
    /**
     * 
     * @type {GetQuestionChaptersResponseItemAll}
     * @memberof GetQuestionChaptersResponseItem
     */
    'all': GetQuestionChaptersResponseItemAll;
    /**
     * 
     * @type {GetQuestionChaptersResponseItemMarked}
     * @memberof GetQuestionChaptersResponseItem
     */
    'marked': GetQuestionChaptersResponseItemMarked;
    /**
     * 
     * @type {GetQuestionChaptersResponseItemIncorrect}
     * @memberof GetQuestionChaptersResponseItem
     */
    'incorrect': GetQuestionChaptersResponseItemIncorrect;
    /**
     * 
     * @type {GetQuestionChaptersResponseItemUnused}
     * @memberof GetQuestionChaptersResponseItem
     */
    'unused': GetQuestionChaptersResponseItemUnused;
}
/**
 * Total question amounts
 * @export
 * @interface GetQuestionChaptersResponseItemAll
 */
export interface GetQuestionChaptersResponseItemAll {
    /**
     * Total amount of questions in group
     * @type {number}
     * @memberof GetQuestionChaptersResponseItemAll
     */
    'total': number;
    /**
     * Amount of questions, available for free plans
     * @type {number}
     * @memberof GetQuestionChaptersResponseItemAll
     */
    'free': number;
    /**
     * Amount of questions, available only for better plans
     * @type {number}
     * @memberof GetQuestionChaptersResponseItemAll
     */
    'paid': number;
}
/**
 * Incorrect question amounts
 * @export
 * @interface GetQuestionChaptersResponseItemIncorrect
 */
export interface GetQuestionChaptersResponseItemIncorrect {
    /**
     * Total amount of questions in group
     * @type {number}
     * @memberof GetQuestionChaptersResponseItemIncorrect
     */
    'total': number;
    /**
     * Amount of questions, available for free plans
     * @type {number}
     * @memberof GetQuestionChaptersResponseItemIncorrect
     */
    'free': number;
    /**
     * Amount of questions, available only for better plans
     * @type {number}
     * @memberof GetQuestionChaptersResponseItemIncorrect
     */
    'paid': number;
}
/**
 * Marked question amounts
 * @export
 * @interface GetQuestionChaptersResponseItemMarked
 */
export interface GetQuestionChaptersResponseItemMarked {
    /**
     * Total amount of questions in group
     * @type {number}
     * @memberof GetQuestionChaptersResponseItemMarked
     */
    'total': number;
    /**
     * Amount of questions, available for free plans
     * @type {number}
     * @memberof GetQuestionChaptersResponseItemMarked
     */
    'free': number;
    /**
     * Amount of questions, available only for better plans
     * @type {number}
     * @memberof GetQuestionChaptersResponseItemMarked
     */
    'paid': number;
}
/**
 * Unused question amounts
 * @export
 * @interface GetQuestionChaptersResponseItemUnused
 */
export interface GetQuestionChaptersResponseItemUnused {
    /**
     * Total amount of questions in group
     * @type {number}
     * @memberof GetQuestionChaptersResponseItemUnused
     */
    'total': number;
    /**
     * Amount of questions, available for free plans
     * @type {number}
     * @memberof GetQuestionChaptersResponseItemUnused
     */
    'free': number;
    /**
     * Amount of questions, available only for better plans
     * @type {number}
     * @memberof GetQuestionChaptersResponseItemUnused
     */
    'paid': number;
}
/**
 * 
 * @export
 * @interface GetQuestionDomainsRequestDto
 */
export interface GetQuestionDomainsRequestDto {
    /**
     * Course ID
     * @type {string}
     * @memberof GetQuestionDomainsRequestDto
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface GetQuestionDomainsResponseItem
 */
export interface GetQuestionDomainsResponseItem {
    /**
     * Domain ID
     * @type {string}
     * @memberof GetQuestionDomainsResponseItem
     */
    'id': string;
    /**
     * Domain title
     * @type {string}
     * @memberof GetQuestionDomainsResponseItem
     */
    'title': string;
    /**
     * Domain sort index
     * @type {number}
     * @memberof GetQuestionDomainsResponseItem
     */
    'sortIndex': number;
    /**
     * 
     * @type {GetQuestionChaptersResponseItemAll}
     * @memberof GetQuestionDomainsResponseItem
     */
    'all': GetQuestionChaptersResponseItemAll;
    /**
     * 
     * @type {GetQuestionChaptersResponseItemMarked}
     * @memberof GetQuestionDomainsResponseItem
     */
    'marked': GetQuestionChaptersResponseItemMarked;
    /**
     * 
     * @type {GetQuestionChaptersResponseItemIncorrect}
     * @memberof GetQuestionDomainsResponseItem
     */
    'incorrect': GetQuestionChaptersResponseItemIncorrect;
    /**
     * 
     * @type {GetQuestionChaptersResponseItemUnused}
     * @memberof GetQuestionDomainsResponseItem
     */
    'unused': GetQuestionChaptersResponseItemUnused;
}
/**
 * 
 * @export
 * @interface Guide
 */
export interface Guide {
    /**
     * 
     * @type {string}
     * @memberof Guide
     */
    'id': string;
    /**
     * 
     * @type {Course}
     * @memberof Guide
     */
    'course': Course;
    /**
     * 
     * @type {string}
     * @memberof Guide
     */
    'courseId': string;
    /**
     * 
     * @type {Array<GuideChapter>}
     * @memberof Guide
     */
    'chapters': Array<GuideChapter>;
    /**
     * 
     * @type {string}
     * @memberof Guide
     */
    'cheatSheetUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof Guide
     */
    'textbookUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof Guide
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Guide
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface GuideChapter
 */
export interface GuideChapter {
    /**
     * 
     * @type {string}
     * @memberof GuideChapter
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapter
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapter
     */
    'audioUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof GuideChapter
     */
    'orderIndex': number;
    /**
     * 
     * @type {Guide}
     * @memberof GuideChapter
     */
    'guide': Guide;
    /**
     * 
     * @type {string}
     * @memberof GuideChapter
     */
    'guideId': string;
    /**
     * 
     * @type {Array<GuideChapterUnit>}
     * @memberof GuideChapter
     */
    'units': Array<GuideChapterUnit>;
    /**
     * 
     * @type {Array<Note>}
     * @memberof GuideChapter
     */
    'notes'?: Array<Note>;
    /**
     * 
     * @type {string}
     * @memberof GuideChapter
     */
    'quizId': string;
    /**
     * 
     * @type {Quiz}
     * @memberof GuideChapter
     */
    'quiz'?: Quiz;
    /**
     * 
     * @type {string}
     * @memberof GuideChapter
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapter
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface GuideChapterResponseDto
 */
export interface GuideChapterResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseDto
     */
    'guideId': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseDto
     */
    'quizId': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseDto
     */
    'audioUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseDto
     */
    'orderIndex': string;
    /**
     * 
     * @type {Array<GuideChapterResponseUnitDto>}
     * @memberof GuideChapterResponseDto
     */
    'units': Array<GuideChapterResponseUnitDto>;
}
/**
 * 
 * @export
 * @interface GuideChapterResponseUnitDto
 */
export interface GuideChapterResponseUnitDto {
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseUnitDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseUnitDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseUnitDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseUnitDto
     */
    'name': string;
    /**
     * 
     * @type {GuideContentDto}
     * @memberof GuideChapterResponseUnitDto
     */
    'content': GuideContentDto;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterResponseUnitDto
     */
    'orderIndex': string;
}
/**
 * 
 * @export
 * @interface GuideChapterUnit
 */
export interface GuideChapterUnit {
    /**
     * 
     * @type {string}
     * @memberof GuideChapterUnit
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterUnit
     */
    'name': string;
    /**
     * 
     * @type {object}
     * @memberof GuideChapterUnit
     */
    'content': object;
    /**
     * 
     * @type {number}
     * @memberof GuideChapterUnit
     */
    'orderIndex': number;
    /**
     * 
     * @type {GuideChapter}
     * @memberof GuideChapterUnit
     */
    'chapter': GuideChapter;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterUnit
     */
    'chapterId': string;
    /**
     * 
     * @type {Array<Note>}
     * @memberof GuideChapterUnit
     */
    'notes'?: Array<Note>;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterUnit
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GuideChapterUnit
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface GuideContentDto
 */
export interface GuideContentDto {
    /**
     * 
     * @type {number}
     * @memberof GuideContentDto
     */
    'time': number;
    /**
     * 
     * @type {string}
     * @memberof GuideContentDto
     */
    'version': string;
    /**
     * 
     * @type {Array<ContentBlockDto>}
     * @memberof GuideContentDto
     */
    'blocks': Array<ContentBlockDto>;
}
/**
 * 
 * @export
 * @interface GuideResponseDto
 */
export interface GuideResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GuideResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GuideResponseDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof GuideResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GuideResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<GuideChapterResponseDto>}
     * @memberof GuideResponseDto
     */
    'chapters': Array<GuideChapterResponseDto>;
}
/**
 * 
 * @export
 * @interface HealthControllerCheckApi200Response
 */
export interface HealthControllerCheckApi200Response {
    /**
     * 
     * @type {string}
     * @memberof HealthControllerCheckApi200Response
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: HealthControllerCheckApi200ResponseInfoValue; }}
     * @memberof HealthControllerCheckApi200Response
     */
    'info'?: { [key: string]: HealthControllerCheckApi200ResponseInfoValue; } | null;
    /**
     * 
     * @type {{ [key: string]: HealthControllerCheckApi200ResponseInfoValue; }}
     * @memberof HealthControllerCheckApi200Response
     */
    'error'?: { [key: string]: HealthControllerCheckApi200ResponseInfoValue; } | null;
    /**
     * 
     * @type {{ [key: string]: HealthControllerCheckApi200ResponseInfoValue; }}
     * @memberof HealthControllerCheckApi200Response
     */
    'details'?: { [key: string]: HealthControllerCheckApi200ResponseInfoValue; };
}
/**
 * 
 * @export
 * @interface HealthControllerCheckApi200ResponseInfoValue
 */
export interface HealthControllerCheckApi200ResponseInfoValue {
    [key: string]: string | any;

    /**
     * 
     * @type {string}
     * @memberof HealthControllerCheckApi200ResponseInfoValue
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface HealthControllerCheckApi503Response
 */
export interface HealthControllerCheckApi503Response {
    /**
     * 
     * @type {string}
     * @memberof HealthControllerCheckApi503Response
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: HealthControllerCheckApi200ResponseInfoValue; }}
     * @memberof HealthControllerCheckApi503Response
     */
    'info'?: { [key: string]: HealthControllerCheckApi200ResponseInfoValue; } | null;
    /**
     * 
     * @type {{ [key: string]: HealthControllerCheckApi200ResponseInfoValue; }}
     * @memberof HealthControllerCheckApi503Response
     */
    'error'?: { [key: string]: HealthControllerCheckApi200ResponseInfoValue; } | null;
    /**
     * 
     * @type {{ [key: string]: HealthControllerCheckApi200ResponseInfoValue; }}
     * @memberof HealthControllerCheckApi503Response
     */
    'details'?: { [key: string]: HealthControllerCheckApi200ResponseInfoValue; };
}
/**
 * 
 * @export
 * @interface InvitedCustomer
 */
export interface InvitedCustomer {
    /**
     * 
     * @type {string}
     * @memberof InvitedCustomer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InvitedCustomer
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InvitedCustomer
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof InvitedCustomer
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitedCustomer
     */
    'passwordHash': string | null;
    /**
     * 
     * @type {string}
     * @memberof InvitedCustomer
     */
    'expireAt': string;
    /**
     * 
     * @type {string}
     * @memberof InvitedCustomer
     */
    'acceptedAt'?: string;
    /**
     * 
     * @type {Course}
     * @memberof InvitedCustomer
     */
    'course': Course;
}
/**
 * 
 * @export
 * @interface InvoiceResponseDto
 */
export interface InvoiceResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponseDto
     */
    'invoiceId': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponseDto
     */
    'clientSecret': string;
    /**
     * 
     * @type {Array<CouponDataDto>}
     * @memberof InvoiceResponseDto
     */
    'coupons': Array<CouponDataDto>;
}
/**
 * 
 * @export
 * @interface LeastKnownCategoryResponseDto
 */
export interface LeastKnownCategoryResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LeastKnownCategoryResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LeastKnownCategoryResponseDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof LeastKnownCategoryResponseDto
     */
    'all': string;
    /**
     * 
     * @type {string}
     * @memberof LeastKnownCategoryResponseDto
     */
    'incorrect': string;
    /**
     * 
     * @type {string}
     * @memberof LeastKnownCategoryResponseDto
     */
    'percentage': string;
}
/**
 * 
 * @export
 * @interface Manual
 */
export interface Manual {
    /**
     * 
     * @type {string}
     * @memberof Manual
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Manual
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof Manual
     */
    'type': ManualTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof Manual
     */
    'content': object;
    /**
     * 
     * @type {Course}
     * @memberof Manual
     */
    'course'?: Course;
}

export const ManualTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type ManualTypeEnum = typeof ManualTypeEnum[keyof typeof ManualTypeEnum];

/**
 * 
 * @export
 * @interface ManualContent
 */
export interface ManualContent {
    /**
     * 
     * @type {string}
     * @memberof ManualContent
     */
    'title'?: string;
    /**
     * 
     * @type {ManualContentContent}
     * @memberof ManualContent
     */
    'content'?: ManualContentContent;
    /**
     * 
     * @type {ManualContentContent}
     * @memberof ManualContent
     */
    'yes'?: ManualContentContent;
    /**
     * 
     * @type {ManualContentContent}
     * @memberof ManualContent
     */
    'no'?: ManualContentContent;
    /**
     * 
     * @type {ManualContentContent}
     * @memberof ManualContent
     */
    'kinda'?: ManualContentContent;
    /**
     * 
     * @type {ManualContentContent}
     * @memberof ManualContent
     */
    'purchase'?: ManualContentContent;
    /**
     * 
     * @type {ManualContentContent}
     * @memberof ManualContent
     */
    'monthlySubscriptions'?: ManualContentContent;
    /**
     * 
     * @type {ManualContentContent}
     * @memberof ManualContent
     */
    'annualSubscriptions'?: ManualContentContent;
    /**
     * 
     * @type {ManualContentContent}
     * @memberof ManualContent
     */
    'mostEffective'?: ManualContentContent;
    /**
     * 
     * @type {string}
     * @memberof ManualContent
     */
    'buttonText'?: string;
}
/**
 * @type ManualContentContent
 * Content data string or any object
 * @export
 */
export type ManualContentContent = object | string;

/**
 * 
 * @export
 * @interface ManualResponseDto
 */
export interface ManualResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ManualResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManualResponseDto
     */
    'type': ManualResponseDtoTypeEnum;
    /**
     * 
     * @type {ManualResponseDtoContent}
     * @memberof ManualResponseDto
     */
    'content': ManualResponseDtoContent;
}

export const ManualResponseDtoTypeEnum = {
    HowProgressIsCalculated: 'how-progress-is-calculated',
    InstructionManual: 'instruction-manual',
    InstructionManualMobile: 'instruction-manual-mobile',
    FlashcardDescriptions: 'flashcard-descriptions',
    PerformanceUpgrade: 'performance-upgrade',
    TestsPageUpgrade: 'tests-page-upgrade',
    StudyGuideUpgrade: 'study-guide-upgrade',
    FlashcardsPageUpgrade: 'flashcards-page-upgrade',
    MnemonicsPageUpgrade: 'mnemonics-page-upgrade',
    TimedTestUpgrade: 'timed-test-upgrade',
    PaymentDiscounts: 'payment-discounts',
    CertificateSignup: 'certificate-signup',
    BonusSectionUpgrade: 'bonus-section-upgrade',
    BonusSectionDefault: 'bonus-section-default'
} as const;

export type ManualResponseDtoTypeEnum = typeof ManualResponseDtoTypeEnum[keyof typeof ManualResponseDtoTypeEnum];

/**
 * @type ManualResponseDtoContent
 * @export
 */
export type ManualResponseDtoContent = ManualContent | string;

/**
 * @type ManualsControllerCreateOneRequest
 * @export
 */
export type ManualsControllerCreateOneRequest = AdminCreateFlashcardsManualDto | AdminCreateInstructionManualDto | AdminCreatePaymentDiscountsInfoDto | AdminCreateUpgradeManualDto | AdminHowProgressIsCalculatedManualDto;

/**
 * @type ManualsControllerGetOne200Response
 * @export
 */
export type ManualsControllerGetOne200Response = ManualResponseDto;

/**
 * 
 * @export
 * @interface MarkedQuestionResponseDto
 */
export interface MarkedQuestionResponseDto {
    /**
     * When question was marked
     * @type {string}
     * @memberof MarkedQuestionResponseDto
     */
    'createdAt': string;
    /**
     * Question ID
     * @type {string}
     * @memberof MarkedQuestionResponseDto
     */
    'questionId': string;
}
/**
 * 
 * @export
 * @interface MnemonicCard
 */
export interface MnemonicCard {
    /**
     * 
     * @type {string}
     * @memberof MnemonicCard
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCard
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof MnemonicCard
     */
    'answersCount': number;
    /**
     * 
     * @type {MnemonicCardsPack}
     * @memberof MnemonicCard
     */
    'pack'?: MnemonicCardsPack;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCard
     */
    'packId': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCard
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCard
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCard
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface MnemonicCardDto
 */
export interface MnemonicCardDto {
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof MnemonicCardDto
     */
    'answersCount': number;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardDto
     */
    'packId': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardDto
     */
    'imageUrl': string;
}
/**
 * 
 * @export
 * @interface MnemonicCardsDto
 */
export interface MnemonicCardsDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof MnemonicCardsDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<MnemonicCardDto>}
     * @memberof MnemonicCardsDto
     */
    'items': Array<MnemonicCardDto>;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsDto
     */
    'packName': string;
}
/**
 * 
 * @export
 * @interface MnemonicCardsPack
 */
export interface MnemonicCardsPack {
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPack
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPack
     */
    'name': string;
    /**
     * 
     * @type {Course}
     * @memberof MnemonicCardsPack
     */
    'course'?: Course;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPack
     */
    'courseId': string;
    /**
     * 
     * @type {Array<MnemonicCard>}
     * @memberof MnemonicCardsPack
     */
    'cards'?: Array<MnemonicCard>;
    /**
     * 
     * @type {MnemonicCardsPackOrder}
     * @memberof MnemonicCardsPack
     */
    'order': MnemonicCardsPackOrder;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPack
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPack
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface MnemonicCardsPackDto
 */
export interface MnemonicCardsPackDto {
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPackDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPackDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPackDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPackDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof MnemonicCardsPackDto
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface MnemonicCardsPackOrder
 */
export interface MnemonicCardsPackOrder {
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPackOrder
     */
    'mnemonicCardsPackId': string;
    /**
     * 
     * @type {MnemonicCardsPack}
     * @memberof MnemonicCardsPackOrder
     */
    'mnemonicCardsPack': MnemonicCardsPack;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPackOrder
     */
    'courseId': string;
    /**
     * 
     * @type {Course}
     * @memberof MnemonicCardsPackOrder
     */
    'course'?: Course;
    /**
     * 
     * @type {number}
     * @memberof MnemonicCardsPackOrder
     */
    'orderIndex': number;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPackOrder
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicCardsPackOrder
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface MnemonicCardsPacksDto
 */
export interface MnemonicCardsPacksDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof MnemonicCardsPacksDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<MnemonicCardsPackDto>}
     * @memberof MnemonicCardsPacksDto
     */
    'items': Array<MnemonicCardsPackDto>;
}
/**
 * 
 * @export
 * @interface NewQuestion
 */
export interface NewQuestion {
    /**
     * 
     * @type {string}
     * @memberof NewQuestion
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestion
     */
    'domainId': string | null;
    /**
     * 
     * @type {string}
     * @memberof NewQuestion
     */
    'chapterId': string | null;
    /**
     * 
     * @type {string}
     * @memberof NewQuestion
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestion
     */
    'explanationText': string | null;
    /**
     * 
     * @type {string}
     * @memberof NewQuestion
     */
    'explanationImageUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof NewQuestion
     */
    'hint': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewQuestion
     */
    'imagesUrls': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof NewQuestion
     */
    'availableForFree': boolean;
    /**
     * 
     * @type {NewQuestionDomain}
     * @memberof NewQuestion
     */
    'domain': NewQuestionDomain | null;
    /**
     * 
     * @type {NewQuestionChapter}
     * @memberof NewQuestion
     */
    'chapter': NewQuestionChapter | null;
    /**
     * 
     * @type {Array<NewQuestionOption>}
     * @memberof NewQuestion
     */
    'options': Array<NewQuestionOption>;
    /**
     * 
     * @type {Array<NewQuestionAnswer>}
     * @memberof NewQuestion
     */
    'answers': Array<NewQuestionAnswer>;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof NewQuestion
     */
    'markedBy': Array<Customer>;
    /**
     * 
     * @type {string}
     * @memberof NewQuestion
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestion
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface NewQuestionAnswer
 */
export interface NewQuestionAnswer {
    /**
     * 
     * @type {string}
     * @memberof NewQuestionAnswer
     */
    'questionId': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionAnswer
     */
    'passageId': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionAnswer
     */
    'optionId'?: string | null;
    /**
     * 
     * @type {NewQuestion}
     * @memberof NewQuestionAnswer
     */
    'question': NewQuestion;
    /**
     * 
     * @type {NewTestPassage}
     * @memberof NewQuestionAnswer
     */
    'passage': NewTestPassage;
    /**
     * 
     * @type {NewQuestionOption}
     * @memberof NewQuestionAnswer
     */
    'option'?: NewQuestionOption;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionAnswer
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionAnswer
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface NewQuestionChapter
 */
export interface NewQuestionChapter {
    /**
     * 
     * @type {string}
     * @memberof NewQuestionChapter
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionChapter
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionChapter
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof NewQuestionChapter
     */
    'sortIndex': number;
    /**
     * 
     * @type {Course}
     * @memberof NewQuestionChapter
     */
    'course': Course;
    /**
     * 
     * @type {Array<NewQuestion>}
     * @memberof NewQuestionChapter
     */
    'questions': Array<NewQuestion>;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionChapter
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionChapter
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface NewQuestionDomain
 */
export interface NewQuestionDomain {
    /**
     * 
     * @type {string}
     * @memberof NewQuestionDomain
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionDomain
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof NewQuestionDomain
     */
    'sortIndex': number;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionDomain
     */
    'courseId': string;
    /**
     * 
     * @type {Course}
     * @memberof NewQuestionDomain
     */
    'course': Course;
    /**
     * 
     * @type {Array<NewQuestion>}
     * @memberof NewQuestionDomain
     */
    'questions': Array<NewQuestion>;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionDomain
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionDomain
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface NewQuestionOption
 */
export interface NewQuestionOption {
    /**
     * 
     * @type {string}
     * @memberof NewQuestionOption
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionOption
     */
    'questionId': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionOption
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof NewQuestionOption
     */
    'orderIndex': number;
    /**
     * 
     * @type {boolean}
     * @memberof NewQuestionOption
     */
    'isCorrect': boolean;
    /**
     * 
     * @type {NewQuestion}
     * @memberof NewQuestionOption
     */
    'question': NewQuestion;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionOption
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NewQuestionOption
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface NewTestPassage
 */
export interface NewTestPassage {
    /**
     * 
     * @type {string}
     * @memberof NewTestPassage
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NewTestPassage
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof NewTestPassage
     */
    'type': NewTestPassageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NewTestPassage
     */
    'closedAt'?: string;
    /**
     * 
     * @type {Customer}
     * @memberof NewTestPassage
     */
    'customer': Customer;
    /**
     * 
     * @type {Array<NewQuestionAnswer>}
     * @memberof NewTestPassage
     */
    'answers': Array<NewQuestionAnswer>;
    /**
     * 
     * @type {string}
     * @memberof NewTestPassage
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NewTestPassage
     */
    'updatedAt': string;
}

export const NewTestPassageTypeEnum = {
    TimedTest: 'timed_test',
    TutorTest: 'tutor_test',
    QuizTest: 'quiz_test'
} as const;

export type NewTestPassageTypeEnum = typeof NewTestPassageTypeEnum[keyof typeof NewTestPassageTypeEnum];

/**
 * 
 * @export
 * @interface Note
 */
export interface Note {
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    'startIndex': number;
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    'endIndex': number;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'selectedText': string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'noteText'?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'chapterId': string;
    /**
     * 
     * @type {GuideChapter}
     * @memberof Note
     */
    'chapter': GuideChapter;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'unitId': string;
    /**
     * 
     * @type {GuideChapter}
     * @memberof Note
     */
    'unit'?: GuideChapter;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'blockId': string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof Note
     */
    'customer': Customer;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface NoteResponseDto
 */
export interface NoteResponseDto {
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDto
     */
    'selectedText': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDto
     */
    'noteText'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteResponseDto
     */
    'startIndex': number;
    /**
     * 
     * @type {number}
     * @memberof NoteResponseDto
     */
    'endIndex': number;
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDto
     */
    'chapterId': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDto
     */
    'unitId': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDto
     */
    'blockId': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDto
     */
    'customerId': string;
}
/**
 * 
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'orderIndex': number;
}
/**
 * 
 * @export
 * @interface PaginatedCouponsResponseDto
 */
export interface PaginatedCouponsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof PaginatedCouponsResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedCouponsResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedCouponsResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedCouponsResponseDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedCouponsResponseDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<CouponResponseDto>}
     * @memberof PaginatedCouponsResponseDto
     */
    'data': Array<CouponResponseDto>;
}
/**
 * 
 * @export
 * @interface PaginatedCoursesDto
 */
export interface PaginatedCoursesDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof PaginatedCoursesDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<CourseDto>}
     * @memberof PaginatedCoursesDto
     */
    'items': Array<CourseDto>;
}
/**
 * 
 * @export
 * @interface PaginatedFlashcardPacksStatsDto
 */
export interface PaginatedFlashcardPacksStatsDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof PaginatedFlashcardPacksStatsDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<FlashcardPackStatsDto>}
     * @memberof PaginatedFlashcardPacksStatsDto
     */
    'items': Array<FlashcardPackStatsDto>;
}
/**
 * 
 * @export
 * @interface PaginatedManualsResponseDto
 */
export interface PaginatedManualsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof PaginatedManualsResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedManualsResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedManualsResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedManualsResponseDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedManualsResponseDto
     */
    'pageSize': number;
    /**
     * 
     * @type {Array<ManualResponseDto>}
     * @memberof PaginatedManualsResponseDto
     */
    'data': Array<ManualResponseDto>;
}
/**
 * 
 * @export
 * @interface PaginatedSingleAnswerFlashcardPacksStatsDto
 */
export interface PaginatedSingleAnswerFlashcardPacksStatsDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof PaginatedSingleAnswerFlashcardPacksStatsDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<SingleAnswerFlashcardPackStatsDto>}
     * @memberof PaginatedSingleAnswerFlashcardPacksStatsDto
     */
    'items': Array<SingleAnswerFlashcardPackStatsDto>;
}
/**
 * 
 * @export
 * @interface PaginatedTestAnswerSplitStatsDto
 */
export interface PaginatedTestAnswerSplitStatsDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof PaginatedTestAnswerSplitStatsDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<TestAnswerSplitStatsDto>}
     * @memberof PaginatedTestAnswerSplitStatsDto
     */
    'items': Array<TestAnswerSplitStatsDto>;
}
/**
 * 
 * @export
 * @interface PaginatedTestSplitStatsDto
 */
export interface PaginatedTestSplitStatsDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof PaginatedTestSplitStatsDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<TestSplitStatsDto>}
     * @memberof PaginatedTestSplitStatsDto
     */
    'items': Array<TestSplitStatsDto>;
}
/**
 * 
 * @export
 * @interface PaginationMetaDto
 */
export interface PaginationMetaDto {
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDto
     */
    'currentPage': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDto
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDto
     */
    'itemCount': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDto
     */
    'itemsPerPage': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDto
     */
    'totalItems': number;
}
/**
 * 
 * @export
 * @interface PatchPasswordDto
 */
export interface PatchPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof PatchPasswordDto
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof PatchPasswordDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface PatchProfileDto
 */
export interface PatchProfileDto {
    /**
     * 
     * @type {string}
     * @memberof PatchProfileDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PauseSubscriptionRequestDto
 */
export interface PauseSubscriptionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PauseSubscriptionRequestDto
     */
    'courseId': string;
    /**
     * 
     * @type {number}
     * @memberof PauseSubscriptionRequestDto
     */
    'months': number;
}
/**
 * 
 * @export
 * @interface PaymentDto
 */
export interface PaymentDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'type': PaymentDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'level'?: PaymentDtoLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'status'?: PaymentDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'period'?: PaymentDtoPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'activeFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'activeTo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentDto
     */
    'onPause': boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'onPauseFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'onPauseUntil'?: string | null;
}

export const PaymentDtoTypeEnum = {
    None: 'None',
    Free: 'Free',
    Purchase: 'Purchase',
    Subscription: 'Subscription',
    Installment: 'Installment'
} as const;

export type PaymentDtoTypeEnum = typeof PaymentDtoTypeEnum[keyof typeof PaymentDtoTypeEnum];
export const PaymentDtoLevelEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type PaymentDtoLevelEnum = typeof PaymentDtoLevelEnum[keyof typeof PaymentDtoLevelEnum];
export const PaymentDtoStatusEnum = {
    Active: 'active',
    Trialing: 'trialing',
    Incomplete: 'incomplete',
    IncompleteExpired: 'incomplete_expired',
    PastDue: 'past_due',
    Canceled: 'canceled',
    Unpaid: 'unpaid'
} as const;

export type PaymentDtoStatusEnum = typeof PaymentDtoStatusEnum[keyof typeof PaymentDtoStatusEnum];
export const PaymentDtoPeriodEnum = {
    Monthly: 'monthly',
    Annual: 'annual'
} as const;

export type PaymentDtoPeriodEnum = typeof PaymentDtoPeriodEnum[keyof typeof PaymentDtoPeriodEnum];

/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'type': PaymentMethodTypeEnum;
    /**
     * 
     * @type {PaymentMethodCard}
     * @memberof PaymentMethod
     */
    'card'?: PaymentMethodCard;
    /**
     * 
     * @type {PaymentMethodLink}
     * @memberof PaymentMethod
     */
    'link'?: PaymentMethodLink;
    /**
     * 
     * @type {PaymentMethodCashapp}
     * @memberof PaymentMethod
     */
    'cashapp'?: PaymentMethodCashapp;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof PaymentMethod
     */
    'customer': Customer;
}

export const PaymentMethodTypeEnum = {
    Card: 'card',
    Link: 'link',
    Cashapp: 'cashapp'
} as const;

export type PaymentMethodTypeEnum = typeof PaymentMethodTypeEnum[keyof typeof PaymentMethodTypeEnum];

/**
 * 
 * @export
 * @interface PaymentMethodCard
 */
export interface PaymentMethodCard {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodCard
     */
    'last4': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodCard
     */
    'brand': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodCard
     */
    'expMonth': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodCard
     */
    'expYear': number;
}
/**
 * 
 * @export
 * @interface PaymentMethodCardDto
 */
export interface PaymentMethodCardDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodCardDto
     */
    'last4': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodCardDto
     */
    'brand': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodCardDto
     */
    'expMonth': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodCardDto
     */
    'expYear': number;
}
/**
 * 
 * @export
 * @interface PaymentMethodCashapp
 */
export interface PaymentMethodCashapp {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodCashapp
     */
    'cashtag': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodCashapp
     */
    'buyerId': string;
}
/**
 * 
 * @export
 * @interface PaymentMethodCashappDto
 */
export interface PaymentMethodCashappDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodCashappDto
     */
    'cashtag': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodCashappDto
     */
    'buyerId': string;
}
/**
 * 
 * @export
 * @interface PaymentMethodDto
 */
export interface PaymentMethodDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodDto
     */
    'id': string;
    /**
     * 
     * @type {PaymentMethodType}
     * @memberof PaymentMethodDto
     */
    'type': PaymentMethodType;
    /**
     * 
     * @type {PaymentMethodCardDto}
     * @memberof PaymentMethodDto
     */
    'card'?: PaymentMethodCardDto;
    /**
     * 
     * @type {PaymentMethodLinkDto}
     * @memberof PaymentMethodDto
     */
    'link'?: PaymentMethodLinkDto;
    /**
     * 
     * @type {PaymentMethodCashappDto}
     * @memberof PaymentMethodDto
     */
    'cashapp'?: PaymentMethodCashappDto;
}
/**
 * 
 * @export
 * @interface PaymentMethodLink
 */
export interface PaymentMethodLink {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodLink
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface PaymentMethodLinkDto
 */
export interface PaymentMethodLinkDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodLinkDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentMethodType = {
    Card: 'card',
    Link: 'link',
    Cashapp: 'cashapp'
} as const;

export type PaymentMethodType = typeof PaymentMethodType[keyof typeof PaymentMethodType];


/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'name': string;
    /**
     * 
     * @type {Array<PlanPhase>}
     * @memberof Plan
     */
    'phases': Array<PlanPhase>;
    /**
     * 
     * @type {Array<PlanPhaseTask>}
     * @memberof Plan
     */
    'planPhaseTasks': Array<PlanPhaseTask>;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'courseId': string;
    /**
     * 
     * @type {Course}
     * @memberof Plan
     */
    'course': Course;
    /**
     * 
     * @type {PlanOrder}
     * @memberof Plan
     */
    'order': PlanOrder;
    /**
     * 
     * @type {Array<CustomerCurrentPlan>}
     * @memberof Plan
     */
    'customerCurrentPlans'?: Array<CustomerCurrentPlan>;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface PlanDto
 */
export interface PlanDto {
    /**
     * 
     * @type {string}
     * @memberof PlanDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlanDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PlanLevel = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type PlanLevel = typeof PlanLevel[keyof typeof PlanLevel];


/**
 * 
 * @export
 * @interface PlanOrder
 */
export interface PlanOrder {
    /**
     * 
     * @type {string}
     * @memberof PlanOrder
     */
    'planId': string;
    /**
     * 
     * @type {Plan}
     * @memberof PlanOrder
     */
    'plan': Plan;
    /**
     * 
     * @type {number}
     * @memberof PlanOrder
     */
    'orderIndex': number;
    /**
     * 
     * @type {string}
     * @memberof PlanOrder
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanOrder
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface PlanPhase
 */
export interface PlanPhase {
    /**
     * 
     * @type {string}
     * @memberof PlanPhase
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlanPhase
     */
    'planId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanPhase
     */
    'name'?: string;
    /**
     * 
     * @type {Plan}
     * @memberof PlanPhase
     */
    'plan': Plan;
    /**
     * 
     * @type {Array<PlanPhaseTask>}
     * @memberof PlanPhase
     */
    'planPhaseTasks': Array<PlanPhaseTask>;
    /**
     * 
     * @type {PlanPhaseOrder}
     * @memberof PlanPhase
     */
    'order': PlanPhaseOrder;
    /**
     * 
     * @type {string}
     * @memberof PlanPhase
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanPhase
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface PlanPhaseDto
 */
export interface PlanPhaseDto {
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<PlanPhaseTaskDto>}
     * @memberof PlanPhaseDto
     */
    'tasks': Array<PlanPhaseTaskDto>;
}
/**
 * 
 * @export
 * @interface PlanPhaseOrder
 */
export interface PlanPhaseOrder {
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseOrder
     */
    'planPhaseId': string;
    /**
     * 
     * @type {PlanPhase}
     * @memberof PlanPhaseOrder
     */
    'planPhase': PlanPhase;
    /**
     * 
     * @type {number}
     * @memberof PlanPhaseOrder
     */
    'orderIndex': number;
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseOrder
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseOrder
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface PlanPhaseTask
 */
export interface PlanPhaseTask {
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseTask
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseTask
     */
    'planPhaseId': string;
    /**
     * 
     * @type {PlanPhase}
     * @memberof PlanPhaseTask
     */
    'planPhase': PlanPhase;
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseTask
     */
    'taskId': string;
    /**
     * 
     * @type {Task}
     * @memberof PlanPhaseTask
     */
    'task': Task;
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseTask
     */
    'planId': string;
    /**
     * 
     * @type {Plan}
     * @memberof PlanPhaseTask
     */
    'plan': Plan;
    /**
     * 
     * @type {PlanPhaseTaskOrder}
     * @memberof PlanPhaseTask
     */
    'order': PlanPhaseTaskOrder;
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseTask
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseTask
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface PlanPhaseTaskDto
 */
export interface PlanPhaseTaskDto {
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseTaskDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseTaskDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanPhaseTaskDto
     */
    'isMarked': boolean;
}
/**
 * 
 * @export
 * @interface PlanPhaseTaskOrder
 */
export interface PlanPhaseTaskOrder {
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseTaskOrder
     */
    'planPhaseTaskId': string;
    /**
     * 
     * @type {PlanPhaseTask}
     * @memberof PlanPhaseTaskOrder
     */
    'planPhaseTask': PlanPhaseTask;
    /**
     * 
     * @type {number}
     * @memberof PlanPhaseTaskOrder
     */
    'orderIndex': number;
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseTaskOrder
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanPhaseTaskOrder
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface PlanPhasesDto
 */
export interface PlanPhasesDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof PlanPhasesDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<PlanPhaseDto>}
     * @memberof PlanPhasesDto
     */
    'items': Array<PlanPhaseDto>;
}
/**
 * 
 * @export
 * @interface PlansDto
 */
export interface PlansDto {
    /**
     * 
     * @type {Array<PlanDto>}
     * @memberof PlansDto
     */
    'plans': Array<PlanDto>;
    /**
     * 
     * @type {string}
     * @memberof PlansDto
     */
    'currentPlanId': string;
}
/**
 * 
 * @export
 * @interface PriceDto
 */
export interface PriceDto {
    /**
     * 
     * @type {string}
     * @memberof PriceDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PriceDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof PriceDto
     */
    'oldAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceDto
     */
    'defaultCouponId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceDto
     */
    'firstMonthDiscountCouponId'?: string;
    /**
     * 
     * @type {CouponResponseDto}
     * @memberof PriceDto
     */
    'defaultCoupon'?: CouponResponseDto;
    /**
     * 
     * @type {CouponResponseDto}
     * @memberof PriceDto
     */
    'firstMonthDiscountCoupon'?: CouponResponseDto;
    /**
     * 
     * @type {CancellationPromotionTermsResponseDto}
     * @memberof PriceDto
     */
    'cancellationPromotionTerms'?: CancellationPromotionTermsResponseDto;
}
/**
 * 
 * @export
 * @interface PublicCourseDto
 */
export interface PublicCourseDto {
    /**
     * 
     * @type {string}
     * @memberof PublicCourseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicCourseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PublicCourseDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PublicCourseDto
     */
    'directionId': string;
    /**
     * 
     * @type {number}
     * @memberof PublicCourseDto
     */
    'assignmentsWeight': number;
    /**
     * 
     * @type {number}
     * @memberof PublicCourseDto
     */
    'flashcardsWeight': number;
    /**
     * 
     * @type {number}
     * @memberof PublicCourseDto
     */
    'testTopicsWeight': number;
    /**
     * 
     * @type {number}
     * @memberof PublicCourseDto
     */
    'amountChaptersAtStudyGuide': number;
    /**
     * 
     * @type {number}
     * @memberof PublicCourseDto
     */
    'amountFlashcardsPacks': number;
    /**
     * 
     * @type {number}
     * @memberof PublicCourseDto
     */
    'amountMnemonicCardsPacks': number;
    /**
     * 
     * @type {number}
     * @memberof PublicCourseDto
     */
    'amountQuestions': number;
    /**
     * 
     * @type {string}
     * @memberof PublicCourseDto
     */
    'type': PublicCourseDtoTypeEnum;
    /**
     * 
     * @type {CoursePricesDto}
     * @memberof PublicCourseDto
     */
    'prices': CoursePricesDto;
    /**
     * 
     * @type {string}
     * @memberof PublicCourseDto
     */
    'leastKnownCategory': PublicCourseDtoLeastKnownCategoryEnum;
}

export const PublicCourseDtoTypeEnum = {
    Default: 'default',
    Certificate: 'certificate'
} as const;

export type PublicCourseDtoTypeEnum = typeof PublicCourseDtoTypeEnum[keyof typeof PublicCourseDtoTypeEnum];
export const PublicCourseDtoLeastKnownCategoryEnum = {
    Domain: 'domain',
    Chapter: 'chapter'
} as const;

export type PublicCourseDtoLeastKnownCategoryEnum = typeof PublicCourseDtoLeastKnownCategoryEnum[keyof typeof PublicCourseDtoLeastKnownCategoryEnum];

/**
 * 
 * @export
 * @interface PurchaseCourseInInstallmentDto
 */
export interface PurchaseCourseInInstallmentDto {
    /**
     * 
     * @type {string}
     * @memberof PurchaseCourseInInstallmentDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseCourseInInstallmentDto
     */
    'promocode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseCourseInInstallmentDto
     */
    'useFirstMonthDiscount': boolean;
    /**
     * 
     * @type {StripePaymentSource}
     * @memberof PurchaseCourseInInstallmentDto
     */
    'source': StripePaymentSource;
    /**
     * 
     * @type {PlanLevel}
     * @memberof PurchaseCourseInInstallmentDto
     */
    'level': PlanLevel;
}
/**
 * 
 * @export
 * @interface PurchaseCourseRequestDto
 */
export interface PurchaseCourseRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PurchaseCourseRequestDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseCourseRequestDto
     */
    'promocode'?: string;
    /**
     * 
     * @type {StripePaymentSource}
     * @memberof PurchaseCourseRequestDto
     */
    'source': StripePaymentSource;
    /**
     * 
     * @type {PlanLevel}
     * @memberof PurchaseCourseRequestDto
     */
    'level': PlanLevel;
}
/**
 * 
 * @export
 * @interface PurchaseDto
 */
export interface PurchaseDto {
    /**
     * 
     * @type {string}
     * @memberof PurchaseDto
     */
    'type': PurchaseDtoTypeEnum;
}

export const PurchaseDtoTypeEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type PurchaseDtoTypeEnum = typeof PurchaseDtoTypeEnum[keyof typeof PurchaseDtoTypeEnum];

/**
 * 
 * @export
 * @interface QuestionAmountsResponseDto
 */
export interface QuestionAmountsResponseDto {
    /**
     * Total amount of questions in group
     * @type {number}
     * @memberof QuestionAmountsResponseDto
     */
    'total': number;
    /**
     * Amount of questions, available for free plans
     * @type {number}
     * @memberof QuestionAmountsResponseDto
     */
    'free': number;
    /**
     * Amount of questions, available only for better plans
     * @type {number}
     * @memberof QuestionAmountsResponseDto
     */
    'paid': number;
}
/**
 * 
 * @export
 * @interface QuestionAnswerResponseDto
 */
export interface QuestionAnswerResponseDto {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerResponseDto
     */
    'optionId': string | null;
}
/**
 * 
 * @export
 * @interface QuestionChapter
 */
export interface QuestionChapter {
    /**
     * 
     * @type {string}
     * @memberof QuestionChapter
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionChapter
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionChapter
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof QuestionChapter
     */
    'sortIndex': number;
    /**
     * 
     * @type {Course}
     * @memberof QuestionChapter
     */
    'course': Course;
    /**
     * 
     * @type {Array<NewQuestion>}
     * @memberof QuestionChapter
     */
    'questions': Array<NewQuestion>;
    /**
     * 
     * @type {string}
     * @memberof QuestionChapter
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionChapter
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface QuestionDomain
 */
export interface QuestionDomain {
    /**
     * 
     * @type {string}
     * @memberof QuestionDomain
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionDomain
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof QuestionDomain
     */
    'sortIndex': number;
    /**
     * 
     * @type {string}
     * @memberof QuestionDomain
     */
    'courseId': string;
    /**
     * 
     * @type {Course}
     * @memberof QuestionDomain
     */
    'course': Course;
    /**
     * 
     * @type {Array<NewQuestion>}
     * @memberof QuestionDomain
     */
    'questions': Array<NewQuestion>;
    /**
     * 
     * @type {string}
     * @memberof QuestionDomain
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionDomain
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface QuestionFlashcardDto
 */
export interface QuestionFlashcardDto {
    /**
     * 
     * @type {string}
     * @memberof QuestionFlashcardDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionFlashcardDto
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionFlashcardDto
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionFlashcardDto
     */
    'questionImageUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionFlashcardDto
     */
    'answerImageUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionFlashcardDto
     */
    'lastAnswer': QuestionFlashcardDtoLastAnswerEnum;
    /**
     * 
     * @type {FlashcardNextIntervals}
     * @memberof QuestionFlashcardDto
     */
    'nextIntervals': FlashcardNextIntervals;
}

export const QuestionFlashcardDtoLastAnswerEnum = {
    No: 'NO',
    Kinda: 'KINDA',
    Yes: 'YES'
} as const;

export type QuestionFlashcardDtoLastAnswerEnum = typeof QuestionFlashcardDtoLastAnswerEnum[keyof typeof QuestionFlashcardDtoLastAnswerEnum];

/**
 * 
 * @export
 * @interface QuestionFlashcardsDto
 */
export interface QuestionFlashcardsDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof QuestionFlashcardsDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<QuestionFlashcardDto>}
     * @memberof QuestionFlashcardsDto
     */
    'items': Array<QuestionFlashcardDto>;
    /**
     * Time to next available flashcard in seconds
     * @type {number}
     * @memberof QuestionFlashcardsDto
     */
    'timeToNextAvailableCard': number;
    /**
     * 
     * @type {string}
     * @memberof QuestionFlashcardsDto
     */
    'packName': string;
}
/**
 * 
 * @export
 * @interface QuestionFlashcardsPackDto
 */
export interface QuestionFlashcardsPackDto {
    /**
     * 
     * @type {number}
     * @memberof QuestionFlashcardsPackDto
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface QuestionOptionResponseDto
 */
export interface QuestionOptionResponseDto {
    /**
     * Option ID
     * @type {string}
     * @memberof QuestionOptionResponseDto
     */
    'id': string;
    /**
     * Option title
     * @type {string}
     * @memberof QuestionOptionResponseDto
     */
    'title': string;
    /**
     * Option order index
     * @type {number}
     * @memberof QuestionOptionResponseDto
     */
    'orderIndex': number;
}
/**
 * 
 * @export
 * @interface QuestionResponseDto
 */
export interface QuestionResponseDto {
    /**
     * Question ID
     * @type {string}
     * @memberof QuestionResponseDto
     */
    'id': string;
    /**
     * Question domain ID
     * @type {string}
     * @memberof QuestionResponseDto
     */
    'domainId': string | null;
    /**
     * Question chapter ID
     * @type {string}
     * @memberof QuestionResponseDto
     */
    'chapterId': string | null;
    /**
     * Question text
     * @type {string}
     * @memberof QuestionResponseDto
     */
    'subject': string;
    /**
     * Explanation text
     * @type {string}
     * @memberof QuestionResponseDto
     */
    'explanationText': string | null;
    /**
     * Explanation image URL
     * @type {string}
     * @memberof QuestionResponseDto
     */
    'explanationImageUrl': string | null;
    /**
     * Hint
     * @type {string}
     * @memberof QuestionResponseDto
     */
    'hint': string;
    /**
     * Question images
     * @type {Array<string>}
     * @memberof QuestionResponseDto
     */
    'imagesUrls': Array<string>;
    /**
     * Is question available for free plans
     * @type {boolean}
     * @memberof QuestionResponseDto
     */
    'availableForFree': boolean;
    /**
     * Is question marked by a user
     * @type {boolean}
     * @memberof QuestionResponseDto
     */
    'isMarked': boolean;
    /**
     * Is question in flashcard pack by customer
     * @type {boolean}
     * @memberof QuestionResponseDto
     */
    'isInFlashcard': boolean;
    /**
     * Question options
     * @type {Array<QuestionOptionResponseDto>}
     * @memberof QuestionResponseDto
     */
    'options': Array<QuestionOptionResponseDto>;
    /**
     * 
     * @type {QuestionResponseDtoAnswer}
     * @memberof QuestionResponseDto
     */
    'answer': QuestionResponseDtoAnswer;
}
/**
 * Question answer
 * @export
 * @interface QuestionResponseDtoAnswer
 */
export interface QuestionResponseDtoAnswer {
    /**
     * 
     * @type {string}
     * @memberof QuestionResponseDtoAnswer
     */
    'optionId': string | null;
}
/**
 * 
 * @export
 * @interface Quiz
 */
export interface Quiz {
    /**
     * 
     * @type {string}
     * @memberof Quiz
     */
    'id': string;
    /**
     * 
     * @type {GuideChapter}
     * @memberof Quiz
     */
    'guideChapter'?: GuideChapter;
    /**
     * 
     * @type {Array<NewQuestion>}
     * @memberof Quiz
     */
    'newQuestions'?: Array<NewQuestion>;
}
/**
 * 
 * @export
 * @interface QuizTestPassageResponseDto
 */
export interface QuizTestPassageResponseDto {
    /**
     * Passage ID
     * @type {string}
     * @memberof QuizTestPassageResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QuizTestPassageResponseDto
     */
    'type': QuizTestPassageResponseDtoTypeEnum;
    /**
     * 
     * @type {Array<QuestionResponseDto>}
     * @memberof QuizTestPassageResponseDto
     */
    'questions': Array<QuestionResponseDto>;
    /**
     * Quiz ID
     * @type {string}
     * @memberof QuizTestPassageResponseDto
     */
    'quizId': string;
}

export const QuizTestPassageResponseDtoTypeEnum = {
    TimedTest: 'timed_test',
    TutorTest: 'tutor_test',
    QuizTest: 'quiz_test'
} as const;

export type QuizTestPassageResponseDtoTypeEnum = typeof QuizTestPassageResponseDtoTypeEnum[keyof typeof QuizTestPassageResponseDtoTypeEnum];

/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'resolvedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'resource': ReportResourceEnum;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'resourceId': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'resourceTitle': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'content': string;
    /**
     * 
     * @type {Customer}
     * @memberof Report
     */
    'customer'?: Customer;
    /**
     * 
     * @type {Course}
     * @memberof Report
     */
    'course'?: Course;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'updatedAt': string;
}

export const ReportResourceEnum = {
    Flashcard: 'flashcard',
    MnemonicCard: 'mnemonic_card',
    TestQuestion: 'test_question'
} as const;

export type ReportResourceEnum = typeof ReportResourceEnum[keyof typeof ReportResourceEnum];

/**
 * 
 * @export
 * @interface ReportCourseDto
 */
export interface ReportCourseDto {
    /**
     * 
     * @type {string}
     * @memberof ReportCourseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportCourseDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface RestoreInstallmentPaymentDto
 */
export interface RestoreInstallmentPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof RestoreInstallmentPaymentDto
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface SearchGuideTextContentBlockDto
 */
export interface SearchGuideTextContentBlockDto {
    /**
     * 
     * @type {Array<SearchGuideTextContentBlockJoinDto>}
     * @memberof SearchGuideTextContentBlockDto
     */
    'blocks': Array<SearchGuideTextContentBlockJoinDto>;
    /**
     * 
     * @type {number}
     * @memberof SearchGuideTextContentBlockDto
     */
    'time': number;
    /**
     * 
     * @type {string}
     * @memberof SearchGuideTextContentBlockDto
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface SearchGuideTextContentBlockJoinDto
 */
export interface SearchGuideTextContentBlockJoinDto {
    /**
     * 
     * @type {string}
     * @memberof SearchGuideTextContentBlockJoinDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SearchGuideTextContentBlockJoinDto
     */
    'type': SearchGuideTextContentBlockJoinDtoTypeEnum;
    /**
     * 
     * @type {SearchGuideTextContentBlockJoinDtoData}
     * @memberof SearchGuideTextContentBlockJoinDto
     */
    'data': SearchGuideTextContentBlockJoinDtoData;
    /**
     * 
     * @type {object}
     * @memberof SearchGuideTextContentBlockJoinDto
     */
    'tunes'?: object;
}

export const SearchGuideTextContentBlockJoinDtoTypeEnum = {
    Paragraph: 'paragraph',
    List: 'list'
} as const;

export type SearchGuideTextContentBlockJoinDtoTypeEnum = typeof SearchGuideTextContentBlockJoinDtoTypeEnum[keyof typeof SearchGuideTextContentBlockJoinDtoTypeEnum];

/**
 * @type SearchGuideTextContentBlockJoinDtoData
 * @export
 */
export type SearchGuideTextContentBlockJoinDtoData = SearchGuideTextContentBlockListDataDto | SearchTextResultFoundTextResponseDto;

/**
 * 
 * @export
 * @interface SearchGuideTextContentBlockListDataDto
 */
export interface SearchGuideTextContentBlockListDataDto {
    /**
     * 
     * @type {string}
     * @memberof SearchGuideTextContentBlockListDataDto
     */
    'style': SearchGuideTextContentBlockListDataDtoStyleEnum;
    /**
     * 
     * @type {Array<SearchTextResultFoundTextResponseDto>}
     * @memberof SearchGuideTextContentBlockListDataDto
     */
    'items': Array<SearchTextResultFoundTextResponseDto>;
}

export const SearchGuideTextContentBlockListDataDtoStyleEnum = {
    Ordered: 'ordered',
    Unordered: 'unordered'
} as const;

export type SearchGuideTextContentBlockListDataDtoStyleEnum = typeof SearchGuideTextContentBlockListDataDtoStyleEnum[keyof typeof SearchGuideTextContentBlockListDataDtoStyleEnum];

/**
 * 
 * @export
 * @interface SearchGuideTextContentBlockListResponseDto
 */
export interface SearchGuideTextContentBlockListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SearchGuideTextContentBlockListResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SearchGuideTextContentBlockListResponseDto
     */
    'type': SearchGuideTextContentBlockListResponseDtoTypeEnum;
    /**
     * 
     * @type {SearchGuideTextContentBlockListDataDto}
     * @memberof SearchGuideTextContentBlockListResponseDto
     */
    'data': SearchGuideTextContentBlockListDataDto;
    /**
     * 
     * @type {object}
     * @memberof SearchGuideTextContentBlockListResponseDto
     */
    'tunes'?: object;
}

export const SearchGuideTextContentBlockListResponseDtoTypeEnum = {
    List: 'list'
} as const;

export type SearchGuideTextContentBlockListResponseDtoTypeEnum = typeof SearchGuideTextContentBlockListResponseDtoTypeEnum[keyof typeof SearchGuideTextContentBlockListResponseDtoTypeEnum];

/**
 * 
 * @export
 * @interface SearchGuideTextContentBlockParagraphResponseDto
 */
export interface SearchGuideTextContentBlockParagraphResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SearchGuideTextContentBlockParagraphResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SearchGuideTextContentBlockParagraphResponseDto
     */
    'type': SearchGuideTextContentBlockParagraphResponseDtoTypeEnum;
    /**
     * 
     * @type {SearchTextResultFoundTextResponseDto}
     * @memberof SearchGuideTextContentBlockParagraphResponseDto
     */
    'data': SearchTextResultFoundTextResponseDto;
    /**
     * 
     * @type {object}
     * @memberof SearchGuideTextContentBlockParagraphResponseDto
     */
    'tunes'?: object;
}

export const SearchGuideTextContentBlockParagraphResponseDtoTypeEnum = {
    Paragraph: 'paragraph'
} as const;

export type SearchGuideTextContentBlockParagraphResponseDtoTypeEnum = typeof SearchGuideTextContentBlockParagraphResponseDtoTypeEnum[keyof typeof SearchGuideTextContentBlockParagraphResponseDtoTypeEnum];

/**
 * 
 * @export
 * @interface SearchGuideTextRequestDto
 */
export interface SearchGuideTextRequestDto {
    /**
     * 
     * @type {string}
     * @memberof SearchGuideTextRequestDto
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface SearchGuideTextResponseDto
 */
export interface SearchGuideTextResponseDto {
    /**
     * 
     * @type {Array<SearchTextGuideChapterResponseDto>}
     * @memberof SearchGuideTextResponseDto
     */
    'chapters': Array<SearchTextGuideChapterResponseDto>;
}
/**
 * 
 * @export
 * @interface SearchTestPassagesRequestDto
 */
export interface SearchTestPassagesRequestDto {
    /**
     * 
     * @type {number}
     * @memberof SearchTestPassagesRequestDto
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchTestPassagesRequestDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchTestPassagesRequestDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof SearchTestPassagesRequestDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTestPassagesRequestDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTestPassagesRequestDto
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface SearchTestPassagesResponseDto
 */
export interface SearchTestPassagesResponseDto {
    /**
     * 
     * @type {PaginationMetaDto}
     * @memberof SearchTestPassagesResponseDto
     */
    'meta': PaginationMetaDto;
    /**
     * 
     * @type {Array<SearchTestPassagesResponseItemDto>}
     * @memberof SearchTestPassagesResponseDto
     */
    'items': Array<SearchTestPassagesResponseItemDto>;
}
/**
 * 
 * @export
 * @interface SearchTestPassagesResponseItemDto
 */
export interface SearchTestPassagesResponseItemDto {
    /**
     * Passage ID
     * @type {string}
     * @memberof SearchTestPassagesResponseItemDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SearchTestPassagesResponseItemDto
     */
    'type': SearchTestPassagesResponseItemDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchTestPassagesResponseItemDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SearchTestPassagesResponseItemDto
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTestPassagesResponseItemDto
     */
    'quizId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTestPassagesResponseItemDto
     */
    'timedTestId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTestPassagesResponseItemDto
     */
    'closedAt': string;
}

export const SearchTestPassagesResponseItemDtoTypeEnum = {
    TimedTest: 'timed_test',
    TutorTest: 'tutor_test',
    QuizTest: 'quiz_test'
} as const;

export type SearchTestPassagesResponseItemDtoTypeEnum = typeof SearchTestPassagesResponseItemDtoTypeEnum[keyof typeof SearchTestPassagesResponseItemDtoTypeEnum];

/**
 * 
 * @export
 * @interface SearchTextGuideChapterResponseDto
 */
export interface SearchTextGuideChapterResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SearchTextGuideChapterResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SearchTextGuideChapterResponseDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SearchTextGuideChapterResponseDto
     */
    'orderIndex': number;
    /**
     * 
     * @type {Array<SearchTextGuideChapterUnitResponseDto>}
     * @memberof SearchTextGuideChapterResponseDto
     */
    'units': Array<SearchTextGuideChapterUnitResponseDto>;
}
/**
 * 
 * @export
 * @interface SearchTextGuideChapterUnitResponseDto
 */
export interface SearchTextGuideChapterUnitResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SearchTextGuideChapterUnitResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SearchTextGuideChapterUnitResponseDto
     */
    'name': string;
    /**
     * 
     * @type {SearchGuideTextContentBlockDto}
     * @memberof SearchTextGuideChapterUnitResponseDto
     */
    'content': SearchGuideTextContentBlockDto;
    /**
     * 
     * @type {number}
     * @memberof SearchTextGuideChapterUnitResponseDto
     */
    'orderIndex': number;
}
/**
 * 
 * @export
 * @interface SearchTextResultFoundTextResponseDto
 */
export interface SearchTextResultFoundTextResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SearchTextResultFoundTextResponseDto
     */
    'sanitizedText': string;
    /**
     * 
     * @type {string}
     * @memberof SearchTextResultFoundTextResponseDto
     */
    'foundText': string;
    /**
     * 
     * @type {number}
     * @memberof SearchTextResultFoundTextResponseDto
     */
    'indexOfFoundText': number;
    /**
     * 
     * @type {string}
     * @memberof SearchTextResultFoundTextResponseDto
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface SingleAnswerFlashcardPackStatsDto
 */
export interface SingleAnswerFlashcardPackStatsDto {
    /**
     * 
     * @type {number}
     * @memberof SingleAnswerFlashcardPackStatsDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof SingleAnswerFlashcardPackStatsDto
     */
    'percent': number;
    /**
     * 
     * @type {string}
     * @memberof SingleAnswerFlashcardPackStatsDto
     */
    'packId': string;
    /**
     * 
     * @type {string}
     * @memberof SingleAnswerFlashcardPackStatsDto
     */
    'packName': string;
}
/**
 * 
 * @export
 * @interface StartTutorTestPassageRequestDto
 */
export interface StartTutorTestPassageRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof StartTutorTestPassageRequestDto
     */
    'domainIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StartTutorTestPassageRequestDto
     */
    'chapterIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StartTutorTestPassageRequestDto
     */
    'modes'?: Array<StartTutorTestPassageRequestDtoModesEnum>;
    /**
     * 
     * @type {number}
     * @memberof StartTutorTestPassageRequestDto
     */
    'questionsAmount': number;
}

export const StartTutorTestPassageRequestDtoModesEnum = {
    Marked: 'MARKED',
    Unused: 'UNUSED',
    Incorrect: 'INCORRECT'
} as const;

export type StartTutorTestPassageRequestDtoModesEnum = typeof StartTutorTestPassageRequestDtoModesEnum[keyof typeof StartTutorTestPassageRequestDtoModesEnum];

/**
 * 
 * @export
 * @interface StatsButtonNamesDto
 */
export interface StatsButtonNamesDto {
    /**
     * 
     * @type {string}
     * @memberof StatsButtonNamesDto
     */
    'topicButtonName': string;
    /**
     * 
     * @type {string}
     * @memberof StatsButtonNamesDto
     */
    'chapterButtonName': string;
}
/**
 * 
 * @export
 * @interface StripeCustomerInfoRequestDto
 */
export interface StripeCustomerInfoRequestDto {
    /**
     * 
     * @type {string}
     * @memberof StripeCustomerInfoRequestDto
     */
    'directionId': string;
    /**
     * 
     * @type {string}
     * @memberof StripeCustomerInfoRequestDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface StripeCustomerInfoResponseDto
 */
export interface StripeCustomerInfoResponseDto {
    /**
     * 
     * @type {string}
     * @memberof StripeCustomerInfoResponseDto
     */
    'customerId': string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeCustomerInfoResponseDto
     */
    'stripeCustomerId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StripeCustomerInfoResponseDto
     */
    'isExistingCustomer': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StripePaymentSource = {
    LandingPage: 'landing page',
    Application: 'application'
} as const;

export type StripePaymentSource = typeof StripePaymentSource[keyof typeof StripePaymentSource];


/**
 * 
 * @export
 * @interface StripeProvider
 */
export interface StripeProvider {
    /**
     * 
     * @type {string}
     * @memberof StripeProvider
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StripeProvider
     */
    'stripeAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof StripeProvider
     */
    'directionId': string;
    /**
     * 
     * @type {object}
     * @memberof StripeProvider
     */
    'credentials': object;
    /**
     * 
     * @type {Direction}
     * @memberof StripeProvider
     */
    'direction'?: Direction;
}
/**
 * 
 * @export
 * @interface SubscribeDto
 */
export interface SubscribeDto {
    /**
     * 
     * @type {string}
     * @memberof SubscribeDto
     */
    'type': SubscribeDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscribeDto
     */
    'period': SubscribeDtoPeriodEnum;
}

export const SubscribeDtoTypeEnum = {
    None: 'none',
    Bronze: 'bronze',
    Silver: 'silver',
    Gold: 'gold'
} as const;

export type SubscribeDtoTypeEnum = typeof SubscribeDtoTypeEnum[keyof typeof SubscribeDtoTypeEnum];
export const SubscribeDtoPeriodEnum = {
    Monthly: 'monthly',
    Annual: 'annual'
} as const;

export type SubscribeDtoPeriodEnum = typeof SubscribeDtoPeriodEnum[keyof typeof SubscribeDtoPeriodEnum];

/**
 * 
 * @export
 * @interface SubscribeToCourseRequestDto
 */
export interface SubscribeToCourseRequestDto {
    /**
     * 
     * @type {string}
     * @memberof SubscribeToCourseRequestDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof SubscribeToCourseRequestDto
     */
    'promocode'?: string;
    /**
     * 
     * @type {StripePaymentSource}
     * @memberof SubscribeToCourseRequestDto
     */
    'source': StripePaymentSource;
    /**
     * 
     * @type {PlanLevel}
     * @memberof SubscribeToCourseRequestDto
     */
    'level': PlanLevel;
    /**
     * 
     * @type {SubscriptionPeriod}
     * @memberof SubscribeToCourseRequestDto
     */
    'period': SubscriptionPeriod;
}
/**
 * 
 * @export
 * @interface SubscribeToMarketingEmailsDto
 */
export interface SubscribeToMarketingEmailsDto {
    /**
     * 
     * @type {string}
     * @memberof SubscribeToMarketingEmailsDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof SubscribeToMarketingEmailsDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SubscriptionPeriod = {
    Monthly: 'monthly',
    Annual: 'annual'
} as const;

export type SubscriptionPeriod = typeof SubscriptionPeriod[keyof typeof SubscriptionPeriod];


/**
 * 
 * @export
 * @interface SubscriptionPricesDto
 */
export interface SubscriptionPricesDto {
    /**
     * 
     * @type {PriceDto}
     * @memberof SubscriptionPricesDto
     */
    'gold': PriceDto;
    /**
     * 
     * @type {PriceDto}
     * @memberof SubscriptionPricesDto
     */
    'silver': PriceDto;
    /**
     * 
     * @type {PriceDto}
     * @memberof SubscriptionPricesDto
     */
    'bronze': PriceDto;
}
/**
 * 
 * @export
 * @interface SuccessResponseDto
 */
export interface SuccessResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponseDto
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseDto
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface Suggestion
 */
export interface Suggestion {
    /**
     * 
     * @type {string}
     * @memberof Suggestion
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Suggestion
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof Suggestion
     */
    'resolvedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Suggestion
     */
    'customerId': string | null;
    /**
     * 
     * @type {Customer}
     * @memberof Suggestion
     */
    'customer': Customer;
    /**
     * 
     * @type {string}
     * @memberof Suggestion
     */
    'courseId': string;
    /**
     * 
     * @type {Course}
     * @memberof Suggestion
     */
    'course': Course;
    /**
     * 
     * @type {string}
     * @memberof Suggestion
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Suggestion
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'courseId': string;
    /**
     * 
     * @type {Course}
     * @memberof Task
     */
    'course': Course;
    /**
     * 
     * @type {Array<PlanPhaseTask>}
     * @memberof Task
     */
    'planPhaseTasks': Array<PlanPhaseTask>;
    /**
     * 
     * @type {Array<CustomerCompletedTask>}
     * @memberof Task
     */
    'customerCompletedTasks'?: Array<CustomerCompletedTask>;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface TasksPointsDto
 */
export interface TasksPointsDto {
    /**
     * Total amount of tasks in current plan
     * @type {number}
     * @memberof TasksPointsDto
     */
    'total': number;
    /**
     * Amount of completed tasks
     * @type {number}
     * @memberof TasksPointsDto
     */
    'marked': number;
    /**
     * Weight of single task in percents
     * @type {number}
     * @memberof TasksPointsDto
     */
    'taskWeightInPercents': number;
    /**
     * Weight of single task in points
     * @type {number}
     * @memberof TasksPointsDto
     */
    'taskWeightInPoints': number;
    /**
     * Total weight of tasks in percents
     * @type {number}
     * @memberof TasksPointsDto
     */
    'totalTaskWeightInPercents': number;
    /**
     * Total weight of tasks in points
     * @type {number}
     * @memberof TasksPointsDto
     */
    'totalTaskWeightInPoints': number;
    /**
     * Amount of completed tasks in percents
     * @type {number}
     * @memberof TasksPointsDto
     */
    'markedInPercents': number;
    /**
     * Amount of completed tasks in points
     * @type {number}
     * @memberof TasksPointsDto
     */
    'markedInPoints': number;
}
/**
 * 
 * @export
 * @interface TestAnswerSplitStatsDto
 */
export interface TestAnswerSplitStatsDto {
    /**
     * 
     * @type {number}
     * @memberof TestAnswerSplitStatsDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof TestAnswerSplitStatsDto
     */
    'percent': number;
    /**
     * 
     * @type {string}
     * @memberof TestAnswerSplitStatsDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TestAnswerSplitStatsDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TestPassageStatsDto
 */
export interface TestPassageStatsDto {
    /**
     * 
     * @type {number}
     * @memberof TestPassageStatsDto
     */
    'total': number;
    /**
     * 
     * @type {TestsAnswerStatsDto}
     * @memberof TestPassageStatsDto
     */
    'correct': TestsAnswerStatsDto;
    /**
     * 
     * @type {TestsAnswerStatsDto}
     * @memberof TestPassageStatsDto
     */
    'incorrect': TestsAnswerStatsDto;
    /**
     * 
     * @type {TestsAnswerStatsDto}
     * @memberof TestPassageStatsDto
     */
    'unused': TestsAnswerStatsDto;
    /**
     * 
     * @type {string}
     * @memberof TestPassageStatsDto
     */
    'id': string;
}
/**
 * @type TestPassagesControllerGetLastUncompletedPassage200Response
 * @export
 */
export type TestPassagesControllerGetLastUncompletedPassage200Response = QuizTestPassageResponseDto | TimedTestPassageResponseDto | TutorTestPassageResponseDto;

/**
 * 
 * @export
 * @interface TestSplitStatsDto
 */
export interface TestSplitStatsDto {
    /**
     * 
     * @type {number}
     * @memberof TestSplitStatsDto
     */
    'total': number;
    /**
     * 
     * @type {TestsAnswerStatsDto}
     * @memberof TestSplitStatsDto
     */
    'correct': TestsAnswerStatsDto;
    /**
     * 
     * @type {TestsAnswerStatsDto}
     * @memberof TestSplitStatsDto
     */
    'incorrect': TestsAnswerStatsDto;
    /**
     * 
     * @type {TestsAnswerStatsDto}
     * @memberof TestSplitStatsDto
     */
    'unused': TestsAnswerStatsDto;
    /**
     * 
     * @type {string}
     * @memberof TestSplitStatsDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TestSplitStatsDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TestsAnswerStatsDto
 */
export interface TestsAnswerStatsDto {
    /**
     * 
     * @type {number}
     * @memberof TestsAnswerStatsDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof TestsAnswerStatsDto
     */
    'percent': number;
}
/**
 * 
 * @export
 * @interface TestsBaseStatsDto
 */
export interface TestsBaseStatsDto {
    /**
     * 
     * @type {number}
     * @memberof TestsBaseStatsDto
     */
    'total': number;
    /**
     * 
     * @type {TestsAnswerStatsDto}
     * @memberof TestsBaseStatsDto
     */
    'correct': TestsAnswerStatsDto;
    /**
     * 
     * @type {TestsAnswerStatsDto}
     * @memberof TestsBaseStatsDto
     */
    'incorrect': TestsAnswerStatsDto;
    /**
     * 
     * @type {TestsAnswerStatsDto}
     * @memberof TestsBaseStatsDto
     */
    'unused': TestsAnswerStatsDto;
}
/**
 * 
 * @export
 * @interface TestsPointsDto
 */
export interface TestsPointsDto {
    /**
     * Total amount of test questions in course
     * @type {number}
     * @memberof TestsPointsDto
     */
    'total': number;
    /**
     * Amount of correct answers
     * @type {number}
     * @memberof TestsPointsDto
     */
    'correct': number;
    /**
     * Weight of single question in percents
     * @type {number}
     * @memberof TestsPointsDto
     */
    'questionWeightInPercents': number;
    /**
     * Weight of single question in points
     * @type {number}
     * @memberof TestsPointsDto
     */
    'questionWeightInPoints': number;
    /**
     * Total weight of test questions in percents
     * @type {number}
     * @memberof TestsPointsDto
     */
    'totalQuestionWeightInPercents': number;
    /**
     * Total weight of test questions in points
     * @type {number}
     * @memberof TestsPointsDto
     */
    'totalQuestionWeightInPoints': number;
    /**
     * Amount of correct questions in percents
     * @type {number}
     * @memberof TestsPointsDto
     */
    'correctInPercents': number;
    /**
     * Amount of correct questions in points
     * @type {number}
     * @memberof TestsPointsDto
     */
    'correctInPoints': number;
}
/**
 * 
 * @export
 * @interface TextBookUrlDto
 */
export interface TextBookUrlDto {
    /**
     * 
     * @type {string}
     * @memberof TextBookUrlDto
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface TimedTest
 */
export interface TimedTest {
    /**
     * 
     * @type {string}
     * @memberof TimedTest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TimedTest
     */
    'courseId': string;
    /**
     * 
     * @type {number}
     * @memberof TimedTest
     */
    'durationSec': number;
    /**
     * 
     * @type {Course}
     * @memberof TimedTest
     */
    'course': Course;
    /**
     * 
     * @type {Array<TimedTestDomain>}
     * @memberof TimedTest
     */
    'timedTestDomains': Array<TimedTestDomain>;
    /**
     * 
     * @type {Array<TimedTestChapter>}
     * @memberof TimedTest
     */
    'timedTestChapters': Array<TimedTestChapter>;
    /**
     * 
     * @type {string}
     * @memberof TimedTest
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TimedTest
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface TimedTestChapter
 */
export interface TimedTestChapter {
    /**
     * 
     * @type {string}
     * @memberof TimedTestChapter
     */
    'timedTestId': string;
    /**
     * 
     * @type {string}
     * @memberof TimedTestChapter
     */
    'chapterId': string;
    /**
     * 
     * @type {number}
     * @memberof TimedTestChapter
     */
    'amountOfQuestions': number;
    /**
     * 
     * @type {TimedTest}
     * @memberof TimedTestChapter
     */
    'timedTest': TimedTest;
    /**
     * 
     * @type {QuestionChapter}
     * @memberof TimedTestChapter
     */
    'chapter': QuestionChapter;
}
/**
 * 
 * @export
 * @interface TimedTestDomain
 */
export interface TimedTestDomain {
    /**
     * 
     * @type {string}
     * @memberof TimedTestDomain
     */
    'timedTestId': string;
    /**
     * 
     * @type {string}
     * @memberof TimedTestDomain
     */
    'domainId': string;
    /**
     * 
     * @type {number}
     * @memberof TimedTestDomain
     */
    'amountOfQuestions': number;
    /**
     * 
     * @type {TimedTest}
     * @memberof TimedTestDomain
     */
    'timedTest': TimedTest;
    /**
     * 
     * @type {QuestionDomain}
     * @memberof TimedTestDomain
     */
    'domain': QuestionDomain;
}
/**
 * 
 * @export
 * @interface TimedTestPassageResponseDto
 */
export interface TimedTestPassageResponseDto {
    /**
     * Passage ID
     * @type {string}
     * @memberof TimedTestPassageResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TimedTestPassageResponseDto
     */
    'type': TimedTestPassageResponseDtoTypeEnum;
    /**
     * 
     * @type {Array<QuestionResponseDto>}
     * @memberof TimedTestPassageResponseDto
     */
    'questions': Array<QuestionResponseDto>;
    /**
     * When timed test will finish automatically
     * @type {string}
     * @memberof TimedTestPassageResponseDto
     */
    'willCloseAt': string;
    /**
     * Timed test ID
     * @type {string}
     * @memberof TimedTestPassageResponseDto
     */
    'timedTestId': string;
}

export const TimedTestPassageResponseDtoTypeEnum = {
    TimedTest: 'timed_test',
    TutorTest: 'tutor_test',
    QuizTest: 'quiz_test'
} as const;

export type TimedTestPassageResponseDtoTypeEnum = typeof TimedTestPassageResponseDtoTypeEnum[keyof typeof TimedTestPassageResponseDtoTypeEnum];

/**
 * 
 * @export
 * @interface TimedTestResponseDto
 */
export interface TimedTestResponseDto {
    /**
     * Timed test ID
     * @type {string}
     * @memberof TimedTestResponseDto
     */
    'id': string;
    /**
     * Course ID
     * @type {string}
     * @memberof TimedTestResponseDto
     */
    'courseId': string;
    /**
     * Duration in seconds
     * @type {number}
     * @memberof TimedTestResponseDto
     */
    'durationSec': number;
    /**
     * Number of questions
     * @type {number}
     * @memberof TimedTestResponseDto
     */
    'questionsCount': number;
}
/**
 * 
 * @export
 * @interface TotalPointsDto
 */
export interface TotalPointsDto {
    /**
     * 
     * @type {number}
     * @memberof TotalPointsDto
     */
    'totalInPercents': number;
    /**
     * 
     * @type {number}
     * @memberof TotalPointsDto
     */
    'totalInPoints': number;
    /**
     * 
     * @type {FlashcardsPointsDto}
     * @memberof TotalPointsDto
     */
    'flashcardsPoints': FlashcardsPointsDto;
    /**
     * 
     * @type {TestsPointsDto}
     * @memberof TotalPointsDto
     */
    'testsPoints': TestsPointsDto;
    /**
     * 
     * @type {TasksPointsDto}
     * @memberof TotalPointsDto
     */
    'tasksPoints': TasksPointsDto;
    /**
     * 
     * @type {number}
     * @memberof TotalPointsDto
     */
    'streak': number;
    /**
     * 
     * @type {boolean}
     * @memberof TotalPointsDto
     */
    'addedStreakToday': boolean;
}
/**
 * 
 * @export
 * @interface TutorTestPassageResponseDto
 */
export interface TutorTestPassageResponseDto {
    /**
     * Passage ID
     * @type {string}
     * @memberof TutorTestPassageResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TutorTestPassageResponseDto
     */
    'type': TutorTestPassageResponseDtoTypeEnum;
    /**
     * 
     * @type {Array<QuestionResponseDto>}
     * @memberof TutorTestPassageResponseDto
     */
    'questions': Array<QuestionResponseDto>;
    /**
     * 
     * @type {string}
     * @memberof TutorTestPassageResponseDto
     */
    'courseId': string;
}

export const TutorTestPassageResponseDtoTypeEnum = {
    TimedTest: 'timed_test',
    TutorTest: 'tutor_test',
    QuizTest: 'quiz_test'
} as const;

export type TutorTestPassageResponseDtoTypeEnum = typeof TutorTestPassageResponseDtoTypeEnum[keyof typeof TutorTestPassageResponseDtoTypeEnum];

/**
 * 
 * @export
 * @interface UnpauseSubscriptionRequestDto
 */
export interface UnpauseSubscriptionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UnpauseSubscriptionRequestDto
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface UpdateAdminDto
 */
export interface UpdateAdminDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'role'?: UpdateAdminDtoRoleEnum;
    /**
     * 
     * @type {Array<DirectionIdDto>}
     * @memberof UpdateAdminDto
     */
    'directions'?: Array<DirectionIdDto>;
}

export const UpdateAdminDtoRoleEnum = {
    Admin: 'admin',
    Superadmin: 'superadmin'
} as const;

export type UpdateAdminDtoRoleEnum = typeof UpdateAdminDtoRoleEnum[keyof typeof UpdateAdminDtoRoleEnum];

/**
 * 
 * @export
 * @interface UpdateDirectionDto
 */
export interface UpdateDirectionDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'darkLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'checkoutLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'darkCheckoutLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'faviconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'signUpTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'restorePasswordTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'changeEmailTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'subscriptionPaymentFailedTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'courseUnreleasedTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'inviteTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'freeAccessGrantedTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'paymentSuccessfulTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'linkTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'linkDescription'?: string;
    /**
     * 
     * @type {CreateDirectionMailSettingsDto}
     * @memberof UpdateDirectionDto
     */
    'mailSettings'?: CreateDirectionMailSettingsDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'landingUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'siteUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'termsOfServiceUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'privacyPolicyUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'mainColorHex'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDirectionDto
     */
    'oldColorHex'?: string;
}
/**
 * 
 * @export
 * @interface UpdateGuideChapterDto
 */
export interface UpdateGuideChapterDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGuideChapterDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGuideChapterDto
     */
    'audioUrl'?: string;
}
/**
 * 
 * @export
 * @interface UpdateGuideChapterUnitDto
 */
export interface UpdateGuideChapterUnitDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGuideChapterUnitDto
     */
    'name'?: string;
    /**
     * 
     * @type {GuideContentDto}
     * @memberof UpdateGuideChapterUnitDto
     */
    'content'?: GuideContentDto;
}
/**
 * 
 * @export
 * @interface UpdateInstallmentPromocodeDto
 */
export interface UpdateInstallmentPromocodeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateInstallmentPromocodeDto
     */
    'invoiceId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInstallmentPromocodeDto
     */
    'promocode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInstallmentPromocodeDto
     */
    'useFirstMonthDiscount': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateInstallmentPromocodeDto
     */
    'directionId': string;
}
/**
 * 
 * @export
 * @interface UpdateMnemonicCardDto
 */
export interface UpdateMnemonicCardDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMnemonicCardDto
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMnemonicCardDto
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMnemonicCardDto
     */
    'answersCount'?: number;
}
/**
 * 
 * @export
 * @interface UpdateMnemonicCardsPackDto
 */
export interface UpdateMnemonicCardsPackDto {
    /**
     * Entity in front of which you want to insert.    The Get many query returns the data in a specific order.    For example you had      1. entity with id \"q\"     2. entity with id \"w\"     3. entity with id \"e\"        You need to insert so that the new entity is before the \"w\". You pass in the field \"before\" \"w\".      1. pack with id \"q\"     2. pack with id \"r\"     3. pack with id \"w\"     4. pack with id \"e\"    To insert at the end, don\'t pass the \"before\" field   
     * @type {string}
     * @memberof UpdateMnemonicCardsPackDto
     */
    'before'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMnemonicCardsPackDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateNoteRequestDto
 */
export interface UpdateNoteRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateNoteRequestDto
     */
    'noteText'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNoteRequestDto
     */
    'selectedText'?: string;
}
/**
 * 
 * @export
 * @interface UploadAudioForGuideRequestDto
 */
export interface UploadAudioForGuideRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UploadAudioForGuideRequestDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof UploadAudioForGuideRequestDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface UploadImageForGuideRequestDto
 */
export interface UploadImageForGuideRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UploadImageForGuideRequestDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof UploadImageForGuideRequestDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface UploadMnemonicCardImageDto
 */
export interface UploadMnemonicCardImageDto {
    /**
     * 
     * @type {string}
     * @memberof UploadMnemonicCardImageDto
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof UploadMnemonicCardImageDto
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface UploadTextbookForGuideRequestDto
 */
export interface UploadTextbookForGuideRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UploadTextbookForGuideRequestDto
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof UploadTextbookForGuideRequestDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface ValidateConfirmationTokenRequestDto
 */
export interface ValidateConfirmationTokenRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ValidateConfirmationTokenRequestDto
     */
    'confirmationToken': string;
    /**
     * 
     * @type {string}
     * @memberof ValidateConfirmationTokenRequestDto
     */
    'courseId': string;
}
/**
 * 
 * @export
 * @interface ValidateConfirmationTokenResponseDto
 */
export interface ValidateConfirmationTokenResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateConfirmationTokenResponseDto
     */
    'isAccountExists': boolean;
}

/**
 * ActiveCampaignApi - axios parameter creator
 * @export
 */
export const ActiveCampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Subscribe to marketing emails
         * @param {SubscribeToMarketingEmailsDto} subscribeToMarketingEmailsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activeCampaignControllerSubscribe: async (subscribeToMarketingEmailsDto: SubscribeToMarketingEmailsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscribeToMarketingEmailsDto' is not null or undefined
            assertParamExists('activeCampaignControllerSubscribe', 'subscribeToMarketingEmailsDto', subscribeToMarketingEmailsDto)
            const localVarPath = `/active-campaign/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscribeToMarketingEmailsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActiveCampaignApi - functional programming interface
 * @export
 */
export const ActiveCampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActiveCampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Subscribe to marketing emails
         * @param {SubscribeToMarketingEmailsDto} subscribeToMarketingEmailsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activeCampaignControllerSubscribe(subscribeToMarketingEmailsDto: SubscribeToMarketingEmailsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activeCampaignControllerSubscribe(subscribeToMarketingEmailsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActiveCampaignApi - factory interface
 * @export
 */
export const ActiveCampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActiveCampaignApiFp(configuration)
    return {
        /**
         * 
         * @summary Subscribe to marketing emails
         * @param {SubscribeToMarketingEmailsDto} subscribeToMarketingEmailsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activeCampaignControllerSubscribe(subscribeToMarketingEmailsDto: SubscribeToMarketingEmailsDto, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.activeCampaignControllerSubscribe(subscribeToMarketingEmailsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActiveCampaignApi - object-oriented interface
 * @export
 * @class ActiveCampaignApi
 * @extends {BaseAPI}
 */
export class ActiveCampaignApi extends BaseAPI {
    /**
     * 
     * @summary Subscribe to marketing emails
     * @param {SubscribeToMarketingEmailsDto} subscribeToMarketingEmailsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveCampaignApi
     */
    public activeCampaignControllerSubscribe(subscribeToMarketingEmailsDto: SubscribeToMarketingEmailsDto, options?: AxiosRequestConfig) {
        return ActiveCampaignApiFp(this.configuration).activeCampaignControllerSubscribe(subscribeToMarketingEmailsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelAuthApi - axios parameter creator
 * @export
 */
export const AdminPanelAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Admin restore password
         * @param {AdminRestorePasswordDto} adminRestorePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthControllerRestorePassword: async (adminRestorePasswordDto: AdminRestorePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminRestorePasswordDto' is not null or undefined
            assertParamExists('adminAuthControllerRestorePassword', 'adminRestorePasswordDto', adminRestorePasswordDto)
            const localVarPath = `/auth/admin/restorePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminRestorePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin sign-in
         * @param {AdminSignInDto} adminSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthControllerSignIn: async (adminSignInDto: AdminSignInDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminSignInDto' is not null or undefined
            assertParamExists('adminAuthControllerSignIn', 'adminSignInDto', adminSignInDto)
            const localVarPath = `/auth/admin/signIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSignInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin update forgotten password
         * @param {AdminForgottenPasswordDto} adminForgottenPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthControllerUpdateForgottenPassword: async (adminForgottenPasswordDto: AdminForgottenPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminForgottenPasswordDto' is not null or undefined
            assertParamExists('adminAuthControllerUpdateForgottenPassword', 'adminForgottenPasswordDto', adminForgottenPasswordDto)
            const localVarPath = `/auth/admin/updateForgottenPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminForgottenPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelAuthApi - functional programming interface
 * @export
 */
export const AdminPanelAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Admin restore password
         * @param {AdminRestorePasswordDto} adminRestorePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthControllerRestorePassword(adminRestorePasswordDto: AdminRestorePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminRestorePasswordResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthControllerRestorePassword(adminRestorePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Admin sign-in
         * @param {AdminSignInDto} adminSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthControllerSignIn(adminSignInDto: AdminSignInDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAuthResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthControllerSignIn(adminSignInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Admin update forgotten password
         * @param {AdminForgottenPasswordDto} adminForgottenPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthControllerUpdateForgottenPassword(adminForgottenPasswordDto: AdminForgottenPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAuthResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthControllerUpdateForgottenPassword(adminForgottenPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelAuthApi - factory interface
 * @export
 */
export const AdminPanelAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Admin restore password
         * @param {AdminRestorePasswordDto} adminRestorePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthControllerRestorePassword(adminRestorePasswordDto: AdminRestorePasswordDto, options?: any): AxiosPromise<AdminRestorePasswordResponseDto> {
            return localVarFp.adminAuthControllerRestorePassword(adminRestorePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin sign-in
         * @param {AdminSignInDto} adminSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthControllerSignIn(adminSignInDto: AdminSignInDto, options?: any): AxiosPromise<AdminAuthResponseDto> {
            return localVarFp.adminAuthControllerSignIn(adminSignInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin update forgotten password
         * @param {AdminForgottenPasswordDto} adminForgottenPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthControllerUpdateForgottenPassword(adminForgottenPasswordDto: AdminForgottenPasswordDto, options?: any): AxiosPromise<AdminAuthResponseDto> {
            return localVarFp.adminAuthControllerUpdateForgottenPassword(adminForgottenPasswordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelAuthApi - object-oriented interface
 * @export
 * @class AdminPanelAuthApi
 * @extends {BaseAPI}
 */
export class AdminPanelAuthApi extends BaseAPI {
    /**
     * 
     * @summary Admin restore password
     * @param {AdminRestorePasswordDto} adminRestorePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelAuthApi
     */
    public adminAuthControllerRestorePassword(adminRestorePasswordDto: AdminRestorePasswordDto, options?: AxiosRequestConfig) {
        return AdminPanelAuthApiFp(this.configuration).adminAuthControllerRestorePassword(adminRestorePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin sign-in
     * @param {AdminSignInDto} adminSignInDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelAuthApi
     */
    public adminAuthControllerSignIn(adminSignInDto: AdminSignInDto, options?: AxiosRequestConfig) {
        return AdminPanelAuthApiFp(this.configuration).adminAuthControllerSignIn(adminSignInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin update forgotten password
     * @param {AdminForgottenPasswordDto} adminForgottenPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelAuthApi
     */
    public adminAuthControllerUpdateForgottenPassword(adminForgottenPasswordDto: AdminForgottenPasswordDto, options?: AxiosRequestConfig) {
        return AdminPanelAuthApiFp(this.configuration).adminAuthControllerUpdateForgottenPassword(adminForgottenPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelBaseApi - axios parameter creator
 * @export
 */
export const AdminPanelBaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get admin info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerGetMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admins/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Admin
         * @param {CreateAdminDto} createAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminsControllerAdmin: async (createAdminDto: CreateAdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdminDto' is not null or undefined
            assertParamExists('createOneBaseAdminsControllerAdmin', 'createAdminDto', createAdminDto)
            const localVarPath = `/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Admin
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminsControllerAdmin: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminsControllerAdmin', 'id', id)
            const localVarPath = `/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Admins
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminsControllerAdmin: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Admin
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminsControllerAdmin: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminsControllerAdmin', 'id', id)
            const localVarPath = `/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Admin
         * @param {string} id 
         * @param {UpdateAdminDto} updateAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminsControllerAdmin: async (id: string, updateAdminDto: UpdateAdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminsControllerAdmin', 'id', id)
            // verify required parameter 'updateAdminDto' is not null or undefined
            assertParamExists('updateOneBaseAdminsControllerAdmin', 'updateAdminDto', updateAdminDto)
            const localVarPath = `/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelBaseApi - functional programming interface
 * @export
 */
export const AdminPanelBaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelBaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get admin info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminsControllerGetMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminsControllerGetMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single Admin
         * @param {CreateAdminDto} createAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminsControllerAdmin(createAdminDto: CreateAdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminsControllerAdmin(createAdminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Admin
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminsControllerAdmin(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminsControllerAdmin(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Admins
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminsControllerAdmin(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminsControllerAdmin(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Admin
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminsControllerAdmin(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminsControllerAdmin(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Admin
         * @param {string} id 
         * @param {UpdateAdminDto} updateAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminsControllerAdmin(id: string, updateAdminDto: UpdateAdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminsControllerAdmin(id, updateAdminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelBaseApi - factory interface
 * @export
 */
export const AdminPanelBaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelBaseApiFp(configuration)
    return {
        /**
         * 
         * @summary Get admin info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerGetMe(options?: any): AxiosPromise<AdminResponseDto> {
            return localVarFp.adminsControllerGetMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Admin
         * @param {CreateAdminDto} createAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminsControllerAdmin(createAdminDto: CreateAdminDto, options?: any): AxiosPromise<Admin> {
            return localVarFp.createOneBaseAdminsControllerAdmin(createAdminDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Admin
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminsControllerAdmin(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminsControllerAdmin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Admins
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminsControllerAdmin(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedResponseDto> {
            return localVarFp.getManyBaseAdminsControllerAdmin(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Admin
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminsControllerAdmin(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminResponseDto> {
            return localVarFp.getOneBaseAdminsControllerAdmin(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Admin
         * @param {string} id 
         * @param {UpdateAdminDto} updateAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminsControllerAdmin(id: string, updateAdminDto: UpdateAdminDto, options?: any): AxiosPromise<AdminResponseDto> {
            return localVarFp.updateOneBaseAdminsControllerAdmin(id, updateAdminDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelBaseApi - object-oriented interface
 * @export
 * @class AdminPanelBaseApi
 * @extends {BaseAPI}
 */
export class AdminPanelBaseApi extends BaseAPI {
    /**
     * 
     * @summary Get admin info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelBaseApi
     */
    public adminsControllerGetMe(options?: AxiosRequestConfig) {
        return AdminPanelBaseApiFp(this.configuration).adminsControllerGetMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Admin
     * @param {CreateAdminDto} createAdminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelBaseApi
     */
    public createOneBaseAdminsControllerAdmin(createAdminDto: CreateAdminDto, options?: AxiosRequestConfig) {
        return AdminPanelBaseApiFp(this.configuration).createOneBaseAdminsControllerAdmin(createAdminDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Admin
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelBaseApi
     */
    public deleteOneBaseAdminsControllerAdmin(id: string, options?: AxiosRequestConfig) {
        return AdminPanelBaseApiFp(this.configuration).deleteOneBaseAdminsControllerAdmin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Admins
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelBaseApi
     */
    public getManyBaseAdminsControllerAdmin(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelBaseApiFp(this.configuration).getManyBaseAdminsControllerAdmin(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Admin
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelBaseApi
     */
    public getOneBaseAdminsControllerAdmin(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelBaseApiFp(this.configuration).getOneBaseAdminsControllerAdmin(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Admin
     * @param {string} id 
     * @param {UpdateAdminDto} updateAdminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelBaseApi
     */
    public updateOneBaseAdminsControllerAdmin(id: string, updateAdminDto: UpdateAdminDto, options?: AxiosRequestConfig) {
        return AdminPanelBaseApiFp(this.configuration).updateOneBaseAdminsControllerAdmin(id, updateAdminDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelCouponsApi - axios parameter creator
 * @export
 */
export const AdminPanelCouponsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} courseId 
         * @param {'monthly' | 'annual' | 'lifetime' | 'installment' | 'installment_first_month'} period 
         * @param {'none' | 'bronze' | 'silver' | 'gold'} [level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDirectionCouponsControllerSearchByCourse: async (courseId: string, period: 'monthly' | 'annual' | 'lifetime' | 'installment' | 'installment_first_month', level?: 'none' | 'bronze' | 'silver' | 'gold', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminDirectionCouponsControllerSearchByCourse', 'courseId', courseId)
            // verify required parameter 'period' is not null or undefined
            assertParamExists('adminDirectionCouponsControllerSearchByCourse', 'period', period)
            const localVarPath = `/admin/coupons/search-by-course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Coupon
         * @param {AdminCreateCouponDto} adminCreateCouponDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminDirectionCouponsControllerCoupon: async (adminCreateCouponDto: AdminCreateCouponDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateCouponDto' is not null or undefined
            assertParamExists('createOneBaseAdminDirectionCouponsControllerCoupon', 'adminCreateCouponDto', adminCreateCouponDto)
            const localVarPath = `/admin/coupons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateCouponDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Coupon
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminDirectionCouponsControllerCoupon: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminDirectionCouponsControllerCoupon', 'id', id)
            const localVarPath = `/admin/coupons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Coupons
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminDirectionCouponsControllerCoupon: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/coupons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Coupon
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminDirectionCouponsControllerCoupon: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminDirectionCouponsControllerCoupon', 'id', id)
            const localVarPath = `/admin/coupons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Coupon
         * @param {string} id 
         * @param {AdminUpdateCouponDto} adminUpdateCouponDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminDirectionCouponsControllerCoupon: async (id: string, adminUpdateCouponDto: AdminUpdateCouponDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminDirectionCouponsControllerCoupon', 'id', id)
            // verify required parameter 'adminUpdateCouponDto' is not null or undefined
            assertParamExists('updateOneBaseAdminDirectionCouponsControllerCoupon', 'adminUpdateCouponDto', adminUpdateCouponDto)
            const localVarPath = `/admin/coupons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateCouponDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelCouponsApi - functional programming interface
 * @export
 */
export const AdminPanelCouponsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelCouponsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} courseId 
         * @param {'monthly' | 'annual' | 'lifetime' | 'installment' | 'installment_first_month'} period 
         * @param {'none' | 'bronze' | 'silver' | 'gold'} [level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDirectionCouponsControllerSearchByCourse(courseId: string, period: 'monthly' | 'annual' | 'lifetime' | 'installment' | 'installment_first_month', level?: 'none' | 'bronze' | 'silver' | 'gold', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCouponsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDirectionCouponsControllerSearchByCourse(courseId, period, level, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single Coupon
         * @param {AdminCreateCouponDto} adminCreateCouponDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminDirectionCouponsControllerCoupon(adminCreateCouponDto: AdminCreateCouponDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminDirectionCouponsControllerCoupon(adminCreateCouponDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Coupon
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminDirectionCouponsControllerCoupon(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminDirectionCouponsControllerCoupon(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Coupons
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminDirectionCouponsControllerCoupon(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCouponsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminDirectionCouponsControllerCoupon(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Coupon
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminDirectionCouponsControllerCoupon(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminDirectionCouponsControllerCoupon(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Coupon
         * @param {string} id 
         * @param {AdminUpdateCouponDto} adminUpdateCouponDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminDirectionCouponsControllerCoupon(id: string, adminUpdateCouponDto: AdminUpdateCouponDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminDirectionCouponsControllerCoupon(id, adminUpdateCouponDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelCouponsApi - factory interface
 * @export
 */
export const AdminPanelCouponsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelCouponsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} courseId 
         * @param {'monthly' | 'annual' | 'lifetime' | 'installment' | 'installment_first_month'} period 
         * @param {'none' | 'bronze' | 'silver' | 'gold'} [level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDirectionCouponsControllerSearchByCourse(courseId: string, period: 'monthly' | 'annual' | 'lifetime' | 'installment' | 'installment_first_month', level?: 'none' | 'bronze' | 'silver' | 'gold', options?: any): AxiosPromise<PaginatedCouponsResponseDto> {
            return localVarFp.adminDirectionCouponsControllerSearchByCourse(courseId, period, level, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Coupon
         * @param {AdminCreateCouponDto} adminCreateCouponDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminDirectionCouponsControllerCoupon(adminCreateCouponDto: AdminCreateCouponDto, options?: any): AxiosPromise<CouponResponseDto> {
            return localVarFp.createOneBaseAdminDirectionCouponsControllerCoupon(adminCreateCouponDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Coupon
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminDirectionCouponsControllerCoupon(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminDirectionCouponsControllerCoupon(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Coupons
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminDirectionCouponsControllerCoupon(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<PaginatedCouponsResponseDto> {
            return localVarFp.getManyBaseAdminDirectionCouponsControllerCoupon(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Coupon
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminDirectionCouponsControllerCoupon(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<CouponResponseDto> {
            return localVarFp.getOneBaseAdminDirectionCouponsControllerCoupon(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Coupon
         * @param {string} id 
         * @param {AdminUpdateCouponDto} adminUpdateCouponDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminDirectionCouponsControllerCoupon(id: string, adminUpdateCouponDto: AdminUpdateCouponDto, options?: any): AxiosPromise<CouponResponseDto> {
            return localVarFp.updateOneBaseAdminDirectionCouponsControllerCoupon(id, adminUpdateCouponDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelCouponsApi - object-oriented interface
 * @export
 * @class AdminPanelCouponsApi
 * @extends {BaseAPI}
 */
export class AdminPanelCouponsApi extends BaseAPI {
    /**
     * 
     * @param {string} courseId 
     * @param {'monthly' | 'annual' | 'lifetime' | 'installment' | 'installment_first_month'} period 
     * @param {'none' | 'bronze' | 'silver' | 'gold'} [level] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCouponsApi
     */
    public adminDirectionCouponsControllerSearchByCourse(courseId: string, period: 'monthly' | 'annual' | 'lifetime' | 'installment' | 'installment_first_month', level?: 'none' | 'bronze' | 'silver' | 'gold', options?: AxiosRequestConfig) {
        return AdminPanelCouponsApiFp(this.configuration).adminDirectionCouponsControllerSearchByCourse(courseId, period, level, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Coupon
     * @param {AdminCreateCouponDto} adminCreateCouponDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCouponsApi
     */
    public createOneBaseAdminDirectionCouponsControllerCoupon(adminCreateCouponDto: AdminCreateCouponDto, options?: AxiosRequestConfig) {
        return AdminPanelCouponsApiFp(this.configuration).createOneBaseAdminDirectionCouponsControllerCoupon(adminCreateCouponDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Coupon
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCouponsApi
     */
    public deleteOneBaseAdminDirectionCouponsControllerCoupon(id: string, options?: AxiosRequestConfig) {
        return AdminPanelCouponsApiFp(this.configuration).deleteOneBaseAdminDirectionCouponsControllerCoupon(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Coupons
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCouponsApi
     */
    public getManyBaseAdminDirectionCouponsControllerCoupon(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelCouponsApiFp(this.configuration).getManyBaseAdminDirectionCouponsControllerCoupon(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Coupon
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCouponsApi
     */
    public getOneBaseAdminDirectionCouponsControllerCoupon(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelCouponsApiFp(this.configuration).getOneBaseAdminDirectionCouponsControllerCoupon(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Coupon
     * @param {string} id 
     * @param {AdminUpdateCouponDto} adminUpdateCouponDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCouponsApi
     */
    public updateOneBaseAdminDirectionCouponsControllerCoupon(id: string, adminUpdateCouponDto: AdminUpdateCouponDto, options?: AxiosRequestConfig) {
        return AdminPanelCouponsApiFp(this.configuration).updateOneBaseAdminDirectionCouponsControllerCoupon(id, adminUpdateCouponDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelCoursesApi - axios parameter creator
 * @export
 */
export const AdminPanelCoursesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create course
         * @param {AdminCreateCourseDto} adminCreateCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerCreateOne: async (adminCreateCourseDto: AdminCreateCourseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateCourseDto' is not null or undefined
            assertParamExists('adminCoursesControllerCreateOne', 'adminCreateCourseDto', adminCreateCourseDto)
            const localVarPath = `/admin/course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateCourseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create course prices
         * @param {string} id 
         * @param {AdminCreateCoursePricesDto} adminCreateCoursePricesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerCreatePrices: async (id: string, adminCreateCoursePricesDto: AdminCreateCoursePricesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCoursesControllerCreatePrices', 'id', id)
            // verify required parameter 'adminCreateCoursePricesDto' is not null or undefined
            assertParamExists('adminCoursesControllerCreatePrices', 'adminCreateCoursePricesDto', adminCreateCoursePricesDto)
            const localVarPath = `/admin/course/{id}/prices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateCoursePricesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete course
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerDeleteOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCoursesControllerDeleteOne', 'id', id)
            const localVarPath = `/admin/course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Duplicate course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerDuplicateCourse: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminCoursesControllerDuplicateCourse', 'courseId', courseId)
            const localVarPath = `/admin/course/{courseId}/duplicate`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Release course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerReleaseCourse: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminCoursesControllerReleaseCourse', 'courseId', courseId)
            const localVarPath = `/admin/course/{courseId}/release`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add admin to course reports
         * @param {string} courseId 
         * @param {CourseReportSubscriberDto} courseReportSubscriberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerSetWatcher: async (courseId: string, courseReportSubscriberDto: CourseReportSubscriberDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminCoursesControllerSetWatcher', 'courseId', courseId)
            // verify required parameter 'courseReportSubscriberDto' is not null or undefined
            assertParamExists('adminCoursesControllerSetWatcher', 'courseReportSubscriberDto', courseReportSubscriberDto)
            const localVarPath = `/admin/course/{courseId}/set-watcher`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseReportSubscriberDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unrelease course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerUnreleaseCourse: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminCoursesControllerUnreleaseCourse', 'courseId', courseId)
            const localVarPath = `/admin/course/{courseId}/unrelease`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add admin to course reports
         * @param {string} courseId 
         * @param {CourseReportSubscriberDto} courseReportSubscriberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerUnsetWatcher: async (courseId: string, courseReportSubscriberDto: CourseReportSubscriberDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminCoursesControllerUnsetWatcher', 'courseId', courseId)
            // verify required parameter 'courseReportSubscriberDto' is not null or undefined
            assertParamExists('adminCoursesControllerUnsetWatcher', 'courseReportSubscriberDto', courseReportSubscriberDto)
            const localVarPath = `/admin/course/{courseId}/unset-watcher`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseReportSubscriberDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update course
         * @param {string} id 
         * @param {AdminUpdateCourseDto} adminUpdateCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerUpdateCourse: async (id: string, adminUpdateCourseDto: AdminUpdateCourseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCoursesControllerUpdateCourse', 'id', id)
            // verify required parameter 'adminUpdateCourseDto' is not null or undefined
            assertParamExists('adminCoursesControllerUpdateCourse', 'adminUpdateCourseDto', adminUpdateCourseDto)
            const localVarPath = `/admin/course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateCourseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update course prices
         * @param {string} id 
         * @param {AdminUpdateCoursePricesDto} adminUpdateCoursePricesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerUpdatePrices: async (id: string, adminUpdateCoursePricesDto: AdminUpdateCoursePricesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCoursesControllerUpdatePrices', 'id', id)
            // verify required parameter 'adminUpdateCoursePricesDto' is not null or undefined
            assertParamExists('adminCoursesControllerUpdatePrices', 'adminUpdateCoursePricesDto', adminUpdateCoursePricesDto)
            const localVarPath = `/admin/course/{id}/prices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateCoursePricesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CSV schema:            Delimiter: ,              Columns: Domain,Chapter,Question,CorAns,Answer1,Answer2,Answer3,Answer4,Explanation,AvailableForFree
         * @summary Upload questions in CSV file
         * @param {string} courseId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerUploadCsvWithQuestions: async (courseId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminCoursesControllerUploadCsvWithQuestions', 'courseId', courseId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('adminCoursesControllerUploadCsvWithQuestions', 'file', file)
            const localVarPath = `/admin/course/{courseId}/upload-questions-csv`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CSV schema:            Delimiter: ,              Columns: Deck,Question,Answer
         * @summary Upload flashcards in CSV file
         * @param {string} courseId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerUploadFlashcardsInCsv: async (courseId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminCoursesControllerUploadFlashcardsInCsv', 'courseId', courseId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('adminCoursesControllerUploadFlashcardsInCsv', 'file', file)
            const localVarPath = `/admin/course/{courseId}/upload-flashcards-csv`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Courses
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCoursesControllerCourse: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Course
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCoursesControllerCourse: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminCoursesControllerCourse', 'id', id)
            const localVarPath = `/admin/course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create manual by course id
         * @param {string} id 
         * @param {ManualsControllerCreateOneRequest} manualsControllerCreateOneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualsControllerCreateOne: async (id: string, manualsControllerCreateOneRequest: ManualsControllerCreateOneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('manualsControllerCreateOne', 'id', id)
            // verify required parameter 'manualsControllerCreateOneRequest' is not null or undefined
            assertParamExists('manualsControllerCreateOne', 'manualsControllerCreateOneRequest', manualsControllerCreateOneRequest)
            const localVarPath = `/admin/courses/{id}/manuals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manualsControllerCreateOneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete manual by type and course id
         * @param {string} id 
         * @param {string} manualType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualsControllerDeleteOne: async (id: string, manualType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('manualsControllerDeleteOne', 'id', id)
            // verify required parameter 'manualType' is not null or undefined
            assertParamExists('manualsControllerDeleteOne', 'manualType', manualType)
            const localVarPath = `/admin/courses/{id}/manuals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manualType !== undefined) {
                localVarQueryParameter['manualType'] = manualType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all manuals by course id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualsControllerGetMany: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('manualsControllerGetMany', 'id', id)
            const localVarPath = `/admin/courses/{id}/manuals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find manual by type and course id
         * @param {string} id 
         * @param {string} manualType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualsControllerGetOne: async (id: string, manualType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('manualsControllerGetOne', 'id', id)
            // verify required parameter 'manualType' is not null or undefined
            assertParamExists('manualsControllerGetOne', 'manualType', manualType)
            const localVarPath = `/admin/courses/{id}/manuals/find`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manualType !== undefined) {
                localVarQueryParameter['manualType'] = manualType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update manual by type and course id
         * @param {string} id 
         * @param {AdminUpdateManualDto} adminUpdateManualDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualsControllerUpdateOne: async (id: string, adminUpdateManualDto: AdminUpdateManualDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('manualsControllerUpdateOne', 'id', id)
            // verify required parameter 'adminUpdateManualDto' is not null or undefined
            assertParamExists('manualsControllerUpdateOne', 'adminUpdateManualDto', adminUpdateManualDto)
            const localVarPath = `/admin/courses/{id}/manuals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateManualDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelCoursesApi - functional programming interface
 * @export
 */
export const AdminPanelCoursesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelCoursesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create course
         * @param {AdminCreateCourseDto} adminCreateCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesControllerCreateOne(adminCreateCourseDto: AdminCreateCourseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesControllerCreateOne(adminCreateCourseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create course prices
         * @param {string} id 
         * @param {AdminCreateCoursePricesDto} adminCreateCoursePricesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesControllerCreatePrices(id: string, adminCreateCoursePricesDto: AdminCreateCoursePricesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesControllerCreatePrices(id, adminCreateCoursePricesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete course
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesControllerDeleteOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesControllerDeleteOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Duplicate course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesControllerDuplicateCourse(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesControllerDuplicateCourse(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Release course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesControllerReleaseCourse(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesControllerReleaseCourse(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add admin to course reports
         * @param {string} courseId 
         * @param {CourseReportSubscriberDto} courseReportSubscriberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesControllerSetWatcher(courseId: string, courseReportSubscriberDto: CourseReportSubscriberDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesControllerSetWatcher(courseId, courseReportSubscriberDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unrelease course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesControllerUnreleaseCourse(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesControllerUnreleaseCourse(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add admin to course reports
         * @param {string} courseId 
         * @param {CourseReportSubscriberDto} courseReportSubscriberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesControllerUnsetWatcher(courseId: string, courseReportSubscriberDto: CourseReportSubscriberDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesControllerUnsetWatcher(courseId, courseReportSubscriberDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update course
         * @param {string} id 
         * @param {AdminUpdateCourseDto} adminUpdateCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesControllerUpdateCourse(id: string, adminUpdateCourseDto: AdminUpdateCourseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesControllerUpdateCourse(id, adminUpdateCourseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update course prices
         * @param {string} id 
         * @param {AdminUpdateCoursePricesDto} adminUpdateCoursePricesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesControllerUpdatePrices(id: string, adminUpdateCoursePricesDto: AdminUpdateCoursePricesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesControllerUpdatePrices(id, adminUpdateCoursePricesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CSV schema:            Delimiter: ,              Columns: Domain,Chapter,Question,CorAns,Answer1,Answer2,Answer3,Answer4,Explanation,AvailableForFree
         * @summary Upload questions in CSV file
         * @param {string} courseId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesControllerUploadCsvWithQuestions(courseId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesControllerUploadCsvWithQuestions(courseId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CSV schema:            Delimiter: ,              Columns: Deck,Question,Answer
         * @summary Upload flashcards in CSV file
         * @param {string} courseId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCoursesControllerUploadFlashcardsInCsv(courseId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Flashcard>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCoursesControllerUploadFlashcardsInCsv(courseId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Courses
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminCoursesControllerCourse(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedCoursesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminCoursesControllerCourse(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Course
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminCoursesControllerCourse(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminCoursesControllerCourse(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create manual by course id
         * @param {string} id 
         * @param {ManualsControllerCreateOneRequest} manualsControllerCreateOneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualsControllerCreateOne(id: string, manualsControllerCreateOneRequest: ManualsControllerCreateOneRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManualsControllerGetOne200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualsControllerCreateOne(id, manualsControllerCreateOneRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete manual by type and course id
         * @param {string} id 
         * @param {string} manualType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualsControllerDeleteOne(id: string, manualType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualsControllerDeleteOne(id, manualType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all manuals by course id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualsControllerGetMany(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaginatedManualsResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualsControllerGetMany(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find manual by type and course id
         * @param {string} id 
         * @param {string} manualType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualsControllerGetOne(id: string, manualType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManualsControllerGetOne200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualsControllerGetOne(id, manualType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update manual by type and course id
         * @param {string} id 
         * @param {AdminUpdateManualDto} adminUpdateManualDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualsControllerUpdateOne(id: string, adminUpdateManualDto: AdminUpdateManualDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManualsControllerGetOne200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualsControllerUpdateOne(id, adminUpdateManualDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelCoursesApi - factory interface
 * @export
 */
export const AdminPanelCoursesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelCoursesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create course
         * @param {AdminCreateCourseDto} adminCreateCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerCreateOne(adminCreateCourseDto: AdminCreateCourseDto, options?: any): AxiosPromise<AdminCourseDto> {
            return localVarFp.adminCoursesControllerCreateOne(adminCreateCourseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create course prices
         * @param {string} id 
         * @param {AdminCreateCoursePricesDto} adminCreateCoursePricesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerCreatePrices(id: string, adminCreateCoursePricesDto: AdminCreateCoursePricesDto, options?: any): AxiosPromise<AdminCourseDto> {
            return localVarFp.adminCoursesControllerCreatePrices(id, adminCreateCoursePricesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete course
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerDeleteOne(id: string, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.adminCoursesControllerDeleteOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Duplicate course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerDuplicateCourse(courseId: string, options?: any): AxiosPromise<AdminCourseDto> {
            return localVarFp.adminCoursesControllerDuplicateCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Release course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerReleaseCourse(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminCoursesControllerReleaseCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add admin to course reports
         * @param {string} courseId 
         * @param {CourseReportSubscriberDto} courseReportSubscriberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerSetWatcher(courseId: string, courseReportSubscriberDto: CourseReportSubscriberDto, options?: any): AxiosPromise<object> {
            return localVarFp.adminCoursesControllerSetWatcher(courseId, courseReportSubscriberDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unrelease course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerUnreleaseCourse(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminCoursesControllerUnreleaseCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add admin to course reports
         * @param {string} courseId 
         * @param {CourseReportSubscriberDto} courseReportSubscriberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerUnsetWatcher(courseId: string, courseReportSubscriberDto: CourseReportSubscriberDto, options?: any): AxiosPromise<void> {
            return localVarFp.adminCoursesControllerUnsetWatcher(courseId, courseReportSubscriberDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update course
         * @param {string} id 
         * @param {AdminUpdateCourseDto} adminUpdateCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerUpdateCourse(id: string, adminUpdateCourseDto: AdminUpdateCourseDto, options?: any): AxiosPromise<AdminCourseDto> {
            return localVarFp.adminCoursesControllerUpdateCourse(id, adminUpdateCourseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update course prices
         * @param {string} id 
         * @param {AdminUpdateCoursePricesDto} adminUpdateCoursePricesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerUpdatePrices(id: string, adminUpdateCoursePricesDto: AdminUpdateCoursePricesDto, options?: any): AxiosPromise<AdminCourseDto> {
            return localVarFp.adminCoursesControllerUpdatePrices(id, adminUpdateCoursePricesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * CSV schema:            Delimiter: ,              Columns: Domain,Chapter,Question,CorAns,Answer1,Answer2,Answer3,Answer4,Explanation,AvailableForFree
         * @summary Upload questions in CSV file
         * @param {string} courseId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerUploadCsvWithQuestions(courseId: string, file: any, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.adminCoursesControllerUploadCsvWithQuestions(courseId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * CSV schema:            Delimiter: ,              Columns: Deck,Question,Answer
         * @summary Upload flashcards in CSV file
         * @param {string} courseId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCoursesControllerUploadFlashcardsInCsv(courseId: string, file: any, options?: any): AxiosPromise<Array<Flashcard>> {
            return localVarFp.adminCoursesControllerUploadFlashcardsInCsv(courseId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Courses
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCoursesControllerCourse(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedCoursesDto> {
            return localVarFp.getManyBaseAdminCoursesControllerCourse(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Course
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCoursesControllerCourse(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminCourseDto> {
            return localVarFp.getOneBaseAdminCoursesControllerCourse(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create manual by course id
         * @param {string} id 
         * @param {ManualsControllerCreateOneRequest} manualsControllerCreateOneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualsControllerCreateOne(id: string, manualsControllerCreateOneRequest: ManualsControllerCreateOneRequest, options?: any): AxiosPromise<ManualsControllerGetOne200Response> {
            return localVarFp.manualsControllerCreateOne(id, manualsControllerCreateOneRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete manual by type and course id
         * @param {string} id 
         * @param {string} manualType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualsControllerDeleteOne(id: string, manualType: string, options?: any): AxiosPromise<void> {
            return localVarFp.manualsControllerDeleteOne(id, manualType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all manuals by course id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualsControllerGetMany(id: string, options?: any): AxiosPromise<Array<PaginatedManualsResponseDto>> {
            return localVarFp.manualsControllerGetMany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find manual by type and course id
         * @param {string} id 
         * @param {string} manualType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualsControllerGetOne(id: string, manualType: string, options?: any): AxiosPromise<ManualsControllerGetOne200Response> {
            return localVarFp.manualsControllerGetOne(id, manualType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update manual by type and course id
         * @param {string} id 
         * @param {AdminUpdateManualDto} adminUpdateManualDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualsControllerUpdateOne(id: string, adminUpdateManualDto: AdminUpdateManualDto, options?: any): AxiosPromise<ManualsControllerGetOne200Response> {
            return localVarFp.manualsControllerUpdateOne(id, adminUpdateManualDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelCoursesApi - object-oriented interface
 * @export
 * @class AdminPanelCoursesApi
 * @extends {BaseAPI}
 */
export class AdminPanelCoursesApi extends BaseAPI {
    /**
     * 
     * @summary Create course
     * @param {AdminCreateCourseDto} adminCreateCourseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public adminCoursesControllerCreateOne(adminCreateCourseDto: AdminCreateCourseDto, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).adminCoursesControllerCreateOne(adminCreateCourseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create course prices
     * @param {string} id 
     * @param {AdminCreateCoursePricesDto} adminCreateCoursePricesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public adminCoursesControllerCreatePrices(id: string, adminCreateCoursePricesDto: AdminCreateCoursePricesDto, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).adminCoursesControllerCreatePrices(id, adminCreateCoursePricesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete course
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public adminCoursesControllerDeleteOne(id: string, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).adminCoursesControllerDeleteOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Duplicate course
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public adminCoursesControllerDuplicateCourse(courseId: string, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).adminCoursesControllerDuplicateCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Release course
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public adminCoursesControllerReleaseCourse(courseId: string, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).adminCoursesControllerReleaseCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add admin to course reports
     * @param {string} courseId 
     * @param {CourseReportSubscriberDto} courseReportSubscriberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public adminCoursesControllerSetWatcher(courseId: string, courseReportSubscriberDto: CourseReportSubscriberDto, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).adminCoursesControllerSetWatcher(courseId, courseReportSubscriberDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unrelease course
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public adminCoursesControllerUnreleaseCourse(courseId: string, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).adminCoursesControllerUnreleaseCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add admin to course reports
     * @param {string} courseId 
     * @param {CourseReportSubscriberDto} courseReportSubscriberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public adminCoursesControllerUnsetWatcher(courseId: string, courseReportSubscriberDto: CourseReportSubscriberDto, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).adminCoursesControllerUnsetWatcher(courseId, courseReportSubscriberDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update course
     * @param {string} id 
     * @param {AdminUpdateCourseDto} adminUpdateCourseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public adminCoursesControllerUpdateCourse(id: string, adminUpdateCourseDto: AdminUpdateCourseDto, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).adminCoursesControllerUpdateCourse(id, adminUpdateCourseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update course prices
     * @param {string} id 
     * @param {AdminUpdateCoursePricesDto} adminUpdateCoursePricesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public adminCoursesControllerUpdatePrices(id: string, adminUpdateCoursePricesDto: AdminUpdateCoursePricesDto, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).adminCoursesControllerUpdatePrices(id, adminUpdateCoursePricesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CSV schema:            Delimiter: ,              Columns: Domain,Chapter,Question,CorAns,Answer1,Answer2,Answer3,Answer4,Explanation,AvailableForFree
     * @summary Upload questions in CSV file
     * @param {string} courseId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public adminCoursesControllerUploadCsvWithQuestions(courseId: string, file: any, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).adminCoursesControllerUploadCsvWithQuestions(courseId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CSV schema:            Delimiter: ,              Columns: Deck,Question,Answer
     * @summary Upload flashcards in CSV file
     * @param {string} courseId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public adminCoursesControllerUploadFlashcardsInCsv(courseId: string, file: any, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).adminCoursesControllerUploadFlashcardsInCsv(courseId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Courses
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public getManyBaseAdminCoursesControllerCourse(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).getManyBaseAdminCoursesControllerCourse(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Course
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public getOneBaseAdminCoursesControllerCourse(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).getOneBaseAdminCoursesControllerCourse(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create manual by course id
     * @param {string} id 
     * @param {ManualsControllerCreateOneRequest} manualsControllerCreateOneRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public manualsControllerCreateOne(id: string, manualsControllerCreateOneRequest: ManualsControllerCreateOneRequest, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).manualsControllerCreateOne(id, manualsControllerCreateOneRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete manual by type and course id
     * @param {string} id 
     * @param {string} manualType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public manualsControllerDeleteOne(id: string, manualType: string, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).manualsControllerDeleteOne(id, manualType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all manuals by course id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public manualsControllerGetMany(id: string, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).manualsControllerGetMany(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find manual by type and course id
     * @param {string} id 
     * @param {string} manualType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public manualsControllerGetOne(id: string, manualType: string, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).manualsControllerGetOne(id, manualType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update manual by type and course id
     * @param {string} id 
     * @param {AdminUpdateManualDto} adminUpdateManualDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCoursesApi
     */
    public manualsControllerUpdateOne(id: string, adminUpdateManualDto: AdminUpdateManualDto, options?: AxiosRequestConfig) {
        return AdminPanelCoursesApiFp(this.configuration).manualsControllerUpdateOne(id, adminUpdateManualDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelCustomersApi - axios parameter creator
 * @export
 */
export const AdminPanelCustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel customer subscription
         * @param {string} id 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomersControllerCancelCustomerSubscription: async (id: string, courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomersControllerCancelCustomerSubscription', 'id', id)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminCustomersControllerCancelCustomerSubscription', 'courseId', courseId)
            const localVarPath = `/admin/customers/{id}/cancel-subscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete customer permanently
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomersControllerDeleteCustomer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomersControllerDeleteCustomer', 'id', id)
            const localVarPath = `/admin/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer progress stats v2
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomersControllerGetCustomerProgressStatsV2: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomersControllerGetCustomerProgressStatsV2', 'id', id)
            const localVarPath = `/admin/customers/{id}/progress-stats/v2`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Customers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCustomersControllerCustomer: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Customer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCustomersControllerCustomer: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminCustomersControllerCustomer', 'id', id)
            const localVarPath = `/admin/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Customer
         * @param {string} id 
         * @param {AdminUpdateCustomerPasswordDto} adminUpdateCustomerPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminCustomersControllerCustomer: async (id: string, adminUpdateCustomerPasswordDto: AdminUpdateCustomerPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminCustomersControllerCustomer', 'id', id)
            // verify required parameter 'adminUpdateCustomerPasswordDto' is not null or undefined
            assertParamExists('updateOneBaseAdminCustomersControllerCustomer', 'adminUpdateCustomerPasswordDto', adminUpdateCustomerPasswordDto)
            const localVarPath = `/admin/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateCustomerPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelCustomersApi - functional programming interface
 * @export
 */
export const AdminPanelCustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelCustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel customer subscription
         * @param {string} id 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomersControllerCancelCustomerSubscription(id: string, courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomersControllerCancelCustomerSubscription(id, courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete customer permanently
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomersControllerDeleteCustomer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomersControllerDeleteCustomer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer progress stats v2
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomersControllerGetCustomerProgressStatsV2(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminCustomerCourseStatisticsDtoV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomersControllerGetCustomerProgressStatsV2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Customers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminCustomersControllerCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedCustomersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminCustomersControllerCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Customer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminCustomersControllerCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminCustomersControllerCustomer(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Customer
         * @param {string} id 
         * @param {AdminUpdateCustomerPasswordDto} adminUpdateCustomerPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminCustomersControllerCustomer(id: string, adminUpdateCustomerPasswordDto: AdminUpdateCustomerPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminCustomersControllerCustomer(id, adminUpdateCustomerPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelCustomersApi - factory interface
 * @export
 */
export const AdminPanelCustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelCustomersApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel customer subscription
         * @param {string} id 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomersControllerCancelCustomerSubscription(id: string, courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminCustomersControllerCancelCustomerSubscription(id, courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete customer permanently
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomersControllerDeleteCustomer(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminCustomersControllerDeleteCustomer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer progress stats v2
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomersControllerGetCustomerProgressStatsV2(id: string, options?: any): AxiosPromise<Array<AdminCustomerCourseStatisticsDtoV2>> {
            return localVarFp.adminCustomersControllerGetCustomerProgressStatsV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Customers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCustomersControllerCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedCustomersDto> {
            return localVarFp.getManyBaseAdminCustomersControllerCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Customer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCustomersControllerCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminCustomerDto> {
            return localVarFp.getOneBaseAdminCustomersControllerCustomer(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Customer
         * @param {string} id 
         * @param {AdminUpdateCustomerPasswordDto} adminUpdateCustomerPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminCustomersControllerCustomer(id: string, adminUpdateCustomerPasswordDto: AdminUpdateCustomerPasswordDto, options?: any): AxiosPromise<AdminCustomerDto> {
            return localVarFp.updateOneBaseAdminCustomersControllerCustomer(id, adminUpdateCustomerPasswordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelCustomersApi - object-oriented interface
 * @export
 * @class AdminPanelCustomersApi
 * @extends {BaseAPI}
 */
export class AdminPanelCustomersApi extends BaseAPI {
    /**
     * 
     * @summary Cancel customer subscription
     * @param {string} id 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomersApi
     */
    public adminCustomersControllerCancelCustomerSubscription(id: string, courseId: string, options?: AxiosRequestConfig) {
        return AdminPanelCustomersApiFp(this.configuration).adminCustomersControllerCancelCustomerSubscription(id, courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete customer permanently
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomersApi
     */
    public adminCustomersControllerDeleteCustomer(id: string, options?: AxiosRequestConfig) {
        return AdminPanelCustomersApiFp(this.configuration).adminCustomersControllerDeleteCustomer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer progress stats v2
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomersApi
     */
    public adminCustomersControllerGetCustomerProgressStatsV2(id: string, options?: AxiosRequestConfig) {
        return AdminPanelCustomersApiFp(this.configuration).adminCustomersControllerGetCustomerProgressStatsV2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Customers
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomersApi
     */
    public getManyBaseAdminCustomersControllerCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelCustomersApiFp(this.configuration).getManyBaseAdminCustomersControllerCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Customer
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomersApi
     */
    public getOneBaseAdminCustomersControllerCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelCustomersApiFp(this.configuration).getOneBaseAdminCustomersControllerCustomer(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Customer
     * @param {string} id 
     * @param {AdminUpdateCustomerPasswordDto} adminUpdateCustomerPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomersApi
     */
    public updateOneBaseAdminCustomersControllerCustomer(id: string, adminUpdateCustomerPasswordDto: AdminUpdateCustomerPasswordDto, options?: AxiosRequestConfig) {
        return AdminPanelCustomersApiFp(this.configuration).updateOneBaseAdminCustomersControllerCustomer(id, adminUpdateCustomerPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelDirectionsApi - axios parameter creator
 * @export
 */
export const AdminPanelDirectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} directionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDirectionsControllerDelete: async (directionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'directionId' is not null or undefined
            assertParamExists('adminDirectionsControllerDelete', 'directionId', directionId)
            const localVarPath = `/admin/directions/{directionId}`
                .replace(`{${"directionId"}}`, encodeURIComponent(String(directionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload logo for direction
         * @param {AdminUploadImageDto} adminUploadImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDirectionsControllerUploadImage: async (adminUploadImageDto: AdminUploadImageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminUploadImageDto' is not null or undefined
            assertParamExists('adminDirectionsControllerUploadImage', 'adminUploadImageDto', adminUploadImageDto)
            const localVarPath = `/admin/directions/uploadImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUploadImageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Direction
         * @param {CreateDirectionDto} createDirectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminDirectionsControllerDirection: async (createDirectionDto: CreateDirectionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDirectionDto' is not null or undefined
            assertParamExists('createOneBaseAdminDirectionsControllerDirection', 'createDirectionDto', createDirectionDto)
            const localVarPath = `/admin/directions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDirectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Directions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminDirectionsControllerDirection: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/directions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Direction
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminDirectionsControllerDirection: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminDirectionsControllerDirection', 'id', id)
            const localVarPath = `/admin/directions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Direction
         * @param {string} id 
         * @param {UpdateDirectionDto} updateDirectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminDirectionsControllerDirection: async (id: string, updateDirectionDto: UpdateDirectionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminDirectionsControllerDirection', 'id', id)
            // verify required parameter 'updateDirectionDto' is not null or undefined
            assertParamExists('updateOneBaseAdminDirectionsControllerDirection', 'updateDirectionDto', updateDirectionDto)
            const localVarPath = `/admin/directions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDirectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelDirectionsApi - functional programming interface
 * @export
 */
export const AdminPanelDirectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelDirectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} directionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDirectionsControllerDelete(directionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDirectionsControllerDelete(directionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload logo for direction
         * @param {AdminUploadImageDto} adminUploadImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDirectionsControllerUploadImage(adminUploadImageDto: AdminUploadImageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSignedUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDirectionsControllerUploadImage(adminUploadImageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single Direction
         * @param {CreateDirectionDto} createDirectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminDirectionsControllerDirection(createDirectionDto: CreateDirectionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDirectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminDirectionsControllerDirection(createDirectionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Directions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminDirectionsControllerDirection(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedDirectionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminDirectionsControllerDirection(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Direction
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminDirectionsControllerDirection(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDirectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminDirectionsControllerDirection(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Direction
         * @param {string} id 
         * @param {UpdateDirectionDto} updateDirectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminDirectionsControllerDirection(id: string, updateDirectionDto: UpdateDirectionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDirectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminDirectionsControllerDirection(id, updateDirectionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelDirectionsApi - factory interface
 * @export
 */
export const AdminPanelDirectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelDirectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} directionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDirectionsControllerDelete(directionId: string, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.adminDirectionsControllerDelete(directionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload logo for direction
         * @param {AdminUploadImageDto} adminUploadImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDirectionsControllerUploadImage(adminUploadImageDto: AdminUploadImageDto, options?: any): AxiosPromise<AdminSignedUrlDto> {
            return localVarFp.adminDirectionsControllerUploadImage(adminUploadImageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Direction
         * @param {CreateDirectionDto} createDirectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminDirectionsControllerDirection(createDirectionDto: CreateDirectionDto, options?: any): AxiosPromise<AdminDirectionDto> {
            return localVarFp.createOneBaseAdminDirectionsControllerDirection(createDirectionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Directions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminDirectionsControllerDirection(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedDirectionsDto> {
            return localVarFp.getManyBaseAdminDirectionsControllerDirection(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Direction
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminDirectionsControllerDirection(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminDirectionDto> {
            return localVarFp.getOneBaseAdminDirectionsControllerDirection(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Direction
         * @param {string} id 
         * @param {UpdateDirectionDto} updateDirectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminDirectionsControllerDirection(id: string, updateDirectionDto: UpdateDirectionDto, options?: any): AxiosPromise<AdminDirectionDto> {
            return localVarFp.updateOneBaseAdminDirectionsControllerDirection(id, updateDirectionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelDirectionsApi - object-oriented interface
 * @export
 * @class AdminPanelDirectionsApi
 * @extends {BaseAPI}
 */
export class AdminPanelDirectionsApi extends BaseAPI {
    /**
     * 
     * @param {string} directionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelDirectionsApi
     */
    public adminDirectionsControllerDelete(directionId: string, options?: AxiosRequestConfig) {
        return AdminPanelDirectionsApiFp(this.configuration).adminDirectionsControllerDelete(directionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload logo for direction
     * @param {AdminUploadImageDto} adminUploadImageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelDirectionsApi
     */
    public adminDirectionsControllerUploadImage(adminUploadImageDto: AdminUploadImageDto, options?: AxiosRequestConfig) {
        return AdminPanelDirectionsApiFp(this.configuration).adminDirectionsControllerUploadImage(adminUploadImageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Direction
     * @param {CreateDirectionDto} createDirectionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelDirectionsApi
     */
    public createOneBaseAdminDirectionsControllerDirection(createDirectionDto: CreateDirectionDto, options?: AxiosRequestConfig) {
        return AdminPanelDirectionsApiFp(this.configuration).createOneBaseAdminDirectionsControllerDirection(createDirectionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Directions
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelDirectionsApi
     */
    public getManyBaseAdminDirectionsControllerDirection(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelDirectionsApiFp(this.configuration).getManyBaseAdminDirectionsControllerDirection(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Direction
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelDirectionsApi
     */
    public getOneBaseAdminDirectionsControllerDirection(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelDirectionsApiFp(this.configuration).getOneBaseAdminDirectionsControllerDirection(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Direction
     * @param {string} id 
     * @param {UpdateDirectionDto} updateDirectionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelDirectionsApi
     */
    public updateOneBaseAdminDirectionsControllerDirection(id: string, updateDirectionDto: UpdateDirectionDto, options?: AxiosRequestConfig) {
        return AdminPanelDirectionsApiFp(this.configuration).updateOneBaseAdminDirectionsControllerDirection(id, updateDirectionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelFlashcardsApi - axios parameter creator
 * @export
 */
export const AdminPanelFlashcardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create flashcard
         * @param {AdminCreateFlashcardDto} adminCreateFlashcardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsControllerCreateFlashcard: async (adminCreateFlashcardDto: AdminCreateFlashcardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateFlashcardDto' is not null or undefined
            assertParamExists('adminFlashcardsControllerCreateFlashcard', 'adminCreateFlashcardDto', adminCreateFlashcardDto)
            const localVarPath = `/admin/flashcards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateFlashcardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create many flashcard
         * @param {AdminCreateManyFlashcardsDto} adminCreateManyFlashcardsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsControllerCreateManyFlashcards: async (adminCreateManyFlashcardsDto: AdminCreateManyFlashcardsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateManyFlashcardsDto' is not null or undefined
            assertParamExists('adminFlashcardsControllerCreateManyFlashcards', 'adminCreateManyFlashcardsDto', adminCreateManyFlashcardsDto)
            const localVarPath = `/admin/flashcards/createMany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateManyFlashcardsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove flashcard
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsControllerRemoveFlashcard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFlashcardsControllerRemoveFlashcard', 'id', id)
            const localVarPath = `/admin/flashcards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update flashcard
         * @param {string} id 
         * @param {AdminUpdateFlashcardDto} adminUpdateFlashcardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsControllerUpdateFlashcard: async (id: string, adminUpdateFlashcardDto: AdminUpdateFlashcardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFlashcardsControllerUpdateFlashcard', 'id', id)
            // verify required parameter 'adminUpdateFlashcardDto' is not null or undefined
            assertParamExists('adminFlashcardsControllerUpdateFlashcard', 'adminUpdateFlashcardDto', adminUpdateFlashcardDto)
            const localVarPath = `/admin/flashcards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateFlashcardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload flashcard image
         * @param {AdminUploadFlashcardImageDto} adminUploadFlashcardImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsControllerUploadImage: async (adminUploadFlashcardImageDto: AdminUploadFlashcardImageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminUploadFlashcardImageDto' is not null or undefined
            assertParamExists('adminFlashcardsControllerUploadImage', 'adminUploadFlashcardImageDto', adminUploadFlashcardImageDto)
            const localVarPath = `/admin/flashcards/uploadImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUploadFlashcardImageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create flashcards pack
         * @param {AdminCreateFlashcardsPackDto} adminCreateFlashcardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsPacksControllerCreateFlashcardsPack: async (adminCreateFlashcardsPackDto: AdminCreateFlashcardsPackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateFlashcardsPackDto' is not null or undefined
            assertParamExists('adminFlashcardsPacksControllerCreateFlashcardsPack', 'adminCreateFlashcardsPackDto', adminCreateFlashcardsPackDto)
            const localVarPath = `/admin/flashcards/packs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateFlashcardsPackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move a single FlashcardsPack
         * @param {string} id 
         * @param {AdminMoveFlashcardsPackDto} adminMoveFlashcardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsPacksControllerMoveFlashcardsPack: async (id: string, adminMoveFlashcardsPackDto: AdminMoveFlashcardsPackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFlashcardsPacksControllerMoveFlashcardsPack', 'id', id)
            // verify required parameter 'adminMoveFlashcardsPackDto' is not null or undefined
            assertParamExists('adminFlashcardsPacksControllerMoveFlashcardsPack', 'adminMoveFlashcardsPackDto', adminMoveFlashcardsPackDto)
            const localVarPath = `/admin/flashcards/packs/{id}/move`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminMoveFlashcardsPackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove flashcard pack
         * @param {string} packId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsPacksControllerRemovePack: async (packId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packId' is not null or undefined
            assertParamExists('adminFlashcardsPacksControllerRemovePack', 'packId', packId)
            const localVarPath = `/admin/flashcards/packs/{packId}`
                .replace(`{${"packId"}}`, encodeURIComponent(String(packId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single FlashcardsPack
         * @param {string} id 
         * @param {AdminUpdateFlashcardsPackDto} adminUpdateFlashcardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsPacksControllerUpdateFlashcardsPack: async (id: string, adminUpdateFlashcardsPackDto: AdminUpdateFlashcardsPackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFlashcardsPacksControllerUpdateFlashcardsPack', 'id', id)
            // verify required parameter 'adminUpdateFlashcardsPackDto' is not null or undefined
            assertParamExists('adminFlashcardsPacksControllerUpdateFlashcardsPack', 'adminUpdateFlashcardsPackDto', adminUpdateFlashcardsPackDto)
            const localVarPath = `/admin/flashcards/packs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateFlashcardsPackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Flashcards
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminFlashcardsControllerFlashcard: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/flashcards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple FlashcardsPacks
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminFlashcardsPacksControllerFlashcardsPack: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/flashcards/packs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Flashcard
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminFlashcardsControllerFlashcard: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminFlashcardsControllerFlashcard', 'id', id)
            const localVarPath = `/admin/flashcards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single FlashcardsPack
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminFlashcardsPacksControllerFlashcardsPack: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminFlashcardsPacksControllerFlashcardsPack', 'id', id)
            const localVarPath = `/admin/flashcards/packs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelFlashcardsApi - functional programming interface
 * @export
 */
export const AdminPanelFlashcardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelFlashcardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create flashcard
         * @param {AdminCreateFlashcardDto} adminCreateFlashcardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFlashcardsControllerCreateFlashcard(adminCreateFlashcardDto: AdminCreateFlashcardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminFlashcardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFlashcardsControllerCreateFlashcard(adminCreateFlashcardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create many flashcard
         * @param {AdminCreateManyFlashcardsDto} adminCreateManyFlashcardsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFlashcardsControllerCreateManyFlashcards(adminCreateManyFlashcardsDto: AdminCreateManyFlashcardsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminFlashcardDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFlashcardsControllerCreateManyFlashcards(adminCreateManyFlashcardsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove flashcard
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFlashcardsControllerRemoveFlashcard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFlashcardsControllerRemoveFlashcard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update flashcard
         * @param {string} id 
         * @param {AdminUpdateFlashcardDto} adminUpdateFlashcardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFlashcardsControllerUpdateFlashcard(id: string, adminUpdateFlashcardDto: AdminUpdateFlashcardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminFlashcardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFlashcardsControllerUpdateFlashcard(id, adminUpdateFlashcardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload flashcard image
         * @param {AdminUploadFlashcardImageDto} adminUploadFlashcardImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFlashcardsControllerUploadImage(adminUploadFlashcardImageDto: AdminUploadFlashcardImageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSignedUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFlashcardsControllerUploadImage(adminUploadFlashcardImageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create flashcards pack
         * @param {AdminCreateFlashcardsPackDto} adminCreateFlashcardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFlashcardsPacksControllerCreateFlashcardsPack(adminCreateFlashcardsPackDto: AdminCreateFlashcardsPackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminFlashcardsPackWithCardsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFlashcardsPacksControllerCreateFlashcardsPack(adminCreateFlashcardsPackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move a single FlashcardsPack
         * @param {string} id 
         * @param {AdminMoveFlashcardsPackDto} adminMoveFlashcardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFlashcardsPacksControllerMoveFlashcardsPack(id: string, adminMoveFlashcardsPackDto: AdminMoveFlashcardsPackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminFlashcardsPackDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFlashcardsPacksControllerMoveFlashcardsPack(id, adminMoveFlashcardsPackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove flashcard pack
         * @param {string} packId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFlashcardsPacksControllerRemovePack(packId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFlashcardsPacksControllerRemovePack(packId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single FlashcardsPack
         * @param {string} id 
         * @param {AdminUpdateFlashcardsPackDto} adminUpdateFlashcardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFlashcardsPacksControllerUpdateFlashcardsPack(id: string, adminUpdateFlashcardsPackDto: AdminUpdateFlashcardsPackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminFlashcardsPackDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFlashcardsPacksControllerUpdateFlashcardsPack(id, adminUpdateFlashcardsPackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Flashcards
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminFlashcardsControllerFlashcard(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedFlashcardsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminFlashcardsControllerFlashcard(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple FlashcardsPacks
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminFlashcardsPacksControllerFlashcardsPack(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedFlashcardPacksDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminFlashcardsPacksControllerFlashcardsPack(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Flashcard
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminFlashcardsControllerFlashcard(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminFlashcardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminFlashcardsControllerFlashcard(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single FlashcardsPack
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminFlashcardsPacksControllerFlashcardsPack(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminFlashcardsPackWithCardsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminFlashcardsPacksControllerFlashcardsPack(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelFlashcardsApi - factory interface
 * @export
 */
export const AdminPanelFlashcardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelFlashcardsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create flashcard
         * @param {AdminCreateFlashcardDto} adminCreateFlashcardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsControllerCreateFlashcard(adminCreateFlashcardDto: AdminCreateFlashcardDto, options?: any): AxiosPromise<AdminFlashcardDto> {
            return localVarFp.adminFlashcardsControllerCreateFlashcard(adminCreateFlashcardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create many flashcard
         * @param {AdminCreateManyFlashcardsDto} adminCreateManyFlashcardsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsControllerCreateManyFlashcards(adminCreateManyFlashcardsDto: AdminCreateManyFlashcardsDto, options?: any): AxiosPromise<Array<AdminFlashcardDto>> {
            return localVarFp.adminFlashcardsControllerCreateManyFlashcards(adminCreateManyFlashcardsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove flashcard
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsControllerRemoveFlashcard(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminFlashcardsControllerRemoveFlashcard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update flashcard
         * @param {string} id 
         * @param {AdminUpdateFlashcardDto} adminUpdateFlashcardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsControllerUpdateFlashcard(id: string, adminUpdateFlashcardDto: AdminUpdateFlashcardDto, options?: any): AxiosPromise<AdminFlashcardDto> {
            return localVarFp.adminFlashcardsControllerUpdateFlashcard(id, adminUpdateFlashcardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload flashcard image
         * @param {AdminUploadFlashcardImageDto} adminUploadFlashcardImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsControllerUploadImage(adminUploadFlashcardImageDto: AdminUploadFlashcardImageDto, options?: any): AxiosPromise<AdminSignedUrlDto> {
            return localVarFp.adminFlashcardsControllerUploadImage(adminUploadFlashcardImageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create flashcards pack
         * @param {AdminCreateFlashcardsPackDto} adminCreateFlashcardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsPacksControllerCreateFlashcardsPack(adminCreateFlashcardsPackDto: AdminCreateFlashcardsPackDto, options?: any): AxiosPromise<AdminFlashcardsPackWithCardsDto> {
            return localVarFp.adminFlashcardsPacksControllerCreateFlashcardsPack(adminCreateFlashcardsPackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move a single FlashcardsPack
         * @param {string} id 
         * @param {AdminMoveFlashcardsPackDto} adminMoveFlashcardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsPacksControllerMoveFlashcardsPack(id: string, adminMoveFlashcardsPackDto: AdminMoveFlashcardsPackDto, options?: any): AxiosPromise<AdminFlashcardsPackDto> {
            return localVarFp.adminFlashcardsPacksControllerMoveFlashcardsPack(id, adminMoveFlashcardsPackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove flashcard pack
         * @param {string} packId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsPacksControllerRemovePack(packId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminFlashcardsPacksControllerRemovePack(packId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single FlashcardsPack
         * @param {string} id 
         * @param {AdminUpdateFlashcardsPackDto} adminUpdateFlashcardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFlashcardsPacksControllerUpdateFlashcardsPack(id: string, adminUpdateFlashcardsPackDto: AdminUpdateFlashcardsPackDto, options?: any): AxiosPromise<AdminFlashcardsPackDto> {
            return localVarFp.adminFlashcardsPacksControllerUpdateFlashcardsPack(id, adminUpdateFlashcardsPackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Flashcards
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminFlashcardsControllerFlashcard(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedFlashcardsDto> {
            return localVarFp.getManyBaseAdminFlashcardsControllerFlashcard(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple FlashcardsPacks
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminFlashcardsPacksControllerFlashcardsPack(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedFlashcardPacksDto> {
            return localVarFp.getManyBaseAdminFlashcardsPacksControllerFlashcardsPack(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Flashcard
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminFlashcardsControllerFlashcard(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminFlashcardDto> {
            return localVarFp.getOneBaseAdminFlashcardsControllerFlashcard(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single FlashcardsPack
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminFlashcardsPacksControllerFlashcardsPack(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminFlashcardsPackWithCardsDto> {
            return localVarFp.getOneBaseAdminFlashcardsPacksControllerFlashcardsPack(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelFlashcardsApi - object-oriented interface
 * @export
 * @class AdminPanelFlashcardsApi
 * @extends {BaseAPI}
 */
export class AdminPanelFlashcardsApi extends BaseAPI {
    /**
     * 
     * @summary Create flashcard
     * @param {AdminCreateFlashcardDto} adminCreateFlashcardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public adminFlashcardsControllerCreateFlashcard(adminCreateFlashcardDto: AdminCreateFlashcardDto, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).adminFlashcardsControllerCreateFlashcard(adminCreateFlashcardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create many flashcard
     * @param {AdminCreateManyFlashcardsDto} adminCreateManyFlashcardsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public adminFlashcardsControllerCreateManyFlashcards(adminCreateManyFlashcardsDto: AdminCreateManyFlashcardsDto, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).adminFlashcardsControllerCreateManyFlashcards(adminCreateManyFlashcardsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove flashcard
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public adminFlashcardsControllerRemoveFlashcard(id: string, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).adminFlashcardsControllerRemoveFlashcard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update flashcard
     * @param {string} id 
     * @param {AdminUpdateFlashcardDto} adminUpdateFlashcardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public adminFlashcardsControllerUpdateFlashcard(id: string, adminUpdateFlashcardDto: AdminUpdateFlashcardDto, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).adminFlashcardsControllerUpdateFlashcard(id, adminUpdateFlashcardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload flashcard image
     * @param {AdminUploadFlashcardImageDto} adminUploadFlashcardImageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public adminFlashcardsControllerUploadImage(adminUploadFlashcardImageDto: AdminUploadFlashcardImageDto, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).adminFlashcardsControllerUploadImage(adminUploadFlashcardImageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create flashcards pack
     * @param {AdminCreateFlashcardsPackDto} adminCreateFlashcardsPackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public adminFlashcardsPacksControllerCreateFlashcardsPack(adminCreateFlashcardsPackDto: AdminCreateFlashcardsPackDto, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).adminFlashcardsPacksControllerCreateFlashcardsPack(adminCreateFlashcardsPackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move a single FlashcardsPack
     * @param {string} id 
     * @param {AdminMoveFlashcardsPackDto} adminMoveFlashcardsPackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public adminFlashcardsPacksControllerMoveFlashcardsPack(id: string, adminMoveFlashcardsPackDto: AdminMoveFlashcardsPackDto, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).adminFlashcardsPacksControllerMoveFlashcardsPack(id, adminMoveFlashcardsPackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove flashcard pack
     * @param {string} packId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public adminFlashcardsPacksControllerRemovePack(packId: string, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).adminFlashcardsPacksControllerRemovePack(packId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single FlashcardsPack
     * @param {string} id 
     * @param {AdminUpdateFlashcardsPackDto} adminUpdateFlashcardsPackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public adminFlashcardsPacksControllerUpdateFlashcardsPack(id: string, adminUpdateFlashcardsPackDto: AdminUpdateFlashcardsPackDto, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).adminFlashcardsPacksControllerUpdateFlashcardsPack(id, adminUpdateFlashcardsPackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Flashcards
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public getManyBaseAdminFlashcardsControllerFlashcard(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).getManyBaseAdminFlashcardsControllerFlashcard(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple FlashcardsPacks
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public getManyBaseAdminFlashcardsPacksControllerFlashcardsPack(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).getManyBaseAdminFlashcardsPacksControllerFlashcardsPack(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Flashcard
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public getOneBaseAdminFlashcardsControllerFlashcard(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).getOneBaseAdminFlashcardsControllerFlashcard(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single FlashcardsPack
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelFlashcardsApi
     */
    public getOneBaseAdminFlashcardsPacksControllerFlashcardsPack(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelFlashcardsApiFp(this.configuration).getOneBaseAdminFlashcardsPacksControllerFlashcardsPack(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelGuidesApi - axios parameter creator
 * @export
 */
export const AdminPanelGuidesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new unit
         * @param {string} chapterId 
         * @param {CreateGuideChapterUnitRequestDto} createGuideChapterUnitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChapterUnitsControllerCreateUnit: async (chapterId: string, createGuideChapterUnitRequestDto: CreateGuideChapterUnitRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chapterId' is not null or undefined
            assertParamExists('adminGuideChapterUnitsControllerCreateUnit', 'chapterId', chapterId)
            // verify required parameter 'createGuideChapterUnitRequestDto' is not null or undefined
            assertParamExists('adminGuideChapterUnitsControllerCreateUnit', 'createGuideChapterUnitRequestDto', createGuideChapterUnitRequestDto)
            const localVarPath = `/admin/guides/chapters/{chapterId}/units`
                .replace(`{${"chapterId"}}`, encodeURIComponent(String(chapterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGuideChapterUnitRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new unit
         * @param {string} chapterId 
         * @param {CreateGuideChapterUnitsBulkRequestDto} createGuideChapterUnitsBulkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChapterUnitsControllerCreateUnitsBulk: async (chapterId: string, createGuideChapterUnitsBulkRequestDto: CreateGuideChapterUnitsBulkRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chapterId' is not null or undefined
            assertParamExists('adminGuideChapterUnitsControllerCreateUnitsBulk', 'chapterId', chapterId)
            // verify required parameter 'createGuideChapterUnitsBulkRequestDto' is not null or undefined
            assertParamExists('adminGuideChapterUnitsControllerCreateUnitsBulk', 'createGuideChapterUnitsBulkRequestDto', createGuideChapterUnitsBulkRequestDto)
            const localVarPath = `/admin/guides/chapters/{chapterId}/units/bulk`
                .replace(`{${"chapterId"}}`, encodeURIComponent(String(chapterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGuideChapterUnitsBulkRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove unit
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChapterUnitsControllerRemoveUnit: async (unitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitId' is not null or undefined
            assertParamExists('adminGuideChapterUnitsControllerRemoveUnit', 'unitId', unitId)
            const localVarPath = `/admin/guides/units/{unitId}`
                .replace(`{${"unitId"}}`, encodeURIComponent(String(unitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update unit
         * @param {string} unitId 
         * @param {UpdateGuideChapterUnitDto} updateGuideChapterUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChapterUnitsControllerUpdateUnit: async (unitId: string, updateGuideChapterUnitDto: UpdateGuideChapterUnitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitId' is not null or undefined
            assertParamExists('adminGuideChapterUnitsControllerUpdateUnit', 'unitId', unitId)
            // verify required parameter 'updateGuideChapterUnitDto' is not null or undefined
            assertParamExists('adminGuideChapterUnitsControllerUpdateUnit', 'updateGuideChapterUnitDto', updateGuideChapterUnitDto)
            const localVarPath = `/admin/guides/units/{unitId}`
                .replace(`{${"unitId"}}`, encodeURIComponent(String(unitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGuideChapterUnitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new chapter
         * @param {string} guideId 
         * @param {CreateNewChapterDto} createNewChapterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChaptersControllerCreateChapter: async (guideId: string, createNewChapterDto: CreateNewChapterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guideId' is not null or undefined
            assertParamExists('adminGuideChaptersControllerCreateChapter', 'guideId', guideId)
            // verify required parameter 'createNewChapterDto' is not null or undefined
            assertParamExists('adminGuideChaptersControllerCreateChapter', 'createNewChapterDto', createNewChapterDto)
            const localVarPath = `/admin/guides/{guideId}/chapters`
                .replace(`{${"guideId"}}`, encodeURIComponent(String(guideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewChapterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get guide chapter
         * @param {string} chapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChaptersControllerGetGuideChapter: async (chapterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chapterId' is not null or undefined
            assertParamExists('adminGuideChaptersControllerGetGuideChapter', 'chapterId', chapterId)
            const localVarPath = `/admin/guides/chapters/{chapterId}`
                .replace(`{${"chapterId"}}`, encodeURIComponent(String(chapterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get guide chapters with pagination
         * @param {string} guideId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChaptersControllerGetGuideChapters: async (guideId: string, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guideId' is not null or undefined
            assertParamExists('adminGuideChaptersControllerGetGuideChapters', 'guideId', guideId)
            const localVarPath = `/admin/guides/{guideId}/chapters`
                .replace(`{${"guideId"}}`, encodeURIComponent(String(guideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove chapter
         * @param {string} chapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChaptersControllerRemoveChapter: async (chapterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chapterId' is not null or undefined
            assertParamExists('adminGuideChaptersControllerRemoveChapter', 'chapterId', chapterId)
            const localVarPath = `/admin/guides/chapters/{chapterId}`
                .replace(`{${"chapterId"}}`, encodeURIComponent(String(chapterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update chapter
         * @param {string} chapterId 
         * @param {UpdateGuideChapterDto} updateGuideChapterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChaptersControllerUpdateChapter: async (chapterId: string, updateGuideChapterDto: UpdateGuideChapterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chapterId' is not null or undefined
            assertParamExists('adminGuideChaptersControllerUpdateChapter', 'chapterId', chapterId)
            // verify required parameter 'updateGuideChapterDto' is not null or undefined
            assertParamExists('adminGuideChaptersControllerUpdateChapter', 'updateGuideChapterDto', updateGuideChapterDto)
            const localVarPath = `/admin/guides/chapters/{chapterId}`
                .replace(`{${"chapterId"}}`, encodeURIComponent(String(chapterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGuideChapterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add or change text book for guide
         * @param {string} guideId 
         * @param {AddTextBookRequestDto} addTextBookRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerAddOrChangeTextBook: async (guideId: string, addTextBookRequestDto: AddTextBookRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guideId' is not null or undefined
            assertParamExists('adminGuidesControllerAddOrChangeTextBook', 'guideId', guideId)
            // verify required parameter 'addTextBookRequestDto' is not null or undefined
            assertParamExists('adminGuidesControllerAddOrChangeTextBook', 'addTextBookRequestDto', addTextBookRequestDto)
            const localVarPath = `/admin/guides/{guideId}/add-text-book`
                .replace(`{${"guideId"}}`, encodeURIComponent(String(guideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTextBookRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create study guide for course
         * @param {CreateGuideRequestDto} createGuideRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerCreateGuide: async (createGuideRequestDto: CreateGuideRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createGuideRequestDto' is not null or undefined
            assertParamExists('adminGuidesControllerCreateGuide', 'createGuideRequestDto', createGuideRequestDto)
            const localVarPath = `/admin/guides`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGuideRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get guide
         * @param {string} guideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerGetGuide: async (guideId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guideId' is not null or undefined
            assertParamExists('adminGuidesControllerGetGuide', 'guideId', guideId)
            const localVarPath = `/admin/guides/{guideId}`
                .replace(`{${"guideId"}}`, encodeURIComponent(String(guideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get guide by course id
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerGetGuideByCourseId: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('adminGuidesControllerGetGuideByCourseId', 'courseId', courseId)
            const localVarPath = `/admin/guides/courses/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many guides
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerGetManyGuides: async (page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/guides`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload audio for guide chapter
         * @param {UploadAudioForGuideRequestDto} uploadAudioForGuideRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerUploadAudio: async (uploadAudioForGuideRequestDto: UploadAudioForGuideRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadAudioForGuideRequestDto' is not null or undefined
            assertParamExists('adminGuidesControllerUploadAudio', 'uploadAudioForGuideRequestDto', uploadAudioForGuideRequestDto)
            const localVarPath = `/admin/guides/upload-audio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadAudioForGuideRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload image for guide chapter
         * @param {UploadImageForGuideRequestDto} uploadImageForGuideRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerUploadImage: async (uploadImageForGuideRequestDto: UploadImageForGuideRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadImageForGuideRequestDto' is not null or undefined
            assertParamExists('adminGuidesControllerUploadImage', 'uploadImageForGuideRequestDto', uploadImageForGuideRequestDto)
            const localVarPath = `/admin/guides/upload-image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadImageForGuideRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload textbook for guide chapter
         * @param {UploadTextbookForGuideRequestDto} uploadTextbookForGuideRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerUploadTextbook: async (uploadTextbookForGuideRequestDto: UploadTextbookForGuideRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadTextbookForGuideRequestDto' is not null or undefined
            assertParamExists('adminGuidesControllerUploadTextbook', 'uploadTextbookForGuideRequestDto', uploadTextbookForGuideRequestDto)
            const localVarPath = `/admin/guides/upload-textbook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadTextbookForGuideRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelGuidesApi - functional programming interface
 * @export
 */
export const AdminPanelGuidesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelGuidesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new unit
         * @param {string} chapterId 
         * @param {CreateGuideChapterUnitRequestDto} createGuideChapterUnitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuideChapterUnitsControllerCreateUnit(chapterId: string, createGuideChapterUnitRequestDto: CreateGuideChapterUnitRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideChapterResponseUnitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuideChapterUnitsControllerCreateUnit(chapterId, createGuideChapterUnitRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new unit
         * @param {string} chapterId 
         * @param {CreateGuideChapterUnitsBulkRequestDto} createGuideChapterUnitsBulkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuideChapterUnitsControllerCreateUnitsBulk(chapterId: string, createGuideChapterUnitsBulkRequestDto: CreateGuideChapterUnitsBulkRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GuideChapterResponseUnitDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuideChapterUnitsControllerCreateUnitsBulk(chapterId, createGuideChapterUnitsBulkRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove unit
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuideChapterUnitsControllerRemoveUnit(unitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuideChapterUnitsControllerRemoveUnit(unitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update unit
         * @param {string} unitId 
         * @param {UpdateGuideChapterUnitDto} updateGuideChapterUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuideChapterUnitsControllerUpdateUnit(unitId: string, updateGuideChapterUnitDto: UpdateGuideChapterUnitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideChapterResponseUnitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuideChapterUnitsControllerUpdateUnit(unitId, updateGuideChapterUnitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new chapter
         * @param {string} guideId 
         * @param {CreateNewChapterDto} createNewChapterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuideChaptersControllerCreateChapter(guideId: string, createNewChapterDto: CreateNewChapterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideChapterResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuideChaptersControllerCreateChapter(guideId, createNewChapterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get guide chapter
         * @param {string} chapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuideChaptersControllerGetGuideChapter(chapterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideChapterResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuideChaptersControllerGetGuideChapter(chapterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get guide chapters with pagination
         * @param {string} guideId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuideChaptersControllerGetGuideChapters(guideId: string, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuideChaptersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuideChaptersControllerGetGuideChapters(guideId, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove chapter
         * @param {string} chapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuideChaptersControllerRemoveChapter(chapterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuideChaptersControllerRemoveChapter(chapterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update chapter
         * @param {string} chapterId 
         * @param {UpdateGuideChapterDto} updateGuideChapterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuideChaptersControllerUpdateChapter(chapterId: string, updateGuideChapterDto: UpdateGuideChapterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideChapterResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuideChaptersControllerUpdateChapter(chapterId, updateGuideChapterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add or change text book for guide
         * @param {string} guideId 
         * @param {AddTextBookRequestDto} addTextBookRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuidesControllerAddOrChangeTextBook(guideId: string, addTextBookRequestDto: AddTextBookRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuidesControllerAddOrChangeTextBook(guideId, addTextBookRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create study guide for course
         * @param {CreateGuideRequestDto} createGuideRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuidesControllerCreateGuide(createGuideRequestDto: CreateGuideRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuidesControllerCreateGuide(createGuideRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get guide
         * @param {string} guideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuidesControllerGetGuide(guideId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuidesControllerGetGuide(guideId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get guide by course id
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuidesControllerGetGuideByCourseId(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuidesControllerGetGuideByCourseId(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many guides
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuidesControllerGetManyGuides(page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyGuidesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuidesControllerGetManyGuides(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload audio for guide chapter
         * @param {UploadAudioForGuideRequestDto} uploadAudioForGuideRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuidesControllerUploadAudio(uploadAudioForGuideRequestDto: UploadAudioForGuideRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSignedUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuidesControllerUploadAudio(uploadAudioForGuideRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload image for guide chapter
         * @param {UploadImageForGuideRequestDto} uploadImageForGuideRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuidesControllerUploadImage(uploadImageForGuideRequestDto: UploadImageForGuideRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSignedUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuidesControllerUploadImage(uploadImageForGuideRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload textbook for guide chapter
         * @param {UploadTextbookForGuideRequestDto} uploadTextbookForGuideRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGuidesControllerUploadTextbook(uploadTextbookForGuideRequestDto: UploadTextbookForGuideRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSignedUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGuidesControllerUploadTextbook(uploadTextbookForGuideRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelGuidesApi - factory interface
 * @export
 */
export const AdminPanelGuidesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelGuidesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new unit
         * @param {string} chapterId 
         * @param {CreateGuideChapterUnitRequestDto} createGuideChapterUnitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChapterUnitsControllerCreateUnit(chapterId: string, createGuideChapterUnitRequestDto: CreateGuideChapterUnitRequestDto, options?: any): AxiosPromise<GuideChapterResponseUnitDto> {
            return localVarFp.adminGuideChapterUnitsControllerCreateUnit(chapterId, createGuideChapterUnitRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new unit
         * @param {string} chapterId 
         * @param {CreateGuideChapterUnitsBulkRequestDto} createGuideChapterUnitsBulkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChapterUnitsControllerCreateUnitsBulk(chapterId: string, createGuideChapterUnitsBulkRequestDto: CreateGuideChapterUnitsBulkRequestDto, options?: any): AxiosPromise<Array<GuideChapterResponseUnitDto>> {
            return localVarFp.adminGuideChapterUnitsControllerCreateUnitsBulk(chapterId, createGuideChapterUnitsBulkRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove unit
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChapterUnitsControllerRemoveUnit(unitId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminGuideChapterUnitsControllerRemoveUnit(unitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update unit
         * @param {string} unitId 
         * @param {UpdateGuideChapterUnitDto} updateGuideChapterUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChapterUnitsControllerUpdateUnit(unitId: string, updateGuideChapterUnitDto: UpdateGuideChapterUnitDto, options?: any): AxiosPromise<GuideChapterResponseUnitDto> {
            return localVarFp.adminGuideChapterUnitsControllerUpdateUnit(unitId, updateGuideChapterUnitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new chapter
         * @param {string} guideId 
         * @param {CreateNewChapterDto} createNewChapterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChaptersControllerCreateChapter(guideId: string, createNewChapterDto: CreateNewChapterDto, options?: any): AxiosPromise<GuideChapterResponseDto> {
            return localVarFp.adminGuideChaptersControllerCreateChapter(guideId, createNewChapterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get guide chapter
         * @param {string} chapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChaptersControllerGetGuideChapter(chapterId: string, options?: any): AxiosPromise<GuideChapterResponseDto> {
            return localVarFp.adminGuideChaptersControllerGetGuideChapter(chapterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get guide chapters with pagination
         * @param {string} guideId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChaptersControllerGetGuideChapters(guideId: string, page?: number, limit?: number, options?: any): AxiosPromise<GetGuideChaptersResponseDto> {
            return localVarFp.adminGuideChaptersControllerGetGuideChapters(guideId, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove chapter
         * @param {string} chapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChaptersControllerRemoveChapter(chapterId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminGuideChaptersControllerRemoveChapter(chapterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update chapter
         * @param {string} chapterId 
         * @param {UpdateGuideChapterDto} updateGuideChapterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuideChaptersControllerUpdateChapter(chapterId: string, updateGuideChapterDto: UpdateGuideChapterDto, options?: any): AxiosPromise<GuideChapterResponseDto> {
            return localVarFp.adminGuideChaptersControllerUpdateChapter(chapterId, updateGuideChapterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add or change text book for guide
         * @param {string} guideId 
         * @param {AddTextBookRequestDto} addTextBookRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerAddOrChangeTextBook(guideId: string, addTextBookRequestDto: AddTextBookRequestDto, options?: any): AxiosPromise<GuideResponseDto> {
            return localVarFp.adminGuidesControllerAddOrChangeTextBook(guideId, addTextBookRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create study guide for course
         * @param {CreateGuideRequestDto} createGuideRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerCreateGuide(createGuideRequestDto: CreateGuideRequestDto, options?: any): AxiosPromise<GuideResponseDto> {
            return localVarFp.adminGuidesControllerCreateGuide(createGuideRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get guide
         * @param {string} guideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerGetGuide(guideId: string, options?: any): AxiosPromise<GuideResponseDto> {
            return localVarFp.adminGuidesControllerGetGuide(guideId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get guide by course id
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerGetGuideByCourseId(courseId: string, options?: any): AxiosPromise<GuideResponseDto> {
            return localVarFp.adminGuidesControllerGetGuideByCourseId(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many guides
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerGetManyGuides(page?: number, limit?: number, options?: any): AxiosPromise<GetManyGuidesResponseDto> {
            return localVarFp.adminGuidesControllerGetManyGuides(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload audio for guide chapter
         * @param {UploadAudioForGuideRequestDto} uploadAudioForGuideRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerUploadAudio(uploadAudioForGuideRequestDto: UploadAudioForGuideRequestDto, options?: any): AxiosPromise<AdminSignedUrlDto> {
            return localVarFp.adminGuidesControllerUploadAudio(uploadAudioForGuideRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload image for guide chapter
         * @param {UploadImageForGuideRequestDto} uploadImageForGuideRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerUploadImage(uploadImageForGuideRequestDto: UploadImageForGuideRequestDto, options?: any): AxiosPromise<AdminSignedUrlDto> {
            return localVarFp.adminGuidesControllerUploadImage(uploadImageForGuideRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload textbook for guide chapter
         * @param {UploadTextbookForGuideRequestDto} uploadTextbookForGuideRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGuidesControllerUploadTextbook(uploadTextbookForGuideRequestDto: UploadTextbookForGuideRequestDto, options?: any): AxiosPromise<AdminSignedUrlDto> {
            return localVarFp.adminGuidesControllerUploadTextbook(uploadTextbookForGuideRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelGuidesApi - object-oriented interface
 * @export
 * @class AdminPanelGuidesApi
 * @extends {BaseAPI}
 */
export class AdminPanelGuidesApi extends BaseAPI {
    /**
     * 
     * @summary Create new unit
     * @param {string} chapterId 
     * @param {CreateGuideChapterUnitRequestDto} createGuideChapterUnitRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuideChapterUnitsControllerCreateUnit(chapterId: string, createGuideChapterUnitRequestDto: CreateGuideChapterUnitRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuideChapterUnitsControllerCreateUnit(chapterId, createGuideChapterUnitRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new unit
     * @param {string} chapterId 
     * @param {CreateGuideChapterUnitsBulkRequestDto} createGuideChapterUnitsBulkRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuideChapterUnitsControllerCreateUnitsBulk(chapterId: string, createGuideChapterUnitsBulkRequestDto: CreateGuideChapterUnitsBulkRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuideChapterUnitsControllerCreateUnitsBulk(chapterId, createGuideChapterUnitsBulkRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove unit
     * @param {string} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuideChapterUnitsControllerRemoveUnit(unitId: string, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuideChapterUnitsControllerRemoveUnit(unitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update unit
     * @param {string} unitId 
     * @param {UpdateGuideChapterUnitDto} updateGuideChapterUnitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuideChapterUnitsControllerUpdateUnit(unitId: string, updateGuideChapterUnitDto: UpdateGuideChapterUnitDto, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuideChapterUnitsControllerUpdateUnit(unitId, updateGuideChapterUnitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new chapter
     * @param {string} guideId 
     * @param {CreateNewChapterDto} createNewChapterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuideChaptersControllerCreateChapter(guideId: string, createNewChapterDto: CreateNewChapterDto, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuideChaptersControllerCreateChapter(guideId, createNewChapterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get guide chapter
     * @param {string} chapterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuideChaptersControllerGetGuideChapter(chapterId: string, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuideChaptersControllerGetGuideChapter(chapterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get guide chapters with pagination
     * @param {string} guideId 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuideChaptersControllerGetGuideChapters(guideId: string, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuideChaptersControllerGetGuideChapters(guideId, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove chapter
     * @param {string} chapterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuideChaptersControllerRemoveChapter(chapterId: string, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuideChaptersControllerRemoveChapter(chapterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update chapter
     * @param {string} chapterId 
     * @param {UpdateGuideChapterDto} updateGuideChapterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuideChaptersControllerUpdateChapter(chapterId: string, updateGuideChapterDto: UpdateGuideChapterDto, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuideChaptersControllerUpdateChapter(chapterId, updateGuideChapterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add or change text book for guide
     * @param {string} guideId 
     * @param {AddTextBookRequestDto} addTextBookRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuidesControllerAddOrChangeTextBook(guideId: string, addTextBookRequestDto: AddTextBookRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuidesControllerAddOrChangeTextBook(guideId, addTextBookRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create study guide for course
     * @param {CreateGuideRequestDto} createGuideRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuidesControllerCreateGuide(createGuideRequestDto: CreateGuideRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuidesControllerCreateGuide(createGuideRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get guide
     * @param {string} guideId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuidesControllerGetGuide(guideId: string, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuidesControllerGetGuide(guideId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get guide by course id
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuidesControllerGetGuideByCourseId(courseId: string, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuidesControllerGetGuideByCourseId(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many guides
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuidesControllerGetManyGuides(page?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuidesControllerGetManyGuides(page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload audio for guide chapter
     * @param {UploadAudioForGuideRequestDto} uploadAudioForGuideRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuidesControllerUploadAudio(uploadAudioForGuideRequestDto: UploadAudioForGuideRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuidesControllerUploadAudio(uploadAudioForGuideRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload image for guide chapter
     * @param {UploadImageForGuideRequestDto} uploadImageForGuideRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuidesControllerUploadImage(uploadImageForGuideRequestDto: UploadImageForGuideRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuidesControllerUploadImage(uploadImageForGuideRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload textbook for guide chapter
     * @param {UploadTextbookForGuideRequestDto} uploadTextbookForGuideRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelGuidesApi
     */
    public adminGuidesControllerUploadTextbook(uploadTextbookForGuideRequestDto: UploadTextbookForGuideRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelGuidesApiFp(this.configuration).adminGuidesControllerUploadTextbook(uploadTextbookForGuideRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelInvitedCustomersApi - axios parameter creator
 * @export
 */
export const AdminPanelInvitedCustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple InvitedCustomers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseInvitedCustomersControllerInvitedCustomer: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/customer-invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single InvitedCustomer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseInvitedCustomersControllerInvitedCustomer: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseInvitedCustomersControllerInvitedCustomer', 'id', id)
            const localVarPath = `/admin/customer-invites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all invited customers
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitedCustomersControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invitedCustomersControllerDelete', 'id', id)
            const localVarPath = `/admin/customer-invites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite user to get free access for course
         * @param {AddFreeAccessDto} addFreeAccessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitedCustomersControllerInvite: async (addFreeAccessDto: AddFreeAccessDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addFreeAccessDto' is not null or undefined
            assertParamExists('invitedCustomersControllerInvite', 'addFreeAccessDto', addFreeAccessDto)
            const localVarPath = `/admin/customer-invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFreeAccessDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelInvitedCustomersApi - functional programming interface
 * @export
 */
export const AdminPanelInvitedCustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelInvitedCustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple InvitedCustomers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseInvitedCustomersControllerInvitedCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedInvitedCustomersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseInvitedCustomersControllerInvitedCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single InvitedCustomer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseInvitedCustomersControllerInvitedCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInvitedCustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseInvitedCustomersControllerInvitedCustomer(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all invited customers
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitedCustomersControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitedCustomersControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invite user to get free access for course
         * @param {AddFreeAccessDto} addFreeAccessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitedCustomersControllerInvite(addFreeAccessDto: AddFreeAccessDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitedCustomersControllerInvite(addFreeAccessDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelInvitedCustomersApi - factory interface
 * @export
 */
export const AdminPanelInvitedCustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelInvitedCustomersApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple InvitedCustomers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseInvitedCustomersControllerInvitedCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedInvitedCustomersDto> {
            return localVarFp.getManyBaseInvitedCustomersControllerInvitedCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single InvitedCustomer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseInvitedCustomersControllerInvitedCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminInvitedCustomerDto> {
            return localVarFp.getOneBaseInvitedCustomersControllerInvitedCustomer(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all invited customers
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitedCustomersControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.invitedCustomersControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite user to get free access for course
         * @param {AddFreeAccessDto} addFreeAccessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitedCustomersControllerInvite(addFreeAccessDto: AddFreeAccessDto, options?: any): AxiosPromise<object> {
            return localVarFp.invitedCustomersControllerInvite(addFreeAccessDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelInvitedCustomersApi - object-oriented interface
 * @export
 * @class AdminPanelInvitedCustomersApi
 * @extends {BaseAPI}
 */
export class AdminPanelInvitedCustomersApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple InvitedCustomers
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelInvitedCustomersApi
     */
    public getManyBaseInvitedCustomersControllerInvitedCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelInvitedCustomersApiFp(this.configuration).getManyBaseInvitedCustomersControllerInvitedCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single InvitedCustomer
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelInvitedCustomersApi
     */
    public getOneBaseInvitedCustomersControllerInvitedCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelInvitedCustomersApiFp(this.configuration).getOneBaseInvitedCustomersControllerInvitedCustomer(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all invited customers
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelInvitedCustomersApi
     */
    public invitedCustomersControllerDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPanelInvitedCustomersApiFp(this.configuration).invitedCustomersControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite user to get free access for course
     * @param {AddFreeAccessDto} addFreeAccessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelInvitedCustomersApi
     */
    public invitedCustomersControllerInvite(addFreeAccessDto: AddFreeAccessDto, options?: AxiosRequestConfig) {
        return AdminPanelInvitedCustomersApiFp(this.configuration).invitedCustomersControllerInvite(addFreeAccessDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelMnemonicCardsApi - axios parameter creator
 * @export
 */
export const AdminPanelMnemonicCardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a mnemonic card
         * @param {CreateMnemonicCardDto} createMnemonicCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsControllerCreateMnemonicCard: async (createMnemonicCardDto: CreateMnemonicCardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMnemonicCardDto' is not null or undefined
            assertParamExists('adminMnemonicCardsControllerCreateMnemonicCard', 'createMnemonicCardDto', createMnemonicCardDto)
            const localVarPath = `/admin/mnemonicCards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMnemonicCardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload image for mnemonic card
         * @param {UploadMnemonicCardImageDto} uploadMnemonicCardImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsControllerUploadImage: async (uploadMnemonicCardImageDto: UploadMnemonicCardImageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadMnemonicCardImageDto' is not null or undefined
            assertParamExists('adminMnemonicCardsControllerUploadImage', 'uploadMnemonicCardImageDto', uploadMnemonicCardImageDto)
            const localVarPath = `/admin/mnemonicCards/uploadImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadMnemonicCardImageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new mnemonic cards pack
         * @param {CreateMnemonicCardsPackDto} createMnemonicCardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsPacksControllerCreateMnemonicCardsPack: async (createMnemonicCardsPackDto: CreateMnemonicCardsPackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMnemonicCardsPackDto' is not null or undefined
            assertParamExists('adminMnemonicCardsPacksControllerCreateMnemonicCardsPack', 'createMnemonicCardsPackDto', createMnemonicCardsPackDto)
            const localVarPath = `/admin/mnemonicCards/packs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMnemonicCardsPackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete mnemonic cards pack
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsPacksControllerDeleteMnemonicCardsPack: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminMnemonicCardsPacksControllerDeleteMnemonicCardsPack', 'id', id)
            const localVarPath = `/admin/mnemonicCards/packs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get single mnemonic cards pack with cards
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsPacksControllerGetOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminMnemonicCardsPacksControllerGetOne', 'id', id)
            const localVarPath = `/admin/mnemonicCards/packs/{id}/cards`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get single mnemonic cards pack
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsPacksControllerGetOnePack: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminMnemonicCardsPacksControllerGetOnePack', 'id', id)
            const localVarPath = `/admin/mnemonicCards/packs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update mnemonic cards pack
         * @param {string} id 
         * @param {UpdateMnemonicCardsPackDto} updateMnemonicCardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsPacksControllerUpdateMnemonicCardsPack: async (id: string, updateMnemonicCardsPackDto: UpdateMnemonicCardsPackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminMnemonicCardsPacksControllerUpdateMnemonicCardsPack', 'id', id)
            // verify required parameter 'updateMnemonicCardsPackDto' is not null or undefined
            assertParamExists('adminMnemonicCardsPacksControllerUpdateMnemonicCardsPack', 'updateMnemonicCardsPackDto', updateMnemonicCardsPackDto)
            const localVarPath = `/admin/mnemonicCards/packs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMnemonicCardsPackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single MnemonicCard
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminMnemonicCardsControllerMnemonicCard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminMnemonicCardsControllerMnemonicCard', 'id', id)
            const localVarPath = `/admin/mnemonicCards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple MnemonicCards
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminMnemonicCardsControllerMnemonicCard: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/mnemonicCards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple MnemonicCardsPacks
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminMnemonicCardsPacksControllerMnemonicCardsPack: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/mnemonicCards/packs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single MnemonicCard
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminMnemonicCardsControllerMnemonicCard: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminMnemonicCardsControllerMnemonicCard', 'id', id)
            const localVarPath = `/admin/mnemonicCards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single MnemonicCard
         * @param {string} id 
         * @param {UpdateMnemonicCardDto} updateMnemonicCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminMnemonicCardsControllerMnemonicCard: async (id: string, updateMnemonicCardDto: UpdateMnemonicCardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminMnemonicCardsControllerMnemonicCard', 'id', id)
            // verify required parameter 'updateMnemonicCardDto' is not null or undefined
            assertParamExists('updateOneBaseAdminMnemonicCardsControllerMnemonicCard', 'updateMnemonicCardDto', updateMnemonicCardDto)
            const localVarPath = `/admin/mnemonicCards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMnemonicCardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelMnemonicCardsApi - functional programming interface
 * @export
 */
export const AdminPanelMnemonicCardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelMnemonicCardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a mnemonic card
         * @param {CreateMnemonicCardDto} createMnemonicCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMnemonicCardsControllerCreateMnemonicCard(createMnemonicCardDto: CreateMnemonicCardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MnemonicCardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMnemonicCardsControllerCreateMnemonicCard(createMnemonicCardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload image for mnemonic card
         * @param {UploadMnemonicCardImageDto} uploadMnemonicCardImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMnemonicCardsControllerUploadImage(uploadMnemonicCardImageDto: UploadMnemonicCardImageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSignedUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMnemonicCardsControllerUploadImage(uploadMnemonicCardImageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new mnemonic cards pack
         * @param {CreateMnemonicCardsPackDto} createMnemonicCardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMnemonicCardsPacksControllerCreateMnemonicCardsPack(createMnemonicCardsPackDto: CreateMnemonicCardsPackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminMnemonicCardsPackDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMnemonicCardsPacksControllerCreateMnemonicCardsPack(createMnemonicCardsPackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete mnemonic cards pack
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMnemonicCardsPacksControllerDeleteMnemonicCardsPack(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMnemonicCardsPacksControllerDeleteMnemonicCardsPack(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get single mnemonic cards pack with cards
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMnemonicCardsPacksControllerGetOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminMnemonicCardsPackDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMnemonicCardsPacksControllerGetOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get single mnemonic cards pack
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMnemonicCardsPacksControllerGetOnePack(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetOneMnemonicCardsPackDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMnemonicCardsPacksControllerGetOnePack(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update mnemonic cards pack
         * @param {string} id 
         * @param {UpdateMnemonicCardsPackDto} updateMnemonicCardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMnemonicCardsPacksControllerUpdateMnemonicCardsPack(id: string, updateMnemonicCardsPackDto: UpdateMnemonicCardsPackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminMnemonicCardsPackDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMnemonicCardsPacksControllerUpdateMnemonicCardsPack(id, updateMnemonicCardsPackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single MnemonicCard
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminMnemonicCardsControllerMnemonicCard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminMnemonicCardsControllerMnemonicCard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple MnemonicCards
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminMnemonicCardsControllerMnemonicCard(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedMnemonicCardsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminMnemonicCardsControllerMnemonicCard(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple MnemonicCardsPacks
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminMnemonicCardsPacksControllerMnemonicCardsPack(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedMnemonicCardPacksDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminMnemonicCardsPacksControllerMnemonicCardsPack(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single MnemonicCard
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminMnemonicCardsControllerMnemonicCard(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminMnemonicCardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminMnemonicCardsControllerMnemonicCard(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single MnemonicCard
         * @param {string} id 
         * @param {UpdateMnemonicCardDto} updateMnemonicCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminMnemonicCardsControllerMnemonicCard(id: string, updateMnemonicCardDto: UpdateMnemonicCardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminMnemonicCardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminMnemonicCardsControllerMnemonicCard(id, updateMnemonicCardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelMnemonicCardsApi - factory interface
 * @export
 */
export const AdminPanelMnemonicCardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelMnemonicCardsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a mnemonic card
         * @param {CreateMnemonicCardDto} createMnemonicCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsControllerCreateMnemonicCard(createMnemonicCardDto: CreateMnemonicCardDto, options?: any): AxiosPromise<MnemonicCardDto> {
            return localVarFp.adminMnemonicCardsControllerCreateMnemonicCard(createMnemonicCardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload image for mnemonic card
         * @param {UploadMnemonicCardImageDto} uploadMnemonicCardImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsControllerUploadImage(uploadMnemonicCardImageDto: UploadMnemonicCardImageDto, options?: any): AxiosPromise<AdminSignedUrlDto> {
            return localVarFp.adminMnemonicCardsControllerUploadImage(uploadMnemonicCardImageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new mnemonic cards pack
         * @param {CreateMnemonicCardsPackDto} createMnemonicCardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsPacksControllerCreateMnemonicCardsPack(createMnemonicCardsPackDto: CreateMnemonicCardsPackDto, options?: any): AxiosPromise<AdminMnemonicCardsPackDto> {
            return localVarFp.adminMnemonicCardsPacksControllerCreateMnemonicCardsPack(createMnemonicCardsPackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete mnemonic cards pack
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsPacksControllerDeleteMnemonicCardsPack(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminMnemonicCardsPacksControllerDeleteMnemonicCardsPack(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get single mnemonic cards pack with cards
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsPacksControllerGetOne(id: string, options?: any): AxiosPromise<AdminMnemonicCardsPackDto> {
            return localVarFp.adminMnemonicCardsPacksControllerGetOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get single mnemonic cards pack
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsPacksControllerGetOnePack(id: string, options?: any): AxiosPromise<AdminGetOneMnemonicCardsPackDto> {
            return localVarFp.adminMnemonicCardsPacksControllerGetOnePack(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update mnemonic cards pack
         * @param {string} id 
         * @param {UpdateMnemonicCardsPackDto} updateMnemonicCardsPackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMnemonicCardsPacksControllerUpdateMnemonicCardsPack(id: string, updateMnemonicCardsPackDto: UpdateMnemonicCardsPackDto, options?: any): AxiosPromise<AdminMnemonicCardsPackDto> {
            return localVarFp.adminMnemonicCardsPacksControllerUpdateMnemonicCardsPack(id, updateMnemonicCardsPackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single MnemonicCard
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminMnemonicCardsControllerMnemonicCard(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminMnemonicCardsControllerMnemonicCard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple MnemonicCards
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminMnemonicCardsControllerMnemonicCard(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedMnemonicCardsDto> {
            return localVarFp.getManyBaseAdminMnemonicCardsControllerMnemonicCard(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple MnemonicCardsPacks
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminMnemonicCardsPacksControllerMnemonicCardsPack(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedMnemonicCardPacksDto> {
            return localVarFp.getManyBaseAdminMnemonicCardsPacksControllerMnemonicCardsPack(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single MnemonicCard
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminMnemonicCardsControllerMnemonicCard(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminMnemonicCardDto> {
            return localVarFp.getOneBaseAdminMnemonicCardsControllerMnemonicCard(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single MnemonicCard
         * @param {string} id 
         * @param {UpdateMnemonicCardDto} updateMnemonicCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminMnemonicCardsControllerMnemonicCard(id: string, updateMnemonicCardDto: UpdateMnemonicCardDto, options?: any): AxiosPromise<AdminMnemonicCardDto> {
            return localVarFp.updateOneBaseAdminMnemonicCardsControllerMnemonicCard(id, updateMnemonicCardDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelMnemonicCardsApi - object-oriented interface
 * @export
 * @class AdminPanelMnemonicCardsApi
 * @extends {BaseAPI}
 */
export class AdminPanelMnemonicCardsApi extends BaseAPI {
    /**
     * 
     * @summary Create a mnemonic card
     * @param {CreateMnemonicCardDto} createMnemonicCardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelMnemonicCardsApi
     */
    public adminMnemonicCardsControllerCreateMnemonicCard(createMnemonicCardDto: CreateMnemonicCardDto, options?: AxiosRequestConfig) {
        return AdminPanelMnemonicCardsApiFp(this.configuration).adminMnemonicCardsControllerCreateMnemonicCard(createMnemonicCardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload image for mnemonic card
     * @param {UploadMnemonicCardImageDto} uploadMnemonicCardImageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelMnemonicCardsApi
     */
    public adminMnemonicCardsControllerUploadImage(uploadMnemonicCardImageDto: UploadMnemonicCardImageDto, options?: AxiosRequestConfig) {
        return AdminPanelMnemonicCardsApiFp(this.configuration).adminMnemonicCardsControllerUploadImage(uploadMnemonicCardImageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new mnemonic cards pack
     * @param {CreateMnemonicCardsPackDto} createMnemonicCardsPackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelMnemonicCardsApi
     */
    public adminMnemonicCardsPacksControllerCreateMnemonicCardsPack(createMnemonicCardsPackDto: CreateMnemonicCardsPackDto, options?: AxiosRequestConfig) {
        return AdminPanelMnemonicCardsApiFp(this.configuration).adminMnemonicCardsPacksControllerCreateMnemonicCardsPack(createMnemonicCardsPackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete mnemonic cards pack
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelMnemonicCardsApi
     */
    public adminMnemonicCardsPacksControllerDeleteMnemonicCardsPack(id: string, options?: AxiosRequestConfig) {
        return AdminPanelMnemonicCardsApiFp(this.configuration).adminMnemonicCardsPacksControllerDeleteMnemonicCardsPack(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get single mnemonic cards pack with cards
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelMnemonicCardsApi
     */
    public adminMnemonicCardsPacksControllerGetOne(id: string, options?: AxiosRequestConfig) {
        return AdminPanelMnemonicCardsApiFp(this.configuration).adminMnemonicCardsPacksControllerGetOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get single mnemonic cards pack
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelMnemonicCardsApi
     */
    public adminMnemonicCardsPacksControllerGetOnePack(id: string, options?: AxiosRequestConfig) {
        return AdminPanelMnemonicCardsApiFp(this.configuration).adminMnemonicCardsPacksControllerGetOnePack(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update mnemonic cards pack
     * @param {string} id 
     * @param {UpdateMnemonicCardsPackDto} updateMnemonicCardsPackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelMnemonicCardsApi
     */
    public adminMnemonicCardsPacksControllerUpdateMnemonicCardsPack(id: string, updateMnemonicCardsPackDto: UpdateMnemonicCardsPackDto, options?: AxiosRequestConfig) {
        return AdminPanelMnemonicCardsApiFp(this.configuration).adminMnemonicCardsPacksControllerUpdateMnemonicCardsPack(id, updateMnemonicCardsPackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single MnemonicCard
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelMnemonicCardsApi
     */
    public deleteOneBaseAdminMnemonicCardsControllerMnemonicCard(id: string, options?: AxiosRequestConfig) {
        return AdminPanelMnemonicCardsApiFp(this.configuration).deleteOneBaseAdminMnemonicCardsControllerMnemonicCard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple MnemonicCards
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelMnemonicCardsApi
     */
    public getManyBaseAdminMnemonicCardsControllerMnemonicCard(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelMnemonicCardsApiFp(this.configuration).getManyBaseAdminMnemonicCardsControllerMnemonicCard(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple MnemonicCardsPacks
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelMnemonicCardsApi
     */
    public getManyBaseAdminMnemonicCardsPacksControllerMnemonicCardsPack(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelMnemonicCardsApiFp(this.configuration).getManyBaseAdminMnemonicCardsPacksControllerMnemonicCardsPack(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single MnemonicCard
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelMnemonicCardsApi
     */
    public getOneBaseAdminMnemonicCardsControllerMnemonicCard(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelMnemonicCardsApiFp(this.configuration).getOneBaseAdminMnemonicCardsControllerMnemonicCard(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single MnemonicCard
     * @param {string} id 
     * @param {UpdateMnemonicCardDto} updateMnemonicCardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelMnemonicCardsApi
     */
    public updateOneBaseAdminMnemonicCardsControllerMnemonicCard(id: string, updateMnemonicCardDto: UpdateMnemonicCardDto, options?: AxiosRequestConfig) {
        return AdminPanelMnemonicCardsApiFp(this.configuration).updateOneBaseAdminMnemonicCardsControllerMnemonicCard(id, updateMnemonicCardDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelPhasesInThePlanApi - axios parameter creator
 * @export
 */
export const AdminPanelPhasesInThePlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single PlanPhase
         * @param {AdminCreatePlanPhaseDto} adminCreatePlanPhaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhasesControllerCreatePlanPhase: async (adminCreatePlanPhaseDto: AdminCreatePlanPhaseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreatePlanPhaseDto' is not null or undefined
            assertParamExists('adminPlanPhasesControllerCreatePlanPhase', 'adminCreatePlanPhaseDto', adminCreatePlanPhaseDto)
            const localVarPath = `/admin/plans/phases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreatePlanPhaseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single PlanPhase
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhasesControllerDeletePlanPhase: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminPlanPhasesControllerDeletePlanPhase', 'id', id)
            const localVarPath = `/admin/plans/phases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple PlanPhases
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhasesControllerFindAll: async (page?: number, limit?: number, planId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/plans/phases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single PlanPhase
         * @param {string} id 
         * @param {AdminUpdatePlanPhaseDto} adminUpdatePlanPhaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhasesControllerUpdatePlanPhase: async (id: string, adminUpdatePlanPhaseDto: AdminUpdatePlanPhaseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminPlanPhasesControllerUpdatePlanPhase', 'id', id)
            // verify required parameter 'adminUpdatePlanPhaseDto' is not null or undefined
            assertParamExists('adminPlanPhasesControllerUpdatePlanPhase', 'adminUpdatePlanPhaseDto', adminUpdatePlanPhaseDto)
            const localVarPath = `/admin/plans/phases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdatePlanPhaseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single PlanPhase
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminPlanPhasesControllerPlanPhase: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminPlanPhasesControllerPlanPhase', 'id', id)
            const localVarPath = `/admin/plans/phases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelPhasesInThePlanApi - functional programming interface
 * @export
 */
export const AdminPanelPhasesInThePlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelPhasesInThePlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single PlanPhase
         * @param {AdminCreatePlanPhaseDto} adminCreatePlanPhaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlanPhasesControllerCreatePlanPhase(adminCreatePlanPhaseDto: AdminCreatePlanPhaseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPlanPhaseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlanPhasesControllerCreatePlanPhase(adminCreatePlanPhaseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single PlanPhase
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlanPhasesControllerDeletePlanPhase(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlanPhasesControllerDeletePlanPhase(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple PlanPhases
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlanPhasesControllerFindAll(page?: number, limit?: number, planId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPlanPhasesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlanPhasesControllerFindAll(page, limit, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single PlanPhase
         * @param {string} id 
         * @param {AdminUpdatePlanPhaseDto} adminUpdatePlanPhaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlanPhasesControllerUpdatePlanPhase(id: string, adminUpdatePlanPhaseDto: AdminUpdatePlanPhaseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPlanPhaseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlanPhasesControllerUpdatePlanPhase(id, adminUpdatePlanPhaseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single PlanPhase
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminPlanPhasesControllerPlanPhase(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanPhase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminPlanPhasesControllerPlanPhase(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelPhasesInThePlanApi - factory interface
 * @export
 */
export const AdminPanelPhasesInThePlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelPhasesInThePlanApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single PlanPhase
         * @param {AdminCreatePlanPhaseDto} adminCreatePlanPhaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhasesControllerCreatePlanPhase(adminCreatePlanPhaseDto: AdminCreatePlanPhaseDto, options?: any): AxiosPromise<AdminPlanPhaseDto> {
            return localVarFp.adminPlanPhasesControllerCreatePlanPhase(adminCreatePlanPhaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single PlanPhase
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhasesControllerDeletePlanPhase(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminPlanPhasesControllerDeletePlanPhase(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple PlanPhases
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhasesControllerFindAll(page?: number, limit?: number, planId?: string, options?: any): AxiosPromise<AdminPlanPhasesDto> {
            return localVarFp.adminPlanPhasesControllerFindAll(page, limit, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single PlanPhase
         * @param {string} id 
         * @param {AdminUpdatePlanPhaseDto} adminUpdatePlanPhaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhasesControllerUpdatePlanPhase(id: string, adminUpdatePlanPhaseDto: AdminUpdatePlanPhaseDto, options?: any): AxiosPromise<AdminPlanPhaseDto> {
            return localVarFp.adminPlanPhasesControllerUpdatePlanPhase(id, adminUpdatePlanPhaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single PlanPhase
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminPlanPhasesControllerPlanPhase(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<PlanPhase> {
            return localVarFp.getOneBaseAdminPlanPhasesControllerPlanPhase(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelPhasesInThePlanApi - object-oriented interface
 * @export
 * @class AdminPanelPhasesInThePlanApi
 * @extends {BaseAPI}
 */
export class AdminPanelPhasesInThePlanApi extends BaseAPI {
    /**
     * 
     * @summary Create a single PlanPhase
     * @param {AdminCreatePlanPhaseDto} adminCreatePlanPhaseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelPhasesInThePlanApi
     */
    public adminPlanPhasesControllerCreatePlanPhase(adminCreatePlanPhaseDto: AdminCreatePlanPhaseDto, options?: AxiosRequestConfig) {
        return AdminPanelPhasesInThePlanApiFp(this.configuration).adminPlanPhasesControllerCreatePlanPhase(adminCreatePlanPhaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single PlanPhase
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelPhasesInThePlanApi
     */
    public adminPlanPhasesControllerDeletePlanPhase(id: string, options?: AxiosRequestConfig) {
        return AdminPanelPhasesInThePlanApiFp(this.configuration).adminPlanPhasesControllerDeletePlanPhase(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple PlanPhases
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [planId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelPhasesInThePlanApi
     */
    public adminPlanPhasesControllerFindAll(page?: number, limit?: number, planId?: string, options?: AxiosRequestConfig) {
        return AdminPanelPhasesInThePlanApiFp(this.configuration).adminPlanPhasesControllerFindAll(page, limit, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single PlanPhase
     * @param {string} id 
     * @param {AdminUpdatePlanPhaseDto} adminUpdatePlanPhaseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelPhasesInThePlanApi
     */
    public adminPlanPhasesControllerUpdatePlanPhase(id: string, adminUpdatePlanPhaseDto: AdminUpdatePlanPhaseDto, options?: AxiosRequestConfig) {
        return AdminPanelPhasesInThePlanApiFp(this.configuration).adminPlanPhasesControllerUpdatePlanPhase(id, adminUpdatePlanPhaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single PlanPhase
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelPhasesInThePlanApi
     */
    public getOneBaseAdminPlanPhasesControllerPlanPhase(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelPhasesInThePlanApiFp(this.configuration).getOneBaseAdminPlanPhasesControllerPlanPhase(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelPlansApi - axios parameter creator
 * @export
 */
export const AdminPanelPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple Plans
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlansControllerFindAll: async (page?: number, limit?: number, courseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Plan
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminPlansControllerPlan: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminPlansControllerPlan', 'id', id)
            const localVarPath = `/admin/plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Plan
         * @param {string} id 
         * @param {AdminUpdatePlanDto} adminUpdatePlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminPlansControllerPlan: async (id: string, adminUpdatePlanDto: AdminUpdatePlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminPlansControllerPlan', 'id', id)
            // verify required parameter 'adminUpdatePlanDto' is not null or undefined
            assertParamExists('updateOneBaseAdminPlansControllerPlan', 'adminUpdatePlanDto', adminUpdatePlanDto)
            const localVarPath = `/admin/plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdatePlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelPlansApi - functional programming interface
 * @export
 */
export const AdminPanelPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Plans
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlansControllerFindAll(page?: number, limit?: number, courseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPlansDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlansControllerFindAll(page, limit, courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Plan
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminPlansControllerPlan(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminPlansControllerPlan(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Plan
         * @param {string} id 
         * @param {AdminUpdatePlanDto} adminUpdatePlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminPlansControllerPlan(id: string, adminUpdatePlanDto: AdminUpdatePlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminPlansControllerPlan(id, adminUpdatePlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelPlansApi - factory interface
 * @export
 */
export const AdminPanelPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelPlansApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Plans
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlansControllerFindAll(page?: number, limit?: number, courseId?: string, options?: any): AxiosPromise<AdminPlansDto> {
            return localVarFp.adminPlansControllerFindAll(page, limit, courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Plan
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminPlansControllerPlan(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminPlanDto> {
            return localVarFp.getOneBaseAdminPlansControllerPlan(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Plan
         * @param {string} id 
         * @param {AdminUpdatePlanDto} adminUpdatePlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminPlansControllerPlan(id: string, adminUpdatePlanDto: AdminUpdatePlanDto, options?: any): AxiosPromise<AdminPlanDto> {
            return localVarFp.updateOneBaseAdminPlansControllerPlan(id, adminUpdatePlanDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelPlansApi - object-oriented interface
 * @export
 * @class AdminPanelPlansApi
 * @extends {BaseAPI}
 */
export class AdminPanelPlansApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple Plans
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [courseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelPlansApi
     */
    public adminPlansControllerFindAll(page?: number, limit?: number, courseId?: string, options?: AxiosRequestConfig) {
        return AdminPanelPlansApiFp(this.configuration).adminPlansControllerFindAll(page, limit, courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Plan
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelPlansApi
     */
    public getOneBaseAdminPlansControllerPlan(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelPlansApiFp(this.configuration).getOneBaseAdminPlansControllerPlan(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Plan
     * @param {string} id 
     * @param {AdminUpdatePlanDto} adminUpdatePlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelPlansApi
     */
    public updateOneBaseAdminPlansControllerPlan(id: string, adminUpdatePlanDto: AdminUpdatePlanDto, options?: AxiosRequestConfig) {
        return AdminPanelPlansApiFp(this.configuration).updateOneBaseAdminPlansControllerPlan(id, adminUpdatePlanDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelQuestionChaptersApi - axios parameter creator
 * @export
 */
export const AdminPanelQuestionChaptersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Merge chapters into one
         * @param {AdminMergeQuestionChaptersRequestDto} adminMergeQuestionChaptersRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminQuestionChaptersControllerMergeChapters: async (adminMergeQuestionChaptersRequestDto: AdminMergeQuestionChaptersRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminMergeQuestionChaptersRequestDto' is not null or undefined
            assertParamExists('adminQuestionChaptersControllerMergeChapters', 'adminMergeQuestionChaptersRequestDto', adminMergeQuestionChaptersRequestDto)
            const localVarPath = `/admin/question-chapters/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminMergeQuestionChaptersRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single QuestionChapter
         * @param {AdminCreateQuestionChapterRequestDto} adminCreateQuestionChapterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminQuestionChaptersControllerQuestionChapter: async (adminCreateQuestionChapterRequestDto: AdminCreateQuestionChapterRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateQuestionChapterRequestDto' is not null or undefined
            assertParamExists('createOneBaseAdminQuestionChaptersControllerQuestionChapter', 'adminCreateQuestionChapterRequestDto', adminCreateQuestionChapterRequestDto)
            const localVarPath = `/admin/question-chapters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateQuestionChapterRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single QuestionChapter
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminQuestionChaptersControllerQuestionChapter: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminQuestionChaptersControllerQuestionChapter', 'id', id)
            const localVarPath = `/admin/question-chapters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple QuestionChapters
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminQuestionChaptersControllerQuestionChapter: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/question-chapters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single QuestionChapter
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminQuestionChaptersControllerQuestionChapter: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminQuestionChaptersControllerQuestionChapter', 'id', id)
            const localVarPath = `/admin/question-chapters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single QuestionChapter
         * @param {string} id 
         * @param {AdminUpdateQuestionChapterRequestDto} adminUpdateQuestionChapterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminQuestionChaptersControllerQuestionChapter: async (id: string, adminUpdateQuestionChapterRequestDto: AdminUpdateQuestionChapterRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminQuestionChaptersControllerQuestionChapter', 'id', id)
            // verify required parameter 'adminUpdateQuestionChapterRequestDto' is not null or undefined
            assertParamExists('updateOneBaseAdminQuestionChaptersControllerQuestionChapter', 'adminUpdateQuestionChapterRequestDto', adminUpdateQuestionChapterRequestDto)
            const localVarPath = `/admin/question-chapters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateQuestionChapterRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelQuestionChaptersApi - functional programming interface
 * @export
 */
export const AdminPanelQuestionChaptersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelQuestionChaptersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Merge chapters into one
         * @param {AdminMergeQuestionChaptersRequestDto} adminMergeQuestionChaptersRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminQuestionChaptersControllerMergeChapters(adminMergeQuestionChaptersRequestDto: AdminMergeQuestionChaptersRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuestionChapterResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminQuestionChaptersControllerMergeChapters(adminMergeQuestionChaptersRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single QuestionChapter
         * @param {AdminCreateQuestionChapterRequestDto} adminCreateQuestionChapterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminQuestionChaptersControllerQuestionChapter(adminCreateQuestionChapterRequestDto: AdminCreateQuestionChapterRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuestionChapterResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminQuestionChaptersControllerQuestionChapter(adminCreateQuestionChapterRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single QuestionChapter
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminQuestionChaptersControllerQuestionChapter(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminQuestionChaptersControllerQuestionChapter(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple QuestionChapters
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminQuestionChaptersControllerQuestionChapter(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedQuestionChaptersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminQuestionChaptersControllerQuestionChapter(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single QuestionChapter
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminQuestionChaptersControllerQuestionChapter(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuestionChapterResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminQuestionChaptersControllerQuestionChapter(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single QuestionChapter
         * @param {string} id 
         * @param {AdminUpdateQuestionChapterRequestDto} adminUpdateQuestionChapterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminQuestionChaptersControllerQuestionChapter(id: string, adminUpdateQuestionChapterRequestDto: AdminUpdateQuestionChapterRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuestionChapterResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminQuestionChaptersControllerQuestionChapter(id, adminUpdateQuestionChapterRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelQuestionChaptersApi - factory interface
 * @export
 */
export const AdminPanelQuestionChaptersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelQuestionChaptersApiFp(configuration)
    return {
        /**
         * 
         * @summary Merge chapters into one
         * @param {AdminMergeQuestionChaptersRequestDto} adminMergeQuestionChaptersRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminQuestionChaptersControllerMergeChapters(adminMergeQuestionChaptersRequestDto: AdminMergeQuestionChaptersRequestDto, options?: any): AxiosPromise<AdminQuestionChapterResponseDto> {
            return localVarFp.adminQuestionChaptersControllerMergeChapters(adminMergeQuestionChaptersRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single QuestionChapter
         * @param {AdminCreateQuestionChapterRequestDto} adminCreateQuestionChapterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminQuestionChaptersControllerQuestionChapter(adminCreateQuestionChapterRequestDto: AdminCreateQuestionChapterRequestDto, options?: any): AxiosPromise<AdminQuestionChapterResponseDto> {
            return localVarFp.createOneBaseAdminQuestionChaptersControllerQuestionChapter(adminCreateQuestionChapterRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single QuestionChapter
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminQuestionChaptersControllerQuestionChapter(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminQuestionChaptersControllerQuestionChapter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple QuestionChapters
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminQuestionChaptersControllerQuestionChapter(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedQuestionChaptersResponseDto> {
            return localVarFp.getManyBaseAdminQuestionChaptersControllerQuestionChapter(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single QuestionChapter
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminQuestionChaptersControllerQuestionChapter(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminQuestionChapterResponseDto> {
            return localVarFp.getOneBaseAdminQuestionChaptersControllerQuestionChapter(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single QuestionChapter
         * @param {string} id 
         * @param {AdminUpdateQuestionChapterRequestDto} adminUpdateQuestionChapterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminQuestionChaptersControllerQuestionChapter(id: string, adminUpdateQuestionChapterRequestDto: AdminUpdateQuestionChapterRequestDto, options?: any): AxiosPromise<AdminQuestionChapterResponseDto> {
            return localVarFp.updateOneBaseAdminQuestionChaptersControllerQuestionChapter(id, adminUpdateQuestionChapterRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelQuestionChaptersApi - object-oriented interface
 * @export
 * @class AdminPanelQuestionChaptersApi
 * @extends {BaseAPI}
 */
export class AdminPanelQuestionChaptersApi extends BaseAPI {
    /**
     * 
     * @summary Merge chapters into one
     * @param {AdminMergeQuestionChaptersRequestDto} adminMergeQuestionChaptersRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionChaptersApi
     */
    public adminQuestionChaptersControllerMergeChapters(adminMergeQuestionChaptersRequestDto: AdminMergeQuestionChaptersRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelQuestionChaptersApiFp(this.configuration).adminQuestionChaptersControllerMergeChapters(adminMergeQuestionChaptersRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single QuestionChapter
     * @param {AdminCreateQuestionChapterRequestDto} adminCreateQuestionChapterRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionChaptersApi
     */
    public createOneBaseAdminQuestionChaptersControllerQuestionChapter(adminCreateQuestionChapterRequestDto: AdminCreateQuestionChapterRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelQuestionChaptersApiFp(this.configuration).createOneBaseAdminQuestionChaptersControllerQuestionChapter(adminCreateQuestionChapterRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single QuestionChapter
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionChaptersApi
     */
    public deleteOneBaseAdminQuestionChaptersControllerQuestionChapter(id: string, options?: AxiosRequestConfig) {
        return AdminPanelQuestionChaptersApiFp(this.configuration).deleteOneBaseAdminQuestionChaptersControllerQuestionChapter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple QuestionChapters
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionChaptersApi
     */
    public getManyBaseAdminQuestionChaptersControllerQuestionChapter(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelQuestionChaptersApiFp(this.configuration).getManyBaseAdminQuestionChaptersControllerQuestionChapter(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single QuestionChapter
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionChaptersApi
     */
    public getOneBaseAdminQuestionChaptersControllerQuestionChapter(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelQuestionChaptersApiFp(this.configuration).getOneBaseAdminQuestionChaptersControllerQuestionChapter(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single QuestionChapter
     * @param {string} id 
     * @param {AdminUpdateQuestionChapterRequestDto} adminUpdateQuestionChapterRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionChaptersApi
     */
    public updateOneBaseAdminQuestionChaptersControllerQuestionChapter(id: string, adminUpdateQuestionChapterRequestDto: AdminUpdateQuestionChapterRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelQuestionChaptersApiFp(this.configuration).updateOneBaseAdminQuestionChaptersControllerQuestionChapter(id, adminUpdateQuestionChapterRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelQuestionDomainsApi - axios parameter creator
 * @export
 */
export const AdminPanelQuestionDomainsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Merge domains into one
         * @param {AdminMergeQuestionDomainsRequestDto} adminMergeQuestionDomainsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminQuestionDomainsControllerMergeChapters: async (adminMergeQuestionDomainsRequestDto: AdminMergeQuestionDomainsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminMergeQuestionDomainsRequestDto' is not null or undefined
            assertParamExists('adminQuestionDomainsControllerMergeChapters', 'adminMergeQuestionDomainsRequestDto', adminMergeQuestionDomainsRequestDto)
            const localVarPath = `/admin/question-domains/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminMergeQuestionDomainsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single QuestionDomain
         * @param {AdminCreateQuestionDomainRequestDto} adminCreateQuestionDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminQuestionDomainsControllerQuestionDomain: async (adminCreateQuestionDomainRequestDto: AdminCreateQuestionDomainRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateQuestionDomainRequestDto' is not null or undefined
            assertParamExists('createOneBaseAdminQuestionDomainsControllerQuestionDomain', 'adminCreateQuestionDomainRequestDto', adminCreateQuestionDomainRequestDto)
            const localVarPath = `/admin/question-domains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateQuestionDomainRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single QuestionDomain
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminQuestionDomainsControllerQuestionDomain: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminQuestionDomainsControllerQuestionDomain', 'id', id)
            const localVarPath = `/admin/question-domains/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple QuestionDomains
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminQuestionDomainsControllerQuestionDomain: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/question-domains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single QuestionDomain
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminQuestionDomainsControllerQuestionDomain: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminQuestionDomainsControllerQuestionDomain', 'id', id)
            const localVarPath = `/admin/question-domains/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single QuestionDomain
         * @param {string} id 
         * @param {AdminUpdateQuestionDomainRequestDto} adminUpdateQuestionDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminQuestionDomainsControllerQuestionDomain: async (id: string, adminUpdateQuestionDomainRequestDto: AdminUpdateQuestionDomainRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminQuestionDomainsControllerQuestionDomain', 'id', id)
            // verify required parameter 'adminUpdateQuestionDomainRequestDto' is not null or undefined
            assertParamExists('updateOneBaseAdminQuestionDomainsControllerQuestionDomain', 'adminUpdateQuestionDomainRequestDto', adminUpdateQuestionDomainRequestDto)
            const localVarPath = `/admin/question-domains/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateQuestionDomainRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelQuestionDomainsApi - functional programming interface
 * @export
 */
export const AdminPanelQuestionDomainsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelQuestionDomainsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Merge domains into one
         * @param {AdminMergeQuestionDomainsRequestDto} adminMergeQuestionDomainsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminQuestionDomainsControllerMergeChapters(adminMergeQuestionDomainsRequestDto: AdminMergeQuestionDomainsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuestionDomainResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminQuestionDomainsControllerMergeChapters(adminMergeQuestionDomainsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single QuestionDomain
         * @param {AdminCreateQuestionDomainRequestDto} adminCreateQuestionDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminQuestionDomainsControllerQuestionDomain(adminCreateQuestionDomainRequestDto: AdminCreateQuestionDomainRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuestionDomainResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminQuestionDomainsControllerQuestionDomain(adminCreateQuestionDomainRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single QuestionDomain
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminQuestionDomainsControllerQuestionDomain(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminQuestionDomainsControllerQuestionDomain(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple QuestionDomains
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminQuestionDomainsControllerQuestionDomain(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedQuestionDomainsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminQuestionDomainsControllerQuestionDomain(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single QuestionDomain
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminQuestionDomainsControllerQuestionDomain(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuestionDomainResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminQuestionDomainsControllerQuestionDomain(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single QuestionDomain
         * @param {string} id 
         * @param {AdminUpdateQuestionDomainRequestDto} adminUpdateQuestionDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminQuestionDomainsControllerQuestionDomain(id: string, adminUpdateQuestionDomainRequestDto: AdminUpdateQuestionDomainRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuestionDomainResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminQuestionDomainsControllerQuestionDomain(id, adminUpdateQuestionDomainRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelQuestionDomainsApi - factory interface
 * @export
 */
export const AdminPanelQuestionDomainsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelQuestionDomainsApiFp(configuration)
    return {
        /**
         * 
         * @summary Merge domains into one
         * @param {AdminMergeQuestionDomainsRequestDto} adminMergeQuestionDomainsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminQuestionDomainsControllerMergeChapters(adminMergeQuestionDomainsRequestDto: AdminMergeQuestionDomainsRequestDto, options?: any): AxiosPromise<AdminQuestionDomainResponseDto> {
            return localVarFp.adminQuestionDomainsControllerMergeChapters(adminMergeQuestionDomainsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single QuestionDomain
         * @param {AdminCreateQuestionDomainRequestDto} adminCreateQuestionDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminQuestionDomainsControllerQuestionDomain(adminCreateQuestionDomainRequestDto: AdminCreateQuestionDomainRequestDto, options?: any): AxiosPromise<AdminQuestionDomainResponseDto> {
            return localVarFp.createOneBaseAdminQuestionDomainsControllerQuestionDomain(adminCreateQuestionDomainRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single QuestionDomain
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminQuestionDomainsControllerQuestionDomain(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminQuestionDomainsControllerQuestionDomain(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple QuestionDomains
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminQuestionDomainsControllerQuestionDomain(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedQuestionDomainsResponseDto> {
            return localVarFp.getManyBaseAdminQuestionDomainsControllerQuestionDomain(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single QuestionDomain
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminQuestionDomainsControllerQuestionDomain(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminQuestionDomainResponseDto> {
            return localVarFp.getOneBaseAdminQuestionDomainsControllerQuestionDomain(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single QuestionDomain
         * @param {string} id 
         * @param {AdminUpdateQuestionDomainRequestDto} adminUpdateQuestionDomainRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminQuestionDomainsControllerQuestionDomain(id: string, adminUpdateQuestionDomainRequestDto: AdminUpdateQuestionDomainRequestDto, options?: any): AxiosPromise<AdminQuestionDomainResponseDto> {
            return localVarFp.updateOneBaseAdminQuestionDomainsControllerQuestionDomain(id, adminUpdateQuestionDomainRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelQuestionDomainsApi - object-oriented interface
 * @export
 * @class AdminPanelQuestionDomainsApi
 * @extends {BaseAPI}
 */
export class AdminPanelQuestionDomainsApi extends BaseAPI {
    /**
     * 
     * @summary Merge domains into one
     * @param {AdminMergeQuestionDomainsRequestDto} adminMergeQuestionDomainsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionDomainsApi
     */
    public adminQuestionDomainsControllerMergeChapters(adminMergeQuestionDomainsRequestDto: AdminMergeQuestionDomainsRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelQuestionDomainsApiFp(this.configuration).adminQuestionDomainsControllerMergeChapters(adminMergeQuestionDomainsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single QuestionDomain
     * @param {AdminCreateQuestionDomainRequestDto} adminCreateQuestionDomainRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionDomainsApi
     */
    public createOneBaseAdminQuestionDomainsControllerQuestionDomain(adminCreateQuestionDomainRequestDto: AdminCreateQuestionDomainRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelQuestionDomainsApiFp(this.configuration).createOneBaseAdminQuestionDomainsControllerQuestionDomain(adminCreateQuestionDomainRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single QuestionDomain
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionDomainsApi
     */
    public deleteOneBaseAdminQuestionDomainsControllerQuestionDomain(id: string, options?: AxiosRequestConfig) {
        return AdminPanelQuestionDomainsApiFp(this.configuration).deleteOneBaseAdminQuestionDomainsControllerQuestionDomain(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple QuestionDomains
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionDomainsApi
     */
    public getManyBaseAdminQuestionDomainsControllerQuestionDomain(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelQuestionDomainsApiFp(this.configuration).getManyBaseAdminQuestionDomainsControllerQuestionDomain(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single QuestionDomain
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionDomainsApi
     */
    public getOneBaseAdminQuestionDomainsControllerQuestionDomain(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelQuestionDomainsApiFp(this.configuration).getOneBaseAdminQuestionDomainsControllerQuestionDomain(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single QuestionDomain
     * @param {string} id 
     * @param {AdminUpdateQuestionDomainRequestDto} adminUpdateQuestionDomainRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionDomainsApi
     */
    public updateOneBaseAdminQuestionDomainsControllerQuestionDomain(id: string, adminUpdateQuestionDomainRequestDto: AdminUpdateQuestionDomainRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelQuestionDomainsApiFp(this.configuration).updateOneBaseAdminQuestionDomainsControllerQuestionDomain(id, adminUpdateQuestionDomainRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelQuestionsApi - axios parameter creator
 * @export
 */
export const AdminPanelQuestionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get presigned url to upload image
         * @param {AdminUploadImageRequestDto} adminUploadImageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNewQuestionsControllerUploadImage: async (adminUploadImageRequestDto: AdminUploadImageRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminUploadImageRequestDto' is not null or undefined
            assertParamExists('adminNewQuestionsControllerUploadImage', 'adminUploadImageRequestDto', adminUploadImageRequestDto)
            const localVarPath = `/admin/questions/upload-image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUploadImageRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single NewQuestion
         * @param {AdminCreateNewQuestionRequestDto} adminCreateNewQuestionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminNewQuestionsControllerNewQuestion: async (adminCreateNewQuestionRequestDto: AdminCreateNewQuestionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateNewQuestionRequestDto' is not null or undefined
            assertParamExists('createOneBaseAdminNewQuestionsControllerNewQuestion', 'adminCreateNewQuestionRequestDto', adminCreateNewQuestionRequestDto)
            const localVarPath = `/admin/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateNewQuestionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single NewQuestion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminNewQuestionsControllerNewQuestion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminNewQuestionsControllerNewQuestion', 'id', id)
            const localVarPath = `/admin/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple NewQuestions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminNewQuestionsControllerNewQuestion: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single NewQuestion
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminNewQuestionsControllerNewQuestion: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminNewQuestionsControllerNewQuestion', 'id', id)
            const localVarPath = `/admin/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single NewQuestion
         * @param {string} id 
         * @param {AdminUpdateNewQuestionRequestDto} adminUpdateNewQuestionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminNewQuestionsControllerNewQuestion: async (id: string, adminUpdateNewQuestionRequestDto: AdminUpdateNewQuestionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminNewQuestionsControllerNewQuestion', 'id', id)
            // verify required parameter 'adminUpdateNewQuestionRequestDto' is not null or undefined
            assertParamExists('updateOneBaseAdminNewQuestionsControllerNewQuestion', 'adminUpdateNewQuestionRequestDto', adminUpdateNewQuestionRequestDto)
            const localVarPath = `/admin/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateNewQuestionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelQuestionsApi - functional programming interface
 * @export
 */
export const AdminPanelQuestionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelQuestionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get presigned url to upload image
         * @param {AdminUploadImageRequestDto} adminUploadImageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNewQuestionsControllerUploadImage(adminUploadImageRequestDto: AdminUploadImageRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSignedUrlResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNewQuestionsControllerUploadImage(adminUploadImageRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single NewQuestion
         * @param {AdminCreateNewQuestionRequestDto} adminCreateNewQuestionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminNewQuestionsControllerNewQuestion(adminCreateNewQuestionRequestDto: AdminCreateNewQuestionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminNewQuestionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminNewQuestionsControllerNewQuestion(adminCreateNewQuestionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single NewQuestion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminNewQuestionsControllerNewQuestion(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminNewQuestionsControllerNewQuestion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple NewQuestions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminNewQuestionsControllerNewQuestion(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedNewQuestionsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminNewQuestionsControllerNewQuestion(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single NewQuestion
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminNewQuestionsControllerNewQuestion(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminNewQuestionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminNewQuestionsControllerNewQuestion(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single NewQuestion
         * @param {string} id 
         * @param {AdminUpdateNewQuestionRequestDto} adminUpdateNewQuestionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminNewQuestionsControllerNewQuestion(id: string, adminUpdateNewQuestionRequestDto: AdminUpdateNewQuestionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminNewQuestionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminNewQuestionsControllerNewQuestion(id, adminUpdateNewQuestionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelQuestionsApi - factory interface
 * @export
 */
export const AdminPanelQuestionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelQuestionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get presigned url to upload image
         * @param {AdminUploadImageRequestDto} adminUploadImageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNewQuestionsControllerUploadImage(adminUploadImageRequestDto: AdminUploadImageRequestDto, options?: any): AxiosPromise<AdminSignedUrlResponseDto> {
            return localVarFp.adminNewQuestionsControllerUploadImage(adminUploadImageRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single NewQuestion
         * @param {AdminCreateNewQuestionRequestDto} adminCreateNewQuestionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminNewQuestionsControllerNewQuestion(adminCreateNewQuestionRequestDto: AdminCreateNewQuestionRequestDto, options?: any): AxiosPromise<AdminNewQuestionResponseDto> {
            return localVarFp.createOneBaseAdminNewQuestionsControllerNewQuestion(adminCreateNewQuestionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single NewQuestion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminNewQuestionsControllerNewQuestion(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminNewQuestionsControllerNewQuestion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple NewQuestions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminNewQuestionsControllerNewQuestion(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedNewQuestionsResponseDto> {
            return localVarFp.getManyBaseAdminNewQuestionsControllerNewQuestion(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single NewQuestion
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminNewQuestionsControllerNewQuestion(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminNewQuestionResponseDto> {
            return localVarFp.getOneBaseAdminNewQuestionsControllerNewQuestion(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single NewQuestion
         * @param {string} id 
         * @param {AdminUpdateNewQuestionRequestDto} adminUpdateNewQuestionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminNewQuestionsControllerNewQuestion(id: string, adminUpdateNewQuestionRequestDto: AdminUpdateNewQuestionRequestDto, options?: any): AxiosPromise<AdminNewQuestionResponseDto> {
            return localVarFp.updateOneBaseAdminNewQuestionsControllerNewQuestion(id, adminUpdateNewQuestionRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelQuestionsApi - object-oriented interface
 * @export
 * @class AdminPanelQuestionsApi
 * @extends {BaseAPI}
 */
export class AdminPanelQuestionsApi extends BaseAPI {
    /**
     * 
     * @summary Get presigned url to upload image
     * @param {AdminUploadImageRequestDto} adminUploadImageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionsApi
     */
    public adminNewQuestionsControllerUploadImage(adminUploadImageRequestDto: AdminUploadImageRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelQuestionsApiFp(this.configuration).adminNewQuestionsControllerUploadImage(adminUploadImageRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single NewQuestion
     * @param {AdminCreateNewQuestionRequestDto} adminCreateNewQuestionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionsApi
     */
    public createOneBaseAdminNewQuestionsControllerNewQuestion(adminCreateNewQuestionRequestDto: AdminCreateNewQuestionRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelQuestionsApiFp(this.configuration).createOneBaseAdminNewQuestionsControllerNewQuestion(adminCreateNewQuestionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single NewQuestion
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionsApi
     */
    public deleteOneBaseAdminNewQuestionsControllerNewQuestion(id: string, options?: AxiosRequestConfig) {
        return AdminPanelQuestionsApiFp(this.configuration).deleteOneBaseAdminNewQuestionsControllerNewQuestion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple NewQuestions
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionsApi
     */
    public getManyBaseAdminNewQuestionsControllerNewQuestion(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelQuestionsApiFp(this.configuration).getManyBaseAdminNewQuestionsControllerNewQuestion(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single NewQuestion
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionsApi
     */
    public getOneBaseAdminNewQuestionsControllerNewQuestion(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelQuestionsApiFp(this.configuration).getOneBaseAdminNewQuestionsControllerNewQuestion(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single NewQuestion
     * @param {string} id 
     * @param {AdminUpdateNewQuestionRequestDto} adminUpdateNewQuestionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuestionsApi
     */
    public updateOneBaseAdminNewQuestionsControllerNewQuestion(id: string, adminUpdateNewQuestionRequestDto: AdminUpdateNewQuestionRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelQuestionsApiFp(this.configuration).updateOneBaseAdminNewQuestionsControllerNewQuestion(id, adminUpdateNewQuestionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelQuizzesApi - axios parameter creator
 * @export
 */
export const AdminPanelQuizzesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single Quiz
         * @param {AdminCreateQuizRequestDto} adminCreateQuizRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminQuizzesControllerQuiz: async (adminCreateQuizRequestDto: AdminCreateQuizRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateQuizRequestDto' is not null or undefined
            assertParamExists('createOneBaseAdminQuizzesControllerQuiz', 'adminCreateQuizRequestDto', adminCreateQuizRequestDto)
            const localVarPath = `/admin/quizzes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateQuizRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Quiz
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminQuizzesControllerQuiz: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminQuizzesControllerQuiz', 'id', id)
            const localVarPath = `/admin/quizzes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Quiz
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminQuizzesControllerQuiz: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminQuizzesControllerQuiz', 'id', id)
            const localVarPath = `/admin/quizzes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Quiz
         * @param {string} id 
         * @param {AdminUpdateQuizRequestDto} adminUpdateQuizRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminQuizzesControllerQuiz: async (id: string, adminUpdateQuizRequestDto: AdminUpdateQuizRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminQuizzesControllerQuiz', 'id', id)
            // verify required parameter 'adminUpdateQuizRequestDto' is not null or undefined
            assertParamExists('updateOneBaseAdminQuizzesControllerQuiz', 'adminUpdateQuizRequestDto', adminUpdateQuizRequestDto)
            const localVarPath = `/admin/quizzes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateQuizRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelQuizzesApi - functional programming interface
 * @export
 */
export const AdminPanelQuizzesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelQuizzesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single Quiz
         * @param {AdminCreateQuizRequestDto} adminCreateQuizRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminQuizzesControllerQuiz(adminCreateQuizRequestDto: AdminCreateQuizRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuizResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminQuizzesControllerQuiz(adminCreateQuizRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Quiz
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminQuizzesControllerQuiz(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminQuizzesControllerQuiz(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Quiz
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminQuizzesControllerQuiz(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuizResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminQuizzesControllerQuiz(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Quiz
         * @param {string} id 
         * @param {AdminUpdateQuizRequestDto} adminUpdateQuizRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminQuizzesControllerQuiz(id: string, adminUpdateQuizRequestDto: AdminUpdateQuizRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminQuizResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminQuizzesControllerQuiz(id, adminUpdateQuizRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelQuizzesApi - factory interface
 * @export
 */
export const AdminPanelQuizzesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelQuizzesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single Quiz
         * @param {AdminCreateQuizRequestDto} adminCreateQuizRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminQuizzesControllerQuiz(adminCreateQuizRequestDto: AdminCreateQuizRequestDto, options?: any): AxiosPromise<AdminQuizResponseDto> {
            return localVarFp.createOneBaseAdminQuizzesControllerQuiz(adminCreateQuizRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Quiz
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminQuizzesControllerQuiz(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminQuizzesControllerQuiz(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Quiz
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminQuizzesControllerQuiz(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminQuizResponseDto> {
            return localVarFp.getOneBaseAdminQuizzesControllerQuiz(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Quiz
         * @param {string} id 
         * @param {AdminUpdateQuizRequestDto} adminUpdateQuizRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminQuizzesControllerQuiz(id: string, adminUpdateQuizRequestDto: AdminUpdateQuizRequestDto, options?: any): AxiosPromise<AdminQuizResponseDto> {
            return localVarFp.updateOneBaseAdminQuizzesControllerQuiz(id, adminUpdateQuizRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelQuizzesApi - object-oriented interface
 * @export
 * @class AdminPanelQuizzesApi
 * @extends {BaseAPI}
 */
export class AdminPanelQuizzesApi extends BaseAPI {
    /**
     * 
     * @summary Create a single Quiz
     * @param {AdminCreateQuizRequestDto} adminCreateQuizRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuizzesApi
     */
    public createOneBaseAdminQuizzesControllerQuiz(adminCreateQuizRequestDto: AdminCreateQuizRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelQuizzesApiFp(this.configuration).createOneBaseAdminQuizzesControllerQuiz(adminCreateQuizRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Quiz
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuizzesApi
     */
    public deleteOneBaseAdminQuizzesControllerQuiz(id: string, options?: AxiosRequestConfig) {
        return AdminPanelQuizzesApiFp(this.configuration).deleteOneBaseAdminQuizzesControllerQuiz(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Quiz
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuizzesApi
     */
    public getOneBaseAdminQuizzesControllerQuiz(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelQuizzesApiFp(this.configuration).getOneBaseAdminQuizzesControllerQuiz(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Quiz
     * @param {string} id 
     * @param {AdminUpdateQuizRequestDto} adminUpdateQuizRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelQuizzesApi
     */
    public updateOneBaseAdminQuizzesControllerQuiz(id: string, adminUpdateQuizRequestDto: AdminUpdateQuizRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelQuizzesApiFp(this.configuration).updateOneBaseAdminQuizzesControllerQuiz(id, adminUpdateQuizRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelReportsApi - axios parameter creator
 * @export
 */
export const AdminPanelReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete all resolved reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReportsControllerDeleteResolved: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/reports/resolved`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark report as unresolved
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReportsControllerMarkUnresolved: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminReportsControllerMarkUnresolved', 'id', id)
            const localVarPath = `/admin/reports/{id}/unresolve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resolve report
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReportsControllerResolve: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminReportsControllerResolve', 'id', id)
            const localVarPath = `/admin/reports/{id}/resolve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Report
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminReportsControllerReport: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminReportsControllerReport', 'id', id)
            const localVarPath = `/admin/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Reports
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminReportsControllerReport: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Report
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminReportsControllerReport: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminReportsControllerReport', 'id', id)
            const localVarPath = `/admin/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelReportsApi - functional programming interface
 * @export
 */
export const AdminPanelReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete all resolved reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReportsControllerDeleteResolved(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminReportsControllerDeleteResolved200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReportsControllerDeleteResolved(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark report as unresolved
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReportsControllerMarkUnresolved(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReportsControllerMarkUnresolved(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resolve report
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReportsControllerResolve(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReportsControllerResolve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Report
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminReportsControllerReport(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminReportsControllerReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Reports
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminReportsControllerReport(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminReportsControllerReport(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Report
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminReportsControllerReport(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminReportsControllerReport(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelReportsApi - factory interface
 * @export
 */
export const AdminPanelReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete all resolved reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReportsControllerDeleteResolved(options?: any): AxiosPromise<AdminReportsControllerDeleteResolved200Response> {
            return localVarFp.adminReportsControllerDeleteResolved(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark report as unresolved
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReportsControllerMarkUnresolved(id: string, options?: any): AxiosPromise<AdminReportDto> {
            return localVarFp.adminReportsControllerMarkUnresolved(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resolve report
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReportsControllerResolve(id: string, options?: any): AxiosPromise<AdminReportDto> {
            return localVarFp.adminReportsControllerResolve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Report
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminReportsControllerReport(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminReportsControllerReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Reports
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminReportsControllerReport(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<Array<AdminReportDto>> {
            return localVarFp.getManyBaseAdminReportsControllerReport(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Report
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminReportsControllerReport(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Report> {
            return localVarFp.getOneBaseAdminReportsControllerReport(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelReportsApi - object-oriented interface
 * @export
 * @class AdminPanelReportsApi
 * @extends {BaseAPI}
 */
export class AdminPanelReportsApi extends BaseAPI {
    /**
     * 
     * @summary Delete all resolved reports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelReportsApi
     */
    public adminReportsControllerDeleteResolved(options?: AxiosRequestConfig) {
        return AdminPanelReportsApiFp(this.configuration).adminReportsControllerDeleteResolved(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark report as unresolved
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelReportsApi
     */
    public adminReportsControllerMarkUnresolved(id: string, options?: AxiosRequestConfig) {
        return AdminPanelReportsApiFp(this.configuration).adminReportsControllerMarkUnresolved(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resolve report
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelReportsApi
     */
    public adminReportsControllerResolve(id: string, options?: AxiosRequestConfig) {
        return AdminPanelReportsApiFp(this.configuration).adminReportsControllerResolve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Report
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelReportsApi
     */
    public deleteOneBaseAdminReportsControllerReport(id: string, options?: AxiosRequestConfig) {
        return AdminPanelReportsApiFp(this.configuration).deleteOneBaseAdminReportsControllerReport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Reports
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelReportsApi
     */
    public getManyBaseAdminReportsControllerReport(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelReportsApiFp(this.configuration).getManyBaseAdminReportsControllerReport(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Report
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelReportsApi
     */
    public getOneBaseAdminReportsControllerReport(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelReportsApiFp(this.configuration).getOneBaseAdminReportsControllerReport(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelSuggestionsApi - axios parameter creator
 * @export
 */
export const AdminPanelSuggestionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Mark as unresolved suggestion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSuggestionControllerMarkAsUnresolved: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSuggestionControllerMarkAsUnresolved', 'id', id)
            const localVarPath = `/admin/suggestions/{id}/unresolve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resolve suggestion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSuggestionControllerResolve: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSuggestionControllerResolve', 'id', id)
            const localVarPath = `/admin/suggestions/{id}/resolve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Suggestions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminSuggestionControllerSuggestion: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/suggestions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Suggestion
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminSuggestionControllerSuggestion: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminSuggestionControllerSuggestion', 'id', id)
            const localVarPath = `/admin/suggestions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelSuggestionsApi - functional programming interface
 * @export
 */
export const AdminPanelSuggestionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelSuggestionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Mark as unresolved suggestion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSuggestionControllerMarkAsUnresolved(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSuggestionControllerMarkAsUnresolved(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resolve suggestion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSuggestionControllerResolve(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSuggestionControllerResolve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Suggestions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminSuggestionControllerSuggestion(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManySuggestionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminSuggestionControllerSuggestion(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Suggestion
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminSuggestionControllerSuggestion(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSuggestionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminSuggestionControllerSuggestion(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelSuggestionsApi - factory interface
 * @export
 */
export const AdminPanelSuggestionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelSuggestionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Mark as unresolved suggestion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSuggestionControllerMarkAsUnresolved(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminSuggestionControllerMarkAsUnresolved(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resolve suggestion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSuggestionControllerResolve(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminSuggestionControllerResolve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Suggestions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminSuggestionControllerSuggestion(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManySuggestionResponseDto> {
            return localVarFp.getManyBaseAdminSuggestionControllerSuggestion(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Suggestion
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminSuggestionControllerSuggestion(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminSuggestionResponseDto> {
            return localVarFp.getOneBaseAdminSuggestionControllerSuggestion(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelSuggestionsApi - object-oriented interface
 * @export
 * @class AdminPanelSuggestionsApi
 * @extends {BaseAPI}
 */
export class AdminPanelSuggestionsApi extends BaseAPI {
    /**
     * 
     * @summary Mark as unresolved suggestion
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelSuggestionsApi
     */
    public adminSuggestionControllerMarkAsUnresolved(id: string, options?: AxiosRequestConfig) {
        return AdminPanelSuggestionsApiFp(this.configuration).adminSuggestionControllerMarkAsUnresolved(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resolve suggestion
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelSuggestionsApi
     */
    public adminSuggestionControllerResolve(id: string, options?: AxiosRequestConfig) {
        return AdminPanelSuggestionsApiFp(this.configuration).adminSuggestionControllerResolve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Suggestions
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelSuggestionsApi
     */
    public getManyBaseAdminSuggestionControllerSuggestion(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelSuggestionsApiFp(this.configuration).getManyBaseAdminSuggestionControllerSuggestion(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Suggestion
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelSuggestionsApi
     */
    public getOneBaseAdminSuggestionControllerSuggestion(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelSuggestionsApiFp(this.configuration).getOneBaseAdminSuggestionControllerSuggestion(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelTasksApi - axios parameter creator
 * @export
 */
export const AdminPanelTasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create multiple Tasks
         * @param {CreateManyTaskDto} createManyTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyBaseAdminTasksControllerTask: async (createManyTaskDto: CreateManyTaskDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyTaskDto' is not null or undefined
            assertParamExists('createManyBaseAdminTasksControllerTask', 'createManyTaskDto', createManyTaskDto)
            const localVarPath = `/admin/tasks/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createManyTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Task
         * @param {AdminCreateTaskDto} adminCreateTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminTasksControllerTask: async (adminCreateTaskDto: AdminCreateTaskDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateTaskDto' is not null or undefined
            assertParamExists('createOneBaseAdminTasksControllerTask', 'adminCreateTaskDto', adminCreateTaskDto)
            const localVarPath = `/admin/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Task
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminTasksControllerTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminTasksControllerTask', 'id', id)
            const localVarPath = `/admin/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Tasks
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminTasksControllerTask: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Task
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminTasksControllerTask: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminTasksControllerTask', 'id', id)
            const localVarPath = `/admin/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Task
         * @param {string} id 
         * @param {AdminUpdateTaskDto} adminUpdateTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminTasksControllerTask: async (id: string, adminUpdateTaskDto: AdminUpdateTaskDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminTasksControllerTask', 'id', id)
            // verify required parameter 'adminUpdateTaskDto' is not null or undefined
            assertParamExists('updateOneBaseAdminTasksControllerTask', 'adminUpdateTaskDto', adminUpdateTaskDto)
            const localVarPath = `/admin/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelTasksApi - functional programming interface
 * @export
 */
export const AdminPanelTasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelTasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create multiple Tasks
         * @param {CreateManyTaskDto} createManyTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyBaseAdminTasksControllerTask(createManyTaskDto: CreateManyTaskDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminTaskDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManyBaseAdminTasksControllerTask(createManyTaskDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single Task
         * @param {AdminCreateTaskDto} adminCreateTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminTasksControllerTask(adminCreateTaskDto: AdminCreateTaskDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminTasksControllerTask(adminCreateTaskDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Task
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminTasksControllerTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminTasksControllerTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Tasks
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminTasksControllerTask(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedTasksDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminTasksControllerTask(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Task
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminTasksControllerTask(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminTasksControllerTask(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Task
         * @param {string} id 
         * @param {AdminUpdateTaskDto} adminUpdateTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminTasksControllerTask(id: string, adminUpdateTaskDto: AdminUpdateTaskDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminTasksControllerTask(id, adminUpdateTaskDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelTasksApi - factory interface
 * @export
 */
export const AdminPanelTasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelTasksApiFp(configuration)
    return {
        /**
         * 
         * @summary Create multiple Tasks
         * @param {CreateManyTaskDto} createManyTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyBaseAdminTasksControllerTask(createManyTaskDto: CreateManyTaskDto, options?: any): AxiosPromise<Array<AdminTaskDto>> {
            return localVarFp.createManyBaseAdminTasksControllerTask(createManyTaskDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Task
         * @param {AdminCreateTaskDto} adminCreateTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminTasksControllerTask(adminCreateTaskDto: AdminCreateTaskDto, options?: any): AxiosPromise<AdminTaskDto> {
            return localVarFp.createOneBaseAdminTasksControllerTask(adminCreateTaskDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Task
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminTasksControllerTask(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminTasksControllerTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Tasks
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminTasksControllerTask(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedTasksDto> {
            return localVarFp.getManyBaseAdminTasksControllerTask(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Task
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminTasksControllerTask(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminTaskDto> {
            return localVarFp.getOneBaseAdminTasksControllerTask(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Task
         * @param {string} id 
         * @param {AdminUpdateTaskDto} adminUpdateTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminTasksControllerTask(id: string, adminUpdateTaskDto: AdminUpdateTaskDto, options?: any): AxiosPromise<AdminTaskDto> {
            return localVarFp.updateOneBaseAdminTasksControllerTask(id, adminUpdateTaskDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelTasksApi - object-oriented interface
 * @export
 * @class AdminPanelTasksApi
 * @extends {BaseAPI}
 */
export class AdminPanelTasksApi extends BaseAPI {
    /**
     * 
     * @summary Create multiple Tasks
     * @param {CreateManyTaskDto} createManyTaskDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTasksApi
     */
    public createManyBaseAdminTasksControllerTask(createManyTaskDto: CreateManyTaskDto, options?: AxiosRequestConfig) {
        return AdminPanelTasksApiFp(this.configuration).createManyBaseAdminTasksControllerTask(createManyTaskDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Task
     * @param {AdminCreateTaskDto} adminCreateTaskDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTasksApi
     */
    public createOneBaseAdminTasksControllerTask(adminCreateTaskDto: AdminCreateTaskDto, options?: AxiosRequestConfig) {
        return AdminPanelTasksApiFp(this.configuration).createOneBaseAdminTasksControllerTask(adminCreateTaskDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Task
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTasksApi
     */
    public deleteOneBaseAdminTasksControllerTask(id: string, options?: AxiosRequestConfig) {
        return AdminPanelTasksApiFp(this.configuration).deleteOneBaseAdminTasksControllerTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Tasks
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTasksApi
     */
    public getManyBaseAdminTasksControllerTask(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelTasksApiFp(this.configuration).getManyBaseAdminTasksControllerTask(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Task
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTasksApi
     */
    public getOneBaseAdminTasksControllerTask(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelTasksApiFp(this.configuration).getOneBaseAdminTasksControllerTask(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Task
     * @param {string} id 
     * @param {AdminUpdateTaskDto} adminUpdateTaskDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTasksApi
     */
    public updateOneBaseAdminTasksControllerTask(id: string, adminUpdateTaskDto: AdminUpdateTaskDto, options?: AxiosRequestConfig) {
        return AdminPanelTasksApiFp(this.configuration).updateOneBaseAdminTasksControllerTask(id, adminUpdateTaskDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelTasksInThePhasesInThePlanApi - axios parameter creator
 * @export
 */
export const AdminPanelTasksInThePhasesInThePlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single PlanPhaseTask
         * @param {AdminCreatePlanPhaseTaskDto} adminCreatePlanPhaseTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhaseTasksControllerCreatePlanPhaseTask: async (adminCreatePlanPhaseTaskDto: AdminCreatePlanPhaseTaskDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreatePlanPhaseTaskDto' is not null or undefined
            assertParamExists('adminPlanPhaseTasksControllerCreatePlanPhaseTask', 'adminCreatePlanPhaseTaskDto', adminCreatePlanPhaseTaskDto)
            const localVarPath = `/admin/phase-tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreatePlanPhaseTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single PlanPhaseTask
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhaseTasksControllerDeletePlanPhaseTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminPlanPhaseTasksControllerDeletePlanPhaseTask', 'id', id)
            const localVarPath = `/admin/phase-tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple PlanPhaseTasks
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [planPhaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhaseTasksControllerFindAll: async (page?: number, limit?: number, planPhaseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/phase-tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (planPhaseId !== undefined) {
                localVarQueryParameter['planPhaseId'] = planPhaseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single PlanPhaseTask
         * @param {string} id 
         * @param {AdminUpdatePlanPhaseTaskDto} adminUpdatePlanPhaseTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhaseTasksControllerUpdatePlanPhaseTask: async (id: string, adminUpdatePlanPhaseTaskDto: AdminUpdatePlanPhaseTaskDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminPlanPhaseTasksControllerUpdatePlanPhaseTask', 'id', id)
            // verify required parameter 'adminUpdatePlanPhaseTaskDto' is not null or undefined
            assertParamExists('adminPlanPhaseTasksControllerUpdatePlanPhaseTask', 'adminUpdatePlanPhaseTaskDto', adminUpdatePlanPhaseTaskDto)
            const localVarPath = `/admin/phase-tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdatePlanPhaseTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single PlanPhaseTask
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminPlanPhaseTasksControllerPlanPhaseTask: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminPlanPhaseTasksControllerPlanPhaseTask', 'id', id)
            const localVarPath = `/admin/phase-tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelTasksInThePhasesInThePlanApi - functional programming interface
 * @export
 */
export const AdminPanelTasksInThePhasesInThePlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelTasksInThePhasesInThePlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single PlanPhaseTask
         * @param {AdminCreatePlanPhaseTaskDto} adminCreatePlanPhaseTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlanPhaseTasksControllerCreatePlanPhaseTask(adminCreatePlanPhaseTaskDto: AdminCreatePlanPhaseTaskDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPlanPhaseTaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlanPhaseTasksControllerCreatePlanPhaseTask(adminCreatePlanPhaseTaskDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single PlanPhaseTask
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlanPhaseTasksControllerDeletePlanPhaseTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlanPhaseTasksControllerDeletePlanPhaseTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple PlanPhaseTasks
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [planPhaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlanPhaseTasksControllerFindAll(page?: number, limit?: number, planPhaseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetManyPlanPhaseTasksResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlanPhaseTasksControllerFindAll(page, limit, planPhaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single PlanPhaseTask
         * @param {string} id 
         * @param {AdminUpdatePlanPhaseTaskDto} adminUpdatePlanPhaseTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlanPhaseTasksControllerUpdatePlanPhaseTask(id: string, adminUpdatePlanPhaseTaskDto: AdminUpdatePlanPhaseTaskDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPlanPhaseTaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlanPhaseTasksControllerUpdatePlanPhaseTask(id, adminUpdatePlanPhaseTaskDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single PlanPhaseTask
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminPlanPhaseTasksControllerPlanPhaseTask(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanPhaseTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminPlanPhaseTasksControllerPlanPhaseTask(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelTasksInThePhasesInThePlanApi - factory interface
 * @export
 */
export const AdminPanelTasksInThePhasesInThePlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelTasksInThePhasesInThePlanApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single PlanPhaseTask
         * @param {AdminCreatePlanPhaseTaskDto} adminCreatePlanPhaseTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhaseTasksControllerCreatePlanPhaseTask(adminCreatePlanPhaseTaskDto: AdminCreatePlanPhaseTaskDto, options?: any): AxiosPromise<AdminPlanPhaseTaskDto> {
            return localVarFp.adminPlanPhaseTasksControllerCreatePlanPhaseTask(adminCreatePlanPhaseTaskDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single PlanPhaseTask
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhaseTasksControllerDeletePlanPhaseTask(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminPlanPhaseTasksControllerDeletePlanPhaseTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple PlanPhaseTasks
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [planPhaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhaseTasksControllerFindAll(page?: number, limit?: number, planPhaseId?: string, options?: any): AxiosPromise<AdminGetManyPlanPhaseTasksResponseDto> {
            return localVarFp.adminPlanPhaseTasksControllerFindAll(page, limit, planPhaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single PlanPhaseTask
         * @param {string} id 
         * @param {AdminUpdatePlanPhaseTaskDto} adminUpdatePlanPhaseTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlanPhaseTasksControllerUpdatePlanPhaseTask(id: string, adminUpdatePlanPhaseTaskDto: AdminUpdatePlanPhaseTaskDto, options?: any): AxiosPromise<AdminPlanPhaseTaskDto> {
            return localVarFp.adminPlanPhaseTasksControllerUpdatePlanPhaseTask(id, adminUpdatePlanPhaseTaskDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single PlanPhaseTask
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminPlanPhaseTasksControllerPlanPhaseTask(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<PlanPhaseTask> {
            return localVarFp.getOneBaseAdminPlanPhaseTasksControllerPlanPhaseTask(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelTasksInThePhasesInThePlanApi - object-oriented interface
 * @export
 * @class AdminPanelTasksInThePhasesInThePlanApi
 * @extends {BaseAPI}
 */
export class AdminPanelTasksInThePhasesInThePlanApi extends BaseAPI {
    /**
     * 
     * @summary Create a single PlanPhaseTask
     * @param {AdminCreatePlanPhaseTaskDto} adminCreatePlanPhaseTaskDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTasksInThePhasesInThePlanApi
     */
    public adminPlanPhaseTasksControllerCreatePlanPhaseTask(adminCreatePlanPhaseTaskDto: AdminCreatePlanPhaseTaskDto, options?: AxiosRequestConfig) {
        return AdminPanelTasksInThePhasesInThePlanApiFp(this.configuration).adminPlanPhaseTasksControllerCreatePlanPhaseTask(adminCreatePlanPhaseTaskDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single PlanPhaseTask
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTasksInThePhasesInThePlanApi
     */
    public adminPlanPhaseTasksControllerDeletePlanPhaseTask(id: string, options?: AxiosRequestConfig) {
        return AdminPanelTasksInThePhasesInThePlanApiFp(this.configuration).adminPlanPhaseTasksControllerDeletePlanPhaseTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple PlanPhaseTasks
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [planPhaseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTasksInThePhasesInThePlanApi
     */
    public adminPlanPhaseTasksControllerFindAll(page?: number, limit?: number, planPhaseId?: string, options?: AxiosRequestConfig) {
        return AdminPanelTasksInThePhasesInThePlanApiFp(this.configuration).adminPlanPhaseTasksControllerFindAll(page, limit, planPhaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single PlanPhaseTask
     * @param {string} id 
     * @param {AdminUpdatePlanPhaseTaskDto} adminUpdatePlanPhaseTaskDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTasksInThePhasesInThePlanApi
     */
    public adminPlanPhaseTasksControllerUpdatePlanPhaseTask(id: string, adminUpdatePlanPhaseTaskDto: AdminUpdatePlanPhaseTaskDto, options?: AxiosRequestConfig) {
        return AdminPanelTasksInThePhasesInThePlanApiFp(this.configuration).adminPlanPhaseTasksControllerUpdatePlanPhaseTask(id, adminUpdatePlanPhaseTaskDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single PlanPhaseTask
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTasksInThePhasesInThePlanApi
     */
    public getOneBaseAdminPlanPhaseTasksControllerPlanPhaseTask(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelTasksInThePhasesInThePlanApiFp(this.configuration).getOneBaseAdminPlanPhaseTasksControllerPlanPhaseTask(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelTimedTestsApi - axios parameter creator
 * @export
 */
export const AdminPanelTimedTestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single TimedTest
         * @param {AdminCreateTimedTestRequestDto} adminCreateTimedTestRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminTimedTestsControllerTimedTest: async (adminCreateTimedTestRequestDto: AdminCreateTimedTestRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateTimedTestRequestDto' is not null or undefined
            assertParamExists('createOneBaseAdminTimedTestsControllerTimedTest', 'adminCreateTimedTestRequestDto', adminCreateTimedTestRequestDto)
            const localVarPath = `/admin/timed-tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateTimedTestRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single TimedTest
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminTimedTestsControllerTimedTest: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminTimedTestsControllerTimedTest', 'id', id)
            const localVarPath = `/admin/timed-tests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple TimedTests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminTimedTestsControllerTimedTest: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/timed-tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single TimedTest
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminTimedTestsControllerTimedTest: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminTimedTestsControllerTimedTest', 'id', id)
            const localVarPath = `/admin/timed-tests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single TimedTest
         * @param {string} id 
         * @param {AdminUpdateTimedTestRequestDto} adminUpdateTimedTestRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminTimedTestsControllerTimedTest: async (id: string, adminUpdateTimedTestRequestDto: AdminUpdateTimedTestRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminTimedTestsControllerTimedTest', 'id', id)
            // verify required parameter 'adminUpdateTimedTestRequestDto' is not null or undefined
            assertParamExists('updateOneBaseAdminTimedTestsControllerTimedTest', 'adminUpdateTimedTestRequestDto', adminUpdateTimedTestRequestDto)
            const localVarPath = `/admin/timed-tests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateTimedTestRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelTimedTestsApi - functional programming interface
 * @export
 */
export const AdminPanelTimedTestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelTimedTestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single TimedTest
         * @param {AdminCreateTimedTestRequestDto} adminCreateTimedTestRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminTimedTestsControllerTimedTest(adminCreateTimedTestRequestDto: AdminCreateTimedTestRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTimedTestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminTimedTestsControllerTimedTest(adminCreateTimedTestRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single TimedTest
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminTimedTestsControllerTimedTest(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminTimedTestsControllerTimedTest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple TimedTests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminTimedTestsControllerTimedTest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedTimedTestsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminTimedTestsControllerTimedTest(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single TimedTest
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminTimedTestsControllerTimedTest(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTimedTestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminTimedTestsControllerTimedTest(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single TimedTest
         * @param {string} id 
         * @param {AdminUpdateTimedTestRequestDto} adminUpdateTimedTestRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminTimedTestsControllerTimedTest(id: string, adminUpdateTimedTestRequestDto: AdminUpdateTimedTestRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTimedTestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminTimedTestsControllerTimedTest(id, adminUpdateTimedTestRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelTimedTestsApi - factory interface
 * @export
 */
export const AdminPanelTimedTestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelTimedTestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single TimedTest
         * @param {AdminCreateTimedTestRequestDto} adminCreateTimedTestRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminTimedTestsControllerTimedTest(adminCreateTimedTestRequestDto: AdminCreateTimedTestRequestDto, options?: any): AxiosPromise<AdminTimedTestResponseDto> {
            return localVarFp.createOneBaseAdminTimedTestsControllerTimedTest(adminCreateTimedTestRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single TimedTest
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminTimedTestsControllerTimedTest(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminTimedTestsControllerTimedTest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple TimedTests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminTimedTestsControllerTimedTest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<AdminPaginatedTimedTestsResponseDto> {
            return localVarFp.getManyBaseAdminTimedTestsControllerTimedTest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single TimedTest
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminTimedTestsControllerTimedTest(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AdminTimedTestResponseDto> {
            return localVarFp.getOneBaseAdminTimedTestsControllerTimedTest(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single TimedTest
         * @param {string} id 
         * @param {AdminUpdateTimedTestRequestDto} adminUpdateTimedTestRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminTimedTestsControllerTimedTest(id: string, adminUpdateTimedTestRequestDto: AdminUpdateTimedTestRequestDto, options?: any): AxiosPromise<AdminTimedTestResponseDto> {
            return localVarFp.updateOneBaseAdminTimedTestsControllerTimedTest(id, adminUpdateTimedTestRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelTimedTestsApi - object-oriented interface
 * @export
 * @class AdminPanelTimedTestsApi
 * @extends {BaseAPI}
 */
export class AdminPanelTimedTestsApi extends BaseAPI {
    /**
     * 
     * @summary Create a single TimedTest
     * @param {AdminCreateTimedTestRequestDto} adminCreateTimedTestRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTimedTestsApi
     */
    public createOneBaseAdminTimedTestsControllerTimedTest(adminCreateTimedTestRequestDto: AdminCreateTimedTestRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelTimedTestsApiFp(this.configuration).createOneBaseAdminTimedTestsControllerTimedTest(adminCreateTimedTestRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single TimedTest
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTimedTestsApi
     */
    public deleteOneBaseAdminTimedTestsControllerTimedTest(id: string, options?: AxiosRequestConfig) {
        return AdminPanelTimedTestsApiFp(this.configuration).deleteOneBaseAdminTimedTestsControllerTimedTest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple TimedTests
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTimedTestsApi
     */
    public getManyBaseAdminTimedTestsControllerTimedTest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelTimedTestsApiFp(this.configuration).getManyBaseAdminTimedTestsControllerTimedTest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single TimedTest
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTimedTestsApi
     */
    public getOneBaseAdminTimedTestsControllerTimedTest(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelTimedTestsApiFp(this.configuration).getOneBaseAdminTimedTestsControllerTimedTest(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single TimedTest
     * @param {string} id 
     * @param {AdminUpdateTimedTestRequestDto} adminUpdateTimedTestRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelTimedTestsApi
     */
    public updateOneBaseAdminTimedTestsControllerTimedTest(id: string, adminUpdateTimedTestRequestDto: AdminUpdateTimedTestRequestDto, options?: AxiosRequestConfig) {
        return AdminPanelTimedTestsApiFp(this.configuration).updateOneBaseAdminTimedTestsControllerTimedTest(id, adminUpdateTimedTestRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change email
         * @param {CustomerChangeEmailDto} customerChangeEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerChangeEmail: async (customerChangeEmailDto: CustomerChangeEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerChangeEmailDto' is not null or undefined
            assertParamExists('customerAuthControllerChangeEmail', 'customerChangeEmailDto', customerChangeEmailDto)
            const localVarPath = `/auth/customer/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerChangeEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm email
         * @param {CustomerConfirmEmailDto} customerConfirmEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerConfirmEmail: async (customerConfirmEmailDto: CustomerConfirmEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerConfirmEmailDto' is not null or undefined
            assertParamExists('customerAuthControllerConfirmEmail', 'customerConfirmEmailDto', customerConfirmEmailDto)
            const localVarPath = `/auth/customer/confirm-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerConfirmEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerRestorePasswordDto} customerRestorePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerRestorePassword: async (customerRestorePasswordDto: CustomerRestorePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerRestorePasswordDto' is not null or undefined
            assertParamExists('customerAuthControllerRestorePassword', 'customerRestorePasswordDto', customerRestorePasswordDto)
            const localVarPath = `/auth/customer/restorePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerRestorePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerSignInDto} customerSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerSignIn: async (customerSignInDto: CustomerSignInDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerSignInDto' is not null or undefined
            assertParamExists('customerAuthControllerSignIn', 'customerSignInDto', customerSignInDto)
            const localVarPath = `/auth/customer/signIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerSignInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerSignUpDto} customerSignUpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerSignUpByEmail: async (customerSignUpDto: CustomerSignUpDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerSignUpDto' is not null or undefined
            assertParamExists('customerAuthControllerSignUpByEmail', 'customerSignUpDto', customerSignUpDto)
            const localVarPath = `/auth/customer/signUp/by-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerSignUpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerSignUpFromLandingDto} customerSignUpFromLandingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerSignUpFromLandingByEmail: async (customerSignUpFromLandingDto: CustomerSignUpFromLandingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerSignUpFromLandingDto' is not null or undefined
            assertParamExists('customerAuthControllerSignUpFromLandingByEmail', 'customerSignUpFromLandingDto', customerSignUpFromLandingDto)
            const localVarPath = `/auth/customer/signUp/landing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerSignUpFromLandingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerSignUpPromoDto} customerSignUpPromoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerSignUpPromo: async (customerSignUpPromoDto: CustomerSignUpPromoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerSignUpPromoDto' is not null or undefined
            assertParamExists('customerAuthControllerSignUpPromo', 'customerSignUpPromoDto', customerSignUpPromoDto)
            const localVarPath = `/auth/customer/signUp/promo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerSignUpPromoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgottenPasswordDto} forgottenPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerUpdateForgottenPassword: async (forgottenPasswordDto: ForgottenPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgottenPasswordDto' is not null or undefined
            assertParamExists('customerAuthControllerUpdateForgottenPassword', 'forgottenPasswordDto', forgottenPasswordDto)
            const localVarPath = `/auth/customer/updateForgottenPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgottenPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change email
         * @param {CustomerChangeEmailDto} customerChangeEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAuthControllerChangeEmail(customerChangeEmailDto: CustomerChangeEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAuthControllerChangeEmail(customerChangeEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm email
         * @param {CustomerConfirmEmailDto} customerConfirmEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAuthControllerConfirmEmail(customerConfirmEmailDto: CustomerConfirmEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAuthControllerConfirmEmail(customerConfirmEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerRestorePasswordDto} customerRestorePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAuthControllerRestorePassword(customerRestorePasswordDto: CustomerRestorePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerRestorePasswordResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAuthControllerRestorePassword(customerRestorePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerSignInDto} customerSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAuthControllerSignIn(customerSignInDto: CustomerSignInDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAuthResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAuthControllerSignIn(customerSignInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerSignUpDto} customerSignUpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAuthControllerSignUpByEmail(customerSignUpDto: CustomerSignUpDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerSignUpResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAuthControllerSignUpByEmail(customerSignUpDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerSignUpFromLandingDto} customerSignUpFromLandingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAuthControllerSignUpFromLandingByEmail(customerSignUpFromLandingDto: CustomerSignUpFromLandingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerSignUpResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAuthControllerSignUpFromLandingByEmail(customerSignUpFromLandingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerSignUpPromoDto} customerSignUpPromoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAuthControllerSignUpPromo(customerSignUpPromoDto: CustomerSignUpPromoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAuthResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAuthControllerSignUpPromo(customerSignUpPromoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgottenPasswordDto} forgottenPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAuthControllerUpdateForgottenPassword(forgottenPasswordDto: ForgottenPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerSignUpResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAuthControllerUpdateForgottenPassword(forgottenPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Change email
         * @param {CustomerChangeEmailDto} customerChangeEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerChangeEmail(customerChangeEmailDto: CustomerChangeEmailDto, options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.customerAuthControllerChangeEmail(customerChangeEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm email
         * @param {CustomerConfirmEmailDto} customerConfirmEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerConfirmEmail(customerConfirmEmailDto: CustomerConfirmEmailDto, options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.customerAuthControllerConfirmEmail(customerConfirmEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerRestorePasswordDto} customerRestorePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerRestorePassword(customerRestorePasswordDto: CustomerRestorePasswordDto, options?: any): AxiosPromise<CustomerRestorePasswordResponseDto> {
            return localVarFp.customerAuthControllerRestorePassword(customerRestorePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerSignInDto} customerSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerSignIn(customerSignInDto: CustomerSignInDto, options?: any): AxiosPromise<CustomerAuthResponseDto> {
            return localVarFp.customerAuthControllerSignIn(customerSignInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerSignUpDto} customerSignUpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerSignUpByEmail(customerSignUpDto: CustomerSignUpDto, options?: any): AxiosPromise<CustomerSignUpResponseDto> {
            return localVarFp.customerAuthControllerSignUpByEmail(customerSignUpDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerSignUpFromLandingDto} customerSignUpFromLandingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerSignUpFromLandingByEmail(customerSignUpFromLandingDto: CustomerSignUpFromLandingDto, options?: any): AxiosPromise<CustomerSignUpResponseDto> {
            return localVarFp.customerAuthControllerSignUpFromLandingByEmail(customerSignUpFromLandingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerSignUpPromoDto} customerSignUpPromoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerSignUpPromo(customerSignUpPromoDto: CustomerSignUpPromoDto, options?: any): AxiosPromise<CustomerAuthResponseDto> {
            return localVarFp.customerAuthControllerSignUpPromo(customerSignUpPromoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgottenPasswordDto} forgottenPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAuthControllerUpdateForgottenPassword(forgottenPasswordDto: ForgottenPasswordDto, options?: any): AxiosPromise<CustomerSignUpResponseDto> {
            return localVarFp.customerAuthControllerUpdateForgottenPassword(forgottenPasswordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Change email
     * @param {CustomerChangeEmailDto} customerChangeEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public customerAuthControllerChangeEmail(customerChangeEmailDto: CustomerChangeEmailDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).customerAuthControllerChangeEmail(customerChangeEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm email
     * @param {CustomerConfirmEmailDto} customerConfirmEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public customerAuthControllerConfirmEmail(customerConfirmEmailDto: CustomerConfirmEmailDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).customerAuthControllerConfirmEmail(customerConfirmEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerRestorePasswordDto} customerRestorePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public customerAuthControllerRestorePassword(customerRestorePasswordDto: CustomerRestorePasswordDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).customerAuthControllerRestorePassword(customerRestorePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerSignInDto} customerSignInDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public customerAuthControllerSignIn(customerSignInDto: CustomerSignInDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).customerAuthControllerSignIn(customerSignInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerSignUpDto} customerSignUpDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public customerAuthControllerSignUpByEmail(customerSignUpDto: CustomerSignUpDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).customerAuthControllerSignUpByEmail(customerSignUpDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerSignUpFromLandingDto} customerSignUpFromLandingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public customerAuthControllerSignUpFromLandingByEmail(customerSignUpFromLandingDto: CustomerSignUpFromLandingDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).customerAuthControllerSignUpFromLandingByEmail(customerSignUpFromLandingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerSignUpPromoDto} customerSignUpPromoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public customerAuthControllerSignUpPromo(customerSignUpPromoDto: CustomerSignUpPromoDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).customerAuthControllerSignUpPromo(customerSignUpPromoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgottenPasswordDto} forgottenPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public customerAuthControllerUpdateForgottenPassword(forgottenPasswordDto: ForgottenPasswordDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).customerAuthControllerUpdateForgottenPassword(forgottenPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoursesV1Api - axios parameter creator
 * @export
 */
export const CoursesV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add exam date to active course
         * @param {AddExamDateDto} addExamDateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerAddExamDate: async (addExamDateDto: AddExamDateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addExamDateDto' is not null or undefined
            assertParamExists('coursesControllerAddExamDate', 'addExamDateDto', addExamDateDto)
            const localVarPath = `/courses/active/exam-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addExamDateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel subscription downgrade and stay on current for selected course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerCancelDowngrade: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerCancelDowngrade', 'courseId', courseId)
            const localVarPath = `/courses/{courseId}/cancel-downgrade`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel subscription trial downgrade and stay on current for selected course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerCancelTrialDowngrade: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerCancelTrialDowngrade', 'courseId', courseId)
            const localVarPath = `/courses/{courseId}/cancel-trial-downgrade`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change subscription for selected course
         * @param {string} courseId 
         * @param {SubscribeDto} subscribeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerChangeSubscription: async (courseId: string, subscribeDto: SubscribeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerChangeSubscription', 'courseId', courseId)
            // verify required parameter 'subscribeDto' is not null or undefined
            assertParamExists('coursesControllerChangeSubscription', 'subscribeDto', subscribeDto)
            const localVarPath = `/courses/{courseId}/subscribe`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscribeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check promocode
         * @param {string} courseId 
         * @param {CheckPromocodeRequestDto} checkPromocodeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerCheckPromocode: async (courseId: string, checkPromocodeRequestDto: CheckPromocodeRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerCheckPromocode', 'courseId', courseId)
            // verify required parameter 'checkPromocodeRequestDto' is not null or undefined
            assertParamExists('coursesControllerCheckPromocode', 'checkPromocodeRequestDto', checkPromocodeRequestDto)
            const localVarPath = `/courses/{courseId}/check-promocode`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkPromocodeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check purchase
         * @param {string} customerId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerCheckPurchase: async (customerId: string, courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('coursesControllerCheckPurchase', 'customerId', customerId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerCheckPurchase', 'courseId', courseId)
            const localVarPath = `/courses/{courseId}/check-purchase`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Count domains and chapters in course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerCountDomainsAndChapters: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerCountDomainsAndChapters', 'courseId', courseId)
            const localVarPath = `/courses/{courseId}/count-domains-and-chapters`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all courses from current direction with payments
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {boolean} [withoutActivated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerFind: async (page?: number, limit?: number, withoutActivated?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (withoutActivated !== undefined) {
                localVarQueryParameter['withoutActivated'] = withoutActivated;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get course info with payments
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGet: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerGet', 'courseId', courseId)
            const localVarPath = `/courses/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get active course with payments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetActive: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get button names
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetButtonNames: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerGetButtonNames', 'courseId', courseId)
            const localVarPath = `/courses/{courseId}/button-names`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public course info
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerGetById: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerGetById', 'courseId', courseId)
            const localVarPath = `/courses/{courseId}/getById`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get active course cheat sheet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetCheatSheetUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/cheat-sheet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all activated courses with payments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetCustomerCourses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/activatedByCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get exam date to active course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetExamDate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/active/exam-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get course manual by its type
         * @param {string} courseId 
         * @param {'how-progress-is-calculated' | 'instruction-manual' | 'instruction-manual-mobile' | 'flashcard-descriptions' | 'performance-upgrade' | 'tests-page-upgrade' | 'study-guide-upgrade' | 'flashcards-page-upgrade' | 'mnemonics-page-upgrade' | 'timed-test-upgrade' | 'payment-discounts' | 'certificate-signup' | 'bonus-section-upgrade' | 'bonus-section-default'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetManual: async (courseId: string, type: 'how-progress-is-calculated' | 'instruction-manual' | 'instruction-manual-mobile' | 'flashcard-descriptions' | 'performance-upgrade' | 'tests-page-upgrade' | 'study-guide-upgrade' | 'flashcards-page-upgrade' | 'mnemonics-page-upgrade' | 'timed-test-upgrade' | 'payment-discounts' | 'certificate-signup' | 'bonus-section-upgrade' | 'bonus-section-default', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerGetManual', 'courseId', courseId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('coursesControllerGetManual', 'type', type)
            const localVarPath = `/courses/{courseId}/manual`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public course info
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetPublic: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerGetPublic', 'courseId', courseId)
            const localVarPath = `/courses/{courseId}/public`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Get active button names. Use GET /courses/{courseId}/button-names instead.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerGetStatsButtonNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/stats-button-names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get active course text book
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetTextBookUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/text-book`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary One-time purchase
         * @param {string} courseId 
         * @param {string} promocode 
         * @param {PurchaseDto} purchaseDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerPurchase: async (courseId: string, promocode: string, purchaseDto: PurchaseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerPurchase', 'courseId', courseId)
            // verify required parameter 'promocode' is not null or undefined
            assertParamExists('coursesControllerPurchase', 'promocode', promocode)
            // verify required parameter 'purchaseDto' is not null or undefined
            assertParamExists('coursesControllerPurchase', 'purchaseDto', purchaseDto)
            const localVarPath = `/courses/{courseId}/purchase`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (promocode !== undefined) {
                localVarQueryParameter['promocode'] = promocode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Purchase course from trial
         * @param {string} courseId 
         * @param {string} promocode 
         * @param {PurchaseDto} purchaseDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerPurchaseFromTrialCourse: async (courseId: string, promocode: string, purchaseDto: PurchaseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerPurchaseFromTrialCourse', 'courseId', courseId)
            // verify required parameter 'promocode' is not null or undefined
            assertParamExists('coursesControllerPurchaseFromTrialCourse', 'promocode', promocode)
            // verify required parameter 'purchaseDto' is not null or undefined
            assertParamExists('coursesControllerPurchaseFromTrialCourse', 'purchaseDto', purchaseDto)
            const localVarPath = `/courses/{courseId}/trial/purchase`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (promocode !== undefined) {
                localVarQueryParameter['promocode'] = promocode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset course results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerResetCourseResults: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set course as active
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerSetActiveCourse: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerSetActiveCourse', 'courseId', courseId)
            const localVarPath = `/courses/{courseId}/setActive`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe to selected course
         * @param {string} courseId 
         * @param {string} promocode 
         * @param {SubscribeDto} subscribeDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerSubscribe: async (courseId: string, promocode: string, subscribeDto: SubscribeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerSubscribe', 'courseId', courseId)
            // verify required parameter 'promocode' is not null or undefined
            assertParamExists('coursesControllerSubscribe', 'promocode', promocode)
            // verify required parameter 'subscribeDto' is not null or undefined
            assertParamExists('coursesControllerSubscribe', 'subscribeDto', subscribeDto)
            const localVarPath = `/courses/{courseId}/subscribe`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (promocode !== undefined) {
                localVarQueryParameter['promocode'] = promocode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscribeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe on course from trial
         * @param {string} courseId 
         * @param {string} promocode 
         * @param {SubscribeDto} subscribeDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerSubscribeFromTrialCourse: async (courseId: string, promocode: string, subscribeDto: SubscribeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerSubscribeFromTrialCourse', 'courseId', courseId)
            // verify required parameter 'promocode' is not null or undefined
            assertParamExists('coursesControllerSubscribeFromTrialCourse', 'promocode', promocode)
            // verify required parameter 'subscribeDto' is not null or undefined
            assertParamExists('coursesControllerSubscribeFromTrialCourse', 'subscribeDto', subscribeDto)
            const localVarPath = `/courses/{courseId}/trial/subscribe`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (promocode !== undefined) {
                localVarQueryParameter['promocode'] = promocode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscribeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unsubscribe from selected course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerUnsubscribe: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesControllerUnsubscribe', 'courseId', courseId)
            const localVarPath = `/courses/{courseId}/unsubscribe`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursesV1Api - functional programming interface
 * @export
 */
export const CoursesV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoursesV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add exam date to active course
         * @param {AddExamDateDto} addExamDateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerAddExamDate(addExamDateDto: AddExamDateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerAddExamDate(addExamDateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel subscription downgrade and stay on current for selected course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerCancelDowngrade(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerCancelDowngrade(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel subscription trial downgrade and stay on current for selected course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerCancelTrialDowngrade(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerCancelTrialDowngrade(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change subscription for selected course
         * @param {string} courseId 
         * @param {SubscribeDto} subscribeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerChangeSubscription(courseId: string, subscribeDto: SubscribeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerChangeSubscription(courseId, subscribeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check promocode
         * @param {string} courseId 
         * @param {CheckPromocodeRequestDto} checkPromocodeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerCheckPromocode(courseId: string, checkPromocodeRequestDto: CheckPromocodeRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckPromocodeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerCheckPromocode(courseId, checkPromocodeRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check purchase
         * @param {string} customerId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async coursesControllerCheckPurchase(customerId: string, courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckPurchaseResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerCheckPurchase(customerId, courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Count domains and chapters in course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerCountDomainsAndChapters(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountDomainsAndChaptersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerCountDomainsAndChapters(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all courses from current direction with payments
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {boolean} [withoutActivated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerFind(page?: number, limit?: number, withoutActivated?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCoursesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerFind(page, limit, withoutActivated, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get course info with payments
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerGet(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerGet(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get active course with payments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerGetActive(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerGetActive(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get button names
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerGetButtonNames(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ButtonNamesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerGetButtonNames(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public course info
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async coursesControllerGetById(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerGetById(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get active course cheat sheet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerGetCheatSheetUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheatSheetUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerGetCheatSheetUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all activated courses with payments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerGetCustomerCourses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerGetCustomerCourses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get exam date to active course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerGetExamDate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerGetExamDate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get course manual by its type
         * @param {string} courseId 
         * @param {'how-progress-is-calculated' | 'instruction-manual' | 'instruction-manual-mobile' | 'flashcard-descriptions' | 'performance-upgrade' | 'tests-page-upgrade' | 'study-guide-upgrade' | 'flashcards-page-upgrade' | 'mnemonics-page-upgrade' | 'timed-test-upgrade' | 'payment-discounts' | 'certificate-signup' | 'bonus-section-upgrade' | 'bonus-section-default'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerGetManual(courseId: string, type: 'how-progress-is-calculated' | 'instruction-manual' | 'instruction-manual-mobile' | 'flashcard-descriptions' | 'performance-upgrade' | 'tests-page-upgrade' | 'study-guide-upgrade' | 'flashcards-page-upgrade' | 'mnemonics-page-upgrade' | 'timed-test-upgrade' | 'payment-discounts' | 'certificate-signup' | 'bonus-section-upgrade' | 'bonus-section-default', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManualResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerGetManual(courseId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public course info
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerGetPublic(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerGetPublic(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Get active button names. Use GET /courses/{courseId}/button-names instead.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async coursesControllerGetStatsButtonNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatsButtonNamesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerGetStatsButtonNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get active course text book
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerGetTextBookUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextBookUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerGetTextBookUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary One-time purchase
         * @param {string} courseId 
         * @param {string} promocode 
         * @param {PurchaseDto} purchaseDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async coursesControllerPurchase(courseId: string, promocode: string, purchaseDto: PurchaseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerPurchase(courseId, promocode, purchaseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Purchase course from trial
         * @param {string} courseId 
         * @param {string} promocode 
         * @param {PurchaseDto} purchaseDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async coursesControllerPurchaseFromTrialCourse(courseId: string, promocode: string, purchaseDto: PurchaseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerPurchaseFromTrialCourse(courseId, promocode, purchaseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset course results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerResetCourseResults(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerResetCourseResults(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set course as active
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerSetActiveCourse(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerSetActiveCourse(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Subscribe to selected course
         * @param {string} courseId 
         * @param {string} promocode 
         * @param {SubscribeDto} subscribeDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async coursesControllerSubscribe(courseId: string, promocode: string, subscribeDto: SubscribeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerSubscribe(courseId, promocode, subscribeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Subscribe on course from trial
         * @param {string} courseId 
         * @param {string} promocode 
         * @param {SubscribeDto} subscribeDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async coursesControllerSubscribeFromTrialCourse(courseId: string, promocode: string, subscribeDto: SubscribeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerSubscribeFromTrialCourse(courseId, promocode, subscribeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unsubscribe from selected course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesControllerUnsubscribe(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesControllerUnsubscribe(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoursesV1Api - factory interface
 * @export
 */
export const CoursesV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoursesV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary Add exam date to active course
         * @param {AddExamDateDto} addExamDateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerAddExamDate(addExamDateDto: AddExamDateDto, options?: any): AxiosPromise<void> {
            return localVarFp.coursesControllerAddExamDate(addExamDateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel subscription downgrade and stay on current for selected course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerCancelDowngrade(courseId: string, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.coursesControllerCancelDowngrade(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel subscription trial downgrade and stay on current for selected course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerCancelTrialDowngrade(courseId: string, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.coursesControllerCancelTrialDowngrade(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change subscription for selected course
         * @param {string} courseId 
         * @param {SubscribeDto} subscribeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerChangeSubscription(courseId: string, subscribeDto: SubscribeDto, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.coursesControllerChangeSubscription(courseId, subscribeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check promocode
         * @param {string} courseId 
         * @param {CheckPromocodeRequestDto} checkPromocodeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerCheckPromocode(courseId: string, checkPromocodeRequestDto: CheckPromocodeRequestDto, options?: any): AxiosPromise<CheckPromocodeResponseDto> {
            return localVarFp.coursesControllerCheckPromocode(courseId, checkPromocodeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check purchase
         * @param {string} customerId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerCheckPurchase(customerId: string, courseId: string, options?: any): AxiosPromise<CheckPurchaseResponseDto> {
            return localVarFp.coursesControllerCheckPurchase(customerId, courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Count domains and chapters in course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerCountDomainsAndChapters(courseId: string, options?: any): AxiosPromise<CountDomainsAndChaptersResponseDto> {
            return localVarFp.coursesControllerCountDomainsAndChapters(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all courses from current direction with payments
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {boolean} [withoutActivated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerFind(page?: number, limit?: number, withoutActivated?: boolean, options?: any): AxiosPromise<PaginatedCoursesDto> {
            return localVarFp.coursesControllerFind(page, limit, withoutActivated, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get course info with payments
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGet(courseId: string, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.coursesControllerGet(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get active course with payments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetActive(options?: any): AxiosPromise<CourseDto> {
            return localVarFp.coursesControllerGetActive(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get button names
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetButtonNames(courseId: string, options?: any): AxiosPromise<ButtonNamesDto> {
            return localVarFp.coursesControllerGetButtonNames(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public course info
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerGetById(courseId: string, options?: any): AxiosPromise<PublicCourseDto> {
            return localVarFp.coursesControllerGetById(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get active course cheat sheet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetCheatSheetUrl(options?: any): AxiosPromise<CheatSheetUrlDto> {
            return localVarFp.coursesControllerGetCheatSheetUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all activated courses with payments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetCustomerCourses(options?: any): AxiosPromise<Array<CourseDto>> {
            return localVarFp.coursesControllerGetCustomerCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get exam date to active course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetExamDate(options?: any): AxiosPromise<string> {
            return localVarFp.coursesControllerGetExamDate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get course manual by its type
         * @param {string} courseId 
         * @param {'how-progress-is-calculated' | 'instruction-manual' | 'instruction-manual-mobile' | 'flashcard-descriptions' | 'performance-upgrade' | 'tests-page-upgrade' | 'study-guide-upgrade' | 'flashcards-page-upgrade' | 'mnemonics-page-upgrade' | 'timed-test-upgrade' | 'payment-discounts' | 'certificate-signup' | 'bonus-section-upgrade' | 'bonus-section-default'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetManual(courseId: string, type: 'how-progress-is-calculated' | 'instruction-manual' | 'instruction-manual-mobile' | 'flashcard-descriptions' | 'performance-upgrade' | 'tests-page-upgrade' | 'study-guide-upgrade' | 'flashcards-page-upgrade' | 'mnemonics-page-upgrade' | 'timed-test-upgrade' | 'payment-discounts' | 'certificate-signup' | 'bonus-section-upgrade' | 'bonus-section-default', options?: any): AxiosPromise<ManualResponseDto> {
            return localVarFp.coursesControllerGetManual(courseId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public course info
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetPublic(courseId: string, options?: any): AxiosPromise<PublicCourseDto> {
            return localVarFp.coursesControllerGetPublic(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Get active button names. Use GET /courses/{courseId}/button-names instead.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerGetStatsButtonNames(options?: any): AxiosPromise<StatsButtonNamesDto> {
            return localVarFp.coursesControllerGetStatsButtonNames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get active course text book
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerGetTextBookUrl(options?: any): AxiosPromise<TextBookUrlDto> {
            return localVarFp.coursesControllerGetTextBookUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary One-time purchase
         * @param {string} courseId 
         * @param {string} promocode 
         * @param {PurchaseDto} purchaseDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerPurchase(courseId: string, promocode: string, purchaseDto: PurchaseDto, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.coursesControllerPurchase(courseId, promocode, purchaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Purchase course from trial
         * @param {string} courseId 
         * @param {string} promocode 
         * @param {PurchaseDto} purchaseDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerPurchaseFromTrialCourse(courseId: string, promocode: string, purchaseDto: PurchaseDto, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.coursesControllerPurchaseFromTrialCourse(courseId, promocode, purchaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset course results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerResetCourseResults(options?: any): AxiosPromise<void> {
            return localVarFp.coursesControllerResetCourseResults(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set course as active
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerSetActiveCourse(courseId: string, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.coursesControllerSetActiveCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Subscribe to selected course
         * @param {string} courseId 
         * @param {string} promocode 
         * @param {SubscribeDto} subscribeDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerSubscribe(courseId: string, promocode: string, subscribeDto: SubscribeDto, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.coursesControllerSubscribe(courseId, promocode, subscribeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Subscribe on course from trial
         * @param {string} courseId 
         * @param {string} promocode 
         * @param {SubscribeDto} subscribeDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        coursesControllerSubscribeFromTrialCourse(courseId: string, promocode: string, subscribeDto: SubscribeDto, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.coursesControllerSubscribeFromTrialCourse(courseId, promocode, subscribeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unsubscribe from selected course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesControllerUnsubscribe(courseId: string, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.coursesControllerUnsubscribe(courseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoursesV1Api - object-oriented interface
 * @export
 * @class CoursesV1Api
 * @extends {BaseAPI}
 */
export class CoursesV1Api extends BaseAPI {
    /**
     * 
     * @summary Add exam date to active course
     * @param {AddExamDateDto} addExamDateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerAddExamDate(addExamDateDto: AddExamDateDto, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerAddExamDate(addExamDateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel subscription downgrade and stay on current for selected course
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerCancelDowngrade(courseId: string, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerCancelDowngrade(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel subscription trial downgrade and stay on current for selected course
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerCancelTrialDowngrade(courseId: string, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerCancelTrialDowngrade(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change subscription for selected course
     * @param {string} courseId 
     * @param {SubscribeDto} subscribeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerChangeSubscription(courseId: string, subscribeDto: SubscribeDto, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerChangeSubscription(courseId, subscribeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check promocode
     * @param {string} courseId 
     * @param {CheckPromocodeRequestDto} checkPromocodeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerCheckPromocode(courseId: string, checkPromocodeRequestDto: CheckPromocodeRequestDto, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerCheckPromocode(courseId, checkPromocodeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check purchase
     * @param {string} customerId 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerCheckPurchase(customerId: string, courseId: string, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerCheckPurchase(customerId, courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Count domains and chapters in course
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerCountDomainsAndChapters(courseId: string, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerCountDomainsAndChapters(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all courses from current direction with payments
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {boolean} [withoutActivated] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerFind(page?: number, limit?: number, withoutActivated?: boolean, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerFind(page, limit, withoutActivated, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get course info with payments
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerGet(courseId: string, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerGet(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get active course with payments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerGetActive(options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerGetActive(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get button names
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerGetButtonNames(courseId: string, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerGetButtonNames(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public course info
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerGetById(courseId: string, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerGetById(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get active course cheat sheet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerGetCheatSheetUrl(options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerGetCheatSheetUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all activated courses with payments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerGetCustomerCourses(options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerGetCustomerCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get exam date to active course
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerGetExamDate(options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerGetExamDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get course manual by its type
     * @param {string} courseId 
     * @param {'how-progress-is-calculated' | 'instruction-manual' | 'instruction-manual-mobile' | 'flashcard-descriptions' | 'performance-upgrade' | 'tests-page-upgrade' | 'study-guide-upgrade' | 'flashcards-page-upgrade' | 'mnemonics-page-upgrade' | 'timed-test-upgrade' | 'payment-discounts' | 'certificate-signup' | 'bonus-section-upgrade' | 'bonus-section-default'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerGetManual(courseId: string, type: 'how-progress-is-calculated' | 'instruction-manual' | 'instruction-manual-mobile' | 'flashcard-descriptions' | 'performance-upgrade' | 'tests-page-upgrade' | 'study-guide-upgrade' | 'flashcards-page-upgrade' | 'mnemonics-page-upgrade' | 'timed-test-upgrade' | 'payment-discounts' | 'certificate-signup' | 'bonus-section-upgrade' | 'bonus-section-default', options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerGetManual(courseId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public course info
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerGetPublic(courseId: string, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerGetPublic(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Get active button names. Use GET /courses/{courseId}/button-names instead.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerGetStatsButtonNames(options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerGetStatsButtonNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get active course text book
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerGetTextBookUrl(options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerGetTextBookUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary One-time purchase
     * @param {string} courseId 
     * @param {string} promocode 
     * @param {PurchaseDto} purchaseDto 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerPurchase(courseId: string, promocode: string, purchaseDto: PurchaseDto, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerPurchase(courseId, promocode, purchaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Purchase course from trial
     * @param {string} courseId 
     * @param {string} promocode 
     * @param {PurchaseDto} purchaseDto 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerPurchaseFromTrialCourse(courseId: string, promocode: string, purchaseDto: PurchaseDto, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerPurchaseFromTrialCourse(courseId, promocode, purchaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset course results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerResetCourseResults(options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerResetCourseResults(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set course as active
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerSetActiveCourse(courseId: string, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerSetActiveCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Subscribe to selected course
     * @param {string} courseId 
     * @param {string} promocode 
     * @param {SubscribeDto} subscribeDto 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerSubscribe(courseId: string, promocode: string, subscribeDto: SubscribeDto, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerSubscribe(courseId, promocode, subscribeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Subscribe on course from trial
     * @param {string} courseId 
     * @param {string} promocode 
     * @param {SubscribeDto} subscribeDto 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerSubscribeFromTrialCourse(courseId: string, promocode: string, subscribeDto: SubscribeDto, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerSubscribeFromTrialCourse(courseId, promocode, subscribeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unsubscribe from selected course
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public coursesControllerUnsubscribe(courseId: string, options?: AxiosRequestConfig) {
        return CoursesV1ApiFp(this.configuration).coursesControllerUnsubscribe(courseId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerAppSettingsApi - axios parameter creator
 * @export
 */
export const CustomerAppSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get customer app settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAppSettingsControllerGetSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer-app-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Toggle show streaks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAppSettingsControllerToggleShowStreaks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer-app-settings/toggle-show-streaks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Toggle turn on sounds in app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAppSettingsControllerToggleTurnOnSoundsInApp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer-app-settings/toggle-sounds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerAppSettingsApi - functional programming interface
 * @export
 */
export const CustomerAppSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerAppSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get customer app settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAppSettingsControllerGetSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAppSettingsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAppSettingsControllerGetSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Toggle show streaks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAppSettingsControllerToggleShowStreaks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAppSettingsControllerToggleShowStreaks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Toggle turn on sounds in app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAppSettingsControllerToggleTurnOnSoundsInApp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAppSettingsControllerToggleTurnOnSoundsInApp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerAppSettingsApi - factory interface
 * @export
 */
export const CustomerAppSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerAppSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get customer app settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAppSettingsControllerGetSettings(options?: any): AxiosPromise<CustomerAppSettingsResponseDto> {
            return localVarFp.customerAppSettingsControllerGetSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Toggle show streaks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAppSettingsControllerToggleShowStreaks(options?: any): AxiosPromise<void> {
            return localVarFp.customerAppSettingsControllerToggleShowStreaks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Toggle turn on sounds in app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAppSettingsControllerToggleTurnOnSoundsInApp(options?: any): AxiosPromise<void> {
            return localVarFp.customerAppSettingsControllerToggleTurnOnSoundsInApp(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerAppSettingsApi - object-oriented interface
 * @export
 * @class CustomerAppSettingsApi
 * @extends {BaseAPI}
 */
export class CustomerAppSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get customer app settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAppSettingsApi
     */
    public customerAppSettingsControllerGetSettings(options?: AxiosRequestConfig) {
        return CustomerAppSettingsApiFp(this.configuration).customerAppSettingsControllerGetSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Toggle show streaks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAppSettingsApi
     */
    public customerAppSettingsControllerToggleShowStreaks(options?: AxiosRequestConfig) {
        return CustomerAppSettingsApiFp(this.configuration).customerAppSettingsControllerToggleShowStreaks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Toggle turn on sounds in app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAppSettingsApi
     */
    public customerAppSettingsControllerToggleTurnOnSoundsInApp(options?: AxiosRequestConfig) {
        return CustomerAppSettingsApiFp(this.configuration).customerAppSettingsControllerToggleTurnOnSoundsInApp(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomersV1Api - axios parameter creator
 * @export
 */
export const CustomersV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get link to the Stripe billing portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerBillingPortal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/billingPortal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create user hash for intercom
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerCreateUserIntercomHash: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/userIntercomHash`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete customer permanently
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeleteAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/deleteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete payment method
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeletePaymentMethod: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/paymentMethod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get payment method
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerGetPaymentMethod: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/paymentMethod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatchPasswordDto} patchPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerPatchPassword: async (patchPasswordDto: PatchPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchPasswordDto' is not null or undefined
            assertParamExists('customersControllerPatchPassword', 'patchPasswordDto', patchPasswordDto)
            const localVarPath = `/customer/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatchProfileDto} patchProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerPatchProfile: async (patchProfileDto: PatchProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchProfileDto' is not null or undefined
            assertParamExists('customersControllerPatchProfile', 'patchProfileDto', patchProfileDto)
            const localVarPath = `/customer/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersV1Api - functional programming interface
 * @export
 */
export const CustomersV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get link to the Stripe billing portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerBillingPortal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingPortalResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerBillingPortal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create user hash for intercom
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerCreateUserIntercomHash(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerCreateUserIntercomHash(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete customer permanently
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerDeleteAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerDeleteAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete payment method
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerDeletePaymentMethod(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerDeletePaymentMethod(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get payment method
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerGetPaymentMethod(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentMethodResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerGetPaymentMethod(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PatchPasswordDto} patchPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerPatchPassword(patchPasswordDto: PatchPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerPatchPassword(patchPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PatchProfileDto} patchProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerPatchProfile(patchProfileDto: PatchProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerPatchProfile(patchProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersV1Api - factory interface
 * @export
 */
export const CustomersV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary Get link to the Stripe billing portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerBillingPortal(options?: any): AxiosPromise<BillingPortalResponseDto> {
            return localVarFp.customersControllerBillingPortal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create user hash for intercom
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerCreateUserIntercomHash(options?: any): AxiosPromise<string> {
            return localVarFp.customersControllerCreateUserIntercomHash(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete customer permanently
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeleteAccount(options?: any): AxiosPromise<void> {
            return localVarFp.customersControllerDeleteAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete payment method
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeletePaymentMethod(options?: any): AxiosPromise<void> {
            return localVarFp.customersControllerDeletePaymentMethod(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get payment method
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerGetPaymentMethod(options?: any): AxiosPromise<GetPaymentMethodResponseDto> {
            return localVarFp.customersControllerGetPaymentMethod(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerMe(options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.customersControllerMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatchPasswordDto} patchPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerPatchPassword(patchPasswordDto: PatchPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.customersControllerPatchPassword(patchPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatchProfileDto} patchProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerPatchProfile(patchProfileDto: PatchProfileDto, options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.customersControllerPatchProfile(patchProfileDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersV1Api - object-oriented interface
 * @export
 * @class CustomersV1Api
 * @extends {BaseAPI}
 */
export class CustomersV1Api extends BaseAPI {
    /**
     * 
     * @summary Get link to the Stripe billing portal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersV1Api
     */
    public customersControllerBillingPortal(options?: AxiosRequestConfig) {
        return CustomersV1ApiFp(this.configuration).customersControllerBillingPortal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create user hash for intercom
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersV1Api
     */
    public customersControllerCreateUserIntercomHash(options?: AxiosRequestConfig) {
        return CustomersV1ApiFp(this.configuration).customersControllerCreateUserIntercomHash(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete customer permanently
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersV1Api
     */
    public customersControllerDeleteAccount(options?: AxiosRequestConfig) {
        return CustomersV1ApiFp(this.configuration).customersControllerDeleteAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete payment method
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersV1Api
     */
    public customersControllerDeletePaymentMethod(options?: AxiosRequestConfig) {
        return CustomersV1ApiFp(this.configuration).customersControllerDeletePaymentMethod(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get payment method
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersV1Api
     */
    public customersControllerGetPaymentMethod(options?: AxiosRequestConfig) {
        return CustomersV1ApiFp(this.configuration).customersControllerGetPaymentMethod(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersV1Api
     */
    public customersControllerMe(options?: AxiosRequestConfig) {
        return CustomersV1ApiFp(this.configuration).customersControllerMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatchPasswordDto} patchPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersV1Api
     */
    public customersControllerPatchPassword(patchPasswordDto: PatchPasswordDto, options?: AxiosRequestConfig) {
        return CustomersV1ApiFp(this.configuration).customersControllerPatchPassword(patchPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatchProfileDto} patchProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersV1Api
     */
    public customersControllerPatchProfile(patchProfileDto: PatchProfileDto, options?: AxiosRequestConfig) {
        return CustomersV1ApiFp(this.configuration).customersControllerPatchProfile(patchProfileDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetApiVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApi: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetApiVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetApiVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerHello(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheckApi(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthControllerCheckApi200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheckApi(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetApiVersion(options?: any): AxiosPromise<string> {
            return localVarFp.appControllerGetApiVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerHello(options?: any): AxiosPromise<void> {
            return localVarFp.appControllerHello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApi(options?: any): AxiosPromise<HealthControllerCheckApi200Response> {
            return localVarFp.healthControllerCheckApi(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetApiVersion(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetApiVersion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerHello(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerHello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheckApi(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerCheckApi(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DirectionsV1Api - axios parameter creator
 * @export
 */
export const DirectionsV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directionsControllerGetAllDirections: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/direction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get direction by site or landing url
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directionsControllerGetDirectionByUrl: async (url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('directionsControllerGetDirectionByUrl', 'url', url)
            const localVarPath = `/direction/{url}`
                .replace(`{${"url"}}`, encodeURIComponent(String(url)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all courses short info in career
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directionsControllerGetDirectionCourses: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('directionsControllerGetDirectionCourses', 'id', id)
            const localVarPath = `/direction/{id}/courses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectionsV1Api - functional programming interface
 * @export
 */
export const DirectionsV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectionsV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async directionsControllerGetAllDirections(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DirectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.directionsControllerGetAllDirections(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get direction by site or landing url
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async directionsControllerGetDirectionByUrl(url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.directionsControllerGetDirectionByUrl(url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all courses short info in career
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async directionsControllerGetDirectionCourses(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseShortInfoResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.directionsControllerGetDirectionCourses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DirectionsV1Api - factory interface
 * @export
 */
export const DirectionsV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectionsV1ApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directionsControllerGetAllDirections(options?: any): AxiosPromise<Array<DirectionDto>> {
            return localVarFp.directionsControllerGetAllDirections(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get direction by site or landing url
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directionsControllerGetDirectionByUrl(url: string, options?: any): AxiosPromise<DirectionDto> {
            return localVarFp.directionsControllerGetDirectionByUrl(url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all courses short info in career
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directionsControllerGetDirectionCourses(id: string, options?: any): AxiosPromise<Array<CourseShortInfoResponseDto>> {
            return localVarFp.directionsControllerGetDirectionCourses(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DirectionsV1Api - object-oriented interface
 * @export
 * @class DirectionsV1Api
 * @extends {BaseAPI}
 */
export class DirectionsV1Api extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectionsV1Api
     */
    public directionsControllerGetAllDirections(options?: AxiosRequestConfig) {
        return DirectionsV1ApiFp(this.configuration).directionsControllerGetAllDirections(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get direction by site or landing url
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectionsV1Api
     */
    public directionsControllerGetDirectionByUrl(url: string, options?: AxiosRequestConfig) {
        return DirectionsV1ApiFp(this.configuration).directionsControllerGetDirectionByUrl(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all courses short info in career
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectionsV1Api
     */
    public directionsControllerGetDirectionCourses(id: string, options?: AxiosRequestConfig) {
        return DirectionsV1ApiFp(this.configuration).directionsControllerGetDirectionCourses(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DummyDataApi - axios parameter creator
 * @export
 */
export const DummyDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary [ADMIN] Answer flashcards from course
         * @param {AdminGenerateDummyFlashcardsRequestDto} adminGenerateDummyFlashcardsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyControllerAdminGenerateFlashcards: async (adminGenerateDummyFlashcardsRequestDto: AdminGenerateDummyFlashcardsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminGenerateDummyFlashcardsRequestDto' is not null or undefined
            assertParamExists('dummyControllerAdminGenerateFlashcards', 'adminGenerateDummyFlashcardsRequestDto', adminGenerateDummyFlashcardsRequestDto)
            const localVarPath = `/dummy/admin/flashcards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminGenerateDummyFlashcardsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [ADMIN] Create timed tests passages for customer
         * @param {AdminGenerateDummyTestsRequestDto} adminGenerateDummyTestsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyControllerAdminGenerateTimedTestPasssages: async (adminGenerateDummyTestsRequestDto: AdminGenerateDummyTestsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminGenerateDummyTestsRequestDto' is not null or undefined
            assertParamExists('dummyControllerAdminGenerateTimedTestPasssages', 'adminGenerateDummyTestsRequestDto', adminGenerateDummyTestsRequestDto)
            const localVarPath = `/dummy/admin/timed-tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminGenerateDummyTestsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [ADMIN] Create tutor tests passages for customer
         * @param {AdminGenerateDummyTestsRequestDto} adminGenerateDummyTestsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyControllerAdminGenerateTutorTestPassages: async (adminGenerateDummyTestsRequestDto: AdminGenerateDummyTestsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminGenerateDummyTestsRequestDto' is not null or undefined
            assertParamExists('dummyControllerAdminGenerateTutorTestPassages', 'adminGenerateDummyTestsRequestDto', adminGenerateDummyTestsRequestDto)
            const localVarPath = `/dummy/admin/tutor-tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminGenerateDummyTestsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [CUSTOMER] Answer flashcards from course
         * @param {GenerateDummyFlashcardsRequestDto} generateDummyFlashcardsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyControllerGenerateFlashcards: async (generateDummyFlashcardsRequestDto: GenerateDummyFlashcardsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateDummyFlashcardsRequestDto' is not null or undefined
            assertParamExists('dummyControllerGenerateFlashcards', 'generateDummyFlashcardsRequestDto', generateDummyFlashcardsRequestDto)
            const localVarPath = `/dummy/flashcards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateDummyFlashcardsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [CUSTOMER] Create timed tests passages
         * @param {GenerateDummyTestsRequestDto} generateDummyTestsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyControllerGenerateTimedTestPasssages: async (generateDummyTestsRequestDto: GenerateDummyTestsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateDummyTestsRequestDto' is not null or undefined
            assertParamExists('dummyControllerGenerateTimedTestPasssages', 'generateDummyTestsRequestDto', generateDummyTestsRequestDto)
            const localVarPath = `/dummy/timed-tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateDummyTestsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [CUSTOMER] Create tutor tests passages
         * @param {GenerateDummyTestsRequestDto} generateDummyTestsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyControllerGenerateTutorTestPassages: async (generateDummyTestsRequestDto: GenerateDummyTestsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateDummyTestsRequestDto' is not null or undefined
            assertParamExists('dummyControllerGenerateTutorTestPassages', 'generateDummyTestsRequestDto', generateDummyTestsRequestDto)
            const localVarPath = `/dummy/tutor-tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateDummyTestsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DummyDataApi - functional programming interface
 * @export
 */
export const DummyDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DummyDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary [ADMIN] Answer flashcards from course
         * @param {AdminGenerateDummyFlashcardsRequestDto} adminGenerateDummyFlashcardsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dummyControllerAdminGenerateFlashcards(adminGenerateDummyFlashcardsRequestDto: AdminGenerateDummyFlashcardsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateDummyFlashcardsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dummyControllerAdminGenerateFlashcards(adminGenerateDummyFlashcardsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [ADMIN] Create timed tests passages for customer
         * @param {AdminGenerateDummyTestsRequestDto} adminGenerateDummyTestsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dummyControllerAdminGenerateTimedTestPasssages(adminGenerateDummyTestsRequestDto: AdminGenerateDummyTestsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenerateDummyTestsResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dummyControllerAdminGenerateTimedTestPasssages(adminGenerateDummyTestsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [ADMIN] Create tutor tests passages for customer
         * @param {AdminGenerateDummyTestsRequestDto} adminGenerateDummyTestsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dummyControllerAdminGenerateTutorTestPassages(adminGenerateDummyTestsRequestDto: AdminGenerateDummyTestsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenerateDummyTestsResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dummyControllerAdminGenerateTutorTestPassages(adminGenerateDummyTestsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [CUSTOMER] Answer flashcards from course
         * @param {GenerateDummyFlashcardsRequestDto} generateDummyFlashcardsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dummyControllerGenerateFlashcards(generateDummyFlashcardsRequestDto: GenerateDummyFlashcardsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateDummyFlashcardsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dummyControllerGenerateFlashcards(generateDummyFlashcardsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [CUSTOMER] Create timed tests passages
         * @param {GenerateDummyTestsRequestDto} generateDummyTestsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dummyControllerGenerateTimedTestPasssages(generateDummyTestsRequestDto: GenerateDummyTestsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenerateDummyTestsResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dummyControllerGenerateTimedTestPasssages(generateDummyTestsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [CUSTOMER] Create tutor tests passages
         * @param {GenerateDummyTestsRequestDto} generateDummyTestsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dummyControllerGenerateTutorTestPassages(generateDummyTestsRequestDto: GenerateDummyTestsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenerateDummyTestsResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dummyControllerGenerateTutorTestPassages(generateDummyTestsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DummyDataApi - factory interface
 * @export
 */
export const DummyDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DummyDataApiFp(configuration)
    return {
        /**
         * 
         * @summary [ADMIN] Answer flashcards from course
         * @param {AdminGenerateDummyFlashcardsRequestDto} adminGenerateDummyFlashcardsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyControllerAdminGenerateFlashcards(adminGenerateDummyFlashcardsRequestDto: AdminGenerateDummyFlashcardsRequestDto, options?: any): AxiosPromise<GenerateDummyFlashcardsResponseDto> {
            return localVarFp.dummyControllerAdminGenerateFlashcards(adminGenerateDummyFlashcardsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [ADMIN] Create timed tests passages for customer
         * @param {AdminGenerateDummyTestsRequestDto} adminGenerateDummyTestsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyControllerAdminGenerateTimedTestPasssages(adminGenerateDummyTestsRequestDto: AdminGenerateDummyTestsRequestDto, options?: any): AxiosPromise<Array<GenerateDummyTestsResponseDto>> {
            return localVarFp.dummyControllerAdminGenerateTimedTestPasssages(adminGenerateDummyTestsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [ADMIN] Create tutor tests passages for customer
         * @param {AdminGenerateDummyTestsRequestDto} adminGenerateDummyTestsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyControllerAdminGenerateTutorTestPassages(adminGenerateDummyTestsRequestDto: AdminGenerateDummyTestsRequestDto, options?: any): AxiosPromise<Array<GenerateDummyTestsResponseDto>> {
            return localVarFp.dummyControllerAdminGenerateTutorTestPassages(adminGenerateDummyTestsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [CUSTOMER] Answer flashcards from course
         * @param {GenerateDummyFlashcardsRequestDto} generateDummyFlashcardsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyControllerGenerateFlashcards(generateDummyFlashcardsRequestDto: GenerateDummyFlashcardsRequestDto, options?: any): AxiosPromise<GenerateDummyFlashcardsResponseDto> {
            return localVarFp.dummyControllerGenerateFlashcards(generateDummyFlashcardsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [CUSTOMER] Create timed tests passages
         * @param {GenerateDummyTestsRequestDto} generateDummyTestsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyControllerGenerateTimedTestPasssages(generateDummyTestsRequestDto: GenerateDummyTestsRequestDto, options?: any): AxiosPromise<Array<GenerateDummyTestsResponseDto>> {
            return localVarFp.dummyControllerGenerateTimedTestPasssages(generateDummyTestsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [CUSTOMER] Create tutor tests passages
         * @param {GenerateDummyTestsRequestDto} generateDummyTestsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyControllerGenerateTutorTestPassages(generateDummyTestsRequestDto: GenerateDummyTestsRequestDto, options?: any): AxiosPromise<Array<GenerateDummyTestsResponseDto>> {
            return localVarFp.dummyControllerGenerateTutorTestPassages(generateDummyTestsRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DummyDataApi - object-oriented interface
 * @export
 * @class DummyDataApi
 * @extends {BaseAPI}
 */
export class DummyDataApi extends BaseAPI {
    /**
     * 
     * @summary [ADMIN] Answer flashcards from course
     * @param {AdminGenerateDummyFlashcardsRequestDto} adminGenerateDummyFlashcardsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DummyDataApi
     */
    public dummyControllerAdminGenerateFlashcards(adminGenerateDummyFlashcardsRequestDto: AdminGenerateDummyFlashcardsRequestDto, options?: AxiosRequestConfig) {
        return DummyDataApiFp(this.configuration).dummyControllerAdminGenerateFlashcards(adminGenerateDummyFlashcardsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [ADMIN] Create timed tests passages for customer
     * @param {AdminGenerateDummyTestsRequestDto} adminGenerateDummyTestsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DummyDataApi
     */
    public dummyControllerAdminGenerateTimedTestPasssages(adminGenerateDummyTestsRequestDto: AdminGenerateDummyTestsRequestDto, options?: AxiosRequestConfig) {
        return DummyDataApiFp(this.configuration).dummyControllerAdminGenerateTimedTestPasssages(adminGenerateDummyTestsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [ADMIN] Create tutor tests passages for customer
     * @param {AdminGenerateDummyTestsRequestDto} adminGenerateDummyTestsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DummyDataApi
     */
    public dummyControllerAdminGenerateTutorTestPassages(adminGenerateDummyTestsRequestDto: AdminGenerateDummyTestsRequestDto, options?: AxiosRequestConfig) {
        return DummyDataApiFp(this.configuration).dummyControllerAdminGenerateTutorTestPassages(adminGenerateDummyTestsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [CUSTOMER] Answer flashcards from course
     * @param {GenerateDummyFlashcardsRequestDto} generateDummyFlashcardsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DummyDataApi
     */
    public dummyControllerGenerateFlashcards(generateDummyFlashcardsRequestDto: GenerateDummyFlashcardsRequestDto, options?: AxiosRequestConfig) {
        return DummyDataApiFp(this.configuration).dummyControllerGenerateFlashcards(generateDummyFlashcardsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [CUSTOMER] Create timed tests passages
     * @param {GenerateDummyTestsRequestDto} generateDummyTestsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DummyDataApi
     */
    public dummyControllerGenerateTimedTestPasssages(generateDummyTestsRequestDto: GenerateDummyTestsRequestDto, options?: AxiosRequestConfig) {
        return DummyDataApiFp(this.configuration).dummyControllerGenerateTimedTestPasssages(generateDummyTestsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [CUSTOMER] Create tutor tests passages
     * @param {GenerateDummyTestsRequestDto} generateDummyTestsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DummyDataApi
     */
    public dummyControllerGenerateTutorTestPassages(generateDummyTestsRequestDto: GenerateDummyTestsRequestDto, options?: AxiosRequestConfig) {
        return DummyDataApiFp(this.configuration).dummyControllerGenerateTutorTestPassages(generateDummyTestsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FlashcardsV1Api - axios parameter creator
 * @export
 */
export const FlashcardsV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add question to question flashcards pack
         * @param {CreateQuestionFlashcardDto} createQuestionFlashcardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerAddToQuestionFlashcards: async (createQuestionFlashcardDto: CreateQuestionFlashcardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createQuestionFlashcardDto' is not null or undefined
            assertParamExists('flashcardControllerAddToQuestionFlashcards', 'createQuestionFlashcardDto', createQuestionFlashcardDto)
            const localVarPath = `/flashcards/packs/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createQuestionFlashcardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete flashcard pack
         * @param {string} packId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerCompleteFlashcardsPack: async (packId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packId' is not null or undefined
            assertParamExists('flashcardControllerCompleteFlashcardsPack', 'packId', packId)
            const localVarPath = `/flashcards/packs/{packId}/complete`
                .replace(`{${"packId"}}`, encodeURIComponent(String(packId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove question from question flashcards pack
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerDeleteQuestionFlashcard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('flashcardControllerDeleteQuestionFlashcard', 'id', id)
            const localVarPath = `/flashcards/packs/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current results for the flashcard pack
         * @param {'questions' | 'cards'} packType 
         * @param {string} [packId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerGetCurrentResults: async (packType: 'questions' | 'cards', packId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packType' is not null or undefined
            assertParamExists('flashcardControllerGetCurrentResults', 'packType', packType)
            const localVarPath = `/flashcards/packs/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (packType !== undefined) {
                localVarQueryParameter['packType'] = packType;
            }

            if (packId !== undefined) {
                localVarQueryParameter['packId'] = packId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get flashcards
         * @param {'questions' | 'cards'} packType 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [packId] 
         * @param {boolean} [onlyLearned] 
         * @param {'UNANSWERED' | 'NO' | 'KINDA' | 'YES'} [answer] 
         * @param {boolean} [onlyAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerGetFlashcards: async (packType: 'questions' | 'cards', page?: number, limit?: number, packId?: string, onlyLearned?: boolean, answer?: 'UNANSWERED' | 'NO' | 'KINDA' | 'YES', onlyAvailable?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packType' is not null or undefined
            assertParamExists('flashcardControllerGetFlashcards', 'packType', packType)
            const localVarPath = `/flashcards/packs/cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (packType !== undefined) {
                localVarQueryParameter['packType'] = packType;
            }

            if (packId !== undefined) {
                localVarQueryParameter['packId'] = packId;
            }

            if (onlyLearned !== undefined) {
                localVarQueryParameter['onlyLearned'] = onlyLearned;
            }

            if (answer !== undefined) {
                localVarQueryParameter['answer'] = answer;
            }

            if (onlyAvailable !== undefined) {
                localVarQueryParameter['onlyAvailable'] = onlyAvailable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get packs list with amount of cards in each pack
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerGetFlashcardsPacks: async (page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/flashcards/packs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get question pack with amount of questions in it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerGetQuestionFlashcardsPack: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/flashcards/packs/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset all flashcard results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerResetFlashcardResults: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/flashcards/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset flashcard results by selected pack
         * @param {'questions' | 'cards'} packType 
         * @param {string} [packId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerResetFlashcardResultsByPack: async (packType: 'questions' | 'cards', packId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packType' is not null or undefined
            assertParamExists('flashcardControllerResetFlashcardResultsByPack', 'packType', packType)
            const localVarPath = `/flashcards/packs/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (packType !== undefined) {
                localVarQueryParameter['packType'] = packType;
            }

            if (packId !== undefined) {
                localVarQueryParameter['packId'] = packId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save answer to the flashcard
         * @param {string} id 
         * @param {CreateFlashcardAnswerDto} createFlashcardAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerSaveFlashcardAnswer: async (id: string, createFlashcardAnswerDto: CreateFlashcardAnswerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('flashcardControllerSaveFlashcardAnswer', 'id', id)
            // verify required parameter 'createFlashcardAnswerDto' is not null or undefined
            assertParamExists('flashcardControllerSaveFlashcardAnswer', 'createFlashcardAnswerDto', createFlashcardAnswerDto)
            const localVarPath = `/flashcards/{id}/answers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFlashcardAnswerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlashcardsV1Api - functional programming interface
 * @export
 */
export const FlashcardsV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlashcardsV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add question to question flashcards pack
         * @param {CreateQuestionFlashcardDto} createQuestionFlashcardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardControllerAddToQuestionFlashcards(createQuestionFlashcardDto: CreateQuestionFlashcardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardControllerAddToQuestionFlashcards(createQuestionFlashcardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete flashcard pack
         * @param {string} packId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardControllerCompleteFlashcardsPack(packId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteFlashcardsPackResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardControllerCompleteFlashcardsPack(packId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove question from question flashcards pack
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardControllerDeleteQuestionFlashcard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardControllerDeleteQuestionFlashcard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current results for the flashcard pack
         * @param {'questions' | 'cards'} packType 
         * @param {string} [packId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardControllerGetCurrentResults(packType: 'questions' | 'cards', packId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCurrentResultsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardControllerGetCurrentResults(packType, packId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get flashcards
         * @param {'questions' | 'cards'} packType 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [packId] 
         * @param {boolean} [onlyLearned] 
         * @param {'UNANSWERED' | 'NO' | 'KINDA' | 'YES'} [answer] 
         * @param {boolean} [onlyAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardControllerGetFlashcards(packType: 'questions' | 'cards', page?: number, limit?: number, packId?: string, onlyLearned?: boolean, answer?: 'UNANSWERED' | 'NO' | 'KINDA' | 'YES', onlyAvailable?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlashcardControllerGetFlashcards200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardControllerGetFlashcards(packType, page, limit, packId, onlyLearned, answer, onlyAvailable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get packs list with amount of cards in each pack
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardControllerGetFlashcardsPacks(page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlashcardsPacksDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardControllerGetFlashcardsPacks(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get question pack with amount of questions in it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardControllerGetQuestionFlashcardsPack(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionFlashcardsPackDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardControllerGetQuestionFlashcardsPack(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset all flashcard results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardControllerResetFlashcardResults(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardControllerResetFlashcardResults(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset flashcard results by selected pack
         * @param {'questions' | 'cards'} packType 
         * @param {string} [packId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardControllerResetFlashcardResultsByPack(packType: 'questions' | 'cards', packId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardControllerResetFlashcardResultsByPack(packType, packId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save answer to the flashcard
         * @param {string} id 
         * @param {CreateFlashcardAnswerDto} createFlashcardAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardControllerSaveFlashcardAnswer(id: string, createFlashcardAnswerDto: CreateFlashcardAnswerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardControllerSaveFlashcardAnswer(id, createFlashcardAnswerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlashcardsV1Api - factory interface
 * @export
 */
export const FlashcardsV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlashcardsV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary Add question to question flashcards pack
         * @param {CreateQuestionFlashcardDto} createQuestionFlashcardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerAddToQuestionFlashcards(createQuestionFlashcardDto: CreateQuestionFlashcardDto, options?: any): AxiosPromise<void> {
            return localVarFp.flashcardControllerAddToQuestionFlashcards(createQuestionFlashcardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete flashcard pack
         * @param {string} packId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerCompleteFlashcardsPack(packId: string, options?: any): AxiosPromise<CompleteFlashcardsPackResponseDto> {
            return localVarFp.flashcardControllerCompleteFlashcardsPack(packId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove question from question flashcards pack
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerDeleteQuestionFlashcard(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.flashcardControllerDeleteQuestionFlashcard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current results for the flashcard pack
         * @param {'questions' | 'cards'} packType 
         * @param {string} [packId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerGetCurrentResults(packType: 'questions' | 'cards', packId?: string, options?: any): AxiosPromise<GetCurrentResultsResponseDto> {
            return localVarFp.flashcardControllerGetCurrentResults(packType, packId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get flashcards
         * @param {'questions' | 'cards'} packType 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [packId] 
         * @param {boolean} [onlyLearned] 
         * @param {'UNANSWERED' | 'NO' | 'KINDA' | 'YES'} [answer] 
         * @param {boolean} [onlyAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerGetFlashcards(packType: 'questions' | 'cards', page?: number, limit?: number, packId?: string, onlyLearned?: boolean, answer?: 'UNANSWERED' | 'NO' | 'KINDA' | 'YES', onlyAvailable?: boolean, options?: any): AxiosPromise<FlashcardControllerGetFlashcards200Response> {
            return localVarFp.flashcardControllerGetFlashcards(packType, page, limit, packId, onlyLearned, answer, onlyAvailable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get packs list with amount of cards in each pack
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerGetFlashcardsPacks(page?: number, limit?: number, options?: any): AxiosPromise<FlashcardsPacksDto> {
            return localVarFp.flashcardControllerGetFlashcardsPacks(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get question pack with amount of questions in it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerGetQuestionFlashcardsPack(options?: any): AxiosPromise<QuestionFlashcardsPackDto> {
            return localVarFp.flashcardControllerGetQuestionFlashcardsPack(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset all flashcard results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerResetFlashcardResults(options?: any): AxiosPromise<void> {
            return localVarFp.flashcardControllerResetFlashcardResults(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset flashcard results by selected pack
         * @param {'questions' | 'cards'} packType 
         * @param {string} [packId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerResetFlashcardResultsByPack(packType: 'questions' | 'cards', packId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.flashcardControllerResetFlashcardResultsByPack(packType, packId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save answer to the flashcard
         * @param {string} id 
         * @param {CreateFlashcardAnswerDto} createFlashcardAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardControllerSaveFlashcardAnswer(id: string, createFlashcardAnswerDto: CreateFlashcardAnswerDto, options?: any): AxiosPromise<void> {
            return localVarFp.flashcardControllerSaveFlashcardAnswer(id, createFlashcardAnswerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlashcardsV1Api - object-oriented interface
 * @export
 * @class FlashcardsV1Api
 * @extends {BaseAPI}
 */
export class FlashcardsV1Api extends BaseAPI {
    /**
     * 
     * @summary Add question to question flashcards pack
     * @param {CreateQuestionFlashcardDto} createQuestionFlashcardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlashcardsV1Api
     */
    public flashcardControllerAddToQuestionFlashcards(createQuestionFlashcardDto: CreateQuestionFlashcardDto, options?: AxiosRequestConfig) {
        return FlashcardsV1ApiFp(this.configuration).flashcardControllerAddToQuestionFlashcards(createQuestionFlashcardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete flashcard pack
     * @param {string} packId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlashcardsV1Api
     */
    public flashcardControllerCompleteFlashcardsPack(packId: string, options?: AxiosRequestConfig) {
        return FlashcardsV1ApiFp(this.configuration).flashcardControllerCompleteFlashcardsPack(packId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove question from question flashcards pack
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlashcardsV1Api
     */
    public flashcardControllerDeleteQuestionFlashcard(id: string, options?: AxiosRequestConfig) {
        return FlashcardsV1ApiFp(this.configuration).flashcardControllerDeleteQuestionFlashcard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current results for the flashcard pack
     * @param {'questions' | 'cards'} packType 
     * @param {string} [packId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlashcardsV1Api
     */
    public flashcardControllerGetCurrentResults(packType: 'questions' | 'cards', packId?: string, options?: AxiosRequestConfig) {
        return FlashcardsV1ApiFp(this.configuration).flashcardControllerGetCurrentResults(packType, packId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get flashcards
     * @param {'questions' | 'cards'} packType 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [packId] 
     * @param {boolean} [onlyLearned] 
     * @param {'UNANSWERED' | 'NO' | 'KINDA' | 'YES'} [answer] 
     * @param {boolean} [onlyAvailable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlashcardsV1Api
     */
    public flashcardControllerGetFlashcards(packType: 'questions' | 'cards', page?: number, limit?: number, packId?: string, onlyLearned?: boolean, answer?: 'UNANSWERED' | 'NO' | 'KINDA' | 'YES', onlyAvailable?: boolean, options?: AxiosRequestConfig) {
        return FlashcardsV1ApiFp(this.configuration).flashcardControllerGetFlashcards(packType, page, limit, packId, onlyLearned, answer, onlyAvailable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get packs list with amount of cards in each pack
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlashcardsV1Api
     */
    public flashcardControllerGetFlashcardsPacks(page?: number, limit?: number, options?: AxiosRequestConfig) {
        return FlashcardsV1ApiFp(this.configuration).flashcardControllerGetFlashcardsPacks(page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get question pack with amount of questions in it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlashcardsV1Api
     */
    public flashcardControllerGetQuestionFlashcardsPack(options?: AxiosRequestConfig) {
        return FlashcardsV1ApiFp(this.configuration).flashcardControllerGetQuestionFlashcardsPack(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset all flashcard results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlashcardsV1Api
     */
    public flashcardControllerResetFlashcardResults(options?: AxiosRequestConfig) {
        return FlashcardsV1ApiFp(this.configuration).flashcardControllerResetFlashcardResults(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset flashcard results by selected pack
     * @param {'questions' | 'cards'} packType 
     * @param {string} [packId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlashcardsV1Api
     */
    public flashcardControllerResetFlashcardResultsByPack(packType: 'questions' | 'cards', packId?: string, options?: AxiosRequestConfig) {
        return FlashcardsV1ApiFp(this.configuration).flashcardControllerResetFlashcardResultsByPack(packType, packId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save answer to the flashcard
     * @param {string} id 
     * @param {CreateFlashcardAnswerDto} createFlashcardAnswerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlashcardsV1Api
     */
    public flashcardControllerSaveFlashcardAnswer(id: string, createFlashcardAnswerDto: CreateFlashcardAnswerDto, options?: AxiosRequestConfig) {
        return FlashcardsV1ApiFp(this.configuration).flashcardControllerSaveFlashcardAnswer(id, createFlashcardAnswerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GuidesApi - axios parameter creator
 * @export
 */
export const GuidesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create note
         * @param {CreateNoteRequestDto} createNoteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerCreateNote: async (createNoteRequestDto: CreateNoteRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNoteRequestDto' is not null or undefined
            assertParamExists('guidesControllerCreateNote', 'createNoteRequestDto', createNoteRequestDto)
            const localVarPath = `/guides/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNoteRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get chapter of study guide
         * @param {string} guideId 
         * @param {string} chapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerGetChapterOfGuide: async (guideId: string, chapterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guideId' is not null or undefined
            assertParamExists('guidesControllerGetChapterOfGuide', 'guideId', guideId)
            // verify required parameter 'chapterId' is not null or undefined
            assertParamExists('guidesControllerGetChapterOfGuide', 'chapterId', chapterId)
            const localVarPath = `/guides/{guideId}/chapters/{chapterId}`
                .replace(`{${"guideId"}}`, encodeURIComponent(String(guideId)))
                .replace(`{${"chapterId"}}`, encodeURIComponent(String(chapterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get study guide for course
         * @param {string} guideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerGetGuide: async (guideId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guideId' is not null or undefined
            assertParamExists('guidesControllerGetGuide', 'guideId', guideId)
            const localVarPath = `/guides/{guideId}`
                .replace(`{${"guideId"}}`, encodeURIComponent(String(guideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get study guide by course id 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerGetGuideByCourseId: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('guidesControllerGetGuideByCourseId', 'courseId', courseId)
            const localVarPath = `/guides/courses/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get notes for guide
         * @param {string} guideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerGetNotes: async (guideId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guideId' is not null or undefined
            assertParamExists('guidesControllerGetNotes', 'guideId', guideId)
            const localVarPath = `/guides/{guideId}/notes`
                .replace(`{${"guideId"}}`, encodeURIComponent(String(guideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get table of content of study guide
         * @param {string} guideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerGetTableOfContentForGuide: async (guideId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guideId' is not null or undefined
            assertParamExists('guidesControllerGetTableOfContentForGuide', 'guideId', guideId)
            const localVarPath = `/guides/{guideId}/table-of-content`
                .replace(`{${"guideId"}}`, encodeURIComponent(String(guideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove note
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerRemoveNote: async (noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('guidesControllerRemoveNote', 'noteId', noteId)
            const localVarPath = `/guides/notes/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search text in guide
         * @param {string} guideId 
         * @param {SearchGuideTextRequestDto} searchGuideTextRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerSearchText: async (guideId: string, searchGuideTextRequestDto: SearchGuideTextRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guideId' is not null or undefined
            assertParamExists('guidesControllerSearchText', 'guideId', guideId)
            // verify required parameter 'searchGuideTextRequestDto' is not null or undefined
            assertParamExists('guidesControllerSearchText', 'searchGuideTextRequestDto', searchGuideTextRequestDto)
            const localVarPath = `/guides/{guideId}/search`
                .replace(`{${"guideId"}}`, encodeURIComponent(String(guideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchGuideTextRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update note
         * @param {string} noteId 
         * @param {UpdateNoteRequestDto} updateNoteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerUpdateNote: async (noteId: string, updateNoteRequestDto: UpdateNoteRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('guidesControllerUpdateNote', 'noteId', noteId)
            // verify required parameter 'updateNoteRequestDto' is not null or undefined
            assertParamExists('guidesControllerUpdateNote', 'updateNoteRequestDto', updateNoteRequestDto)
            const localVarPath = `/guides/notes/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNoteRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuidesApi - functional programming interface
 * @export
 */
export const GuidesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuidesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create note
         * @param {CreateNoteRequestDto} createNoteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidesControllerCreateNote(createNoteRequestDto: CreateNoteRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidesControllerCreateNote(createNoteRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get chapter of study guide
         * @param {string} guideId 
         * @param {string} chapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidesControllerGetChapterOfGuide(guideId: string, chapterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerChapterResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidesControllerGetChapterOfGuide(guideId, chapterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get study guide for course
         * @param {string} guideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidesControllerGetGuide(guideId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerGuideResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidesControllerGetGuide(guideId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get study guide by course id 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidesControllerGetGuideByCourseId(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerGuideResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidesControllerGetGuideByCourseId(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get notes for guide
         * @param {string} guideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidesControllerGetNotes(guideId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetChaptersWithNotesResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidesControllerGetNotes(guideId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get table of content of study guide
         * @param {string} guideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidesControllerGetTableOfContentForGuide(guideId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerTableOfGuideContentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidesControllerGetTableOfContentForGuide(guideId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove note
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidesControllerRemoveNote(noteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidesControllerRemoveNote(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search text in guide
         * @param {string} guideId 
         * @param {SearchGuideTextRequestDto} searchGuideTextRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidesControllerSearchText(guideId: string, searchGuideTextRequestDto: SearchGuideTextRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchGuideTextResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidesControllerSearchText(guideId, searchGuideTextRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update note
         * @param {string} noteId 
         * @param {UpdateNoteRequestDto} updateNoteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidesControllerUpdateNote(noteId: string, updateNoteRequestDto: UpdateNoteRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidesControllerUpdateNote(noteId, updateNoteRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GuidesApi - factory interface
 * @export
 */
export const GuidesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuidesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create note
         * @param {CreateNoteRequestDto} createNoteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerCreateNote(createNoteRequestDto: CreateNoteRequestDto, options?: any): AxiosPromise<NoteResponseDto> {
            return localVarFp.guidesControllerCreateNote(createNoteRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get chapter of study guide
         * @param {string} guideId 
         * @param {string} chapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerGetChapterOfGuide(guideId: string, chapterId: string, options?: any): AxiosPromise<CustomerChapterResponseDto> {
            return localVarFp.guidesControllerGetChapterOfGuide(guideId, chapterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get study guide for course
         * @param {string} guideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerGetGuide(guideId: string, options?: any): AxiosPromise<CustomerGuideResponseDto> {
            return localVarFp.guidesControllerGetGuide(guideId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get study guide by course id 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerGetGuideByCourseId(courseId: string, options?: any): AxiosPromise<CustomerGuideResponseDto> {
            return localVarFp.guidesControllerGetGuideByCourseId(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get notes for guide
         * @param {string} guideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerGetNotes(guideId: string, options?: any): AxiosPromise<Array<GetChaptersWithNotesResponseDto>> {
            return localVarFp.guidesControllerGetNotes(guideId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get table of content of study guide
         * @param {string} guideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerGetTableOfContentForGuide(guideId: string, options?: any): AxiosPromise<CustomerTableOfGuideContentResponseDto> {
            return localVarFp.guidesControllerGetTableOfContentForGuide(guideId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove note
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerRemoveNote(noteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.guidesControllerRemoveNote(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search text in guide
         * @param {string} guideId 
         * @param {SearchGuideTextRequestDto} searchGuideTextRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerSearchText(guideId: string, searchGuideTextRequestDto: SearchGuideTextRequestDto, options?: any): AxiosPromise<SearchGuideTextResponseDto> {
            return localVarFp.guidesControllerSearchText(guideId, searchGuideTextRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update note
         * @param {string} noteId 
         * @param {UpdateNoteRequestDto} updateNoteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidesControllerUpdateNote(noteId: string, updateNoteRequestDto: UpdateNoteRequestDto, options?: any): AxiosPromise<NoteResponseDto> {
            return localVarFp.guidesControllerUpdateNote(noteId, updateNoteRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuidesApi - object-oriented interface
 * @export
 * @class GuidesApi
 * @extends {BaseAPI}
 */
export class GuidesApi extends BaseAPI {
    /**
     * 
     * @summary Create note
     * @param {CreateNoteRequestDto} createNoteRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidesApi
     */
    public guidesControllerCreateNote(createNoteRequestDto: CreateNoteRequestDto, options?: AxiosRequestConfig) {
        return GuidesApiFp(this.configuration).guidesControllerCreateNote(createNoteRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get chapter of study guide
     * @param {string} guideId 
     * @param {string} chapterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidesApi
     */
    public guidesControllerGetChapterOfGuide(guideId: string, chapterId: string, options?: AxiosRequestConfig) {
        return GuidesApiFp(this.configuration).guidesControllerGetChapterOfGuide(guideId, chapterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get study guide for course
     * @param {string} guideId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidesApi
     */
    public guidesControllerGetGuide(guideId: string, options?: AxiosRequestConfig) {
        return GuidesApiFp(this.configuration).guidesControllerGetGuide(guideId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get study guide by course id 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidesApi
     */
    public guidesControllerGetGuideByCourseId(courseId: string, options?: AxiosRequestConfig) {
        return GuidesApiFp(this.configuration).guidesControllerGetGuideByCourseId(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get notes for guide
     * @param {string} guideId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidesApi
     */
    public guidesControllerGetNotes(guideId: string, options?: AxiosRequestConfig) {
        return GuidesApiFp(this.configuration).guidesControllerGetNotes(guideId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get table of content of study guide
     * @param {string} guideId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidesApi
     */
    public guidesControllerGetTableOfContentForGuide(guideId: string, options?: AxiosRequestConfig) {
        return GuidesApiFp(this.configuration).guidesControllerGetTableOfContentForGuide(guideId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove note
     * @param {string} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidesApi
     */
    public guidesControllerRemoveNote(noteId: string, options?: AxiosRequestConfig) {
        return GuidesApiFp(this.configuration).guidesControllerRemoveNote(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search text in guide
     * @param {string} guideId 
     * @param {SearchGuideTextRequestDto} searchGuideTextRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidesApi
     */
    public guidesControllerSearchText(guideId: string, searchGuideTextRequestDto: SearchGuideTextRequestDto, options?: AxiosRequestConfig) {
        return GuidesApiFp(this.configuration).guidesControllerSearchText(guideId, searchGuideTextRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update note
     * @param {string} noteId 
     * @param {UpdateNoteRequestDto} updateNoteRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidesApi
     */
    public guidesControllerUpdateNote(noteId: string, updateNoteRequestDto: UpdateNoteRequestDto, options?: AxiosRequestConfig) {
        return GuidesApiFp(this.configuration).guidesControllerUpdateNote(noteId, updateNoteRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MnemonicCardsV1Api - axios parameter creator
 * @export
 */
export const MnemonicCardsV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get mnemonic cards by pack
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mnemonicCardsControllerGetMnemonicCards: async (id: string, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mnemonicCardsControllerGetMnemonicCards', 'id', id)
            const localVarPath = `/mnemonicCards/packs/{id}/cards`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many mnemonic card packs
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mnemonicCardsControllerGetMnemonicCardsPacks: async (page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mnemonicCards/packs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MnemonicCardsV1Api - functional programming interface
 * @export
 */
export const MnemonicCardsV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MnemonicCardsV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get mnemonic cards by pack
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mnemonicCardsControllerGetMnemonicCards(id: string, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MnemonicCardsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mnemonicCardsControllerGetMnemonicCards(id, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many mnemonic card packs
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mnemonicCardsControllerGetMnemonicCardsPacks(page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MnemonicCardsPacksDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mnemonicCardsControllerGetMnemonicCardsPacks(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MnemonicCardsV1Api - factory interface
 * @export
 */
export const MnemonicCardsV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MnemonicCardsV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary Get mnemonic cards by pack
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mnemonicCardsControllerGetMnemonicCards(id: string, page?: number, limit?: number, options?: any): AxiosPromise<MnemonicCardsDto> {
            return localVarFp.mnemonicCardsControllerGetMnemonicCards(id, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many mnemonic card packs
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mnemonicCardsControllerGetMnemonicCardsPacks(page?: number, limit?: number, options?: any): AxiosPromise<MnemonicCardsPacksDto> {
            return localVarFp.mnemonicCardsControllerGetMnemonicCardsPacks(page, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MnemonicCardsV1Api - object-oriented interface
 * @export
 * @class MnemonicCardsV1Api
 * @extends {BaseAPI}
 */
export class MnemonicCardsV1Api extends BaseAPI {
    /**
     * 
     * @summary Get mnemonic cards by pack
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnemonicCardsV1Api
     */
    public mnemonicCardsControllerGetMnemonicCards(id: string, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return MnemonicCardsV1ApiFp(this.configuration).mnemonicCardsControllerGetMnemonicCards(id, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many mnemonic card packs
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnemonicCardsV1Api
     */
    public mnemonicCardsControllerGetMnemonicCardsPacks(page?: number, limit?: number, options?: AxiosRequestConfig) {
        return MnemonicCardsV1ApiFp(this.configuration).mnemonicCardsControllerGetMnemonicCardsPacks(page, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsV1Api - axios parameter creator
 * @export
 */
export const PaymentsV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApplyCancellationPromotionRequestDto} applyCancellationPromotionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerApplyCancellationPromotion: async (applyCancellationPromotionRequestDto: ApplyCancellationPromotionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyCancellationPromotionRequestDto' is not null or undefined
            assertParamExists('paymentsControllerApplyCancellationPromotion', 'applyCancellationPromotionRequestDto', applyCancellationPromotionRequestDto)
            const localVarPath = `/payments/apply-cancellation-promotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyCancellationPromotionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePromocodeRequestDto} changePromocodeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerChangePromocode: async (changePromocodeRequestDto: ChangePromocodeRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePromocodeRequestDto' is not null or undefined
            assertParamExists('paymentsControllerChangePromocode', 'changePromocodeRequestDto', changePromocodeRequestDto)
            const localVarPath = `/payments/change-promocode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePromocodeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePromocodeRequestDto} deletePromocodeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerDeletePromocode: async (deletePromocodeRequestDto: DeletePromocodeRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePromocodeRequestDto' is not null or undefined
            assertParamExists('paymentsControllerDeletePromocode', 'deletePromocodeRequestDto', deletePromocodeRequestDto)
            const localVarPath = `/payments/delete-promocode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePromocodeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PauseSubscriptionRequestDto} pauseSubscriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerPauseSubscription: async (pauseSubscriptionRequestDto: PauseSubscriptionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pauseSubscriptionRequestDto' is not null or undefined
            assertParamExists('paymentsControllerPauseSubscription', 'pauseSubscriptionRequestDto', pauseSubscriptionRequestDto)
            const localVarPath = `/payments/pause-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pauseSubscriptionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PurchaseCourseRequestDto} purchaseCourseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerPurchaseCourse: async (purchaseCourseRequestDto: PurchaseCourseRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseCourseRequestDto' is not null or undefined
            assertParamExists('paymentsControllerPurchaseCourse', 'purchaseCourseRequestDto', purchaseCourseRequestDto)
            const localVarPath = `/payments/purchase-course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseCourseRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PurchaseCourseInInstallmentDto} purchaseCourseInInstallmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerPurchaseCourseInInstallment: async (purchaseCourseInInstallmentDto: PurchaseCourseInInstallmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseCourseInInstallmentDto' is not null or undefined
            assertParamExists('paymentsControllerPurchaseCourseInInstallment', 'purchaseCourseInInstallmentDto', purchaseCourseInInstallmentDto)
            const localVarPath = `/payments/purchase-course-in-installment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseCourseInInstallmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RestoreInstallmentPaymentDto} restoreInstallmentPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerRestoreInstallmentPayment: async (restoreInstallmentPaymentDto: RestoreInstallmentPaymentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restoreInstallmentPaymentDto' is not null or undefined
            assertParamExists('paymentsControllerRestoreInstallmentPayment', 'restoreInstallmentPaymentDto', restoreInstallmentPaymentDto)
            const localVarPath = `/payments/restore-installment-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restoreInstallmentPaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubscribeToCourseRequestDto} subscribeToCourseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSubscribeToCourse: async (subscribeToCourseRequestDto: SubscribeToCourseRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscribeToCourseRequestDto' is not null or undefined
            assertParamExists('paymentsControllerSubscribeToCourse', 'subscribeToCourseRequestDto', subscribeToCourseRequestDto)
            const localVarPath = `/payments/subscribe-to-course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscribeToCourseRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UnpauseSubscriptionRequestDto} unpauseSubscriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerUnpauseSubscription: async (unpauseSubscriptionRequestDto: UnpauseSubscriptionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unpauseSubscriptionRequestDto' is not null or undefined
            assertParamExists('paymentsControllerUnpauseSubscription', 'unpauseSubscriptionRequestDto', unpauseSubscriptionRequestDto)
            const localVarPath = `/payments/unpause-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unpauseSubscriptionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateInstallmentPromocodeDto} updateInstallmentPromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerUpdateInstallmentPromocode: async (updateInstallmentPromocodeDto: UpdateInstallmentPromocodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateInstallmentPromocodeDto' is not null or undefined
            assertParamExists('paymentsControllerUpdateInstallmentPromocode', 'updateInstallmentPromocodeDto', updateInstallmentPromocodeDto)
            const localVarPath = `/payments/installment-promocode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInstallmentPromocodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateConfirmationTokenRequestDto} validateConfirmationTokenRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerValidateConfirmationToken: async (validateConfirmationTokenRequestDto: ValidateConfirmationTokenRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateConfirmationTokenRequestDto' is not null or undefined
            assertParamExists('paymentsControllerValidateConfirmationToken', 'validateConfirmationTokenRequestDto', validateConfirmationTokenRequestDto)
            const localVarPath = `/payments/validate-confirmation-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateConfirmationTokenRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsV1Api - functional programming interface
 * @export
 */
export const PaymentsV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApplyCancellationPromotionRequestDto} applyCancellationPromotionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerApplyCancellationPromotion(applyCancellationPromotionRequestDto: ApplyCancellationPromotionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerApplyCancellationPromotion(applyCancellationPromotionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChangePromocodeRequestDto} changePromocodeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerChangePromocode(changePromocodeRequestDto: ChangePromocodeRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerChangePromocode(changePromocodeRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePromocodeRequestDto} deletePromocodeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerDeletePromocode(deletePromocodeRequestDto: DeletePromocodeRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerDeletePromocode(deletePromocodeRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PauseSubscriptionRequestDto} pauseSubscriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerPauseSubscription(pauseSubscriptionRequestDto: PauseSubscriptionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerPauseSubscription(pauseSubscriptionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PurchaseCourseRequestDto} purchaseCourseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerPurchaseCourse(purchaseCourseRequestDto: PurchaseCourseRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerPurchaseCourse(purchaseCourseRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PurchaseCourseInInstallmentDto} purchaseCourseInInstallmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerPurchaseCourseInInstallment(purchaseCourseInInstallmentDto: PurchaseCourseInInstallmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerPurchaseCourseInInstallment(purchaseCourseInInstallmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RestoreInstallmentPaymentDto} restoreInstallmentPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerRestoreInstallmentPayment(restoreInstallmentPaymentDto: RestoreInstallmentPaymentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerRestoreInstallmentPayment(restoreInstallmentPaymentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SubscribeToCourseRequestDto} subscribeToCourseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerSubscribeToCourse(subscribeToCourseRequestDto: SubscribeToCourseRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerSubscribeToCourse(subscribeToCourseRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UnpauseSubscriptionRequestDto} unpauseSubscriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerUnpauseSubscription(unpauseSubscriptionRequestDto: UnpauseSubscriptionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerUnpauseSubscription(unpauseSubscriptionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateInstallmentPromocodeDto} updateInstallmentPromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerUpdateInstallmentPromocode(updateInstallmentPromocodeDto: UpdateInstallmentPromocodeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerUpdateInstallmentPromocode(updateInstallmentPromocodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ValidateConfirmationTokenRequestDto} validateConfirmationTokenRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerValidateConfirmationToken(validateConfirmationTokenRequestDto: ValidateConfirmationTokenRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateConfirmationTokenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerValidateConfirmationToken(validateConfirmationTokenRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsV1Api - factory interface
 * @export
 */
export const PaymentsV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsV1ApiFp(configuration)
    return {
        /**
         * 
         * @param {ApplyCancellationPromotionRequestDto} applyCancellationPromotionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerApplyCancellationPromotion(applyCancellationPromotionRequestDto: ApplyCancellationPromotionRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.paymentsControllerApplyCancellationPromotion(applyCancellationPromotionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangePromocodeRequestDto} changePromocodeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerChangePromocode(changePromocodeRequestDto: ChangePromocodeRequestDto, options?: any): AxiosPromise<InvoiceResponseDto> {
            return localVarFp.paymentsControllerChangePromocode(changePromocodeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePromocodeRequestDto} deletePromocodeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerDeletePromocode(deletePromocodeRequestDto: DeletePromocodeRequestDto, options?: any): AxiosPromise<InvoiceResponseDto> {
            return localVarFp.paymentsControllerDeletePromocode(deletePromocodeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PauseSubscriptionRequestDto} pauseSubscriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerPauseSubscription(pauseSubscriptionRequestDto: PauseSubscriptionRequestDto, options?: any): AxiosPromise<PaymentDto> {
            return localVarFp.paymentsControllerPauseSubscription(pauseSubscriptionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PurchaseCourseRequestDto} purchaseCourseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerPurchaseCourse(purchaseCourseRequestDto: PurchaseCourseRequestDto, options?: any): AxiosPromise<InvoiceResponseDto> {
            return localVarFp.paymentsControllerPurchaseCourse(purchaseCourseRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PurchaseCourseInInstallmentDto} purchaseCourseInInstallmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerPurchaseCourseInInstallment(purchaseCourseInInstallmentDto: PurchaseCourseInInstallmentDto, options?: any): AxiosPromise<InvoiceResponseDto> {
            return localVarFp.paymentsControllerPurchaseCourseInInstallment(purchaseCourseInInstallmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RestoreInstallmentPaymentDto} restoreInstallmentPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerRestoreInstallmentPayment(restoreInstallmentPaymentDto: RestoreInstallmentPaymentDto, options?: any): AxiosPromise<PaymentDto> {
            return localVarFp.paymentsControllerRestoreInstallmentPayment(restoreInstallmentPaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubscribeToCourseRequestDto} subscribeToCourseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSubscribeToCourse(subscribeToCourseRequestDto: SubscribeToCourseRequestDto, options?: any): AxiosPromise<InvoiceResponseDto> {
            return localVarFp.paymentsControllerSubscribeToCourse(subscribeToCourseRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnpauseSubscriptionRequestDto} unpauseSubscriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerUnpauseSubscription(unpauseSubscriptionRequestDto: UnpauseSubscriptionRequestDto, options?: any): AxiosPromise<PaymentDto> {
            return localVarFp.paymentsControllerUnpauseSubscription(unpauseSubscriptionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateInstallmentPromocodeDto} updateInstallmentPromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerUpdateInstallmentPromocode(updateInstallmentPromocodeDto: UpdateInstallmentPromocodeDto, options?: any): AxiosPromise<void> {
            return localVarFp.paymentsControllerUpdateInstallmentPromocode(updateInstallmentPromocodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidateConfirmationTokenRequestDto} validateConfirmationTokenRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerValidateConfirmationToken(validateConfirmationTokenRequestDto: ValidateConfirmationTokenRequestDto, options?: any): AxiosPromise<ValidateConfirmationTokenResponseDto> {
            return localVarFp.paymentsControllerValidateConfirmationToken(validateConfirmationTokenRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsV1Api - object-oriented interface
 * @export
 * @class PaymentsV1Api
 * @extends {BaseAPI}
 */
export class PaymentsV1Api extends BaseAPI {
    /**
     * 
     * @param {ApplyCancellationPromotionRequestDto} applyCancellationPromotionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsV1Api
     */
    public paymentsControllerApplyCancellationPromotion(applyCancellationPromotionRequestDto: ApplyCancellationPromotionRequestDto, options?: AxiosRequestConfig) {
        return PaymentsV1ApiFp(this.configuration).paymentsControllerApplyCancellationPromotion(applyCancellationPromotionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangePromocodeRequestDto} changePromocodeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsV1Api
     */
    public paymentsControllerChangePromocode(changePromocodeRequestDto: ChangePromocodeRequestDto, options?: AxiosRequestConfig) {
        return PaymentsV1ApiFp(this.configuration).paymentsControllerChangePromocode(changePromocodeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePromocodeRequestDto} deletePromocodeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsV1Api
     */
    public paymentsControllerDeletePromocode(deletePromocodeRequestDto: DeletePromocodeRequestDto, options?: AxiosRequestConfig) {
        return PaymentsV1ApiFp(this.configuration).paymentsControllerDeletePromocode(deletePromocodeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PauseSubscriptionRequestDto} pauseSubscriptionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsV1Api
     */
    public paymentsControllerPauseSubscription(pauseSubscriptionRequestDto: PauseSubscriptionRequestDto, options?: AxiosRequestConfig) {
        return PaymentsV1ApiFp(this.configuration).paymentsControllerPauseSubscription(pauseSubscriptionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PurchaseCourseRequestDto} purchaseCourseRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsV1Api
     */
    public paymentsControllerPurchaseCourse(purchaseCourseRequestDto: PurchaseCourseRequestDto, options?: AxiosRequestConfig) {
        return PaymentsV1ApiFp(this.configuration).paymentsControllerPurchaseCourse(purchaseCourseRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PurchaseCourseInInstallmentDto} purchaseCourseInInstallmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsV1Api
     */
    public paymentsControllerPurchaseCourseInInstallment(purchaseCourseInInstallmentDto: PurchaseCourseInInstallmentDto, options?: AxiosRequestConfig) {
        return PaymentsV1ApiFp(this.configuration).paymentsControllerPurchaseCourseInInstallment(purchaseCourseInInstallmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestoreInstallmentPaymentDto} restoreInstallmentPaymentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsV1Api
     */
    public paymentsControllerRestoreInstallmentPayment(restoreInstallmentPaymentDto: RestoreInstallmentPaymentDto, options?: AxiosRequestConfig) {
        return PaymentsV1ApiFp(this.configuration).paymentsControllerRestoreInstallmentPayment(restoreInstallmentPaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubscribeToCourseRequestDto} subscribeToCourseRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsV1Api
     */
    public paymentsControllerSubscribeToCourse(subscribeToCourseRequestDto: SubscribeToCourseRequestDto, options?: AxiosRequestConfig) {
        return PaymentsV1ApiFp(this.configuration).paymentsControllerSubscribeToCourse(subscribeToCourseRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnpauseSubscriptionRequestDto} unpauseSubscriptionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsV1Api
     */
    public paymentsControllerUnpauseSubscription(unpauseSubscriptionRequestDto: UnpauseSubscriptionRequestDto, options?: AxiosRequestConfig) {
        return PaymentsV1ApiFp(this.configuration).paymentsControllerUnpauseSubscription(unpauseSubscriptionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateInstallmentPromocodeDto} updateInstallmentPromocodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsV1Api
     */
    public paymentsControllerUpdateInstallmentPromocode(updateInstallmentPromocodeDto: UpdateInstallmentPromocodeDto, options?: AxiosRequestConfig) {
        return PaymentsV1ApiFp(this.configuration).paymentsControllerUpdateInstallmentPromocode(updateInstallmentPromocodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ValidateConfirmationTokenRequestDto} validateConfirmationTokenRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsV1Api
     */
    public paymentsControllerValidateConfirmationToken(validateConfirmationTokenRequestDto: ValidateConfirmationTokenRequestDto, options?: AxiosRequestConfig) {
        return PaymentsV1ApiFp(this.configuration).paymentsControllerValidateConfirmationToken(validateConfirmationTokenRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlansV1Api - axios parameter creator
 * @export
 */
export const PlansV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change current plan
         * @param {ChangeCurrentPlanDto} changeCurrentPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerChangeCurrentPlan: async (changeCurrentPlanDto: ChangeCurrentPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeCurrentPlanDto' is not null or undefined
            assertParamExists('planControllerChangeCurrentPlan', 'changeCurrentPlanDto', changeCurrentPlanDto)
            const localVarPath = `/plans/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeCurrentPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve plan phases
         * @param {string} planId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetPlanPhases: async (planId: string, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('planControllerGetPlanPhases', 'planId', planId)
            const localVarPath = `/plans/{planId}/phases`
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve course plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetPlans: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlansV1Api - functional programming interface
 * @export
 */
export const PlansV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlansV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change current plan
         * @param {ChangeCurrentPlanDto} changeCurrentPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerChangeCurrentPlan(changeCurrentPlanDto: ChangeCurrentPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerChangeCurrentPlan(changeCurrentPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve plan phases
         * @param {string} planId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerGetPlanPhases(planId: string, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanPhasesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerGetPlanPhases(planId, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve course plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerGetPlans(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlansDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerGetPlans(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlansV1Api - factory interface
 * @export
 */
export const PlansV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlansV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary Change current plan
         * @param {ChangeCurrentPlanDto} changeCurrentPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerChangeCurrentPlan(changeCurrentPlanDto: ChangeCurrentPlanDto, options?: any): AxiosPromise<void> {
            return localVarFp.planControllerChangeCurrentPlan(changeCurrentPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve plan phases
         * @param {string} planId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetPlanPhases(planId: string, page?: number, limit?: number, options?: any): AxiosPromise<PlanPhasesDto> {
            return localVarFp.planControllerGetPlanPhases(planId, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve course plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetPlans(options?: any): AxiosPromise<PlansDto> {
            return localVarFp.planControllerGetPlans(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlansV1Api - object-oriented interface
 * @export
 * @class PlansV1Api
 * @extends {BaseAPI}
 */
export class PlansV1Api extends BaseAPI {
    /**
     * 
     * @summary Change current plan
     * @param {ChangeCurrentPlanDto} changeCurrentPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansV1Api
     */
    public planControllerChangeCurrentPlan(changeCurrentPlanDto: ChangeCurrentPlanDto, options?: AxiosRequestConfig) {
        return PlansV1ApiFp(this.configuration).planControllerChangeCurrentPlan(changeCurrentPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve plan phases
     * @param {string} planId 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansV1Api
     */
    public planControllerGetPlanPhases(planId: string, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return PlansV1ApiFp(this.configuration).planControllerGetPlanPhases(planId, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve course plans
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansV1Api
     */
    public planControllerGetPlans(options?: AxiosRequestConfig) {
        return PlansV1ApiFp(this.configuration).planControllerGetPlans(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionChaptersApi - axios parameter creator
 * @export
 */
export const QuestionChaptersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get least known question chapters in course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionChaptersControllerGetLeastKnownChapters: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('questionChaptersControllerGetLeastKnownChapters', 'courseId', courseId)
            const localVarPath = `/question-chapters/least-known`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get question chapters
         * @param {GetQuestionChaptersRequestDto} getQuestionChaptersRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionChaptersControllerGetMany: async (getQuestionChaptersRequestDto: GetQuestionChaptersRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getQuestionChaptersRequestDto' is not null or undefined
            assertParamExists('questionChaptersControllerGetMany', 'getQuestionChaptersRequestDto', getQuestionChaptersRequestDto)
            const localVarPath = `/question-chapters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getQuestionChaptersRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionChaptersApi - functional programming interface
 * @export
 */
export const QuestionChaptersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionChaptersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get least known question chapters in course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionChaptersControllerGetLeastKnownChapters(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeastKnownCategoryResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionChaptersControllerGetLeastKnownChapters(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get question chapters
         * @param {GetQuestionChaptersRequestDto} getQuestionChaptersRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionChaptersControllerGetMany(getQuestionChaptersRequestDto: GetQuestionChaptersRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetQuestionChaptersResponseItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionChaptersControllerGetMany(getQuestionChaptersRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionChaptersApi - factory interface
 * @export
 */
export const QuestionChaptersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionChaptersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get least known question chapters in course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionChaptersControllerGetLeastKnownChapters(courseId: string, options?: any): AxiosPromise<Array<LeastKnownCategoryResponseDto>> {
            return localVarFp.questionChaptersControllerGetLeastKnownChapters(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get question chapters
         * @param {GetQuestionChaptersRequestDto} getQuestionChaptersRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionChaptersControllerGetMany(getQuestionChaptersRequestDto: GetQuestionChaptersRequestDto, options?: any): AxiosPromise<Array<GetQuestionChaptersResponseItem>> {
            return localVarFp.questionChaptersControllerGetMany(getQuestionChaptersRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionChaptersApi - object-oriented interface
 * @export
 * @class QuestionChaptersApi
 * @extends {BaseAPI}
 */
export class QuestionChaptersApi extends BaseAPI {
    /**
     * 
     * @summary Get least known question chapters in course
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionChaptersApi
     */
    public questionChaptersControllerGetLeastKnownChapters(courseId: string, options?: AxiosRequestConfig) {
        return QuestionChaptersApiFp(this.configuration).questionChaptersControllerGetLeastKnownChapters(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get question chapters
     * @param {GetQuestionChaptersRequestDto} getQuestionChaptersRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionChaptersApi
     */
    public questionChaptersControllerGetMany(getQuestionChaptersRequestDto: GetQuestionChaptersRequestDto, options?: AxiosRequestConfig) {
        return QuestionChaptersApiFp(this.configuration).questionChaptersControllerGetMany(getQuestionChaptersRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionDomainsApi - axios parameter creator
 * @export
 */
export const QuestionDomainsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get least known question domains in course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionDomainsControllerGetLeastKnownDomains: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('questionDomainsControllerGetLeastKnownDomains', 'courseId', courseId)
            const localVarPath = `/question-domains/least-known`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get question domains
         * @param {GetQuestionDomainsRequestDto} getQuestionDomainsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionDomainsControllerGetQuestionDomains: async (getQuestionDomainsRequestDto: GetQuestionDomainsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getQuestionDomainsRequestDto' is not null or undefined
            assertParamExists('questionDomainsControllerGetQuestionDomains', 'getQuestionDomainsRequestDto', getQuestionDomainsRequestDto)
            const localVarPath = `/question-domains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getQuestionDomainsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionDomainsApi - functional programming interface
 * @export
 */
export const QuestionDomainsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionDomainsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get least known question domains in course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionDomainsControllerGetLeastKnownDomains(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeastKnownCategoryResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionDomainsControllerGetLeastKnownDomains(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get question domains
         * @param {GetQuestionDomainsRequestDto} getQuestionDomainsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionDomainsControllerGetQuestionDomains(getQuestionDomainsRequestDto: GetQuestionDomainsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetQuestionDomainsResponseItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionDomainsControllerGetQuestionDomains(getQuestionDomainsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionDomainsApi - factory interface
 * @export
 */
export const QuestionDomainsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionDomainsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get least known question domains in course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionDomainsControllerGetLeastKnownDomains(courseId: string, options?: any): AxiosPromise<Array<LeastKnownCategoryResponseDto>> {
            return localVarFp.questionDomainsControllerGetLeastKnownDomains(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get question domains
         * @param {GetQuestionDomainsRequestDto} getQuestionDomainsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionDomainsControllerGetQuestionDomains(getQuestionDomainsRequestDto: GetQuestionDomainsRequestDto, options?: any): AxiosPromise<Array<GetQuestionDomainsResponseItem>> {
            return localVarFp.questionDomainsControllerGetQuestionDomains(getQuestionDomainsRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionDomainsApi - object-oriented interface
 * @export
 * @class QuestionDomainsApi
 * @extends {BaseAPI}
 */
export class QuestionDomainsApi extends BaseAPI {
    /**
     * 
     * @summary Get least known question domains in course
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionDomainsApi
     */
    public questionDomainsControllerGetLeastKnownDomains(courseId: string, options?: AxiosRequestConfig) {
        return QuestionDomainsApiFp(this.configuration).questionDomainsControllerGetLeastKnownDomains(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get question domains
     * @param {GetQuestionDomainsRequestDto} getQuestionDomainsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionDomainsApi
     */
    public questionDomainsControllerGetQuestionDomains(getQuestionDomainsRequestDto: GetQuestionDomainsRequestDto, options?: AxiosRequestConfig) {
        return QuestionDomainsApiFp(this.configuration).questionDomainsControllerGetQuestionDomains(getQuestionDomainsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionsApi - axios parameter creator
 * @export
 */
export const QuestionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Mark a question
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markedQuestionsControllerCreate: async (questionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('markedQuestionsControllerCreate', 'questionId', questionId)
            const localVarPath = `/questions/{question_id}/mark`
                .replace(`{${"question_id"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unmark a question
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markedQuestionsControllerDelete: async (questionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('markedQuestionsControllerDelete', 'questionId', questionId)
            const localVarPath = `/questions/{question_id}/unmark`
                .replace(`{${"question_id"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionsApi - functional programming interface
 * @export
 */
export const QuestionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Mark a question
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markedQuestionsControllerCreate(questionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkedQuestionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markedQuestionsControllerCreate(questionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unmark a question
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markedQuestionsControllerDelete(questionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markedQuestionsControllerDelete(questionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionsApi - factory interface
 * @export
 */
export const QuestionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Mark a question
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markedQuestionsControllerCreate(questionId: string, options?: any): AxiosPromise<MarkedQuestionResponseDto> {
            return localVarFp.markedQuestionsControllerCreate(questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unmark a question
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markedQuestionsControllerDelete(questionId: string, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.markedQuestionsControllerDelete(questionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionsApi - object-oriented interface
 * @export
 * @class QuestionsApi
 * @extends {BaseAPI}
 */
export class QuestionsApi extends BaseAPI {
    /**
     * 
     * @summary Mark a question
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public markedQuestionsControllerCreate(questionId: string, options?: AxiosRequestConfig) {
        return QuestionsApiFp(this.configuration).markedQuestionsControllerCreate(questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unmark a question
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public markedQuestionsControllerDelete(questionId: string, options?: AxiosRequestConfig) {
        return QuestionsApiFp(this.configuration).markedQuestionsControllerDelete(questionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuizzesApi - axios parameter creator
 * @export
 */
export const QuizzesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Start quiz passage
         * @param {string} quizId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizzesControllerStartQuiz: async (quizId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quizId' is not null or undefined
            assertParamExists('quizzesControllerStartQuiz', 'quizId', quizId)
            const localVarPath = `/test-quizzes/{quiz_id}/start`
                .replace(`{${"quiz_id"}}`, encodeURIComponent(String(quizId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuizzesApi - functional programming interface
 * @export
 */
export const QuizzesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuizzesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Start quiz passage
         * @param {string} quizId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quizzesControllerStartQuiz(quizId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizTestPassageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quizzesControllerStartQuiz(quizId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuizzesApi - factory interface
 * @export
 */
export const QuizzesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuizzesApiFp(configuration)
    return {
        /**
         * 
         * @summary Start quiz passage
         * @param {string} quizId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizzesControllerStartQuiz(quizId: string, options?: any): AxiosPromise<QuizTestPassageResponseDto> {
            return localVarFp.quizzesControllerStartQuiz(quizId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuizzesApi - object-oriented interface
 * @export
 * @class QuizzesApi
 * @extends {BaseAPI}
 */
export class QuizzesApi extends BaseAPI {
    /**
     * 
     * @summary Start quiz passage
     * @param {string} quizId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizzesApi
     */
    public quizzesControllerStartQuiz(quizId: string, options?: AxiosRequestConfig) {
        return QuizzesApiFp(this.configuration).quizzesControllerStartQuiz(quizId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create report
         * @param {CreateReportDto} createReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsControllerCreateReport: async (createReportDto: CreateReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReportDto' is not null or undefined
            assertParamExists('reportsControllerCreateReport', 'createReportDto', createReportDto)
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create report
         * @param {CreateReportDto} createReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsControllerCreateReport(createReportDto: CreateReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsControllerCreateReport(createReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create report
         * @param {CreateReportDto} createReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsControllerCreateReport(createReportDto: CreateReportDto, options?: any): AxiosPromise<void> {
            return localVarFp.reportsControllerCreateReport(createReportDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Create report
     * @param {CreateReportDto} createReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsControllerCreateReport(createReportDto: CreateReportDto, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsControllerCreateReport(createReportDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticsV2Api - axios parameter creator
 * @export
 */
export const StatisticsV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get flashcards statistics (packs) by answer type
         * @param {'UNANSWERED' | 'NO' | 'KINDA' | 'YES'} type 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'percent' | 'count'} [sortBy] 
         * @param {'desc' | 'asc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardStatisticsControllerGetFlashcardStatisticsByAnswerType: async (type: 'UNANSWERED' | 'NO' | 'KINDA' | 'YES', page?: number, limit?: number, sortBy?: 'percent' | 'count', order?: 'desc' | 'asc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('flashcardStatisticsControllerGetFlashcardStatisticsByAnswerType', 'type', type)
            const localVarPath = `/v2/statistics/flashcards/answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get flashcards statistics (single pack)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardStatisticsControllerGetFlashcardStatisticsByPack: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('flashcardStatisticsControllerGetFlashcardStatisticsByPack', 'id', id)
            const localVarPath = `/v2/statistics/flashcards/packs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get flashcards statistics (packs)
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardStatisticsControllerGetFlashcardStatisticsPaginated: async (page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/statistics/flashcards/packs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get flashcards statistics (pie chart)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardStatisticsControllerGetFlashcardsStatisticsTotal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/statistics/flashcards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get points amount for course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsControllerGetCoursePoints: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('pointsControllerGetCoursePoints', 'courseId', courseId)
            const localVarPath = `/v2/statistics/points/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get points amount for active course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsControllerGetPoints: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/statistics/points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tests statistics by passage (under bar chart)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStatisticsControllerGetTestPassageStatistics: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testStatisticsControllerGetTestPassageStatistics', 'id', id)
            const localVarPath = `/v2/statistics/tests/passages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tests statistics by passage and split (bar chart)
         * @param {string} id 
         * @param {'domain' | 'chapter'} splitType 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStatisticsControllerGetTestPassageStatisticsSplit: async (id: string, splitType: 'domain' | 'chapter', page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testStatisticsControllerGetTestPassageStatisticsSplit', 'id', id)
            // verify required parameter 'splitType' is not null or undefined
            assertParamExists('testStatisticsControllerGetTestPassageStatisticsSplit', 'splitType', splitType)
            const localVarPath = `/v2/statistics/tests/passages/{id}/split`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (splitType !== undefined) {
                localVarQueryParameter['splitType'] = splitType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tests statistics (bar chart)
         * @param {'domain' | 'chapter'} splitType 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStatisticsControllerGetTestStatisticsTotalSplit: async (splitType: 'domain' | 'chapter', page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'splitType' is not null or undefined
            assertParamExists('testStatisticsControllerGetTestStatisticsTotalSplit', 'splitType', splitType)
            const localVarPath = `/v2/statistics/tests/split`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (splitType !== undefined) {
                localVarQueryParameter['splitType'] = splitType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tests statistics by answer and split (on pie chart)
         * @param {'domain' | 'chapter'} splitType 
         * @param {'correct' | 'incorrect' | 'unused'} answerType 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'percent' | 'count'} [sortBy] 
         * @param {'desc' | 'asc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStatisticsControllerGetTestsStatisticsSplit: async (splitType: 'domain' | 'chapter', answerType: 'correct' | 'incorrect' | 'unused', page?: number, limit?: number, sortBy?: 'percent' | 'count', order?: 'desc' | 'asc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'splitType' is not null or undefined
            assertParamExists('testStatisticsControllerGetTestsStatisticsSplit', 'splitType', splitType)
            // verify required parameter 'answerType' is not null or undefined
            assertParamExists('testStatisticsControllerGetTestsStatisticsSplit', 'answerType', answerType)
            const localVarPath = `/v2/statistics/tests/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (splitType !== undefined) {
                localVarQueryParameter['splitType'] = splitType;
            }

            if (answerType !== undefined) {
                localVarQueryParameter['answerType'] = answerType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tests statistics (pie chart)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStatisticsControllerGetTestsStatisticsTotal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/statistics/tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsV2Api - functional programming interface
 * @export
 */
export const StatisticsV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get flashcards statistics (packs) by answer type
         * @param {'UNANSWERED' | 'NO' | 'KINDA' | 'YES'} type 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'percent' | 'count'} [sortBy] 
         * @param {'desc' | 'asc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardStatisticsControllerGetFlashcardStatisticsByAnswerType(type: 'UNANSWERED' | 'NO' | 'KINDA' | 'YES', page?: number, limit?: number, sortBy?: 'percent' | 'count', order?: 'desc' | 'asc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSingleAnswerFlashcardPacksStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardStatisticsControllerGetFlashcardStatisticsByAnswerType(type, page, limit, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get flashcards statistics (single pack)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardStatisticsControllerGetFlashcardStatisticsByPack(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlashcardPackStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardStatisticsControllerGetFlashcardStatisticsByPack(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get flashcards statistics (packs)
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardStatisticsControllerGetFlashcardStatisticsPaginated(page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedFlashcardPacksStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardStatisticsControllerGetFlashcardStatisticsPaginated(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get flashcards statistics (pie chart)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flashcardStatisticsControllerGetFlashcardsStatisticsTotal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlashcardsBaseStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flashcardStatisticsControllerGetFlashcardsStatisticsTotal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get points amount for course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsControllerGetCoursePoints(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalPointsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsControllerGetCoursePoints(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get points amount for active course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsControllerGetPoints(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalPointsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsControllerGetPoints(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tests statistics by passage (under bar chart)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testStatisticsControllerGetTestPassageStatistics(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestPassageStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testStatisticsControllerGetTestPassageStatistics(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tests statistics by passage and split (bar chart)
         * @param {string} id 
         * @param {'domain' | 'chapter'} splitType 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testStatisticsControllerGetTestPassageStatisticsSplit(id: string, splitType: 'domain' | 'chapter', page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTestSplitStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testStatisticsControllerGetTestPassageStatisticsSplit(id, splitType, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tests statistics (bar chart)
         * @param {'domain' | 'chapter'} splitType 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testStatisticsControllerGetTestStatisticsTotalSplit(splitType: 'domain' | 'chapter', page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTestSplitStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testStatisticsControllerGetTestStatisticsTotalSplit(splitType, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tests statistics by answer and split (on pie chart)
         * @param {'domain' | 'chapter'} splitType 
         * @param {'correct' | 'incorrect' | 'unused'} answerType 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'percent' | 'count'} [sortBy] 
         * @param {'desc' | 'asc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testStatisticsControllerGetTestsStatisticsSplit(splitType: 'domain' | 'chapter', answerType: 'correct' | 'incorrect' | 'unused', page?: number, limit?: number, sortBy?: 'percent' | 'count', order?: 'desc' | 'asc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTestAnswerSplitStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testStatisticsControllerGetTestsStatisticsSplit(splitType, answerType, page, limit, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tests statistics (pie chart)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testStatisticsControllerGetTestsStatisticsTotal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestsBaseStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testStatisticsControllerGetTestsStatisticsTotal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticsV2Api - factory interface
 * @export
 */
export const StatisticsV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsV2ApiFp(configuration)
    return {
        /**
         * 
         * @summary Get flashcards statistics (packs) by answer type
         * @param {'UNANSWERED' | 'NO' | 'KINDA' | 'YES'} type 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'percent' | 'count'} [sortBy] 
         * @param {'desc' | 'asc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardStatisticsControllerGetFlashcardStatisticsByAnswerType(type: 'UNANSWERED' | 'NO' | 'KINDA' | 'YES', page?: number, limit?: number, sortBy?: 'percent' | 'count', order?: 'desc' | 'asc', options?: any): AxiosPromise<PaginatedSingleAnswerFlashcardPacksStatsDto> {
            return localVarFp.flashcardStatisticsControllerGetFlashcardStatisticsByAnswerType(type, page, limit, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get flashcards statistics (single pack)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardStatisticsControllerGetFlashcardStatisticsByPack(id: string, options?: any): AxiosPromise<FlashcardPackStatsDto> {
            return localVarFp.flashcardStatisticsControllerGetFlashcardStatisticsByPack(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get flashcards statistics (packs)
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardStatisticsControllerGetFlashcardStatisticsPaginated(page?: number, limit?: number, options?: any): AxiosPromise<PaginatedFlashcardPacksStatsDto> {
            return localVarFp.flashcardStatisticsControllerGetFlashcardStatisticsPaginated(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get flashcards statistics (pie chart)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flashcardStatisticsControllerGetFlashcardsStatisticsTotal(options?: any): AxiosPromise<FlashcardsBaseStatsDto> {
            return localVarFp.flashcardStatisticsControllerGetFlashcardsStatisticsTotal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get points amount for course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsControllerGetCoursePoints(courseId: string, options?: any): AxiosPromise<TotalPointsDto> {
            return localVarFp.pointsControllerGetCoursePoints(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get points amount for active course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsControllerGetPoints(options?: any): AxiosPromise<TotalPointsDto> {
            return localVarFp.pointsControllerGetPoints(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tests statistics by passage (under bar chart)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStatisticsControllerGetTestPassageStatistics(id: string, options?: any): AxiosPromise<TestPassageStatsDto> {
            return localVarFp.testStatisticsControllerGetTestPassageStatistics(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tests statistics by passage and split (bar chart)
         * @param {string} id 
         * @param {'domain' | 'chapter'} splitType 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStatisticsControllerGetTestPassageStatisticsSplit(id: string, splitType: 'domain' | 'chapter', page?: number, limit?: number, options?: any): AxiosPromise<PaginatedTestSplitStatsDto> {
            return localVarFp.testStatisticsControllerGetTestPassageStatisticsSplit(id, splitType, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tests statistics (bar chart)
         * @param {'domain' | 'chapter'} splitType 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStatisticsControllerGetTestStatisticsTotalSplit(splitType: 'domain' | 'chapter', page?: number, limit?: number, options?: any): AxiosPromise<PaginatedTestSplitStatsDto> {
            return localVarFp.testStatisticsControllerGetTestStatisticsTotalSplit(splitType, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tests statistics by answer and split (on pie chart)
         * @param {'domain' | 'chapter'} splitType 
         * @param {'correct' | 'incorrect' | 'unused'} answerType 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'percent' | 'count'} [sortBy] 
         * @param {'desc' | 'asc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStatisticsControllerGetTestsStatisticsSplit(splitType: 'domain' | 'chapter', answerType: 'correct' | 'incorrect' | 'unused', page?: number, limit?: number, sortBy?: 'percent' | 'count', order?: 'desc' | 'asc', options?: any): AxiosPromise<PaginatedTestAnswerSplitStatsDto> {
            return localVarFp.testStatisticsControllerGetTestsStatisticsSplit(splitType, answerType, page, limit, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tests statistics (pie chart)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStatisticsControllerGetTestsStatisticsTotal(options?: any): AxiosPromise<TestsBaseStatsDto> {
            return localVarFp.testStatisticsControllerGetTestsStatisticsTotal(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsV2Api - object-oriented interface
 * @export
 * @class StatisticsV2Api
 * @extends {BaseAPI}
 */
export class StatisticsV2Api extends BaseAPI {
    /**
     * 
     * @summary Get flashcards statistics (packs) by answer type
     * @param {'UNANSWERED' | 'NO' | 'KINDA' | 'YES'} type 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {'percent' | 'count'} [sortBy] 
     * @param {'desc' | 'asc'} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsV2Api
     */
    public flashcardStatisticsControllerGetFlashcardStatisticsByAnswerType(type: 'UNANSWERED' | 'NO' | 'KINDA' | 'YES', page?: number, limit?: number, sortBy?: 'percent' | 'count', order?: 'desc' | 'asc', options?: AxiosRequestConfig) {
        return StatisticsV2ApiFp(this.configuration).flashcardStatisticsControllerGetFlashcardStatisticsByAnswerType(type, page, limit, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get flashcards statistics (single pack)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsV2Api
     */
    public flashcardStatisticsControllerGetFlashcardStatisticsByPack(id: string, options?: AxiosRequestConfig) {
        return StatisticsV2ApiFp(this.configuration).flashcardStatisticsControllerGetFlashcardStatisticsByPack(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get flashcards statistics (packs)
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsV2Api
     */
    public flashcardStatisticsControllerGetFlashcardStatisticsPaginated(page?: number, limit?: number, options?: AxiosRequestConfig) {
        return StatisticsV2ApiFp(this.configuration).flashcardStatisticsControllerGetFlashcardStatisticsPaginated(page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get flashcards statistics (pie chart)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsV2Api
     */
    public flashcardStatisticsControllerGetFlashcardsStatisticsTotal(options?: AxiosRequestConfig) {
        return StatisticsV2ApiFp(this.configuration).flashcardStatisticsControllerGetFlashcardsStatisticsTotal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get points amount for course
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsV2Api
     */
    public pointsControllerGetCoursePoints(courseId: string, options?: AxiosRequestConfig) {
        return StatisticsV2ApiFp(this.configuration).pointsControllerGetCoursePoints(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get points amount for active course
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsV2Api
     */
    public pointsControllerGetPoints(options?: AxiosRequestConfig) {
        return StatisticsV2ApiFp(this.configuration).pointsControllerGetPoints(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tests statistics by passage (under bar chart)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsV2Api
     */
    public testStatisticsControllerGetTestPassageStatistics(id: string, options?: AxiosRequestConfig) {
        return StatisticsV2ApiFp(this.configuration).testStatisticsControllerGetTestPassageStatistics(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tests statistics by passage and split (bar chart)
     * @param {string} id 
     * @param {'domain' | 'chapter'} splitType 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsV2Api
     */
    public testStatisticsControllerGetTestPassageStatisticsSplit(id: string, splitType: 'domain' | 'chapter', page?: number, limit?: number, options?: AxiosRequestConfig) {
        return StatisticsV2ApiFp(this.configuration).testStatisticsControllerGetTestPassageStatisticsSplit(id, splitType, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tests statistics (bar chart)
     * @param {'domain' | 'chapter'} splitType 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsV2Api
     */
    public testStatisticsControllerGetTestStatisticsTotalSplit(splitType: 'domain' | 'chapter', page?: number, limit?: number, options?: AxiosRequestConfig) {
        return StatisticsV2ApiFp(this.configuration).testStatisticsControllerGetTestStatisticsTotalSplit(splitType, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tests statistics by answer and split (on pie chart)
     * @param {'domain' | 'chapter'} splitType 
     * @param {'correct' | 'incorrect' | 'unused'} answerType 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {'percent' | 'count'} [sortBy] 
     * @param {'desc' | 'asc'} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsV2Api
     */
    public testStatisticsControllerGetTestsStatisticsSplit(splitType: 'domain' | 'chapter', answerType: 'correct' | 'incorrect' | 'unused', page?: number, limit?: number, sortBy?: 'percent' | 'count', order?: 'desc' | 'asc', options?: AxiosRequestConfig) {
        return StatisticsV2ApiFp(this.configuration).testStatisticsControllerGetTestsStatisticsSplit(splitType, answerType, page, limit, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tests statistics (pie chart)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsV2Api
     */
    public testStatisticsControllerGetTestsStatisticsTotal(options?: AxiosRequestConfig) {
        return StatisticsV2ApiFp(this.configuration).testStatisticsControllerGetTestsStatisticsTotal(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get billing portal url for customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreateBillingPortalSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stripe/billing-portal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get payment intent secret for landing page purchases
         * @param {GetCustomerPaymentIntentSecretRequestDto} getCustomerPaymentIntentSecretRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreateCustomerPaymentIntent: async (getCustomerPaymentIntentSecretRequestDto: GetCustomerPaymentIntentSecretRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCustomerPaymentIntentSecretRequestDto' is not null or undefined
            assertParamExists('stripeControllerCreateCustomerPaymentIntent', 'getCustomerPaymentIntentSecretRequestDto', getCustomerPaymentIntentSecretRequestDto)
            const localVarPath = `/stripe/payment-intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCustomerPaymentIntentSecretRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get setup intent secret for client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCustomerSetupIntentSecret: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stripe/setup-intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer id
         * @param {StripeCustomerInfoRequestDto} stripeCustomerInfoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerGetCustomer: async (stripeCustomerInfoRequestDto: StripeCustomerInfoRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeCustomerInfoRequestDto' is not null or undefined
            assertParamExists('stripeControllerGetCustomer', 'stripeCustomerInfoRequestDto', stripeCustomerInfoRequestDto)
            const localVarPath = `/stripe/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripeCustomerInfoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get billing portal url for customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerCreateBillingPortalSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingPortalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerCreateBillingPortalSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get payment intent secret for landing page purchases
         * @param {GetCustomerPaymentIntentSecretRequestDto} getCustomerPaymentIntentSecretRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerCreateCustomerPaymentIntent(getCustomerPaymentIntentSecretRequestDto: GetCustomerPaymentIntentSecretRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientSecretDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerCreateCustomerPaymentIntent(getCustomerPaymentIntentSecretRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get setup intent secret for client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerCustomerSetupIntentSecret(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientSecretDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerCustomerSetupIntentSecret(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer id
         * @param {StripeCustomerInfoRequestDto} stripeCustomerInfoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerGetCustomer(stripeCustomerInfoRequestDto: StripeCustomerInfoRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeCustomerInfoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerGetCustomer(stripeCustomerInfoRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeApiFp(configuration)
    return {
        /**
         * 
         * @summary Get billing portal url for customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreateBillingPortalSession(options?: any): AxiosPromise<BillingPortalDto> {
            return localVarFp.stripeControllerCreateBillingPortalSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get payment intent secret for landing page purchases
         * @param {GetCustomerPaymentIntentSecretRequestDto} getCustomerPaymentIntentSecretRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreateCustomerPaymentIntent(getCustomerPaymentIntentSecretRequestDto: GetCustomerPaymentIntentSecretRequestDto, options?: any): AxiosPromise<ClientSecretDto> {
            return localVarFp.stripeControllerCreateCustomerPaymentIntent(getCustomerPaymentIntentSecretRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get setup intent secret for client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCustomerSetupIntentSecret(options?: any): AxiosPromise<ClientSecretDto> {
            return localVarFp.stripeControllerCustomerSetupIntentSecret(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer id
         * @param {StripeCustomerInfoRequestDto} stripeCustomerInfoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerGetCustomer(stripeCustomerInfoRequestDto: StripeCustomerInfoRequestDto, options?: any): AxiosPromise<StripeCustomerInfoResponseDto> {
            return localVarFp.stripeControllerGetCustomer(stripeCustomerInfoRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     * 
     * @summary Get billing portal url for customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerCreateBillingPortalSession(options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerCreateBillingPortalSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get payment intent secret for landing page purchases
     * @param {GetCustomerPaymentIntentSecretRequestDto} getCustomerPaymentIntentSecretRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerCreateCustomerPaymentIntent(getCustomerPaymentIntentSecretRequestDto: GetCustomerPaymentIntentSecretRequestDto, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerCreateCustomerPaymentIntent(getCustomerPaymentIntentSecretRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get setup intent secret for client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerCustomerSetupIntentSecret(options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerCustomerSetupIntentSecret(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer id
     * @param {StripeCustomerInfoRequestDto} stripeCustomerInfoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerGetCustomer(stripeCustomerInfoRequestDto: StripeCustomerInfoRequestDto, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerGetCustomer(stripeCustomerInfoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuggestionsApi - axios parameter creator
 * @export
 */
export const SuggestionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add suggestion
         * @param {AddSuggestionRequestDto} addSuggestionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestionControllerAddSuggestion: async (addSuggestionRequestDto: AddSuggestionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addSuggestionRequestDto' is not null or undefined
            assertParamExists('suggestionControllerAddSuggestion', 'addSuggestionRequestDto', addSuggestionRequestDto)
            const localVarPath = `/suggestions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addSuggestionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuggestionsApi - functional programming interface
 * @export
 */
export const SuggestionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuggestionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add suggestion
         * @param {AddSuggestionRequestDto} addSuggestionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestionControllerAddSuggestion(addSuggestionRequestDto: AddSuggestionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suggestionControllerAddSuggestion(addSuggestionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuggestionsApi - factory interface
 * @export
 */
export const SuggestionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuggestionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add suggestion
         * @param {AddSuggestionRequestDto} addSuggestionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestionControllerAddSuggestion(addSuggestionRequestDto: AddSuggestionRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.suggestionControllerAddSuggestion(addSuggestionRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuggestionsApi - object-oriented interface
 * @export
 * @class SuggestionsApi
 * @extends {BaseAPI}
 */
export class SuggestionsApi extends BaseAPI {
    /**
     * 
     * @summary Add suggestion
     * @param {AddSuggestionRequestDto} addSuggestionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuggestionsApi
     */
    public suggestionControllerAddSuggestion(addSuggestionRequestDto: AddSuggestionRequestDto, options?: AxiosRequestConfig) {
        return SuggestionsApiFp(this.configuration).suggestionControllerAddSuggestion(addSuggestionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TasksV1Api - axios parameter creator
 * @export
 */
export const TasksV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Mark task as completed
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerMark: async (taskId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('tasksControllerMark', 'taskId', taskId)
            const localVarPath = `/tasks/{taskId}/mark`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unmark task
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerUnMark: async (taskId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('tasksControllerUnMark', 'taskId', taskId)
            const localVarPath = `/tasks/{taskId}/unMark`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksV1Api - functional programming interface
 * @export
 */
export const TasksV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Mark task as completed
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerMark(taskId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCompletedTaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerMark(taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unmark task
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksControllerUnMark(taskId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksControllerUnMark(taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TasksV1Api - factory interface
 * @export
 */
export const TasksV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary Mark task as completed
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerMark(taskId: string, options?: any): AxiosPromise<CustomerCompletedTaskDto> {
            return localVarFp.tasksControllerMark(taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unmark task
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksControllerUnMark(taskId: string, options?: any): AxiosPromise<void> {
            return localVarFp.tasksControllerUnMark(taskId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TasksV1Api - object-oriented interface
 * @export
 * @class TasksV1Api
 * @extends {BaseAPI}
 */
export class TasksV1Api extends BaseAPI {
    /**
     * 
     * @summary Mark task as completed
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksV1Api
     */
    public tasksControllerMark(taskId: string, options?: AxiosRequestConfig) {
        return TasksV1ApiFp(this.configuration).tasksControllerMark(taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unmark task
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksV1Api
     */
    public tasksControllerUnMark(taskId: string, options?: AxiosRequestConfig) {
        return TasksV1ApiFp(this.configuration).tasksControllerUnMark(taskId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestPassagesApi - axios parameter creator
 * @export
 */
export const TestPassagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Answer question
         * @param {string} passageId 
         * @param {AnswerQuestionRequestDto} answerQuestionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerAnswerQuestion: async (passageId: string, answerQuestionRequestDto: AnswerQuestionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passageId' is not null or undefined
            assertParamExists('testPassagesControllerAnswerQuestion', 'passageId', passageId)
            // verify required parameter 'answerQuestionRequestDto' is not null or undefined
            assertParamExists('testPassagesControllerAnswerQuestion', 'answerQuestionRequestDto', answerQuestionRequestDto)
            const localVarPath = `/passages/{passage_id}/answer`
                .replace(`{${"passage_id"}}`, encodeURIComponent(String(passageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answerQuestionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finish passage
         * @param {string} passageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerFinishPassage: async (passageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passageId' is not null or undefined
            assertParamExists('testPassagesControllerFinishPassage', 'passageId', passageId)
            const localVarPath = `/passages/{passage_id}/finish`
                .replace(`{${"passage_id"}}`, encodeURIComponent(String(passageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get last uncompleted passage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerGetLastUncompletedPassage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/passages/last-uncompleted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check last uncompleted passage exists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerGetLastUncompletedPassageId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/passages/last-uncompleted-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} passageId 
         * @param {GetPassageResultsRequestDto} getPassageResultsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerGetPassageResults: async (passageId: string, getPassageResultsRequestDto: GetPassageResultsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passageId' is not null or undefined
            assertParamExists('testPassagesControllerGetPassageResults', 'passageId', passageId)
            // verify required parameter 'getPassageResultsRequestDto' is not null or undefined
            assertParamExists('testPassagesControllerGetPassageResults', 'getPassageResultsRequestDto', getPassageResultsRequestDto)
            const localVarPath = `/passages/{passage_id}/results`
                .replace(`{${"passage_id"}}`, encodeURIComponent(String(passageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPassageResultsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} passageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerRestartPassage: async (passageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passageId' is not null or undefined
            assertParamExists('testPassagesControllerRestartPassage', 'passageId', passageId)
            const localVarPath = `/passages/{passage_id}/restart`
                .replace(`{${"passage_id"}}`, encodeURIComponent(String(passageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchTestPassagesRequestDto} searchTestPassagesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerSearchPassages: async (searchTestPassagesRequestDto: SearchTestPassagesRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTestPassagesRequestDto' is not null or undefined
            assertParamExists('testPassagesControllerSearchPassages', 'searchTestPassagesRequestDto', searchTestPassagesRequestDto)
            const localVarPath = `/passages/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchTestPassagesRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestPassagesApi - functional programming interface
 * @export
 */
export const TestPassagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestPassagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Answer question
         * @param {string} passageId 
         * @param {AnswerQuestionRequestDto} answerQuestionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPassagesControllerAnswerQuestion(passageId: string, answerQuestionRequestDto: AnswerQuestionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnswerQuestionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPassagesControllerAnswerQuestion(passageId, answerQuestionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finish passage
         * @param {string} passageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPassagesControllerFinishPassage(passageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinishedPassageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPassagesControllerFinishPassage(passageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get last uncompleted passage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPassagesControllerGetLastUncompletedPassage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestPassagesControllerGetLastUncompletedPassage200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPassagesControllerGetLastUncompletedPassage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check last uncompleted passage exists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPassagesControllerGetLastUncompletedPassageId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPassagesControllerGetLastUncompletedPassageId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} passageId 
         * @param {GetPassageResultsRequestDto} getPassageResultsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPassagesControllerGetPassageResults(passageId: string, getPassageResultsRequestDto: GetPassageResultsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPassageResultsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPassagesControllerGetPassageResults(passageId, getPassageResultsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} passageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPassagesControllerRestartPassage(passageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestPassagesControllerGetLastUncompletedPassage200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPassagesControllerRestartPassage(passageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchTestPassagesRequestDto} searchTestPassagesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPassagesControllerSearchPassages(searchTestPassagesRequestDto: SearchTestPassagesRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchTestPassagesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPassagesControllerSearchPassages(searchTestPassagesRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestPassagesApi - factory interface
 * @export
 */
export const TestPassagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestPassagesApiFp(configuration)
    return {
        /**
         * 
         * @summary Answer question
         * @param {string} passageId 
         * @param {AnswerQuestionRequestDto} answerQuestionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerAnswerQuestion(passageId: string, answerQuestionRequestDto: AnswerQuestionRequestDto, options?: any): AxiosPromise<AnswerQuestionResponseDto> {
            return localVarFp.testPassagesControllerAnswerQuestion(passageId, answerQuestionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finish passage
         * @param {string} passageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerFinishPassage(passageId: string, options?: any): AxiosPromise<FinishedPassageResponseDto> {
            return localVarFp.testPassagesControllerFinishPassage(passageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get last uncompleted passage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerGetLastUncompletedPassage(options?: any): AxiosPromise<TestPassagesControllerGetLastUncompletedPassage200Response> {
            return localVarFp.testPassagesControllerGetLastUncompletedPassage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check last uncompleted passage exists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerGetLastUncompletedPassageId(options?: any): AxiosPromise<string> {
            return localVarFp.testPassagesControllerGetLastUncompletedPassageId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} passageId 
         * @param {GetPassageResultsRequestDto} getPassageResultsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerGetPassageResults(passageId: string, getPassageResultsRequestDto: GetPassageResultsRequestDto, options?: any): AxiosPromise<GetPassageResultsResponseDto> {
            return localVarFp.testPassagesControllerGetPassageResults(passageId, getPassageResultsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} passageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerRestartPassage(passageId: string, options?: any): AxiosPromise<TestPassagesControllerGetLastUncompletedPassage200Response> {
            return localVarFp.testPassagesControllerRestartPassage(passageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchTestPassagesRequestDto} searchTestPassagesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPassagesControllerSearchPassages(searchTestPassagesRequestDto: SearchTestPassagesRequestDto, options?: any): AxiosPromise<SearchTestPassagesResponseDto> {
            return localVarFp.testPassagesControllerSearchPassages(searchTestPassagesRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestPassagesApi - object-oriented interface
 * @export
 * @class TestPassagesApi
 * @extends {BaseAPI}
 */
export class TestPassagesApi extends BaseAPI {
    /**
     * 
     * @summary Answer question
     * @param {string} passageId 
     * @param {AnswerQuestionRequestDto} answerQuestionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPassagesApi
     */
    public testPassagesControllerAnswerQuestion(passageId: string, answerQuestionRequestDto: AnswerQuestionRequestDto, options?: AxiosRequestConfig) {
        return TestPassagesApiFp(this.configuration).testPassagesControllerAnswerQuestion(passageId, answerQuestionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finish passage
     * @param {string} passageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPassagesApi
     */
    public testPassagesControllerFinishPassage(passageId: string, options?: AxiosRequestConfig) {
        return TestPassagesApiFp(this.configuration).testPassagesControllerFinishPassage(passageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get last uncompleted passage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPassagesApi
     */
    public testPassagesControllerGetLastUncompletedPassage(options?: AxiosRequestConfig) {
        return TestPassagesApiFp(this.configuration).testPassagesControllerGetLastUncompletedPassage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check last uncompleted passage exists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPassagesApi
     */
    public testPassagesControllerGetLastUncompletedPassageId(options?: AxiosRequestConfig) {
        return TestPassagesApiFp(this.configuration).testPassagesControllerGetLastUncompletedPassageId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} passageId 
     * @param {GetPassageResultsRequestDto} getPassageResultsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPassagesApi
     */
    public testPassagesControllerGetPassageResults(passageId: string, getPassageResultsRequestDto: GetPassageResultsRequestDto, options?: AxiosRequestConfig) {
        return TestPassagesApiFp(this.configuration).testPassagesControllerGetPassageResults(passageId, getPassageResultsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} passageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPassagesApi
     */
    public testPassagesControllerRestartPassage(passageId: string, options?: AxiosRequestConfig) {
        return TestPassagesApiFp(this.configuration).testPassagesControllerRestartPassage(passageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchTestPassagesRequestDto} searchTestPassagesRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPassagesApi
     */
    public testPassagesControllerSearchPassages(searchTestPassagesRequestDto: SearchTestPassagesRequestDto, options?: AxiosRequestConfig) {
        return TestPassagesApiFp(this.configuration).testPassagesControllerSearchPassages(searchTestPassagesRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TimedTestsApi - axios parameter creator
 * @export
 */
export const TimedTestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get next timed test
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timedTestsControllerGetNextTimedTest: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('timedTestsControllerGetNextTimedTest', 'courseId', courseId)
            const localVarPath = `/timed-tests/next`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start timed test passage
         * @param {string} timedTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timedTestsControllerStartTimedTest: async (timedTestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timedTestId' is not null or undefined
            assertParamExists('timedTestsControllerStartTimedTest', 'timedTestId', timedTestId)
            const localVarPath = `/timed-tests/{timed_test_id}/start`
                .replace(`{${"timed_test_id"}}`, encodeURIComponent(String(timedTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimedTestsApi - functional programming interface
 * @export
 */
export const TimedTestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimedTestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get next timed test
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timedTestsControllerGetNextTimedTest(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimedTestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timedTestsControllerGetNextTimedTest(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start timed test passage
         * @param {string} timedTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timedTestsControllerStartTimedTest(timedTestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimedTestPassageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timedTestsControllerStartTimedTest(timedTestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimedTestsApi - factory interface
 * @export
 */
export const TimedTestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimedTestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get next timed test
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timedTestsControllerGetNextTimedTest(courseId: string, options?: any): AxiosPromise<TimedTestResponseDto> {
            return localVarFp.timedTestsControllerGetNextTimedTest(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start timed test passage
         * @param {string} timedTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timedTestsControllerStartTimedTest(timedTestId: string, options?: any): AxiosPromise<TimedTestPassageResponseDto> {
            return localVarFp.timedTestsControllerStartTimedTest(timedTestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimedTestsApi - object-oriented interface
 * @export
 * @class TimedTestsApi
 * @extends {BaseAPI}
 */
export class TimedTestsApi extends BaseAPI {
    /**
     * 
     * @summary Get next timed test
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimedTestsApi
     */
    public timedTestsControllerGetNextTimedTest(courseId: string, options?: AxiosRequestConfig) {
        return TimedTestsApiFp(this.configuration).timedTestsControllerGetNextTimedTest(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start timed test passage
     * @param {string} timedTestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimedTestsApi
     */
    public timedTestsControllerStartTimedTest(timedTestId: string, options?: AxiosRequestConfig) {
        return TimedTestsApiFp(this.configuration).timedTestsControllerStartTimedTest(timedTestId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TutorTestsApi - axios parameter creator
 * @export
 */
export const TutorTestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Start tutor test passage
         * @param {StartTutorTestPassageRequestDto} startTutorTestPassageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tutorTestsControllerStartTutorTest: async (startTutorTestPassageRequestDto: StartTutorTestPassageRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTutorTestPassageRequestDto' is not null or undefined
            assertParamExists('tutorTestsControllerStartTutorTest', 'startTutorTestPassageRequestDto', startTutorTestPassageRequestDto)
            const localVarPath = `/tutor-tests/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startTutorTestPassageRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TutorTestsApi - functional programming interface
 * @export
 */
export const TutorTestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TutorTestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Start tutor test passage
         * @param {StartTutorTestPassageRequestDto} startTutorTestPassageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tutorTestsControllerStartTutorTest(startTutorTestPassageRequestDto: StartTutorTestPassageRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutorTestPassageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tutorTestsControllerStartTutorTest(startTutorTestPassageRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TutorTestsApi - factory interface
 * @export
 */
export const TutorTestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TutorTestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Start tutor test passage
         * @param {StartTutorTestPassageRequestDto} startTutorTestPassageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tutorTestsControllerStartTutorTest(startTutorTestPassageRequestDto: StartTutorTestPassageRequestDto, options?: any): AxiosPromise<TutorTestPassageResponseDto> {
            return localVarFp.tutorTestsControllerStartTutorTest(startTutorTestPassageRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TutorTestsApi - object-oriented interface
 * @export
 * @class TutorTestsApi
 * @extends {BaseAPI}
 */
export class TutorTestsApi extends BaseAPI {
    /**
     * 
     * @summary Start tutor test passage
     * @param {StartTutorTestPassageRequestDto} startTutorTestPassageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorTestsApi
     */
    public tutorTestsControllerStartTutorTest(startTutorTestPassageRequestDto: StartTutorTestPassageRequestDto, options?: AxiosRequestConfig) {
        return TutorTestsApiFp(this.configuration).tutorTestsControllerStartTutorTest(startTutorTestPassageRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


