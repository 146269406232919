import { Button, IconButton } from 'UI';
import { CreateReportDtoResourceEnum } from 'api/generated';
import {
  ArrowBackIcon,
  CloseIcon,
  HintIcon,
  MoreIcon,
  PlusIcon,
  RefreshIcon,
  SpamIcon,
  SubtractIcon,
} from 'assets/icons';
import { ReportModal } from 'components';
import { useClientSize, useOnClickOutside } from 'hooks';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { selectors, useAppSelector } from 'store';
import styled, { css, useTheme } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import FooterAddToFlashcardButton from './components/FooterAddToFlashcardButton';
import FooterRecheckButton from './components/FooterRecheckButton';
import MobileMenu from './components/MobileMenu';

export enum TestMenuButton {
  RECHECKING = 'Mark for review',
  ADD_FLASHCARD = 'Add to flashcard',
  END = 'End',
  REPORT = 'Report a problem',
  REMOVE_RECHECKING = 'Remove from marked',
  REMOVE_FLASHCARD = 'Remove from flashcards',
  HINT = 'Hint',
}

type FooterProps = {
  onEndClick: () => void;
  onButtonClick?: () => void;
  buttonText: string;
  onPrevClick: () => void;
  isDisabledButton?: boolean;
  type?: 'submit' | 'reset' | 'button';
  isLoading: boolean;
  hintText?: string;
};

const TestFooter: FC<FooterProps> = ({
  onEndClick,
  onButtonClick,
  buttonText,
  type = 'button',
  isDisabledButton,
  onPrevClick,
  isLoading,
  hintText,
}) => {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const [isShowHint, setIsShowHint] = useState(false);

  const { colors } = useTheme();
  const { getIsBreakpoint } = useClientSize();
  const isWidthSm = getIsBreakpoint('sm');

  const tutorTest = useAppSelector(selectors.tests.selectTutorTest);
  const timedTest = useAppSelector(selectors.tests.selectTimedTest);
  const currentIndex = useAppSelector(selectors.tests.selectCurrentQuestionIndex) || 0;
  const currentTimedQuestion = timedTest?.questions[currentIndex];
  const currentTutorTestQuestion = tutorTest?.questions[currentIndex];

  const questionId = currentTutorTestQuestion?.id || currentTimedQuestion?.id || '';

  const iconMapping: Record<TestMenuButton, ReactNode> = {
    [TestMenuButton.ADD_FLASHCARD]: <PlusIcon width={24} height={24} color={colors.primary[1]} />,
    [TestMenuButton.END]: <CloseIcon width={24} height={24} color={colors.system.red} />,
    [TestMenuButton.RECHECKING]: <RefreshIcon width={24} height={24} color={colors.primary[1]} />,
    [TestMenuButton.REPORT]: <SpamIcon width={24} height={24} color={colors.system.red} />,
    [TestMenuButton.REMOVE_FLASHCARD]: <SubtractIcon width={24} height={24} color={colors.primary[1]} />,
    [TestMenuButton.REMOVE_RECHECKING]: <SubtractIcon width={24} height={24} color={colors.primary[1]} />,
    [TestMenuButton.HINT]: <HintIcon width={24} height={24} color={colors.primary[1]} />,
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsShowMenu((prev) => !prev);
  };

  const toggleHint = () => {
    setIsShowHint((prev: boolean) => !prev);
  };

  useOnClickOutside(containerRef, () => setIsShowHint(false));

  const isShowMobileMenu = isShowMenu && isWidthSm;
  const isFirstQuestion = currentIndex === 0;

  const isMarkedInitialValue = Boolean(currentTimedQuestion?.isMarked || currentTutorTestQuestion?.isMarked);
  const isAddedToFlashcardInitialValue = Boolean(
    currentTimedQuestion?.isInFlashcard || currentTutorTestQuestion?.isInFlashcard,
  );

  const aa = () => {
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    if (isShowHint && isShowMobileMenu) {
      aa();
    }
  }, [isShowHint]);

  return (
    <>
      <Container ref={containerRef}>
        <StyledButton
          iconComponent={<ArrowBackIcon width={24} height={24} color={colors.primary[1]} />}
          variant="secondary"
          size="small"
          onClick={onPrevClick}
          disabled={isFirstQuestion}>
          Previous
        </StyledButton>
        <ButtonsContainer>
          <StyledButton
            iconComponent={iconMapping[TestMenuButton.REPORT]}
            variant="secondary"
            size="small"
            onClick={() => setIsShowReportModal(true)}>
            {TestMenuButton.REPORT}
          </StyledButton>
          {hintText && (
            <StyledButton
              iconComponent={iconMapping[TestMenuButton.HINT]}
              variant="secondary"
              size="small"
              onClick={toggleHint}>
              {TestMenuButton.HINT}

              {isShowHint && (
                <Hint>
                  <HintIcon width={24} height={24} color={colors.system.green} />
                  <HintText>{hintText}</HintText>
                </Hint>
              )}
            </StyledButton>
          )}
          <FooterRecheckButton
            iconMapping={iconMapping}
            isMarkedInitialValue={isMarkedInitialValue}
            questionId={questionId}
          />
          <FooterAddToFlashcardButton
            iconMapping={iconMapping}
            isAddedToFlashcardInitialValue={isAddedToFlashcardInitialValue}
            questionId={questionId}
          />
        </ButtonsContainer>
        <MobileButtonsContainer>
          <ConfirmButton
            variant="primary"
            size="small"
            type={type}
            onClick={onButtonClick}
            disabled={isDisabledButton}
            isLoading={isLoading}>
            {buttonText}
          </ConfirmButton>
          <StyledIconButton iconComponent={<MoreIcon />} onClick={toggleMenu} $active={isShowMenu} />
        </MobileButtonsContainer>
        {isShowMobileMenu && (
          <MobileMenu
            isMarkedInitialValue={isMarkedInitialValue}
            isAddedToFlashcardInitialValue={isAddedToFlashcardInitialValue}
            endTest={onEndClick}
            iconMapping={iconMapping}
            closeMenu={() => setIsShowMenu(false)}
            toggleHint={hintText ? toggleHint : undefined}
          />
        )}
        <ReportModal
          resource={CreateReportDtoResourceEnum.TestQuestion}
          resourceId={questionId}
          isOpen={isShowReportModal}
          onClose={() => setIsShowReportModal(false)}
        />
      </Container>
      {isShowHint && isShowMobileMenu && (
        <Hint className="hint">
          <HintIcon width={24} height={24} color={colors.system.green} />
          <HintText>{hintText}</HintText>
        </Hint>
      )}
    </>
  );
};

export default TestFooter;

const Hint = styled.div`
  position: absolute;
  bottom: 100px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding: 12px 16px;
  border-radius: 16px;
  text-align: left;
  background-color: #e6efea;
  max-width: 468px;
  min-width: 200px;

  ${respondToWidth.sm`
    top: 40px;
    bottom: auto;
    left: 16px;
    right: 16px;
  `}

  svg {
    min-width: 24px;
  }
`;

const HintText = styled.p`
  white-space: pre-wrap;
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[1]};
    ${typography.body_large_regular_16}
  `}
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 40px;
  position: relative;

  ${({ theme: { colors } }) => css`
    background-color: ${colors.neutrals[11]};
    ${respondToWidth.sm`
    padding-inline: 16px;
    border-top: 1px solid ${colors.neutrals[9]};
  `}
  `}
`;

const StyledButton = styled(Button)`
  width: auto;

  ${respondToWidth.sm`
    max-width:136px;
  `}
`;

const ButtonsContainer = styled.div`
  margin-left: 24px;
  display: flex;
  gap: 24px;

  ${respondToWidth.sm`
    display:none;
  `}
`;

const MobileButtonsContainer = styled.div`
  display: none;
  gap: 23px;
  align-items: center;

  ${respondToWidth.sm`
    display:flex;
  `}
`;

const ConfirmButton = styled(Button)`
  width: 136px;
  padding-inline: 0;
`;

const StyledIconButton = styled(IconButton)<{ $active: boolean }>`
  border-color: ${({ theme: { colors }, $active }) => $active && colors.primary[1]};
`;
