// import { CloseIcon } from 'assets/icons';
//@ts-ignore
import onboardingVideo from 'assets/video/video.mp4';
import { FC } from 'react';
import styled from 'styled-components';

// import { respondToWidth } from 'styles/general/respondTo';

interface StepInfoProps {
  title: string;
  description: string;
  stepCount: number;
  isTrial: boolean;
}

const bulletPoints = ['Exam Pass Guarantee', 'Exam Simulator', 'Interactive Study Guide', 'Flashcard System'];

const StepInfo: FC<StepInfoProps> = ({ title, description, stepCount, isTrial }) => {
  return (
    <div>
      <StepTitle>{title}</StepTitle>
      <StepDescription>{description}</StepDescription>
      {stepCount === 1 && (
        <VideoContainer>
          <video width="100%" controls muted autoPlay>
            <track default kind="contains" />
            <source src={onboardingVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </VideoContainer>
      )}
      {stepCount === 4 && <StepTitleList>Upgrade to get access:</StepTitleList>}
      {stepCount === 4 && (
        <Container>
          {bulletPoints.map((text, index) => (
            <BulletPoint key={index}>{text}</BulletPoint>
          ))}
        </Container>
      )}
      <StepCount>{`${stepCount} of ${isTrial ? 4 : 3}`}</StepCount>
    </div>
  );
};

export default StepInfo;

const StepTitle = styled.h3`
  margin-top: -55px;

  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  letter-spacing: -0.2px;
`;

const StepDescription = styled.p`
  margin-top: 12px;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

const StepTitleList = styled.p`
  margin-bottom: 12px;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

const StepCount = styled.p`
  display: block;
  position: absolute;
  bottom: 30px;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #848484;
`;

const VideoContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: 45px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BulletPoint = styled.div`
  position: relative;
  font-size: 16px;

  font-style: normal;

  line-height: 28px;
  padding-left: 16px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 8px;
    height: 8px;
    background-color: #cc6a44;
    border-radius: 50%;
    transform: translateY(-50%);
  }
`;
