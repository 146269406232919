import Button from 'UI/Button';
import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import StyledText from 'UI/StyledText';
import { BonusSection } from 'UI/SubscriptionItem/components/BonusSection/BonusSection';
import { ResponsiveCapability } from 'UI/SubscriptionItem/components/ResponsiveCapability/ResponsiveCapability';
import { ResponsiveTabs } from 'UI/SubscriptionItem/components/ResponsiveTabs/ResponsiveTabs';
import { CourseDtoTypeEnum, PaymentDto, PaymentDtoLevelEnum, SubscribeDtoTypeEnum } from 'api/generated';
import { Cross } from 'assets/icons';
import { PaymentOptionsSelector } from 'components/Modals/ChooseProductModal/components/LifetimeProducts/components';
import { ActivePlan, Plan } from 'components/Modals/ChooseProductModal/components/Products/types';
import { levelMapping } from 'constant';
import Lottie from 'lottie-react';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { CourseOptionsForPayment } from 'store/ducks/courses/types';
import styled from 'styled-components';

import { CheckmarkIcon } from './components/CheckMark/CheckMark';
import { PriceSection } from './components/PriceSection/PriceSection';

export type CapabilityType = {
  include: boolean;
  title?: string;
};

type PlanItemProps = {
  plan: Plan;
  isCurrentSubscription?: boolean;
  className?: string;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  planType: ActivePlan | undefined;
  animationData: any;
  termsCount: number;
  mainColor: any;
  currentPayment: PaymentDto | undefined;
  activePeriod: string;
  setActivePeriod: Dispatch<SetStateAction<string>>;
  setIsShowBonusModal: Dispatch<SetStateAction<boolean>>;
  indexPlan: number;
  isResponsive: boolean;
  isShow: boolean;
  courseId: string;
  setPlanType: Dispatch<SetStateAction<ActivePlan | undefined>>;
  cancelSubscriptionDowngrade: (courseId: string, isCancelTrial?: boolean) => Promise<void>;
  toggleSubscription: (options: CourseOptionsForPayment, isCurrent: boolean) => void;
  // TODO change to a necessary type after the Dto will be updated
  mostEffectiveLabel?: {
    phrase?: string;
    color?: string;
  };
  goldPurchasePrice: number | undefined;
  goldInstallmentPrice: number | undefined;
};

const SubscriptionItemLifeTime: FC<PlanItemProps> = ({
  isResponsive,
  animationData,
  activePeriod,
  setActivePeriod,
  setIsShowBonusModal,
  className,
  mainColor,
  planType,
  setPlanType,
  currentPayment,
  setActiveIndex,
  termsCount,
  isShow,
  courseId,
  plan,
  toggleSubscription,
  goldInstallmentPrice,
  goldPurchasePrice,
}) => {
  const [options, setOptions] = useState<any>();
  const [isSelect, setIsSelect] = useState(false);
  const [term, setTerm] = useState<any>();

  const dispatch = useAppDispatch();
  const paymentOption = useAppSelector(selectors.courses.selectPaymentOption);
  const isPaymentPlan = paymentOption === 'paymentPlan';
  const title = plan ? levelMapping[plan.type] : levelMapping.bronze;
  // @ts-ignore
  const currentPriceLifetime = plan?.purchasePrice?.amount;
  // @ts-ignore
  const installmentPriceLifetime = plan?.installmentPrice?.amount;
  const capabilities = plan?.capabilities;
  const capabilitiesResponsive = plan?.capabilitiesResponsive;

  const subscribeType = plan?.type;
  const isGoldSubscribe = subscribeType === 'gold';

  const disabled = currentPayment?.level !== PaymentDtoLevelEnum.None;

  const isCurrentSubscribe = currentPayment?.level === subscribeType;

  const buttonText = 'Purchase';

  const onChoosePlan = (plan: Plan, term: TermEnum, subscribeType: SubscribeDtoTypeEnum, isSelect: boolean) => {
    const { cancellationPromotionTerms, oldAmount, amount } = plan || {};
    const { discount, discountType, duration, durationInMonths } = cancellationPromotionTerms || {};

    const options = {
      term,
      type: CourseDtoTypeEnum.Certificate,
      firstMonthDiscountId: plan?.firstMonthDiscountCouponId,
      courseId,
      subscribeType,
      amount,
      oldAmount,
      isUpdate: false,
      isDowngrade: false,
      couponCode: plan?.defaultCoupon?.promoCode,
      discount,
      discountType,
      duration,
      durationInMonth: durationInMonths,
      firstMonthDiscountCode: plan?.firstMonthDiscountCoupon?.promoCode,
    };

    setOptions(options);
    setTerm(term);
    setPlanType({ planType: subscribeType, term: term });
    setIsSelect(isSelect || false);
  };

  dispatch(actions.courses.setDisabledStatus(disabled));

  useEffect(() => {
    onChoosePlan(
      isPaymentPlan ? (plan?.installmentPrice as Plan) : (plan?.purchasePrice as Plan),
      TermEnum.LIFE_TIME,
      subscribeType,
      true,
    );
  }, [subscribeType, paymentOption]);

  useEffect(() => {
    if (isPaymentPlan) {
      setActiveIndex(0);
      setActivePeriod('gold');
    }
  }, [isPaymentPlan]);

  return (
    <div style={{ display: 'flex' }}>
      <Container className={className} type={plan?.type} isShow={isShow && isResponsive} isResponsive={isResponsive}>
        {isGoldSubscribe && !isResponsive && <MostEffectiveTable>EXAM PASS GUARANTEE</MostEffectiveTable>}

        {!isGoldSubscribe && <div style={{ height: '35px' }}></div>}

        <PlanInformation>
          <AnimatedContainer>
            <Lottie animationData={animationData} />
          </AnimatedContainer>

          <TitleWithLabel>
            <Title>{title}</Title>
          </TitleWithLabel>

          {isGoldSubscribe && <Table isResponsive={isResponsive}>Flash Sale: Save 30%</Table>}

          <CommonPriceContainer isResponsive={isResponsive}>
            <PriceSection
              onChoosePlan={() => {}}
              installmentPriceLifetime={installmentPriceLifetime}
              currentPaymentPeriod={currentPayment?.period}
              isCurrentSubscribe={isCurrentSubscribe}
              isResponsive={isResponsive}
              mainColor={mainColor}
              planType={planType}
              subscribeType={subscribeType}
              term={term}
              termsCount={termsCount}
              isSelect={isSelect}
              plan={plan}
              isGoldSubscribe={isGoldSubscribe}
              currentPriceLifetime={currentPriceLifetime}
            />
          </CommonPriceContainer>

          {
            <StyledButton
              disabled={disabled}
              onClick={() => toggleSubscription(options, false)}
              variant={'primary'}
              size="small">
              {buttonText}
            </StyledButton>
          }
        </PlanInformation>

        {isResponsive && (
          <PaymentOptionsSelector
            isResponsive={isResponsive}
            mainColor={mainColor}
            goldInstallmentPrice={goldInstallmentPrice}
            goldPurchasePrice={goldPurchasePrice}
            subscribeType={subscribeType}
          />
        )}

        {isResponsive && activePeriod === 'gold' && (
          <BonusSection isProduct={false} setIsShowBonusModal={setIsShowBonusModal} />
        )}

        {isResponsive && !isPaymentPlan && (
          <ResponsiveTabs
            isShow={isShow}
            mainColor={mainColor}
            activePeriod={!isPaymentPlan ? activePeriod : 'gold'}
            setActiveIndex={setActiveIndex}
            setActivePeriod={setActivePeriod}
          />
        )}

        {!isResponsive ? (
          <CapabilityList>
            {capabilities?.map((item, index) => (
              <CapabilityItem $include={item.include} key={index}>
                <IconContainer>
                  {item.include ? <CheckmarkIcon mainColor={mainColor} /> : <Cross size={24} />}
                </IconContainer>
              </CapabilityItem>
            ))}
          </CapabilityList>
        ) : (
          <ResponsiveCapability mainColor={mainColor} capabilitiesResponsive={capabilitiesResponsive} />
        )}
      </Container>
    </div>
  );
};

export default SubscriptionItemLifeTime;

const Container = styled.div<{ type: string; isShow: boolean; isResponsive: boolean }>`
  margin-top: ${({ isResponsive }) => (isResponsive ? '30px' : 'unset')};
  width: ${({ isShow }) => (!isShow ? '255px' : '100%')};
  display: ${({ isShow, isResponsive }) => (isShow ? 'flex' : !isResponsive ? 'flex' : 'none')};
  flex-direction: column;
  align-items: start;
  height: 100%;
  text-align: start;
  position: relative;
  padding: 0px 18px 31px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  color: ${({ theme: { colors } }) => colors.neutrals[1]};
  border-top: ${({ type }) => (type === 'gold' ? '35px' : '2px')} solid
    ${({ theme, type }) => (type !== 'gold' ? theme.colors.neutrals[9] : theme.colors.primary[1])};
  border-right: 4px solid ${({ theme, type }) => (type !== 'gold' ? theme.colors.neutrals[9] : theme.colors.primary[1])};
  border-bottom: 4px solid
    ${({ theme, type }) => (type !== 'gold' ? theme.colors.neutrals[9] : theme.colors.primary[1])};
  border-left: 4px solid ${({ theme, type }) => (type !== 'gold' ? theme.colors.neutrals[9] : theme.colors.primary[1])};
  border-radius: 16px;

  @media (max-width: 1180px) {
    border: 0;
    padding: 0px 0px 31px;
  }
`;

const CommonPriceContainer = styled.div<{ isResponsive: boolean }>`
  display: ${({ isResponsive }) => (isResponsive ? 'flex' : 'block')};
  gap: 7px;
  margin-top: 20px;
`;

const AnimatedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled(StyledText)`
  ${({ theme: { typography } }) => typography.title_4_bold_24};
  margin-bottom: 20px;
`;

const MostEffectiveTable = styled.p`
  z-index: 1;
  position: absolute;
  top: -30px;
  left: 15%;
  ${({ theme: { typography } }) => typography.body_large_bold_16}

  color: #FFFFFF;
`;

const Table = styled.p<{ isResponsive: boolean }>`
  text-align: center;
  background: ${({ isResponsive, theme: { colors } }) => (isResponsive ? colors.neutrals[9] : 'unset')};
  padding: ${({ isResponsive }) => (isResponsive ? '8px 12px' : 'unset')};
  border-radius: ${({ isResponsive }) => (isResponsive ? '12px' : 'unset')};
  ${({ theme: { typography } }) => typography.body_large_extrabold_16}
  color: ${({ theme: { colors } }) => colors.primary[1]};
  margin-bottom: 10px;
  /* position: absolute; */
  width: ${({ isResponsive }) => (isResponsive ? '100%' : 'unset')};
  top: ${({ isResponsive }) => (isResponsive ? '260px' : 'unset')};
  left: ${({ isResponsive }) => (isResponsive ? 'unset' : '16%')};
`;

const CapabilityList = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: start;
  margin-bottom: 24px;
`;

const PlanInformation = styled.div`
  /* position: sticky; */
  top: 0;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
  width: 100%;
  margin-bottom: 10px;
  padding-top: 15px;
`;

const CapabilityItem = styled.div<{ $include: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  color: ${({ theme: { colors }, $include }) => !$include && colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_large_semibold_16};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
`;

const TitleWithLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  padding: ${({ variant }) => (variant === 'secondary' ? '7px 32px' : '8px 32px')};
  margin-top: 16px;
  margin-bottom: 10px;

  @media (min-width: 1350px) {
    white-space: nowrap;
  }
`;
